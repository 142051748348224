// source: admpb/calendar.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.admpb.Calendar', null, global);
goog.exportSymbol('proto.admpb.ListCalendarRequest', null, global);
goog.exportSymbol('proto.admpb.ListCalendarResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.Calendar = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.Calendar, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.Calendar.displayName = 'proto.admpb.Calendar';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListCalendarRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ListCalendarRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListCalendarRequest.displayName = 'proto.admpb.ListCalendarRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListCalendarResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ListCalendarResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ListCalendarResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListCalendarResponse.displayName = 'proto.admpb.ListCalendarResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.Calendar.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.Calendar.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.Calendar} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.Calendar.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    trade: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    settle: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    dayOfTheWeek: jspb.Message.getFieldWithDefault(msg, 5, ""),
    holidayName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    isCurrent: jspb.Message.getBooleanFieldWithDefault(msg, 7, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.Calendar}
 */
proto.admpb.Calendar.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.Calendar;
  return proto.admpb.Calendar.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.Calendar} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.Calendar}
 */
proto.admpb.Calendar.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCalendarId(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setTrade(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSettle(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDayOfTheWeek(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setHolidayName(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsCurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.Calendar.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.Calendar.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.Calendar} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.Calendar.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTrade();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getSettle();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getDayOfTheWeek();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getHolidayName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getIsCurrent();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
};


/**
 * optional string calendar_id = 1;
 * @return {string}
 */
proto.admpb.Calendar.prototype.getCalendarId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setCalendarId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.admpb.Calendar.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.Calendar} returns this
*/
proto.admpb.Calendar.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.Calendar.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool trade = 3;
 * @return {boolean}
 */
proto.admpb.Calendar.prototype.getTrade = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setTrade = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional bool settle = 4;
 * @return {boolean}
 */
proto.admpb.Calendar.prototype.getSettle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setSettle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional string day_of_the_week = 5;
 * @return {string}
 */
proto.admpb.Calendar.prototype.getDayOfTheWeek = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setDayOfTheWeek = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string holiday_name = 6;
 * @return {string}
 */
proto.admpb.Calendar.prototype.getHolidayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setHolidayName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional bool is_current = 7;
 * @return {boolean}
 */
proto.admpb.Calendar.prototype.getIsCurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.Calendar} returns this
 */
proto.admpb.Calendar.prototype.setIsCurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListCalendarRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListCalendarRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListCalendarRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListCalendarRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    holidayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    iscurrent: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListCalendarRequest}
 */
proto.admpb.ListCalendarRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListCalendarRequest;
  return proto.admpb.ListCalendarRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListCalendarRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListCalendarRequest}
 */
proto.admpb.ListCalendarRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHolidayName(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIscurrent(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListCalendarRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListCalendarRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListCalendarRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListCalendarRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getHolidayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIscurrent();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional google.type.Date from_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.admpb.ListCalendarRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.ListCalendarRequest} returns this
*/
proto.admpb.ListCalendarRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.ListCalendarRequest} returns this
 */
proto.admpb.ListCalendarRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.ListCalendarRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date to_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.admpb.ListCalendarRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.ListCalendarRequest} returns this
*/
proto.admpb.ListCalendarRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.ListCalendarRequest} returns this
 */
proto.admpb.ListCalendarRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.ListCalendarRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string holiday_name = 3;
 * @return {string}
 */
proto.admpb.ListCalendarRequest.prototype.getHolidayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ListCalendarRequest} returns this
 */
proto.admpb.ListCalendarRequest.prototype.setHolidayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool IsCurrent = 4;
 * @return {boolean}
 */
proto.admpb.ListCalendarRequest.prototype.getIscurrent = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.admpb.ListCalendarRequest} returns this
 */
proto.admpb.ListCalendarRequest.prototype.setIscurrent = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ListCalendarResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListCalendarResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListCalendarResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListCalendarResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListCalendarResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calendarsList: jspb.Message.toObjectList(msg.getCalendarsList(),
    proto.admpb.Calendar.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListCalendarResponse}
 */
proto.admpb.ListCalendarResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListCalendarResponse;
  return proto.admpb.ListCalendarResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListCalendarResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListCalendarResponse}
 */
proto.admpb.ListCalendarResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.Calendar;
      reader.readMessage(value,proto.admpb.Calendar.deserializeBinaryFromReader);
      msg.addCalendars(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListCalendarResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListCalendarResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListCalendarResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListCalendarResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalendarsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.Calendar.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Calendar calendars = 1;
 * @return {!Array<!proto.admpb.Calendar>}
 */
proto.admpb.ListCalendarResponse.prototype.getCalendarsList = function() {
  return /** @type{!Array<!proto.admpb.Calendar>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.Calendar, 1));
};


/**
 * @param {!Array<!proto.admpb.Calendar>} value
 * @return {!proto.admpb.ListCalendarResponse} returns this
*/
proto.admpb.ListCalendarResponse.prototype.setCalendarsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.Calendar=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.Calendar}
 */
proto.admpb.ListCalendarResponse.prototype.addCalendars = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.Calendar, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ListCalendarResponse} returns this
 */
proto.admpb.ListCalendarResponse.prototype.clearCalendarsList = function() {
  return this.setCalendarsList([]);
};


goog.object.extend(exports, proto.admpb);
