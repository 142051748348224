/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListSecurityMasterRequest } from '../../../proto/assetpb/securitymaster_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from '../../../components/Notification/Notification';

/*Material UI Table Components*/
import { Box, TextField, IconButton, Tooltip } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

import { Save as SaveIcon } from '@material-ui/icons';

/*Moment JS*/
import moment from 'moment-timezone';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

const fileName =
  'SecurityMaster_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

export default function SecurityMasterTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const {
    securityMasterServiceClient: securityMaster,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [symbolValue, setSymbolValue] = React.useState('');
  const [cusipValue, setCusipValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }

    setCurrentPath(path);
  }, [params]);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListSecurityMasterRequest();
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);

    securityMaster.listSecurityMaster(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }
      const rows = res.toObject().securityMastersList.map((data) => ({
        securityId: data.securityId,
        originalCusip: data.originalCusip,
        symbol: data.symbol,
        cusip: data.cusip,
        symbolDescription: data.symbolDescription,
        marginable: data.marginable,
        securityType: data.securityType,
        secSubType: data.secSubType,
        assetType: data.assetType,
        whenIssued: data.whenIssued,
        leverageFactor: data.leverageFactor,
        createdAt: data.createdAt
          ? moment(new Date(data.createdAt.seconds * 1000)).format(
              'MM/DD/YYYY hh:mm'
            )
          : '--',
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Security Master');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'assetType',
      label: 'Asset Type',
    },
    {
      name: 'createdAt',
      label: 'Created At',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'leverageFactor',
      label: 'Leverage Factor',
    },
    {
      name: 'marginable',
      label: 'Marginable',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'secSubType',
      label: 'Sec Sub Type',
    },
    {
      name: 'securityType',
      label: 'Security Type',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'whenIssued',
      label: 'When Issued',
    },
  ];

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [5, 8, 2, 9, 4, 7, 6, 0, 10, 3, 1],
    selectableRowsHeader: false,
    selectableRows: 'none',
    serverSide: true,
    rowsPerPage: 10,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();

      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Security Master');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Security Master');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSymbol
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  type="symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Security Master'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
