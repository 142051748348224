// source: acatspb/outputposition.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.acatspb.ListOutputPositionResponse', null, global);
goog.exportSymbol('proto.acatspb.OutputPosition', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.OutputPosition = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.OutputPosition, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.OutputPosition.displayName = 'proto.acatspb.OutputPosition';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.ListOutputPositionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.acatspb.ListOutputPositionResponse.repeatedFields_, null);
};
goog.inherits(proto.acatspb.ListOutputPositionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.ListOutputPositionResponse.displayName = 'proto.acatspb.ListOutputPositionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.OutputPosition.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.OutputPosition.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.OutputPosition} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.OutputPosition.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subrecordType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    distributionSide: jspb.Message.getFieldWithDefault(msg, 3, ""),
    acatsControlNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetSequenceNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processingDate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    distributionParticipant: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    daysInStatus: jspb.Message.getFieldWithDefault(msg, 9, ""),
    registrationIndicator: jspb.Message.getFieldWithDefault(msg, 10, ""),
    settlementDate: (f = msg.getSettlementDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    settlementTiming: jspb.Message.getFieldWithDefault(msg, 12, ""),
    valueFreeIndicator: jspb.Message.getFieldWithDefault(msg, 13, ""),
    debitCreditIndicator: jspb.Message.getFieldWithDefault(msg, 14, ""),
    atAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tiAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 16, ""),
    atTransferType: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tiTransferType: jspb.Message.getFieldWithDefault(msg, 18, ""),
    atOriginalReceiverNumber: jspb.Message.getFieldWithDefault(msg, 19, ""),
    tiOriginalReceiverNumber: jspb.Message.getFieldWithDefault(msg, 20, ""),
    atOriginalDelivererNumber: jspb.Message.getFieldWithDefault(msg, 21, ""),
    tiOriginalDelivererNumber: jspb.Message.getFieldWithDefault(msg, 22, ""),
    originalReceiverCustomerAccountNumber: jspb.Message.getFieldWithDefault(msg, 23, ""),
    originalReceiverCustomerAccountName: jspb.Message.getFieldWithDefault(msg, 24, ""),
    originalReceiverTaxIdPrimary: jspb.Message.getFieldWithDefault(msg, 25, ""),
    originalReceiverTaxIdSecondary: jspb.Message.getFieldWithDefault(msg, 26, ""),
    originalReceiverCustomerAccountType: jspb.Message.getFieldWithDefault(msg, 27, ""),
    giftDonationIndicator: jspb.Message.getFieldWithDefault(msg, 28, ""),
    originalReceiverOccBrokerNumber: jspb.Message.getFieldWithDefault(msg, 29, ""),
    originalReceiverCorrespondent: jspb.Message.getFieldWithDefault(msg, 30, ""),
    contraParticipantType: jspb.Message.getFieldWithDefault(msg, 31, ""),
    originalDelivererCustomerAccountNumber: jspb.Message.getFieldWithDefault(msg, 32, ""),
    transferTypeReject: jspb.Message.getFieldWithDefault(msg, 33, ""),
    associatedAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 34, ""),
    associatedSettlementDate: jspb.Message.getFieldWithDefault(msg, 35, ""),
    atTransactionReferenceId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    tiTransactionReferenceId: jspb.Message.getFieldWithDefault(msg, 37, ""),
    atAssetSequenceNumber: jspb.Message.getFieldWithDefault(msg, 38, ""),
    assetCategory: jspb.Message.getFieldWithDefault(msg, 39, ""),
    optionCategory: jspb.Message.getFieldWithDefault(msg, 40, ""),
    settlingLocation: jspb.Message.getFieldWithDefault(msg, 41, ""),
    settlingLocationReason: jspb.Message.getFieldWithDefault(msg, 42, ""),
    isinCountryCode: jspb.Message.getFieldWithDefault(msg, 43, ""),
    isinSecurityIssueId: jspb.Message.getFieldWithDefault(msg, 44, ""),
    isinSecurityCheckDigit: jspb.Message.getFieldWithDefault(msg, 45, ""),
    dtcSubIssueType: jspb.Message.getFieldWithDefault(msg, 46, ""),
    foreignSecurityTradeDate: jspb.Message.getFieldWithDefault(msg, 47, ""),
    assetDescription1: jspb.Message.getFieldWithDefault(msg, 48, ""),
    assetDescription2: jspb.Message.getFieldWithDefault(msg, 49, ""),
    assetQuantityRequestIndicator: jspb.Message.getFieldWithDefault(msg, 50, ""),
    assetRequestTransactionCode: jspb.Message.getFieldWithDefault(msg, 51, ""),
    assetQuantity: jspb.Message.getFieldWithDefault(msg, 52, ""),
    assetPercent: jspb.Message.getFieldWithDefault(msg, 53, ""),
    positionCode: jspb.Message.getFieldWithDefault(msg, 54, ""),
    isoCurrencyCode: jspb.Message.getFieldWithDefault(msg, 55, ""),
    assetAmount: jspb.Message.getFieldWithDefault(msg, 56, ""),
    bearerBond: jspb.Message.getFieldWithDefault(msg, 57, ""),
    cashMarginShortCode: jspb.Message.getFieldWithDefault(msg, 58, ""),
    whenIssuedIndicator: jspb.Message.getFieldWithDefault(msg, 59, ""),
    transferTypeReason: jspb.Message.getFieldWithDefault(msg, 60, ""),
    mbsSerialNote: jspb.Message.getFieldWithDefault(msg, 61, ""),
    assetComment1: jspb.Message.getFieldWithDefault(msg, 62, ""),
    assetComment2: jspb.Message.getFieldWithDefault(msg, 63, ""),
    comments1: jspb.Message.getFieldWithDefault(msg, 64, ""),
    comments2: jspb.Message.getFieldWithDefault(msg, 65, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.OutputPosition}
 */
proto.acatspb.OutputPosition.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.OutputPosition;
  return proto.acatspb.OutputPosition.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.OutputPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.OutputPosition}
 */
proto.acatspb.OutputPosition.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubrecordType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionSide(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcatsControlNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetSequenceNumber(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setProcessingDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionParticipant(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysInStatus(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationIndicator(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettlementDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlementTiming(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setValueFreeIndicator(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setDebitCreditIndicator(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAcatsControlNumber(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiAcatsControlNumber(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransferType(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransferType(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalReceiverNumber(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalReceiverNumber(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalDelivererNumber(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalDelivererNumber(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountNumber(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountName(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdPrimary(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdSecondary(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountType(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftDonationIndicator(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverOccBrokerNumber(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCorrespondent(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraParticipantType(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalDelivererCustomerAccountNumber(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeReject(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedAcatsControlNumber(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedSettlementDate(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransactionReferenceId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransactionReferenceId(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAssetSequenceNumber(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetCategory(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCategory(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocation(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocationReason(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinCountryCode(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityIssueId(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityCheckDigit(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtcSubIssueType(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setForeignSecurityTradeDate(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetDescription1(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetDescription2(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantityRequestIndicator(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetRequestTransactionCode(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantity(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPercent(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionCode(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCurrencyCode(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetAmount(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setBearerBond(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashMarginShortCode(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhenIssuedIndicator(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeReason(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setMbsSerialNote(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetComment1(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetComment2(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments1(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments2(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.OutputPosition.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.OutputPosition.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.OutputPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.OutputPosition.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubrecordType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDistributionSide();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProcessingDate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDistributionParticipant();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getDaysInStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getRegistrationIndicator();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSettlementDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettlementTiming();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getValueFreeIndicator();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getDebitCreditIndicator();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAtAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTiAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAtTransferType();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTiTransferType();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAtOriginalReceiverNumber();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTiOriginalReceiverNumber();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAtOriginalDelivererNumber();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTiOriginalDelivererNumber();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountName();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdPrimary();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdSecondary();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountType();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getGiftDonationIndicator();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getOriginalReceiverOccBrokerNumber();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getOriginalReceiverCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getContraParticipantType();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getOriginalDelivererCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getTransferTypeReject();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getAssociatedAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getAssociatedSettlementDate();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getAtTransactionReferenceId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getTiTransactionReferenceId();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getAtAssetSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getAssetCategory();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getOptionCategory();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getSettlingLocation();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getSettlingLocationReason();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getIsinCountryCode();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getIsinSecurityIssueId();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getIsinSecurityCheckDigit();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getDtcSubIssueType();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getForeignSecurityTradeDate();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getAssetDescription1();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getAssetDescription2();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getAssetQuantityRequestIndicator();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getAssetRequestTransactionCode();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getAssetQuantity();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getAssetPercent();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getPositionCode();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getIsoCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getAssetAmount();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getBearerBond();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getCashMarginShortCode();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getWhenIssuedIndicator();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getTransferTypeReason();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getMbsSerialNote();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getAssetComment1();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getAssetComment2();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getComments1();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getComments2();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
};


/**
 * optional string record_type = 1;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getRecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setRecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subrecord_type = 2;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getSubrecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setSubrecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string distribution_side = 3;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getDistributionSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setDistributionSide = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string acats_control_number = 4;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string asset_sequence_number = 5;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string processing_date = 6;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getProcessingDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setProcessingDate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string distribution_participant = 7;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getDistributionParticipant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setDistributionParticipant = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string days_in_status = 9;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getDaysInStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setDaysInStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string registration_indicator = 10;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getRegistrationIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setRegistrationIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.type.Date settlement_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.acatspb.OutputPosition.prototype.getSettlementDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.acatspb.OutputPosition} returns this
*/
proto.acatspb.OutputPosition.prototype.setSettlementDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.clearSettlementDate = function() {
  return this.setSettlementDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.OutputPosition.prototype.hasSettlementDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string settlement_timing = 12;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getSettlementTiming = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setSettlementTiming = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string value_free_indicator = 13;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getValueFreeIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setValueFreeIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string debit_credit_indicator = 14;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getDebitCreditIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setDebitCreditIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string at_acats_control_number = 15;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ti_acats_control_number = 16;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTiAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTiAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string at_transfer_type = 17;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string ti_transfer_type = 18;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTiTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTiTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string at_original_receiver_number = 19;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtOriginalReceiverNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtOriginalReceiverNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ti_original_receiver_number = 20;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTiOriginalReceiverNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTiOriginalReceiverNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string at_original_deliverer_number = 21;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtOriginalDelivererNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtOriginalDelivererNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string ti_original_deliverer_number = 22;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTiOriginalDelivererNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTiOriginalDelivererNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string original_receiver_customer_account_number = 23;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string original_receiver_customer_account_name = 24;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverCustomerAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverCustomerAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string original_receiver_tax_id_primary = 25;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverTaxIdPrimary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverTaxIdPrimary = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string original_receiver_tax_id_secondary = 26;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverTaxIdSecondary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverTaxIdSecondary = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string original_receiver_customer_account_type = 27;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverCustomerAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverCustomerAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string gift_donation_indicator = 28;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getGiftDonationIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setGiftDonationIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string original_receiver_occ_broker_number = 29;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverOccBrokerNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverOccBrokerNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string original_receiver_correspondent = 30;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalReceiverCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalReceiverCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string contra_participant_type = 31;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getContraParticipantType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setContraParticipantType = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string original_deliverer_customer_account_number = 32;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOriginalDelivererCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOriginalDelivererCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string transfer_type_reject = 33;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTransferTypeReject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTransferTypeReject = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string associated_acats_control_number = 34;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssociatedAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssociatedAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string associated_settlement_date = 35;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssociatedSettlementDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssociatedSettlementDate = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string at_transaction_reference_id = 36;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtTransactionReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtTransactionReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string ti_transaction_reference_id = 37;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTiTransactionReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTiTransactionReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string at_asset_sequence_number = 38;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAtAssetSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAtAssetSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string asset_category = 39;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string option_category = 40;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getOptionCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setOptionCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string settling_location = 41;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getSettlingLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setSettlingLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string settling_location_reason = 42;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getSettlingLocationReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setSettlingLocationReason = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string isin_country_code = 43;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getIsinCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setIsinCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string isin_security_issue_id = 44;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getIsinSecurityIssueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setIsinSecurityIssueId = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string isin_security_check_digit = 45;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getIsinSecurityCheckDigit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setIsinSecurityCheckDigit = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string dtc_sub_issue_type = 46;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getDtcSubIssueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setDtcSubIssueType = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string foreign_security_trade_date = 47;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getForeignSecurityTradeDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setForeignSecurityTradeDate = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string asset_description1 = 48;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string asset_description2 = 49;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string asset_quantity_request_indicator = 50;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetQuantityRequestIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetQuantityRequestIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string asset_request_transaction_code = 51;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetRequestTransactionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetRequestTransactionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string asset_quantity = 52;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string asset_percent = 53;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string position_code = 54;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getPositionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setPositionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string iso_currency_code = 55;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getIsoCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setIsoCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string asset_amount = 56;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string bearer_bond = 57;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getBearerBond = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setBearerBond = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string cash_margin_short_code = 58;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getCashMarginShortCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setCashMarginShortCode = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string when_issued_indicator = 59;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getWhenIssuedIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setWhenIssuedIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string transfer_type_reason = 60;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getTransferTypeReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setTransferTypeReason = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string mbs_serial_note = 61;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getMbsSerialNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setMbsSerialNote = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string asset_comment1 = 62;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetComment1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetComment1 = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string asset_comment2 = 63;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getAssetComment2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setAssetComment2 = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string comments1 = 64;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getComments1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setComments1 = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string comments2 = 65;
 * @return {string}
 */
proto.acatspb.OutputPosition.prototype.getComments2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.OutputPosition} returns this
 */
proto.acatspb.OutputPosition.prototype.setComments2 = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.acatspb.ListOutputPositionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.ListOutputPositionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.ListOutputPositionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.ListOutputPositionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListOutputPositionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    outputPositionList: jspb.Message.toObjectList(msg.getOutputPositionList(),
    proto.acatspb.OutputPosition.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.ListOutputPositionResponse}
 */
proto.acatspb.ListOutputPositionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.ListOutputPositionResponse;
  return proto.acatspb.ListOutputPositionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.ListOutputPositionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.ListOutputPositionResponse}
 */
proto.acatspb.ListOutputPositionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.acatspb.OutputPosition;
      reader.readMessage(value,proto.acatspb.OutputPosition.deserializeBinaryFromReader);
      msg.addOutputPosition(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.ListOutputPositionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.ListOutputPositionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.ListOutputPositionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListOutputPositionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOutputPositionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.acatspb.OutputPosition.serializeBinaryToWriter
    );
  }
};


/**
 * repeated OutputPosition output_position = 1;
 * @return {!Array<!proto.acatspb.OutputPosition>}
 */
proto.acatspb.ListOutputPositionResponse.prototype.getOutputPositionList = function() {
  return /** @type{!Array<!proto.acatspb.OutputPosition>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.acatspb.OutputPosition, 1));
};


/**
 * @param {!Array<!proto.acatspb.OutputPosition>} value
 * @return {!proto.acatspb.ListOutputPositionResponse} returns this
*/
proto.acatspb.ListOutputPositionResponse.prototype.setOutputPositionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.acatspb.OutputPosition=} opt_value
 * @param {number=} opt_index
 * @return {!proto.acatspb.OutputPosition}
 */
proto.acatspb.ListOutputPositionResponse.prototype.addOutputPosition = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.acatspb.OutputPosition, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.acatspb.ListOutputPositionResponse} returns this
 */
proto.acatspb.ListOutputPositionResponse.prototype.clearOutputPositionList = function() {
  return this.setOutputPositionList([]);
};


goog.object.extend(exports, proto.acatspb);
