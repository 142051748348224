// source: accountpb/generalledger.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.accountpb.CreateGeneralLedgerResponse', null, global);
goog.exportSymbol('proto.accountpb.DeleteGeneralLedgerRequest', null, global);
goog.exportSymbol('proto.accountpb.DeleteGeneralLedgerResponse', null, global);
goog.exportSymbol('proto.accountpb.GeneralLedger', null, global);
goog.exportSymbol('proto.accountpb.ListGeneralLedgerResponse', null, global);
goog.exportSymbol('proto.accountpb.ReadGeneralLedgerRequest', null, global);
goog.exportSymbol('proto.accountpb.ReadGeneralLedgerResponse', null, global);
goog.exportSymbol('proto.accountpb.UpdateGeneralLedgerResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.GeneralLedger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.GeneralLedger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.GeneralLedger.displayName = 'proto.accountpb.GeneralLedger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.CreateGeneralLedgerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.CreateGeneralLedgerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.CreateGeneralLedgerResponse.displayName = 'proto.accountpb.CreateGeneralLedgerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.UpdateGeneralLedgerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.UpdateGeneralLedgerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.UpdateGeneralLedgerResponse.displayName = 'proto.accountpb.UpdateGeneralLedgerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadGeneralLedgerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadGeneralLedgerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadGeneralLedgerRequest.displayName = 'proto.accountpb.ReadGeneralLedgerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ReadGeneralLedgerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.ReadGeneralLedgerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ReadGeneralLedgerResponse.displayName = 'proto.accountpb.ReadGeneralLedgerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteGeneralLedgerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteGeneralLedgerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteGeneralLedgerResponse.displayName = 'proto.accountpb.DeleteGeneralLedgerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.DeleteGeneralLedgerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.accountpb.DeleteGeneralLedgerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.DeleteGeneralLedgerRequest.displayName = 'proto.accountpb.DeleteGeneralLedgerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.accountpb.ListGeneralLedgerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.accountpb.ListGeneralLedgerResponse.repeatedFields_, null);
};
goog.inherits(proto.accountpb.ListGeneralLedgerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.accountpb.ListGeneralLedgerResponse.displayName = 'proto.accountpb.ListGeneralLedgerResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.GeneralLedger.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.GeneralLedger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.GeneralLedger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GeneralLedger.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    glType: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 6, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 7, ""),
    status: jspb.Message.getFieldWithDefault(msg, 8, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    createdBy: jspb.Message.getFieldWithDefault(msg, 10, ""),
    referenceNo: jspb.Message.getFieldWithDefault(msg, 11, ""),
    forCorrespondent: jspb.Message.getFieldWithDefault(msg, 12, ""),
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 13, ""),
    purpose: jspb.Message.getFieldWithDefault(msg, 14, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.GeneralLedger}
 */
proto.accountpb.GeneralLedger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.GeneralLedger;
  return proto.accountpb.GeneralLedger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.GeneralLedger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.GeneralLedger}
 */
proto.accountpb.GeneralLedger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGlType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 9:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setReferenceNo(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setForCorrespondent(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPurpose(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.GeneralLedger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.GeneralLedger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.GeneralLedger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.GeneralLedger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGlType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReferenceNo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getForCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getBaseCurrency();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPurpose();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string gl_type = 5;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getGlType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setGlType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_designator = 6;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string broker = 7;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string status = 8;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 9;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.accountpb.GeneralLedger.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 9));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.accountpb.GeneralLedger} returns this
*/
proto.accountpb.GeneralLedger.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.GeneralLedger.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string created_by = 10;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string reference_no = 11;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getReferenceNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setReferenceNo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string for_correspondent = 12;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getForCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setForCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string base_currency = 13;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string purpose = 14;
 * @return {string}
 */
proto.accountpb.GeneralLedger.prototype.getPurpose = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.GeneralLedger} returns this
 */
proto.accountpb.GeneralLedger.prototype.setPurpose = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.CreateGeneralLedgerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.CreateGeneralLedgerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.CreateGeneralLedgerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateGeneralLedgerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalLedger: (f = msg.getGeneralLedger()) && proto.accountpb.GeneralLedger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.CreateGeneralLedgerResponse}
 */
proto.accountpb.CreateGeneralLedgerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.CreateGeneralLedgerResponse;
  return proto.accountpb.CreateGeneralLedgerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.CreateGeneralLedgerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.CreateGeneralLedgerResponse}
 */
proto.accountpb.CreateGeneralLedgerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.GeneralLedger;
      reader.readMessage(value,proto.accountpb.GeneralLedger.deserializeBinaryFromReader);
      msg.setGeneralLedger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.CreateGeneralLedgerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.CreateGeneralLedgerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.CreateGeneralLedgerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.CreateGeneralLedgerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralLedger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.GeneralLedger.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralLedger general_ledger = 1;
 * @return {?proto.accountpb.GeneralLedger}
 */
proto.accountpb.CreateGeneralLedgerResponse.prototype.getGeneralLedger = function() {
  return /** @type{?proto.accountpb.GeneralLedger} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.GeneralLedger, 1));
};


/**
 * @param {?proto.accountpb.GeneralLedger|undefined} value
 * @return {!proto.accountpb.CreateGeneralLedgerResponse} returns this
*/
proto.accountpb.CreateGeneralLedgerResponse.prototype.setGeneralLedger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.CreateGeneralLedgerResponse} returns this
 */
proto.accountpb.CreateGeneralLedgerResponse.prototype.clearGeneralLedger = function() {
  return this.setGeneralLedger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.CreateGeneralLedgerResponse.prototype.hasGeneralLedger = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.UpdateGeneralLedgerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.UpdateGeneralLedgerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.UpdateGeneralLedgerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateGeneralLedgerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalLedger: (f = msg.getGeneralLedger()) && proto.accountpb.GeneralLedger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.UpdateGeneralLedgerResponse}
 */
proto.accountpb.UpdateGeneralLedgerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.UpdateGeneralLedgerResponse;
  return proto.accountpb.UpdateGeneralLedgerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.UpdateGeneralLedgerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.UpdateGeneralLedgerResponse}
 */
proto.accountpb.UpdateGeneralLedgerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.GeneralLedger;
      reader.readMessage(value,proto.accountpb.GeneralLedger.deserializeBinaryFromReader);
      msg.setGeneralLedger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.UpdateGeneralLedgerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.UpdateGeneralLedgerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.UpdateGeneralLedgerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.UpdateGeneralLedgerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralLedger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.GeneralLedger.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralLedger general_ledger = 1;
 * @return {?proto.accountpb.GeneralLedger}
 */
proto.accountpb.UpdateGeneralLedgerResponse.prototype.getGeneralLedger = function() {
  return /** @type{?proto.accountpb.GeneralLedger} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.GeneralLedger, 1));
};


/**
 * @param {?proto.accountpb.GeneralLedger|undefined} value
 * @return {!proto.accountpb.UpdateGeneralLedgerResponse} returns this
*/
proto.accountpb.UpdateGeneralLedgerResponse.prototype.setGeneralLedger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.UpdateGeneralLedgerResponse} returns this
 */
proto.accountpb.UpdateGeneralLedgerResponse.prototype.clearGeneralLedger = function() {
  return this.setGeneralLedger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.UpdateGeneralLedgerResponse.prototype.hasGeneralLedger = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadGeneralLedgerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadGeneralLedgerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadGeneralLedgerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadGeneralLedgerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadGeneralLedgerRequest}
 */
proto.accountpb.ReadGeneralLedgerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadGeneralLedgerRequest;
  return proto.accountpb.ReadGeneralLedgerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadGeneralLedgerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadGeneralLedgerRequest}
 */
proto.accountpb.ReadGeneralLedgerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadGeneralLedgerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadGeneralLedgerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadGeneralLedgerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadGeneralLedgerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.ReadGeneralLedgerRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.ReadGeneralLedgerRequest} returns this
 */
proto.accountpb.ReadGeneralLedgerRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ReadGeneralLedgerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ReadGeneralLedgerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ReadGeneralLedgerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadGeneralLedgerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalLedger: (f = msg.getGeneralLedger()) && proto.accountpb.GeneralLedger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ReadGeneralLedgerResponse}
 */
proto.accountpb.ReadGeneralLedgerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ReadGeneralLedgerResponse;
  return proto.accountpb.ReadGeneralLedgerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ReadGeneralLedgerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ReadGeneralLedgerResponse}
 */
proto.accountpb.ReadGeneralLedgerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.GeneralLedger;
      reader.readMessage(value,proto.accountpb.GeneralLedger.deserializeBinaryFromReader);
      msg.setGeneralLedger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ReadGeneralLedgerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ReadGeneralLedgerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ReadGeneralLedgerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ReadGeneralLedgerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralLedger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.GeneralLedger.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralLedger general_ledger = 1;
 * @return {?proto.accountpb.GeneralLedger}
 */
proto.accountpb.ReadGeneralLedgerResponse.prototype.getGeneralLedger = function() {
  return /** @type{?proto.accountpb.GeneralLedger} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.GeneralLedger, 1));
};


/**
 * @param {?proto.accountpb.GeneralLedger|undefined} value
 * @return {!proto.accountpb.ReadGeneralLedgerResponse} returns this
*/
proto.accountpb.ReadGeneralLedgerResponse.prototype.setGeneralLedger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.ReadGeneralLedgerResponse} returns this
 */
proto.accountpb.ReadGeneralLedgerResponse.prototype.clearGeneralLedger = function() {
  return this.setGeneralLedger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.ReadGeneralLedgerResponse.prototype.hasGeneralLedger = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteGeneralLedgerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteGeneralLedgerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteGeneralLedgerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteGeneralLedgerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalLedger: (f = msg.getGeneralLedger()) && proto.accountpb.GeneralLedger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteGeneralLedgerResponse}
 */
proto.accountpb.DeleteGeneralLedgerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteGeneralLedgerResponse;
  return proto.accountpb.DeleteGeneralLedgerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteGeneralLedgerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteGeneralLedgerResponse}
 */
proto.accountpb.DeleteGeneralLedgerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.GeneralLedger;
      reader.readMessage(value,proto.accountpb.GeneralLedger.deserializeBinaryFromReader);
      msg.setGeneralLedger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteGeneralLedgerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteGeneralLedgerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteGeneralLedgerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteGeneralLedgerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralLedger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.accountpb.GeneralLedger.serializeBinaryToWriter
    );
  }
};


/**
 * optional GeneralLedger general_ledger = 1;
 * @return {?proto.accountpb.GeneralLedger}
 */
proto.accountpb.DeleteGeneralLedgerResponse.prototype.getGeneralLedger = function() {
  return /** @type{?proto.accountpb.GeneralLedger} */ (
    jspb.Message.getWrapperField(this, proto.accountpb.GeneralLedger, 1));
};


/**
 * @param {?proto.accountpb.GeneralLedger|undefined} value
 * @return {!proto.accountpb.DeleteGeneralLedgerResponse} returns this
*/
proto.accountpb.DeleteGeneralLedgerResponse.prototype.setGeneralLedger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.accountpb.DeleteGeneralLedgerResponse} returns this
 */
proto.accountpb.DeleteGeneralLedgerResponse.prototype.clearGeneralLedger = function() {
  return this.setGeneralLedger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.accountpb.DeleteGeneralLedgerResponse.prototype.hasGeneralLedger = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.DeleteGeneralLedgerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.DeleteGeneralLedgerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.DeleteGeneralLedgerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteGeneralLedgerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.DeleteGeneralLedgerRequest}
 */
proto.accountpb.DeleteGeneralLedgerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.DeleteGeneralLedgerRequest;
  return proto.accountpb.DeleteGeneralLedgerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.DeleteGeneralLedgerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.DeleteGeneralLedgerRequest}
 */
proto.accountpb.DeleteGeneralLedgerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.DeleteGeneralLedgerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.DeleteGeneralLedgerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.DeleteGeneralLedgerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.DeleteGeneralLedgerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.accountpb.DeleteGeneralLedgerRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.accountpb.DeleteGeneralLedgerRequest} returns this
 */
proto.accountpb.DeleteGeneralLedgerRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.accountpb.ListGeneralLedgerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.accountpb.ListGeneralLedgerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.accountpb.ListGeneralLedgerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.accountpb.ListGeneralLedgerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListGeneralLedgerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    generalLedgersList: jspb.Message.toObjectList(msg.getGeneralLedgersList(),
    proto.accountpb.GeneralLedger.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.accountpb.ListGeneralLedgerResponse}
 */
proto.accountpb.ListGeneralLedgerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.accountpb.ListGeneralLedgerResponse;
  return proto.accountpb.ListGeneralLedgerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.accountpb.ListGeneralLedgerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.accountpb.ListGeneralLedgerResponse}
 */
proto.accountpb.ListGeneralLedgerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.accountpb.GeneralLedger;
      reader.readMessage(value,proto.accountpb.GeneralLedger.deserializeBinaryFromReader);
      msg.addGeneralLedgers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.accountpb.ListGeneralLedgerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.accountpb.ListGeneralLedgerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.accountpb.ListGeneralLedgerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.accountpb.ListGeneralLedgerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getGeneralLedgersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.accountpb.GeneralLedger.serializeBinaryToWriter
    );
  }
};


/**
 * repeated GeneralLedger general_ledgers = 1;
 * @return {!Array<!proto.accountpb.GeneralLedger>}
 */
proto.accountpb.ListGeneralLedgerResponse.prototype.getGeneralLedgersList = function() {
  return /** @type{!Array<!proto.accountpb.GeneralLedger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.accountpb.GeneralLedger, 1));
};


/**
 * @param {!Array<!proto.accountpb.GeneralLedger>} value
 * @return {!proto.accountpb.ListGeneralLedgerResponse} returns this
*/
proto.accountpb.ListGeneralLedgerResponse.prototype.setGeneralLedgersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.accountpb.GeneralLedger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.accountpb.GeneralLedger}
 */
proto.accountpb.ListGeneralLedgerResponse.prototype.addGeneralLedgers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.accountpb.GeneralLedger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.accountpb.ListGeneralLedgerResponse} returns this
 */
proto.accountpb.ListGeneralLedgerResponse.prototype.clearGeneralLedgersList = function() {
  return this.setGeneralLedgersList([]);
};


goog.object.extend(exports, proto.accountpb);
