/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListPositionRequest } from '../../../proto/reportpb/position_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { Pagination } from 'proto/utilspb/pagination_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
  Button,
} from '@material-ui/core';

/*Icons*/
import { Visibility as ViewIcon, Close as CloseIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment/moment.js';
import { formatPbDate, formatCurrency, formatDollar } from 'lib/fmt';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import SaveIcon from '@material-ui/icons/Save';
import UpdateIcon from '@material-ui/icons/Update';

/*Custom components*/
import CsvDownload from '../../../components/Table/CsvDownload';
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';
import PositionDetailsTable from './PositionDetailsTable';
import ServerSideTableBodyFooter from '../../../components/Table/ServerSideTableBodyFooter';
import PositionModal from './PositionModal';
import SelectCurrency from 'components/AutoComplete/Currency';
import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function PositionTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    listServiceClient: position,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: 50,
    pageNo: 0,
    reload: false,
    sortName: '',
    sortDirection: '',
  });

  const [footerData, setFooterData] = useState({});
  const [filterList, setFilterList] = useState({});

  const search = async (dontNotifySuccess) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
    };
    paginationCopy.pageNo = dontNotifySuccess ? paginationCopy.pageNo : 0;

    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNumberValue
        ? '&masterAccountNo=' + masterAccountNumberValue
        : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (baseCurrencyValue ? '&baseCurrency=' + baseCurrencyValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let paging = new Pagination();
    paging.setPageNo(paginationCopy.pageNo);
    paging.setPageSize(paginationCopy.rowsPerPage);
    paging.setSortName(paginationCopy.sortName);
    paging.setSortDirection(paginationCopy.sortDirection);

    try {
      const allData = await getData(paging, false);
      paginationCopy.count = allData.summary.totalRows;
      setPagination(paginationCopy);
      setFooterData({
        tdLongQty: allData.summary.tdLongQty,
        tdShortQty: allData.summary.tdShortQty,
        sdLongQty: allData.summary.sdLongQty,
        sdShortQty: allData.summary.sdShortQty,
        realTdQty: allData.summary.realTdQty,
        realSdQty: allData.summary.realSdQty,
        closingPrice: formatDollar(allData.summary.closingPrice, ''),
        tdLongMarketValue: formatDollar(allData.summary.tdLongMarketValue, ''),
        tdShortMarketValue: formatDollar(
          allData.summary.tdShortMarketValue,
          ''
        ),
        sdLongMarketValue: formatDollar(allData.summary.sdLongMarketValue, ''),
        sdShortMarketValue: formatDollar(
          allData.summary.sdShortMarketValue,
          ''
        ),
        tdCostBasis: formatDollar(allData.summary.tdCostBasis, ''),
        realTdValue: formatDollar(allData.summary.realTdValue, ''),
        realSdValue: formatDollar(allData.summary.realSdValue, ''),
      });

      const rows = allData.list;

      setRows(rows);
      if (!dontNotifySuccess) {
        notifyInfo(allData.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }

    setLoading(false);
  };

  const getData = async (paging) => {
    let list = new ListPositionRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setMasterAccountNo(masterAccountNumberValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);
    if (paging) {
      list.setPagination(paging);
    }
    if (baseCurrencyValue) {
      list.setCurrenciesList([baseCurrencyValue]);
    }

    return new Promise((resolve, reject) => {
      position.listPosition(list, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          const d = res.toObject();

          resolve({
            summary: d.summary,
            list: d.positionsList.map((data) => ({
              positionId: data.positionId,
              correspondent: data.correspondent,
              accountNo: data.accountNo,
              masterAccountNo: data.masterAccountNo,
              accountName: data.accountName,
              accountDesignator: data.accountDesignator,
              broker: data.broker,
              type: data.type,
              systemDate: formatPbDate(data.systemDate),
              originalCusip: data.originalCusip,
              symbol: data.symbol,
              cusip: data.cusip,
              symbolDescription: data.symbolDescription,
              assetType: data.assetType,
              closingPrice: formatCurrency(data.closingPrice, ''),
              tdShortQty: data.tdShortQty,
              tdShortMarketValue: formatCurrency(data.tdShortMarketValue, ''),
              tdLongQty: data.tdLongQty,
              tdLongMarketValue: formatCurrency(data.tdLongMarketValue, ''),
              sdShortQty: data.sdShortQty,
              sdShortMarketValue: formatCurrency(data.sdShortMarketValue, ''),
              sdLongQty: data.sdLongQty,
              sdLongMarketValue: formatCurrency(data.sdLongMarketValue, ''),
              tdCostBasis: formatCurrency(data.tdCostBasis, ''),
              contraAccountDesignator: data.contraAccountDesignator,
              realSdQty: data.realSdQty,
              realSdValue: formatCurrency(data.realSdValue, ''),
              realTdQty: data.realTdQty,
              realTdValue: formatCurrency(data.realTdValue, ''),
              currency: data.currency,
            })),
          });
        }
      });
    });
  };

  const handleOpenMovePositionModal = async (selected) => {
    let selectedRows = [];
    selected.forEach(function(row) {
      selectedRows.push(rows[row.dataIndex]);
    });

    setSelectedData(selectedRows);
    setOpenMoveModal(true);
  };

  const handleCloseMovePositionModal = (isExecuteSearch) => {
    if (isExecuteSearch) {
      setRowsSelected([]);
      search(true);
    }

    setOpenMoveModal(false);
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Position');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [openMoveModal, setOpenMoveModal] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(false);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <Tooltip title="View Details" arrow>
              <IconButton
                aria-label="details"
                onClick={() => {
                  handleOpen(
                    rows[dataIndex].accountNo,
                    rows[dataIndex].originalCusip
                  );
                }}
              >
                <ViewIcon />
              </IconButton>
            </Tooltip>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'assetType',
      label: 'Asset Type',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'closingPrice',
      label: 'Closing Price',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'contraAccountDesignator',
      label: 'Contra Account Designator',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'currency',
      label: 'Currency',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'sdLongMarketValue',
      label: 'SD Long Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdLongQty',
      label: 'SD Long Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdShortMarketValue',
      label: 'SD Short Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'sdShortQty',
      label: 'SD Short Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'tdCostBasis',
      label: 'TD Cost Basis',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdLongMarketValue',
      label: 'TD Long Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdLongQty',
      label: 'TD Long Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdShortMarketValue',
      label: 'TD Short Market Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'tdShortQty',
      label: 'TD Short Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'realTdQty',
      label: 'Real TD Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'realTdValue',
      label: 'Real TD Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'realSdQty',
      label: 'Real SD Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'realSdValue',
      label: 'Real SD Value',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
  ];

  const fileName =
    'Position_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    serverSide: true,
    count: pagination.count,
    page: pagination.pageNo,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000, 15000, 30000, 100000],
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      9,
      8,
      3,
      2,
      11,
      1,
      5,
      24,
      18,
      16,
      10,
      17,
      4,
      6,
      23,
      22,
      21,
      20,
      15,
      14,
      13,
      12,
      19,
      7,
      25,
      26,
      27,
      28,
    ],
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    rowsPerPage: pagination.rowsPerPage,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      setOpenCsvDownload(true);
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Position');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Position');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;

      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage':
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
          });
          break;
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filter = {};
            tableState.filterList.forEach((item, index) => {
              if (item.length) {
                filter[columns[index].name] = item[0];
              }
            });
            setFilterList(filter);
          }
          break;
        default:
      }
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <ServerSideTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          data={footerData}
          additionalCell={true}
        ></ServerSideTableBodyFooter>
      );
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Process" arrow>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="process"
                  size="small"
                  startIcon={<UpdateIcon />}
                  onClick={() => {
                    handleOpenMovePositionModal(selectedRows.data);
                  }}
                >
                  Move Position
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setRowsSelected(rowsSelected);
    },
    rowsSelected: rowsSelected,
    isRowSelectable: (dataIndex) =>
      !(
        rows[dataIndex].tdShortQty === '0' && rows[dataIndex].tdLongQty === '0'
      ),
  };

  const profile = useProfile();
  const [openCsvDownload, setOpenCsvDownload] = React.useState(false);
  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [
    masterAccountNumberValue,
    setMasterAccountNumberValue,
  ] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [typeValue, setTypeValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [cusipValue, setCusipValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [baseCurrencyValue, setBaseCurrencyValue] = React.useState('');

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      accountName: query.get('accountName'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
      systemDate: query.get('systemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNumberValue(data.masterAccountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    if (pagination.reload) {
      search(true);
    }
    // eslint-disable-next-line
  }, [pagination.pageNo,
    pagination.rowsPerPage,
    pagination.reload,
    pagination.sortName,
    pagination.sortDirection,
  ]);

  const [selectedAccountNo, setSelectedAccountNo] = React.useState('');
  const [selectedOriginalCusip, setSelectedOriginalCusip] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (accountNo, originalCusip) => {
    setSelectedAccountNo(accountNo);
    setSelectedOriginalCusip(originalCusip);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return;
        }
      });

      return ok;
    });

    return filtered;
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={false}
                  type="correspondent"
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountNo"
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountName"
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  showAccountName={false}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="masterAccountNo"
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNumberValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setMasterAccountNumberValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="accountDesignator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="broker">
                  Broker
                </InputLabel>
                <Select
                  required
                  labelId="broker"
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink id="type">
                  Type
                </InputLabel>
                <Select
                  required
                  labelId="type"
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSymbol
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  type="symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Cusip"
                  type="text"
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ maxWidth: '10%' }}>
                <SelectCurrency
                  freeSolo={false}
                  type="baseCurrency"
                  name="baseCurrency"
                  label="Base Currency"
                  value={baseCurrencyValue}
                  onChange={(e) => {
                    setBaseCurrencyValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {openCsvDownload && (
          <CsvDownload
            getData={getData}
            filename={fileName}
            dataKey="list"
            breaks={false}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Position'}
            data={filterRows()}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <PositionDetailsTable
                accountno={selectedAccountNo}
                originalcusip={selectedOriginalCusip}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
      {openMoveModal && (
        <PositionModal
          onClose={handleCloseMovePositionModal}
          open={openMoveModal}
          value={selectedData}
        ></PositionModal>
      )}
    </div>
  );
}
