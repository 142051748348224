// source: reportpb/accountmanagement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.AccountManagement', null, global);
goog.exportSymbol('proto.reportpb.AccountManagementBalanceDetail', null, global);
goog.exportSymbol('proto.reportpb.AccountManagementMarginReqDetail', null, global);
goog.exportSymbol('proto.reportpb.AccountManagementPositionDetail', null, global);
goog.exportSymbol('proto.reportpb.AccountManagementSegregationDetail', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementBalanceDetailRequest', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementBalanceDetailResponse', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementMarginReqDetailRequest', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementMarginReqDetailResponse', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementPositionDetailRequest', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementPositionDetailResponse', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementRequest', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementResponse', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementSegregationDetailRequest', null, global);
goog.exportSymbol('proto.reportpb.ListAccountManagementSegregationDetailResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountManagement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountManagement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountManagement.displayName = 'proto.reportpb.AccountManagement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListAccountManagementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementRequest.displayName = 'proto.reportpb.ListAccountManagementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListAccountManagementResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListAccountManagementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementResponse.displayName = 'proto.reportpb.ListAccountManagementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountManagementBalanceDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountManagementBalanceDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountManagementBalanceDetail.displayName = 'proto.reportpb.AccountManagementBalanceDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListAccountManagementBalanceDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementBalanceDetailRequest.displayName = 'proto.reportpb.ListAccountManagementBalanceDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListAccountManagementBalanceDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListAccountManagementBalanceDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementBalanceDetailResponse.displayName = 'proto.reportpb.ListAccountManagementBalanceDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountManagementPositionDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountManagementPositionDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountManagementPositionDetail.displayName = 'proto.reportpb.AccountManagementPositionDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementPositionDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListAccountManagementPositionDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementPositionDetailRequest.displayName = 'proto.reportpb.ListAccountManagementPositionDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementPositionDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListAccountManagementPositionDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListAccountManagementPositionDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementPositionDetailResponse.displayName = 'proto.reportpb.ListAccountManagementPositionDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountManagementMarginReqDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountManagementMarginReqDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountManagementMarginReqDetail.displayName = 'proto.reportpb.AccountManagementMarginReqDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListAccountManagementMarginReqDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementMarginReqDetailRequest.displayName = 'proto.reportpb.ListAccountManagementMarginReqDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListAccountManagementMarginReqDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListAccountManagementMarginReqDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementMarginReqDetailResponse.displayName = 'proto.reportpb.ListAccountManagementMarginReqDetailResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountManagementSegregationDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountManagementSegregationDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountManagementSegregationDetail.displayName = 'proto.reportpb.AccountManagementSegregationDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListAccountManagementSegregationDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementSegregationDetailRequest.displayName = 'proto.reportpb.ListAccountManagementSegregationDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListAccountManagementSegregationDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListAccountManagementSegregationDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListAccountManagementSegregationDetailResponse.displayName = 'proto.reportpb.ListAccountManagementSegregationDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountManagement.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountManagement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountManagement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagement.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    status: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountManagement}
 */
proto.reportpb.AccountManagement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountManagement;
  return proto.reportpb.AccountManagement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountManagement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountManagement}
 */
proto.reportpb.AccountManagement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountManagement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountManagement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountManagement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_designator = 7;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string status = 9;
 * @return {string}
 */
proto.reportpb.AccountManagement.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagement} returns this
 */
proto.reportpb.AccountManagement.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementRequest}
 */
proto.reportpb.ListAccountManagementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementRequest;
  return proto.reportpb.ListAccountManagementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementRequest}
 */
proto.reportpb.ListAccountManagementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
*/
proto.reportpb.ListAccountManagementRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListAccountManagementRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_designator = 7;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.reportpb.ListAccountManagementRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementRequest} returns this
 */
proto.reportpb.ListAccountManagementRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListAccountManagementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountManagementsList: jspb.Message.toObjectList(msg.getAccountManagementsList(),
    proto.reportpb.AccountManagement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementResponse}
 */
proto.reportpb.ListAccountManagementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementResponse;
  return proto.reportpb.ListAccountManagementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementResponse}
 */
proto.reportpb.ListAccountManagementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.AccountManagement;
      reader.readMessage(value,proto.reportpb.AccountManagement.deserializeBinaryFromReader);
      msg.addAccountManagements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountManagementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.AccountManagement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountManagement account_managements = 1;
 * @return {!Array<!proto.reportpb.AccountManagement>}
 */
proto.reportpb.ListAccountManagementResponse.prototype.getAccountManagementsList = function() {
  return /** @type{!Array<!proto.reportpb.AccountManagement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountManagement, 1));
};


/**
 * @param {!Array<!proto.reportpb.AccountManagement>} value
 * @return {!proto.reportpb.ListAccountManagementResponse} returns this
*/
proto.reportpb.ListAccountManagementResponse.prototype.setAccountManagementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.AccountManagement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountManagement}
 */
proto.reportpb.ListAccountManagementResponse.prototype.addAccountManagements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.AccountManagement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListAccountManagementResponse} returns this
 */
proto.reportpb.ListAccountManagementResponse.prototype.clearAccountManagementsList = function() {
  return this.setAccountManagementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountManagementBalanceDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountManagementBalanceDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementBalanceDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tdCashBalance: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tdEquity: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sdCashBalance: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    sdEquity: jspb.Message.getFieldWithDefault(msg, 11, ""),
    sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 12, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountManagementBalanceDetail}
 */
proto.reportpb.AccountManagementBalanceDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountManagementBalanceDetail;
  return proto.reportpb.AccountManagementBalanceDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountManagementBalanceDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountManagementBalanceDetail}
 */
proto.reportpb.AccountManagementBalanceDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountManagementBalanceDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountManagementBalanceDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementBalanceDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string td_cash_balance = 3;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string td_long_market_value = 4;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string td_short_market_value = 5;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string td_equity = 6;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string td_adjusted_balance = 7;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sd_cash_balance = 8;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sd_long_market_value = 9;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string sd_short_market_value = 10;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string sd_equity = 11;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string sd_adjusted_balance = 12;
 * @return {string}
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementBalanceDetail} returns this
 */
proto.reportpb.AccountManagementBalanceDetail.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementBalanceDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailRequest}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementBalanceDetailRequest;
  return proto.reportpb.ListAccountManagementBalanceDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailRequest}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementBalanceDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailRequest} returns this
*/
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementBalanceDetailRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementBalanceDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    balanceDetailsList: jspb.Message.toObjectList(msg.getBalanceDetailsList(),
    proto.reportpb.AccountManagementBalanceDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailResponse}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementBalanceDetailResponse;
  return proto.reportpb.ListAccountManagementBalanceDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailResponse}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.AccountManagementBalanceDetail;
      reader.readMessage(value,proto.reportpb.AccountManagementBalanceDetail.deserializeBinaryFromReader);
      msg.addBalanceDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementBalanceDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.AccountManagementBalanceDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountManagementBalanceDetail balance_details = 1;
 * @return {!Array<!proto.reportpb.AccountManagementBalanceDetail>}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.getBalanceDetailsList = function() {
  return /** @type{!Array<!proto.reportpb.AccountManagementBalanceDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountManagementBalanceDetail, 1));
};


/**
 * @param {!Array<!proto.reportpb.AccountManagementBalanceDetail>} value
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailResponse} returns this
*/
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.setBalanceDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.AccountManagementBalanceDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountManagementBalanceDetail}
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.addBalanceDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.AccountManagementBalanceDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListAccountManagementBalanceDetailResponse} returns this
 */
proto.reportpb.ListAccountManagementBalanceDetailResponse.prototype.clearBalanceDetailsList = function() {
  return this.setBalanceDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountManagementPositionDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountManagementPositionDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementPositionDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tdQty: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tdMarketValue: jspb.Message.getFieldWithDefault(msg, 7, ""),
    sdQty: jspb.Message.getFieldWithDefault(msg, 8, ""),
    sdMarketValue: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountManagementPositionDetail}
 */
proto.reportpb.AccountManagementPositionDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountManagementPositionDetail;
  return proto.reportpb.AccountManagementPositionDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountManagementPositionDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountManagementPositionDetail}
 */
proto.reportpb.AccountManagementPositionDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdQty(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdMarketValue(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdQty(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdMarketValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountManagementPositionDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountManagementPositionDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementPositionDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTdQty();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTdMarketValue();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSdQty();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSdMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_description = 4;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string closing_price = 5;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string td_qty = 6;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getTdQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setTdQty = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string td_market_value = 7;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getTdMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setTdMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string sd_qty = 8;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getSdQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setSdQty = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string sd_market_value = 9;
 * @return {string}
 */
proto.reportpb.AccountManagementPositionDetail.prototype.getSdMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementPositionDetail} returns this
 */
proto.reportpb.AccountManagementPositionDetail.prototype.setSdMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementPositionDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailRequest}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementPositionDetailRequest;
  return proto.reportpb.ListAccountManagementPositionDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailRequest}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementPositionDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListAccountManagementPositionDetailRequest} returns this
*/
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementPositionDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementPositionDetailRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementPositionDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementPositionDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    positionDetailsList: jspb.Message.toObjectList(msg.getPositionDetailsList(),
    proto.reportpb.AccountManagementPositionDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailResponse}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementPositionDetailResponse;
  return proto.reportpb.ListAccountManagementPositionDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementPositionDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailResponse}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.AccountManagementPositionDetail;
      reader.readMessage(value,proto.reportpb.AccountManagementPositionDetail.deserializeBinaryFromReader);
      msg.addPositionDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementPositionDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementPositionDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPositionDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.AccountManagementPositionDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountManagementPositionDetail position_details = 1;
 * @return {!Array<!proto.reportpb.AccountManagementPositionDetail>}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.getPositionDetailsList = function() {
  return /** @type{!Array<!proto.reportpb.AccountManagementPositionDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountManagementPositionDetail, 1));
};


/**
 * @param {!Array<!proto.reportpb.AccountManagementPositionDetail>} value
 * @return {!proto.reportpb.ListAccountManagementPositionDetailResponse} returns this
*/
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.setPositionDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.AccountManagementPositionDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountManagementPositionDetail}
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.addPositionDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.AccountManagementPositionDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListAccountManagementPositionDetailResponse} returns this
 */
proto.reportpb.ListAccountManagementPositionDetailResponse.prototype.clearPositionDetailsList = function() {
  return this.setPositionDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountManagementMarginReqDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountManagementMarginReqDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementMarginReqDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 2, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 3, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 4, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 5, ""),
    openingBp: jspb.Message.getFieldWithDefault(msg, 6, ""),
    bpCall: jspb.Message.getFieldWithDefault(msg, 7, ""),
    exchangeReq: jspb.Message.getFieldWithDefault(msg, 8, ""),
    exchangeCall: jspb.Message.getFieldWithDefault(msg, 9, ""),
    houseReq: jspb.Message.getFieldWithDefault(msg, 10, ""),
    houseCall: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fedReq: jspb.Message.getFieldWithDefault(msg, 12, ""),
    fedCall: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountManagementMarginReqDetail}
 */
proto.reportpb.AccountManagementMarginReqDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountManagementMarginReqDetail;
  return proto.reportpb.AccountManagementMarginReqDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountManagementMarginReqDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountManagementMarginReqDetail}
 */
proto.reportpb.AccountManagementMarginReqDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultiplier(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningBp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBpCall(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeReq(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeCall(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseReq(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseCall(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedReq(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountManagementMarginReqDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountManagementMarginReqDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementMarginReqDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMultiplier();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getOpeningBp();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBpCall();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getExchangeReq();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExchangeCall();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getHouseReq();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getHouseCall();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFedReq();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFedCall();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string master_account_no = 1;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cash_balance = 2;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string market_value = 3;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string equity = 4;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string multiplier = 5;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getMultiplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string opening_bp = 6;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getOpeningBp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setOpeningBp = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string bp_call = 7;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getBpCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setBpCall = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string exchange_req = 8;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getExchangeReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setExchangeReq = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string exchange_call = 9;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getExchangeCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setExchangeCall = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string house_req = 10;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getHouseReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setHouseReq = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string house_call = 11;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getHouseCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setHouseCall = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string fed_req = 12;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getFedReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setFedReq = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string fed_call = 13;
 * @return {string}
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.getFedCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementMarginReqDetail} returns this
 */
proto.reportpb.AccountManagementMarginReqDetail.prototype.setFedCall = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementMarginReqDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailRequest}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementMarginReqDetailRequest;
  return proto.reportpb.ListAccountManagementMarginReqDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailRequest}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementMarginReqDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} returns this
*/
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementMarginReqDetailRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementMarginReqDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    marginReqDetailsList: jspb.Message.toObjectList(msg.getMarginReqDetailsList(),
    proto.reportpb.AccountManagementMarginReqDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailResponse}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementMarginReqDetailResponse;
  return proto.reportpb.ListAccountManagementMarginReqDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailResponse}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.AccountManagementMarginReqDetail;
      reader.readMessage(value,proto.reportpb.AccountManagementMarginReqDetail.deserializeBinaryFromReader);
      msg.addMarginReqDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementMarginReqDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMarginReqDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.AccountManagementMarginReqDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountManagementMarginReqDetail margin_req_details = 1;
 * @return {!Array<!proto.reportpb.AccountManagementMarginReqDetail>}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.getMarginReqDetailsList = function() {
  return /** @type{!Array<!proto.reportpb.AccountManagementMarginReqDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountManagementMarginReqDetail, 1));
};


/**
 * @param {!Array<!proto.reportpb.AccountManagementMarginReqDetail>} value
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailResponse} returns this
*/
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.setMarginReqDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.AccountManagementMarginReqDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountManagementMarginReqDetail}
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.addMarginReqDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.AccountManagementMarginReqDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListAccountManagementMarginReqDetailResponse} returns this
 */
proto.reportpb.ListAccountManagementMarginReqDetailResponse.prototype.clearMarginReqDetailsList = function() {
  return this.setMarginReqDetailsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountManagementSegregationDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountManagementSegregationDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementSegregationDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    segAmt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    excessMarginAmt: jspb.Message.getFieldWithDefault(msg, 7, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    segQty: jspb.Message.getFieldWithDefault(msg, 9, ""),
    excessMarginQty: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountManagementSegregationDetail}
 */
proto.reportpb.AccountManagementSegregationDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountManagementSegregationDetail;
  return proto.reportpb.AccountManagementSegregationDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountManagementSegregationDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountManagementSegregationDetail}
 */
proto.reportpb.AccountManagementSegregationDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegAmt(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMarginAmt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegQty(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMarginQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountManagementSegregationDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountManagementSegregationDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountManagementSegregationDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSegAmt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExcessMarginAmt();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSegQty();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExcessMarginQty();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string master_account_no = 1;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_name = 2;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_description = 4;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string market_value = 5;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string seg_amt = 6;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getSegAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setSegAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string excess_margin_amt = 7;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getExcessMarginAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setExcessMarginAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string quantity = 8;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string seg_qty = 9;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getSegQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setSegQty = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string excess_margin_qty = 10;
 * @return {string}
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.getExcessMarginQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountManagementSegregationDetail} returns this
 */
proto.reportpb.AccountManagementSegregationDetail.prototype.setExcessMarginQty = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementSegregationDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailRequest}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementSegregationDetailRequest;
  return proto.reportpb.ListAccountManagementSegregationDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailRequest}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementSegregationDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date settle_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailRequest} returns this
*/
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailRequest} returns this
 */
proto.reportpb.ListAccountManagementSegregationDetailRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListAccountManagementSegregationDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    segregationDetailsList: jspb.Message.toObjectList(msg.getSegregationDetailsList(),
    proto.reportpb.AccountManagementSegregationDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailResponse}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListAccountManagementSegregationDetailResponse;
  return proto.reportpb.ListAccountManagementSegregationDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailResponse}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.AccountManagementSegregationDetail;
      reader.readMessage(value,proto.reportpb.AccountManagementSegregationDetail.deserializeBinaryFromReader);
      msg.addSegregationDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListAccountManagementSegregationDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegregationDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.AccountManagementSegregationDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated AccountManagementSegregationDetail segregation_details = 1;
 * @return {!Array<!proto.reportpb.AccountManagementSegregationDetail>}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.getSegregationDetailsList = function() {
  return /** @type{!Array<!proto.reportpb.AccountManagementSegregationDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.AccountManagementSegregationDetail, 1));
};


/**
 * @param {!Array<!proto.reportpb.AccountManagementSegregationDetail>} value
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailResponse} returns this
*/
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.setSegregationDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.AccountManagementSegregationDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.AccountManagementSegregationDetail}
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.addSegregationDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.AccountManagementSegregationDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListAccountManagementSegregationDetailResponse} returns this
 */
proto.reportpb.ListAccountManagementSegregationDetailResponse.prototype.clearSegregationDetailsList = function() {
  return this.setSegregationDetailsList([]);
};


goog.object.extend(exports, proto.reportpb);
