/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  ListSpinOffRequest,
  UpdateSpinOffRequest,
} from '../../../proto/reorgpb/spinoff_grpc_web_pb';
import { ListPendingTrnsRequest } from '../../../proto/trnspb/pendingtrns_grpc_web_pb';
import {
  BatchProcessPendingAnnouncementTransactionRequest,
  PendingAnnouncement,
} from '../../../proto/trnspb/transaction_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Box,
  TextField,
  Tooltip,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Modal,
  Backdrop,
  Fade,
  Typography,
  Button,
  TableRow,
  TableCell,
  Table,
  TableBody,
  CircularProgress,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable, { ExpandButton } from 'mui-datatables';

/*Icons*/
import {
  Save as SaveIcon,
  CheckCircle as CheckCircleIcon,
  Clear as ClearIcon,
  Create as EditIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatPercentage, formatCurrency } from 'lib/fmt';

/*Custom components*/
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function SpinOffTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const [pendingTrnsOnly, setPendingTrnsOnly] = React.useState(true);

  const {
    profileServiceClient: systemDate,
    spinOffServiceClient: spinoff,
    tableSettingsServiceClient: tableSettings,
    pendingTrnsServiceClient: pendingtrns,
    transactionServiceClient: transaction,
  } = useContext(ServiceContext);

  const search = (showNotif) => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListSpinOffRequest();

    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);

      list.setFromSystemDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);

      list.setToSystemDate(gglToDate);
    }
    list.setTargetSymbol(symbolValue);
    list.setPendingTrnsOnly(pendingTrnsOnly);

    spinoff.listSpinOff(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().spinOffsList.map((data) => ({
        cash: formatCurrency(data.cash),
        expirationDate: formatPbDate(data.expirationDate),
        initiatingOriginalCusip: data.initiatingOriginalCusip,
        initiatingSymbol: data.initiatingSymbol,
        newRate: formatPercentage(data.newRate),
        oldRate: formatPercentage(data.oldRate),
        positionDate: formatPbDate(data.positionDate),
        processDate: formatPbDate(data.processDate),
        systemDate: formatPbDate(data.systemDate),
        targetOriginalCusip: data.targetOriginalCusip,
        targetSymbol: data.targetSymbol,
        textNumber: data.textNumber,
        pendingTrnsCount: data.pendingTrnsCount,
        noCil: data.noCil,
        netCash: formatCurrency(data.netCash),
        netQty: data.netQty,
        detail: data.detail,
        netPayQty: data.netPayQty,
        netPayCash: formatCurrency(data.netPayCash),
      }));
      setRows(rows);
      if (showNotif) {
        notifyInfo(rows.length + ' search results.');
      }
      setLoading(false);
    });
  };

  const listPendingSpinOff = async (symbol) => {
    let list = new ListPendingTrnsRequest();
    list.setSymbol(symbol);
    list.setEntryType('SPIN');
    list.setDateType('system_date');
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setFromDate(gglSystemDate);
      list.setToDate(gglSystemDate);
    }

    return new Promise((resolve, reject) => {
      pendingtrns.listPendingTrns(list, {}, (error, res) => {
        if (error) {
          reject(error);
        } else {
          resolve(res.toObject().pendingTransactionsList);
        }
      });
    });
  };

  const saveCashRate = (textNumber, noCil, newRate, processDate) => {
    let req = new UpdateSpinOffRequest();
    req.setTextNumber(textNumber);
    req.setNoCil(noCil);
    req.setNewRate(newRate);

    let gglProcessDate = new google_date.Date();
    let [y, m, d] = processDate.split('-');
    gglProcessDate.setYear(y);
    gglProcessDate.setMonth(m);
    gglProcessDate.setDay(d);
    req.setProcessDate(gglProcessDate);

    spinoff.updateSpinOff(req, {}, (err) => {
      if (err) {
        console.error(err.message);
        notifyError(err.message);
        return;
      }
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].textNumber === textNumber) {
          rows[i].noCil = noCil;
          rows[i].newRate = formatPercentage(newRate);
          break;
        }
      }
      notifySuccess('Rates has been updated.');
      handleClose();
    });
  };

  const handleProcess = (selected) => {
    setIsProcessing(true);

    let selectedRows = [];
    selected.forEach(function(row) {
      let symbol = {
        symbol: rows[row.dataIndex].symbol,
      };

      selectedRows.push(symbol);
    });

    function processFunction(item) {
      rows.forEach((row) => {
        if (row.symbol === item.symbol) {
          let pd = new PendingAnnouncement();
          pd.setSymbol(item.symbol);
          pd.setEntryType('SPIN');

          symbolList.push(pd);
        }
      });
    }

    let symbolList = [];
    selectedRows.forEach(processFunction);

    let processReq = new BatchProcessPendingAnnouncementTransactionRequest();
    processReq.setPendingAnnouncementsList(symbolList);
    transaction.batchProcessPendingAnnouncementTransaction(
      processReq,
      {},
      (err, res) => {
        if (err) {
          notifyError(err.message);
          setIsProcessing(false);
          return false;
        }
        if (res) {
          const result = res.toObject().responsesList;
          let hasError = false;

          result.forEach((r) => {
            if (r.errorMessage) {
              hasError = true;
              notifyError(r.status + ' ' + r.trnsId + ' ' + r.errorMessage);
            } else {
              if (hasError) {
                notifySuccess('Record has been processed successfuly.');
              }
            }
          });

          if (!hasError) {
            notifySuccess(
              result.length + ' record(s) has been processed successfuly.'
            );
          }

          setRowsSelected([]);
          setIsProcessing(false);
          search(false);
        }
      }
    );
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('SpinOff');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className={classes.grdRow} style={{ margin: '0px' }}>
              <div className={classes.grdCell1}>
                <Tooltip title="Edit" arrow>
                  <div>
                    <IconButton
                      aria-label="edit"
                      onClick={() => handleOpen(rows[dataIndex])}
                    >
                      <EditIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px', width: '130px' },
        }),
      },
    },
    {
      name: 'processDate',
      label: 'Process Date',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'positionDate',
      label: 'Position Date',
    },
    {
      name: 'expirationDate',
      label: 'Expiration Date',
    },
    {
      name: 'textNumber',
      label: 'Text Number',
    },
    {
      name: 'targetSymbol',
      label: 'Target Symbol',
    },
    {
      name: 'targetOriginalCusip',
      label: 'Target Original Cusip',
    },
    {
      name: 'initiatingSymbol',
      label: 'Initiating Symbol',
    },
    {
      name: 'initiatingOriginalCusip',
      label: 'Initiating Original Cusip',
    },
    {
      name: 'newRate',
      label: 'New Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'oldRate',
      label: 'Old Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'cash',
      label: 'Cash',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'netCash',
      label: 'Net Cash',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'netQty',
      label: 'Net QTY',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'netPayCash',
      label: 'Net Pay Cash',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'netPayQty',
      label: 'Net Pay QTY',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'noCil',
      label: 'No CIL',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].noCil;
          return (
            <div align="center">{f === true ? <CheckCircleIcon /> : null}</div>
          );
        },
      },
    },
    {
      name: 'detail',
      label: 'Detail',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].detail;
          return (
            <div>
              {v ? (
                v.length > 15 ? (
                  <Tooltip title={v} arrow>
                    <div>{`${v.substring(0, 15) + '...'}`}</div>
                  </Tooltip>
                ) : (
                  v
                )
              ) : (
                ''
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'pendingTrnsCount',
      label: 'Pending TRNS Count',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
  ];

  const subColumns = [
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'contraAccountNo',
      label: 'Contra Account No',
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
    },
    {
      name: 'qty',
      label: 'Qty',
    },
    {
      name: 'netAmt',
      label: 'Net Amt',
    },
    {
      name: 'description',
      label: 'Description',
    },
  ];

  const fileName =
    'SpinOff_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      1,
      2,
      3,
      4,
      5,
      6,
      7,
      8,
      9,
      10,
      11,
      12,
      13,
      14,
      15,
      16,
      17,
      18,
      19,
    ],
    selectableRowsHeader: true,
    selectableRows: 'multiple',
    expandableRows: pendingTrnsOnly ? true : false,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <TableRow key={rowMeta.rowIndex}>
          <TableCell colSpan={21} style={{ padding: 0 }}>
            <Table>
              <TableBody>
                <TableRow style={{ background: '#8698ff' }}>
                  {subColumns.map((col) => (
                    <TableCell
                      style={{
                        height: 20,
                        borderBottom: 'none',
                        fontWeight: 'bold',
                        color: '#ffffff',
                      }}
                      align={col.align || 'left'}
                      key={col.name}
                    >
                      {col.label}
                    </TableCell>
                  ))}
                </TableRow>
                {(subRows['dataIndex' + rowMeta.dataIndex] || []).map(
                  (row, index) => (
                    <TableRow key={index} style={{ background: '#f3f5f6' }}>
                      <TableCell>{row.correspondent}</TableCell>
                      <TableCell>{row.accountNo}</TableCell>
                      <TableCell>{row.contraAccountNo}</TableCell>
                      <TableCell>
                        {row.tradeDate ? formatPbDate(row.tradeDate) : '--'}
                      </TableCell>
                      <TableCell>
                        {row.settleDate ? formatPbDate(row.settleDate) : '--'}
                      </TableCell>
                      <TableCell>{row.qty}</TableCell>
                      <TableCell>{formatCurrency(row.netAmt)}</TableCell>
                      <TableCell>{row.description}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableCell>
        </TableRow>
      );
    },
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {
      (async () => {
        let sub = { ...subRows };
        for (let i = 0; i < curExpanded.length; i++) {
          const currentIndex = curExpanded[i].dataIndex;
          const currentRow = rows[currentIndex];

          if (currentRow) {
            const symbol = currentRow.targetSymbol;
            const data = await listPendingSpinOff(symbol);
            sub['dataIndex' + currentIndex] = data;
          }
        }

        setSubRows(sub);
      })();
    },
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('SpinOff');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('SpinOff');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customToolbarSelect: (selectedRows, a, b) => {
      return (
        <div
          className={classes.grdRow}
          style={{ margin: 0, padding: 0, marginRight: 20 }}
        >
          <div align={'left'} className={classes.grdCell1}>
            <Tooltip title="Process" arrow>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="process"
                  size="small"
                  disabled={isProcessing ? true : false}
                  startIcon={
                    isProcessing ? (
                      <CircularProgress
                        style={{ color: '#ffffff', height: 20, width: 20 }}
                      />
                    ) : null
                  }
                  onClick={() => {
                    handleProcess(selectedRows.data);
                  }}
                >
                  {isProcessing ? 'Processing' : 'Process'}
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      );
    },
    onRowSelectionChange: (
      currentRowsSelected,
      allRowsSelected,
      rowsSelected
    ) => {
      setRowsSelected(rowsSelected);
    },
    rowsSelected: rowsSelected,
    isRowSelectable: (dataIndex) => rows[dataIndex].pendingTrnsCount > 0,
  };

  const [rows, setRows] = useState([]);
  const [subRows, setSubRows] = useState({});

  const [fromDateValue, setFromDateValue] = useState('');
  const [toDateValue, setToDateValue] = useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  function handleClear(e) {
    e.stopPropagation();

    setFromDateValue('');
    setToDateValue('');
  }

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      symbol: query.get('symbol'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
    };

    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
        notifyError(err);
      }

      setSystemDateValue(
        moment(
          new Date(formatPbDate(res.toObject().profile.systemDate))
        ).format('yyyy-MM-DD')
      );
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  const [modalTextNumberValue, setModalTextNumberValue] = React.useState('');
  const [modalNoCilValue, setModalNoCilValue] = React.useState('');
  const [modalNewRateValue, setModalNewRateValue] = React.useState('');
  const [modalProcessDateValue, setModalProcessDateValue] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (rowData) => {
    setModalTextNumberValue(rowData.textNumber);
    setModalNoCilValue(rowData.noCil);
    setModalNewRateValue(rowData.newRate.replace('%', ''));
    setModalProcessDateValue(
      moment(new Date(rowData.processDate)).format('yyyy-MM-DD')
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const components = {
    ExpandButton: function(props) {
      if (props.dataIndex >= 0) {
        if (!pendingTrnsOnly) {
          return <div style={{ width: '24px' }} />;
        }
      }
      return <ExpandButton {...props} />;
    },
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Process Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(e) => handleClear(e, 'date')}
                        style={{ padding: 0 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Process Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={(e) => handleClear(e, 'date')}
                        style={{ padding: 0 }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSymbol
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  type="symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={pendingTrnsOnly}
                        onChange={(e) => {
                          setPendingTrnsOnly(e.target.checked);
                        }}
                        name="pendingTrnsOnly"
                      />
                    }
                    label="Show with Pending TRNS only"
                  />
                </FormGroup>
              </div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer} style={{ marginTop: 26 }}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search(true)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Spin-Off'}
            data={rows}
            columns={columns}
            options={options}
            components={components}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        className={classes.modalBackdrop}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              className={classes.textBold}
              variant="h4"
              gutterBottom
            >
              Edit Cash Rate
            </Typography>
            <Box id="transition-modal-description">
              <form className={classes.modalForm} noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="New Rate"
                      placeholder="New Rate"
                      fullWidth={true}
                      margin="normal"
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={modalNewRateValue}
                      onChange={(e) => setModalNewRateValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={modalNoCilValue}
                            onChange={(e) => {
                              setModalNoCilValue(e.target.checked);
                            }}
                            name="noCil"
                          />
                        }
                        label="No CIL"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveCashRate(
                          modalTextNumberValue,
                          modalNoCilValue,
                          modalNewRateValue,
                          modalProcessDateValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
