/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  InterestRate,
  ListInterestRateRequest,
  DeleteInterestRateRequest,
} from '../../../proto/feepb/interestrate_grpc_web_pb.js';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import ClientAccountNoSelect from '../../../components/AutoComplete/ClientAccountNo';
import ClientAccountNameSelect from '../../../components/AutoComplete/ClientAccountName';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

function RenderComponent() {
  const [, setValue] = useState(0);
  return () => setValue((value) => ++value);
}

function saveMarginRate(
  marginRate,
  rows,
  isAdd,
  closeModal,
  fromDate,
  toDate,
  accountNo,
  federalRate,
  creditRateAdj,
  creditRate,
  debitRateAdj,
  debitRate,
  markupRate,
  markdownRate,
  note,
  status,
  rateId,
  reRender
) {
  if (!accountNo) {
    notifyError('Account No is required.');
    return;
  }
  if (!fromDate) {
    notifyError('From Date is required.');
    return;
  }
  if (!toDate) {
    notifyError('To Date required.');
    return;
  }
  if (!creditRate) {
    notifyError('creditRate Type is required.');
    return;
  }
  if (!debitRate) {
    notifyError('debitRate is required.');
    return;
  }

  if (!markupRate) {
    notifyError('markupRate is required.');
    return;
  }

  if (!markdownRate) {
    notifyError('markdownRate is required.');
    return;
  }

  if (isAdd) {
    let addReq = new InterestRate();
    addReq.setAccountNo(accountNo);
    addReq.setFederalRate(federalRate);
    addReq.setCreditRateAdj(creditRateAdj);
    addReq.setCreditRate(creditRate);
    addReq.setDebitRateAdj(debitRateAdj);
    addReq.setDebitRate(debitRate);
    addReq.setMarkupRate(markupRate);
    addReq.setMarkdownRate(markdownRate);
    addReq.setNote(note);
    addReq.setIsStatus(status);
    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      addReq.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      addReq.setToDate(gglToDate);
    }

    marginRate.createInterestRate(addReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
      } else {
        let obj = res.toObject().interestRate;

        let newMarginRate = {
          marginRateId: obj.marginRateId,
          correspondent: obj.correspondent,
          accountNo: obj.accountNo,
          accountName: obj.accountName,
          masterAccountNo: obj.masterAccountNo,
          accountDesignator: obj.accountDesignator,
          broker: obj.broker,
          type: obj.type,
          fromDate: formatPbDate(obj.fromDate), //moment(new Date(fromDate)).format('yyyy-MM-DD'),
          toDate: formatPbDate(obj.toDate), //moment(new Date(toDate)).format('yyyy-MM-DD'),
          federalRate: obj.federalRate,
          creditRateAdj: obj.creditRateAdj,
          creditRate: obj.creditRate,
          debitRateAdj: obj.debitRateAdj,
          debitRate: obj.debitRate,
          markupRate: obj.markupRate,
          markdownRate: obj.markdownRate,
          note: obj.note,
          isStatus: obj.isStatus,
        };
        rows.push(newMarginRate);
        reRender();
        notifySuccess('New Margin Interest Rate has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new InterestRate();
    updateReq.setMarginRateId(rateId);
    updateReq.setAccountNo(accountNo);
    updateReq.setFederalRate(federalRate);
    updateReq.setCreditRateAdj(creditRateAdj);
    updateReq.setCreditRate(creditRate);
    updateReq.setDebitRateAdj(debitRateAdj);
    updateReq.setDebitRate(debitRate);
    updateReq.setMarkupRate(markupRate);
    updateReq.setMarkdownRate(markdownRate);
    updateReq.setNote(note);
    updateReq.setIsStatus(status);

    if (fromDate) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDate.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      updateReq.setFromDate(gglFromDate);
    }

    if (toDate) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDate.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      updateReq.setToDate(gglToDate);
    }

    marginRate.updateInterestRate(updateReq, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
      } else {
        let obj = res.toObject().interestRate;
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].marginRateId === rateId) {
            rows[i].accountNo = obj.accountNo;
            rows[i].masterAccountNo = obj.masterAccountNo;
            rows[i].accountDesignator = obj.accountDesignator;
            rows[i].fromDate = formatPbDate(obj.fromDate);
            rows[i].toDate = formatPbDate(obj.toDate);
            rows[i].creditRate = obj.creditRate;
            rows[i].debitRate = obj.debitRate;
            rows[i].markupRate = obj.markupRate;
            rows[i].markdownRate = obj.markdownRate;
            rows[i].note = obj.note;
            rows[i].isStatus = obj.isStatus ? 'active' : 'inactive';
          }
        }
        reRender();
        notifySuccess('Margin Interest Rate has been updated.');
        closeModal();
      }
    });
  }
}

function removeMarginRateSingle(
  marginRate,
  rows,
  marginRateId,
  confirm,
  reRender
) {
  confirm({
    description:
      "You are about to delete margin interest rate '" +
      marginRateId +
      "' in your system. Please confirm your action.",
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      let deleteReq = new DeleteInterestRateRequest();
      deleteReq.setMarginRateId(marginRateId);

      marginRate.deleteInterestRate(deleteReq, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.messsage);
          return;
        }

        for (let c = 0; c < rows.length; c++) {
          if (rows[c].marginRateId === marginRateId) {
            //rows[c].isStatus = 'inactive';
            rows.splice(c, 1);
          }
          reRender();
        }
      });
      notifySuccess('Margin Interest Rate has been deleted');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}

function removeMarginRate(
  marginRate,
  rows,
  rateIDs,
  handleClick,
  confirm,
  reRender
) {
  if (rateIDs.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to delete ' +
      rateIDs.length +
      ' Maring Interest Rate ID(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Delete',
  })
    .then(() => {
      rateIDs.forEach(removeFunction);

      function removeFunction(item) {
        let deleteReq = new DeleteInterestRateRequest();
        deleteReq.setMarginRateId(item);

        marginRate.deleteInterestRate(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].rateId === item) {
              //rows[c].status = 'inactive';
              rows.splice(c, 1);
            }
          }
          reRender();
        });
      }
      handleClick('', '', true);
      notifySuccess(
        rateIDs.length + ' Margin Interest Rate has been deactivated'
      );
    })
    .catch(() => {
      /* ... */
    });
}

export default function MarginInterestRateTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const confirm = useConfirm();
  const reRender = RenderComponent(); //Re-renders component when something change in component state

  const {
    interestRateServiceClient: marginRate,
    profileServiceClient: systemDate,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (statusValue ? '&status=' + statusValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListInterestRateRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setIsStatus(statusValue);

    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);
      list.setFromDate(gglFromDate);
    }

    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);
      list.setToDate(gglToDate);
    }

    marginRate.listInterestRate(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().interestRatesList.map((data) => ({
        fromDate: formatPbDate(data.fromDate),
        toDate: formatPbDate(data.toDate),
        correspondent: data.correspondent,
        accountNo: data.accountNo,
        accountName: data.accountName,
        masterAccountNo: data.masterAccountNo,
        accountDesignator: data.accountDesignator,
        broker: data.broker,
        type: data.type,
        federalRate: data.federalRate,
        creditRateAdj: data.creditRateAdj,
        creditRate: data.creditRate,
        debitRate: data.debitRate,
        debitRateAdj: data.debitRateAdj,
        markupRate: data.markupRate,
        markdownRate: data.markdownRate,
        note: data.note,
        isStatus: data.isStatus ? 'active' : 'inactive',
        marginRateId: data.marginRateId,
      }));

      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Margin Interest Rate');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          if (!rows[dataIndex]) {
            return;
          }

          const isItemSelected = isSelected(rows[dataIndex].marginRateId);
          const labelId = `enhanced-table-checkbox-${dataIndex}`;

          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCellNone}>
                <Checkbox
                  onClick={(event) =>
                    handleClick(event, rows[dataIndex].marginRateId)
                  }
                  checked={isItemSelected}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Delete" arrow>
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      removeMarginRateSingle(
                        marginRate,
                        rows,
                        rows[dataIndex].marginRateId,
                        confirm,
                        reRender
                      );
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Edit" arrow>
                  <IconButton
                    aria-label="edit"
                    onClick={() => handleOpen(rows[dataIndex], false)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'creditRate',
      label: 'Credit Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'creditRateAdj',
      label: 'Credit Rate Adj',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'debitRate',
      label: 'Debit Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'debitRateAdj',
      label: 'Debit Rate Adj',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'federalRate',
      label: 'Federal Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'fromDate',
      label: 'From Date',
    },
    {
      name: 'isStatus',
      label: 'Status',
    },
    {
      name: 'markdownRate',
      label: 'Markdown Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'markupRate',
      label: 'Markup Rate',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'note',
      label: 'Note',
    },
    {
      name: 'toDate',
      label: 'To Date',
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const handleClick = (event, marginRateId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(marginRateId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, marginRateId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };
  const isSelected = (marginRateId) => selected.indexOf(marginRateId) !== -1;

  const fileName =
    'MarginInterestRate_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      5,
      3,
      2,
      15,
      1,
      4,
      18,
      11,
      17,
      10,
      7,
      6,
      9,
      8,
      14,
      13,
      16,
      12,
    ],
    selectableRowsHeader: false,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Margin Interest Rate');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Margin Interest Rate');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [fromDateValue, setFromDateValue] = React.useState('');
  const [toDateValue, setToDateValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState(true);

  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      accountName: query.get('accountName'),
      status: query.get('status'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    //Get system date
    let profileReq = new ReadProfileRequest();

    systemDate.readProfile(profileReq, {}, (err, res) => {
      if (err) {
        console.log(err);
      } else {
        if (!data.fromDate) {
          setFromDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
        if (!data.toDate) {
          setToDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      }
    });

    setCurrentPath(path);
  }, [systemDate, params]);

  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [modalFromDateValue, setModalFromDateValue] = React.useState('');
  const [modalToDateValue, setModalToDateValue] = React.useState('');

  const [modalFederalRateValue, setModalFederalRateValue] = React.useState('');
  const [modalCreditRateAdjValue, setModalCreditRateAdjValue] = React.useState(
    ''
  );
  const [modalCreditRateValue, setModalCreditRateValue] = React.useState('');
  const [modalDebitRateAdjValue, setModalDebitRateAdjValue] = React.useState(
    ''
  );
  const [modalDebitRateValue, setModalDebitRateValue] = React.useState('');
  const [modalMarkupRateValue, setModalMarkupRateValue] = React.useState('');
  const [modalMarkdownRateValue, setModalMarkdownRateValue] = React.useState(
    ''
  );
  const [modalNoteValue, setModalNoteValue] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState(true);

  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const [rateId, setRateId] = React.useState('');
  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalFromDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalToDateValue(moment(new Date()).format('yyyy-MM-DD'));
      setModalAccountNoValue('');
      setModalFederalRateValue('');
      setModalCreditRateAdjValue('');
      setModalCreditRateValue('');
      setModalDebitRateAdjValue('');
      setModalDebitRateValue('');
      setModalMarkupRateValue('');
      setModalMarkdownRateValue('');
      setModalNoteValue('');
      setModalStatusValue(true);

      setModalAddAction(true);
      setModalTitleValue('Add New');
    } else {
      setModalFromDateValue(
        rowData.fromDate
          ? moment(new Date(rowData.fromDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalToDateValue(
        rowData.toDate
          ? moment(new Date(rowData.toDate)).format('yyyy-MM-DD')
          : ''
      );
      setModalAccountNoValue(rowData.accountNo);
      setModalFederalRateValue(rowData.federalRate);
      setModalCreditRateAdjValue(rowData.creditRateAdj);
      setModalCreditRateValue(rowData.creditRate);
      setModalDebitRateAdjValue(rowData.debitRateAdj);
      setModalDebitRateValue(rowData.debitRate);
      setModalMarkupRateValue(rowData.markupRate);
      setModalMarkdownRateValue(rowData.markdownRate);
      setModalNoteValue(rowData.note);
      setModalStatusValue(rowData.isStatus === 'active' ? true : false);
      setRateId(rowData.marginRateId);

      setModalAddAction(false);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (modalFromDateValue) {
      var dt = new Date(modalFromDateValue);
      var year = dt.getFullYear();
      var month = dt.getMonth();
      var day = dt.getDate();
      var toDate = new Date(year + 50, month, day);
      setModalToDateValue(moment(new Date(toDate)).format('yyyy-MM-DD'));
    }
  }, [modalFromDateValue]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <ClientAccountNoSelect
                  required={false}
                  labelid="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => setAccountNoValue(e.target.value)}
                  setNewValue={(event, newValue) => {
                    if (newValue) {
                      setAccountNoValue(
                        newValue.accountNo ? newValue.accountNo : newValue
                      );
                    } else {
                      setAccountNoValue('');
                      return;
                    }
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <ClientAccountNameSelect
                  required={false}
                  labelid="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => setAccountNameValue(e.target.value)}
                  setNewValue={(event, newValue) => {
                    if (newValue) {
                      setAccountNameValue(
                        newValue.accountName ? newValue.accountName : newValue
                      );
                    } else {
                      setAccountNameValue('');
                      return;
                    }
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setStatusValue(e.target.value);
                  }}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeMarginRate(
                  marginRate,
                  rows,
                  rowID,
                  handleClick,
                  confirm,
                  reRender
                );
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Margin Interest Rate'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Margin Interest Rate
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <ClientAccountNoSelect
                      required
                      labelid="modalAccountNo"
                      label="Account No"
                      value={modalAccountNoValue}
                      onChange={(e) => setModalAccountNoValue(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalAccountNoValue(
                            newValue.accountNo ? newValue.accountNo : newValue
                          );
                        } else {
                          setModalAccountNoValue('');
                          return;
                        }
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      required
                      label="From Date"
                      type="date"
                      value={modalFromDateValue}
                      onChange={(e) => setModalFromDateValue(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      fullWidth
                      disabled
                      label="Federal Rate"
                      value={modalFederalRateValue}
                      onChange={(e) => setModalFederalRateValue(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      fullWidth
                      label="To Date"
                      type="date"
                      title="Default: +50 Years of FromDate"
                      disabled={false}
                      value={modalToDateValue}
                      onChange={(e) => setModalToDateValue(e.target.value)}
                      className={classes.textField}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      disabled={true}
                      label="Credit Rate Adj"
                      type="number"
                      fullWidth={true}
                      value={modalCreditRateAdjValue}
                      onChange={(e) =>
                        setModalCreditRateAdjValue(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      required
                      label="Credit Rate"
                      type="number"
                      fullWidth={true}
                      value={modalCreditRateValue}
                      onChange={(e) => setModalCreditRateValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Debit Rate Adj"
                      disabled={true}
                      type="number"
                      fullWidth={true}
                      value={modalDebitRateAdjValue}
                      onChange={(e) =>
                        setModalDebitRateAdjValue(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Debit Rate"
                      type="number"
                      fullWidth={true}
                      required
                      value={modalDebitRateValue}
                      onChange={(e) => setModalDebitRateValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Markup Rate"
                      required
                      type="number"
                      fullWidth={true}
                      value={modalMarkupRateValue}
                      onChange={(e) => setModalMarkupRateValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Markdown Rate"
                      required
                      type="number"
                      fullWidth={true}
                      value={modalMarkdownRateValue}
                      onChange={(e) =>
                        setModalMarkdownRateValue(e.target.value)
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Note"
                      fullWidth={true}
                      value={modalNoteValue}
                      onChange={(e) => setModalNoteValue(e.target.value)}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <div className={classes.grdCell1}>
                      <InputLabel shrink>Status</InputLabel>
                      <Select
                        displayEmpty
                        fullWidth
                        value={modalStatusValue}
                        onChange={(e) => setModalStatusValue(e.target.value)}
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                      </Select>
                    </div>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveMarginRate(
                          marginRate,
                          rows,
                          modalAddAction,
                          handleClose,
                          modalFromDateValue,
                          modalToDateValue,
                          modalAccountNoValue,
                          modalFederalRateValue,
                          modalCreditRateAdjValue,
                          modalCreditRateValue,
                          modalDebitRateAdjValue,
                          modalDebitRateValue,
                          modalMarkupRateValue,
                          modalMarkdownRateValue,
                          modalNoteValue,
                          modalStatusValue,
                          rateId,
                          reRender
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
