// source: reportpb/statement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var accountpb_address_pb = require('../accountpb/address_pb.js');
goog.object.extend(proto, accountpb_address_pb);
var commonpb_file_pb = require('../commonpb/file_pb.js');
goog.object.extend(proto, commonpb_file_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var reportpb_activity_pb = require('../reportpb/activity_pb.js');
goog.object.extend(proto, reportpb_activity_pb);
var reportpb_balance_pb = require('../reportpb/balance_pb.js');
goog.object.extend(proto, reportpb_balance_pb);
var reportpb_statementdisclosure_pb = require('../reportpb/statementdisclosure_pb.js');
goog.object.extend(proto, reportpb_statementdisclosure_pb);
var reportpb_template_pb = require('../reportpb/template_pb.js');
goog.object.extend(proto, reportpb_template_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.AccountDetails', null, global);
goog.exportSymbol('proto.reportpb.DownloadStatementRequest', null, global);
goog.exportSymbol('proto.reportpb.ListStatementRequest', null, global);
goog.exportSymbol('proto.reportpb.ListStatementResponse', null, global);
goog.exportSymbol('proto.reportpb.Statement', null, global);
goog.exportSymbol('proto.reportpb.StatementBalance', null, global);
goog.exportSymbol('proto.reportpb.StatementData', null, global);
goog.exportSymbol('proto.reportpb.StatementHolding', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Statement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Statement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Statement.displayName = 'proto.reportpb.Statement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.StatementData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.StatementData.repeatedFields_, null);
};
goog.inherits(proto.reportpb.StatementData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.StatementData.displayName = 'proto.reportpb.StatementData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AccountDetails = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AccountDetails, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AccountDetails.displayName = 'proto.reportpb.AccountDetails';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.StatementHolding = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.StatementHolding, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.StatementHolding.displayName = 'proto.reportpb.StatementHolding';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.StatementBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.StatementBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.StatementBalance.displayName = 'proto.reportpb.StatementBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListStatementRequest.displayName = 'proto.reportpb.ListStatementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListStatementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListStatementResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListStatementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListStatementResponse.displayName = 'proto.reportpb.ListStatementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.DownloadStatementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.DownloadStatementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.DownloadStatementRequest.displayName = 'proto.reportpb.DownloadStatementRequest';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Statement.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Statement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Statement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Statement.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    beginningBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
    addition: jspb.Message.getFieldWithDefault(msg, 8, ""),
    subtraction: jspb.Message.getFieldWithDefault(msg, 9, ""),
    transaction: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dividend: jspb.Message.getFieldWithDefault(msg, 11, ""),
    endingValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    interest: jspb.Message.getFieldWithDefault(msg, 13, ""),
    shortTermGain: jspb.Message.getFieldWithDefault(msg, 14, ""),
    shortTermLoss: jspb.Message.getFieldWithDefault(msg, 15, ""),
    shortTermNetLoss: jspb.Message.getFieldWithDefault(msg, 16, ""),
    longTermGain: jspb.Message.getFieldWithDefault(msg, 17, ""),
    longTermLoss: jspb.Message.getFieldWithDefault(msg, 18, ""),
    longTermNetLoss: jspb.Message.getFieldWithDefault(msg, 19, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 20, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    ytdBeginningBalance: jspb.Message.getFieldWithDefault(msg, 22, ""),
    ytdAddition: jspb.Message.getFieldWithDefault(msg, 23, ""),
    ytdSubtraction: jspb.Message.getFieldWithDefault(msg, 24, ""),
    ytdTransaction: jspb.Message.getFieldWithDefault(msg, 25, ""),
    ytdDividend: jspb.Message.getFieldWithDefault(msg, 26, ""),
    ytdEndingValue: jspb.Message.getFieldWithDefault(msg, 27, ""),
    ytdInterest: jspb.Message.getFieldWithDefault(msg, 28, ""),
    ytdShortTermGain: jspb.Message.getFieldWithDefault(msg, 29, ""),
    ytdShortTermLoss: jspb.Message.getFieldWithDefault(msg, 30, ""),
    ytdShortTermNetLoss: jspb.Message.getFieldWithDefault(msg, 31, ""),
    ytdLongTermGain: jspb.Message.getFieldWithDefault(msg, 32, ""),
    ytdLongTermLoss: jspb.Message.getFieldWithDefault(msg, 33, ""),
    ytdLongTermNetLoss: jspb.Message.getFieldWithDefault(msg, 34, ""),
    ytdFees: jspb.Message.getFieldWithDefault(msg, 35, ""),
    currency: jspb.Message.getFieldWithDefault(msg, 36, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Statement}
 */
proto.reportpb.Statement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Statement;
  return proto.reportpb.Statement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Statement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Statement}
 */
proto.reportpb.Statement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setBeginningBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setAddition(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubtraction(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransaction(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDividend(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setEndingValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setInterest(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTermGain(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTermLoss(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortTermNetLoss(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongTermGain(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongTermLoss(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongTermNetLoss(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdBeginningBalance(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdAddition(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdSubtraction(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdTransaction(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdDividend(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdEndingValue(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdInterest(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdShortTermGain(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdShortTermLoss(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdShortTermNetLoss(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdLongTermGain(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdLongTermLoss(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdLongTermNetLoss(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setYtdFees(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Statement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Statement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Statement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Statement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getBeginningBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAddition();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSubtraction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getTransaction();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDividend();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getEndingValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getInterest();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getShortTermGain();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getShortTermLoss();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getShortTermNetLoss();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getLongTermGain();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getLongTermLoss();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLongTermNetLoss();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getYtdBeginningBalance();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getYtdAddition();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getYtdSubtraction();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getYtdTransaction();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getYtdDividend();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getYtdEndingValue();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getYtdInterest();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getYtdShortTermGain();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getYtdShortTermLoss();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getYtdShortTermNetLoss();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getYtdLongTermGain();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getYtdLongTermLoss();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getYtdLongTermNetLoss();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getYtdFees();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
};


/**
 * optional string month = 1;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.reportpb.Statement.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string beginning_balance = 7;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getBeginningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setBeginningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string addition = 8;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getAddition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setAddition = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string subtraction = 9;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getSubtraction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setSubtraction = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string transaction = 10;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string dividend = 11;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getDividend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setDividend = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string ending_value = 12;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getEndingValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setEndingValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string interest = 13;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getInterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setInterest = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string short_term_gain = 14;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getShortTermGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setShortTermGain = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string short_term_loss = 15;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getShortTermLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setShortTermLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string short_term_net_loss = 16;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getShortTermNetLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setShortTermNetLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string long_term_gain = 17;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getLongTermGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setLongTermGain = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string long_term_loss = 18;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getLongTermLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setLongTermLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string long_term_net_loss = 19;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getLongTermNetLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setLongTermNetLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string fees = 20;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string account_id = 21;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string ytd_beginning_balance = 22;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdBeginningBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdBeginningBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string ytd_addition = 23;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdAddition = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdAddition = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string ytd_subtraction = 24;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdSubtraction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdSubtraction = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string ytd_transaction = 25;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdTransaction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdTransaction = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string ytd_dividend = 26;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdDividend = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdDividend = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string ytd_ending_value = 27;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdEndingValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdEndingValue = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string ytd_interest = 28;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdInterest = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdInterest = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string ytd_short_term_gain = 29;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdShortTermGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdShortTermGain = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string ytd_short_term_loss = 30;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdShortTermLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdShortTermLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string ytd_short_term_net_loss = 31;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdShortTermNetLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdShortTermNetLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string ytd_long_term_gain = 32;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdLongTermGain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdLongTermGain = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string ytd_long_term_loss = 33;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdLongTermLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdLongTermLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string ytd_long_term_net_loss = 34;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdLongTermNetLoss = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdLongTermNetLoss = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string ytd_fees = 35;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getYtdFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setYtdFees = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string currency = 36;
 * @return {string}
 */
proto.reportpb.Statement.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Statement} returns this
 */
proto.reportpb.Statement.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.StatementData.repeatedFields_ = [3,4,5,6,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.StatementData.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.StatementData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.StatementData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementData.toObject = function(includeInstance, msg) {
  var f, obj = {
    summary: (f = msg.getSummary()) && proto.reportpb.Statement.toObject(includeInstance, f),
    accountDetails: (f = msg.getAccountDetails()) && proto.reportpb.AccountDetails.toObject(includeInstance, f),
    holdingsList: jspb.Message.toObjectList(msg.getHoldingsList(),
    proto.reportpb.StatementHolding.toObject, includeInstance),
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    reportpb_activity_pb.Activity.toObject, includeInstance),
    incomesList: jspb.Message.toObjectList(msg.getIncomesList(),
    reportpb_activity_pb.Activity.toObject, includeInstance),
    transfersList: jspb.Message.toObjectList(msg.getTransfersList(),
    reportpb_activity_pb.Activity.toObject, includeInstance),
    address: (f = msg.getAddress()) && accountpb_address_pb.Address.toObject(includeInstance, f),
    accountSummary: (f = msg.getAccountSummary()) && reportpb_balance_pb.Balance.toObject(includeInstance, f),
    disclosure: (f = msg.getDisclosure()) && reportpb_statementdisclosure_pb.StatementDisclosure.toObject(includeInstance, f),
    template: (f = msg.getTemplate()) && reportpb_template_pb.Template.toObject(includeInstance, f),
    cash: (f = msg.getCash()) && proto.reportpb.StatementBalance.toObject(includeInstance, f),
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    reportpb_activity_pb.Activity.toObject, includeInstance),
    systemCurrency: jspb.Message.getFieldWithDefault(msg, 13, ""),
    baseCurrency: jspb.Message.getFieldWithDefault(msg, 14, ""),
    localSummary: (f = msg.getLocalSummary()) && proto.reportpb.Statement.toObject(includeInstance, f),
    localAccountSummary: (f = msg.getLocalAccountSummary()) && reportpb_balance_pb.Balance.toObject(includeInstance, f),
    localCash: (f = msg.getLocalCash()) && proto.reportpb.StatementBalance.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.StatementData}
 */
proto.reportpb.StatementData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.StatementData;
  return proto.reportpb.StatementData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.StatementData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.StatementData}
 */
proto.reportpb.StatementData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Statement;
      reader.readMessage(value,proto.reportpb.Statement.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    case 2:
      var value = new proto.reportpb.AccountDetails;
      reader.readMessage(value,proto.reportpb.AccountDetails.deserializeBinaryFromReader);
      msg.setAccountDetails(value);
      break;
    case 3:
      var value = new proto.reportpb.StatementHolding;
      reader.readMessage(value,proto.reportpb.StatementHolding.deserializeBinaryFromReader);
      msg.addHoldings(value);
      break;
    case 4:
      var value = new reportpb_activity_pb.Activity;
      reader.readMessage(value,reportpb_activity_pb.Activity.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 5:
      var value = new reportpb_activity_pb.Activity;
      reader.readMessage(value,reportpb_activity_pb.Activity.deserializeBinaryFromReader);
      msg.addIncomes(value);
      break;
    case 6:
      var value = new reportpb_activity_pb.Activity;
      reader.readMessage(value,reportpb_activity_pb.Activity.deserializeBinaryFromReader);
      msg.addTransfers(value);
      break;
    case 7:
      var value = new accountpb_address_pb.Address;
      reader.readMessage(value,accountpb_address_pb.Address.deserializeBinaryFromReader);
      msg.setAddress(value);
      break;
    case 8:
      var value = new reportpb_balance_pb.Balance;
      reader.readMessage(value,reportpb_balance_pb.Balance.deserializeBinaryFromReader);
      msg.setAccountSummary(value);
      break;
    case 9:
      var value = new reportpb_statementdisclosure_pb.StatementDisclosure;
      reader.readMessage(value,reportpb_statementdisclosure_pb.StatementDisclosure.deserializeBinaryFromReader);
      msg.setDisclosure(value);
      break;
    case 10:
      var value = new reportpb_template_pb.Template;
      reader.readMessage(value,reportpb_template_pb.Template.deserializeBinaryFromReader);
      msg.setTemplate(value);
      break;
    case 11:
      var value = new proto.reportpb.StatementBalance;
      reader.readMessage(value,proto.reportpb.StatementBalance.deserializeBinaryFromReader);
      msg.setCash(value);
      break;
    case 12:
      var value = new reportpb_activity_pb.Activity;
      reader.readMessage(value,reportpb_activity_pb.Activity.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemCurrency(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseCurrency(value);
      break;
    case 15:
      var value = new proto.reportpb.Statement;
      reader.readMessage(value,proto.reportpb.Statement.deserializeBinaryFromReader);
      msg.setLocalSummary(value);
      break;
    case 16:
      var value = new reportpb_balance_pb.Balance;
      reader.readMessage(value,reportpb_balance_pb.Balance.deserializeBinaryFromReader);
      msg.setLocalAccountSummary(value);
      break;
    case 17:
      var value = new proto.reportpb.StatementBalance;
      reader.readMessage(value,proto.reportpb.StatementBalance.deserializeBinaryFromReader);
      msg.setLocalCash(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.StatementData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.StatementData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.StatementData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.Statement.serializeBinaryToWriter
    );
  }
  f = message.getAccountDetails();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.AccountDetails.serializeBinaryToWriter
    );
  }
  f = message.getHoldingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reportpb.StatementHolding.serializeBinaryToWriter
    );
  }
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      reportpb_activity_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getIncomesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      reportpb_activity_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getTransfersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      6,
      f,
      reportpb_activity_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getAddress();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      accountpb_address_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getAccountSummary();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      reportpb_balance_pb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getDisclosure();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      reportpb_statementdisclosure_pb.StatementDisclosure.serializeBinaryToWriter
    );
  }
  f = message.getTemplate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      reportpb_template_pb.Template.serializeBinaryToWriter
    );
  }
  f = message.getCash();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.reportpb.StatementBalance.serializeBinaryToWriter
    );
  }
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      reportpb_activity_pb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getSystemCurrency();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getBaseCurrency();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLocalSummary();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.reportpb.Statement.serializeBinaryToWriter
    );
  }
  f = message.getLocalAccountSummary();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      reportpb_balance_pb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getLocalCash();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      proto.reportpb.StatementBalance.serializeBinaryToWriter
    );
  }
};


/**
 * optional Statement summary = 1;
 * @return {?proto.reportpb.Statement}
 */
proto.reportpb.StatementData.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.Statement} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.Statement, 1));
};


/**
 * @param {?proto.reportpb.Statement|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AccountDetails account_details = 2;
 * @return {?proto.reportpb.AccountDetails}
 */
proto.reportpb.StatementData.prototype.getAccountDetails = function() {
  return /** @type{?proto.reportpb.AccountDetails} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.AccountDetails, 2));
};


/**
 * @param {?proto.reportpb.AccountDetails|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setAccountDetails = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearAccountDetails = function() {
  return this.setAccountDetails(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasAccountDetails = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated StatementHolding holdings = 3;
 * @return {!Array<!proto.reportpb.StatementHolding>}
 */
proto.reportpb.StatementData.prototype.getHoldingsList = function() {
  return /** @type{!Array<!proto.reportpb.StatementHolding>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.StatementHolding, 3));
};


/**
 * @param {!Array<!proto.reportpb.StatementHolding>} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setHoldingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reportpb.StatementHolding=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.StatementHolding}
 */
proto.reportpb.StatementData.prototype.addHoldings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reportpb.StatementHolding, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearHoldingsList = function() {
  return this.setHoldingsList([]);
};


/**
 * repeated Activity transactions = 4;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.StatementData.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, reportpb_activity_pb.Activity, 4));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.StatementData.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * repeated Activity incomes = 5;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.StatementData.prototype.getIncomesList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, reportpb_activity_pb.Activity, 5));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setIncomesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.StatementData.prototype.addIncomes = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearIncomesList = function() {
  return this.setIncomesList([]);
};


/**
 * repeated Activity transfers = 6;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.StatementData.prototype.getTransfersList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, reportpb_activity_pb.Activity, 6));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setTransfersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 6, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.StatementData.prototype.addTransfers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 6, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearTransfersList = function() {
  return this.setTransfersList([]);
};


/**
 * optional accountpb.Address address = 7;
 * @return {?proto.accountpb.Address}
 */
proto.reportpb.StatementData.prototype.getAddress = function() {
  return /** @type{?proto.accountpb.Address} */ (
    jspb.Message.getWrapperField(this, accountpb_address_pb.Address, 7));
};


/**
 * @param {?proto.accountpb.Address|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setAddress = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearAddress = function() {
  return this.setAddress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasAddress = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional Balance account_summary = 8;
 * @return {?proto.reportpb.Balance}
 */
proto.reportpb.StatementData.prototype.getAccountSummary = function() {
  return /** @type{?proto.reportpb.Balance} */ (
    jspb.Message.getWrapperField(this, reportpb_balance_pb.Balance, 8));
};


/**
 * @param {?proto.reportpb.Balance|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setAccountSummary = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearAccountSummary = function() {
  return this.setAccountSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasAccountSummary = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional StatementDisclosure disclosure = 9;
 * @return {?proto.reportpb.StatementDisclosure}
 */
proto.reportpb.StatementData.prototype.getDisclosure = function() {
  return /** @type{?proto.reportpb.StatementDisclosure} */ (
    jspb.Message.getWrapperField(this, reportpb_statementdisclosure_pb.StatementDisclosure, 9));
};


/**
 * @param {?proto.reportpb.StatementDisclosure|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setDisclosure = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearDisclosure = function() {
  return this.setDisclosure(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasDisclosure = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional Template template = 10;
 * @return {?proto.reportpb.Template}
 */
proto.reportpb.StatementData.prototype.getTemplate = function() {
  return /** @type{?proto.reportpb.Template} */ (
    jspb.Message.getWrapperField(this, reportpb_template_pb.Template, 10));
};


/**
 * @param {?proto.reportpb.Template|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setTemplate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearTemplate = function() {
  return this.setTemplate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasTemplate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional StatementBalance cash = 11;
 * @return {?proto.reportpb.StatementBalance}
 */
proto.reportpb.StatementData.prototype.getCash = function() {
  return /** @type{?proto.reportpb.StatementBalance} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.StatementBalance, 11));
};


/**
 * @param {?proto.reportpb.StatementBalance|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setCash = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearCash = function() {
  return this.setCash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasCash = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated Activity fees = 12;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.StatementData.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, reportpb_activity_pb.Activity, 12));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.StatementData.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};


/**
 * optional string system_currency = 13;
 * @return {string}
 */
proto.reportpb.StatementData.prototype.getSystemCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.setSystemCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string base_currency = 14;
 * @return {string}
 */
proto.reportpb.StatementData.prototype.getBaseCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.setBaseCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional Statement local_summary = 15;
 * @return {?proto.reportpb.Statement}
 */
proto.reportpb.StatementData.prototype.getLocalSummary = function() {
  return /** @type{?proto.reportpb.Statement} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.Statement, 15));
};


/**
 * @param {?proto.reportpb.Statement|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setLocalSummary = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearLocalSummary = function() {
  return this.setLocalSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasLocalSummary = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Balance local_account_summary = 16;
 * @return {?proto.reportpb.Balance}
 */
proto.reportpb.StatementData.prototype.getLocalAccountSummary = function() {
  return /** @type{?proto.reportpb.Balance} */ (
    jspb.Message.getWrapperField(this, reportpb_balance_pb.Balance, 16));
};


/**
 * @param {?proto.reportpb.Balance|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setLocalAccountSummary = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearLocalAccountSummary = function() {
  return this.setLocalAccountSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasLocalAccountSummary = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional StatementBalance local_cash = 17;
 * @return {?proto.reportpb.StatementBalance}
 */
proto.reportpb.StatementData.prototype.getLocalCash = function() {
  return /** @type{?proto.reportpb.StatementBalance} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.StatementBalance, 17));
};


/**
 * @param {?proto.reportpb.StatementBalance|undefined} value
 * @return {!proto.reportpb.StatementData} returns this
*/
proto.reportpb.StatementData.prototype.setLocalCash = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementData} returns this
 */
proto.reportpb.StatementData.prototype.clearLocalCash = function() {
  return this.setLocalCash(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementData.prototype.hasLocalCash = function() {
  return jspb.Message.getField(this, 17) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AccountDetails.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AccountDetails.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AccountDetails} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountDetails.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountOwner: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AccountDetails}
 */
proto.reportpb.AccountDetails.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AccountDetails;
  return proto.reportpb.AccountDetails.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AccountDetails} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AccountDetails}
 */
proto.reportpb.AccountDetails.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountOwner(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AccountDetails.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AccountDetails.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AccountDetails} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AccountDetails.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountOwner();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_owner = 1;
 * @return {string}
 */
proto.reportpb.AccountDetails.prototype.getAccountOwner = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountDetails} returns this
 */
proto.reportpb.AccountDetails.prototype.setAccountOwner = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_type = 2;
 * @return {string}
 */
proto.reportpb.AccountDetails.prototype.getAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AccountDetails} returns this
 */
proto.reportpb.AccountDetails.prototype.setAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.StatementHolding.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.StatementHolding.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.StatementHolding} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementHolding.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 2, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 3, ""),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 4, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 5, ""),
    costPrice: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unrealized: jspb.Message.getFieldWithDefault(msg, 7, ""),
    tdCostBasis: jspb.Message.getFieldWithDefault(msg, 8, ""),
    localClosingPrice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    localMarketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    localCostPrice: jspb.Message.getFieldWithDefault(msg, 11, ""),
    localUnrealized: jspb.Message.getFieldWithDefault(msg, 12, ""),
    localTdCostBasis: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.StatementHolding}
 */
proto.reportpb.StatementHolding.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.StatementHolding;
  return proto.reportpb.StatementHolding.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.StatementHolding} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.StatementHolding}
 */
proto.reportpb.StatementHolding.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPrice(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrealized(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalClosingPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalCostPrice(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalUnrealized(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalTdCostBasis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.StatementHolding.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.StatementHolding.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.StatementHolding} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementHolding.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCostPrice();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnrealized();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLocalClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLocalMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLocalCostPrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLocalUnrealized();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLocalTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol_description = 2;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string qty = 3;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string closing_price = 4;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string market_value = 5;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cost_price = 6;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getCostPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setCostPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string unrealized = 7;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string td_cost_basis = 8;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string local_closing_price = 9;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getLocalClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setLocalClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string local_market_value = 10;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getLocalMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setLocalMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string local_cost_price = 11;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getLocalCostPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setLocalCostPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string local_unrealized = 12;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getLocalUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setLocalUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string local_td_cost_basis = 13;
 * @return {string}
 */
proto.reportpb.StatementHolding.prototype.getLocalTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementHolding} returns this
 */
proto.reportpb.StatementHolding.prototype.setLocalTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.StatementBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.StatementBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.StatementBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    balanceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 7, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 8, ""),
    type: jspb.Message.getFieldWithDefault(msg, 9, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tdCashBalance: jspb.Message.getFieldWithDefault(msg, 11, ""),
    tdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    tdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tdEquity: jspb.Message.getFieldWithDefault(msg, 14, ""),
    tdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 15, ""),
    sdCashBalance: jspb.Message.getFieldWithDefault(msg, 16, ""),
    sdLongMarketValue: jspb.Message.getFieldWithDefault(msg, 17, ""),
    sdShortMarketValue: jspb.Message.getFieldWithDefault(msg, 18, ""),
    sdEquity: jspb.Message.getFieldWithDefault(msg, 19, ""),
    sdAdjustedBalance: jspb.Message.getFieldWithDefault(msg, 20, ""),
    nextSdCashBalance: jspb.Message.getFieldWithDefault(msg, 21, ""),
    balance: (f = msg.getBalance()) && reportpb_balance_pb.Balance.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 23, ""),
    description: jspb.Message.getFieldWithDefault(msg, 24, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 25, ""),
    marketPrice: jspb.Message.getFieldWithDefault(msg, 26, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 27, ""),
    costPrice: jspb.Message.getFieldWithDefault(msg, 28, ""),
    unrealized: jspb.Message.getFieldWithDefault(msg, 29, ""),
    tdCostBasis: jspb.Message.getFieldWithDefault(msg, 30, ""),
    markedAtSwapRate: jspb.Message.getFieldWithDefault(msg, 31, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.StatementBalance}
 */
proto.reportpb.StatementBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.StatementBalance;
  return proto.reportpb.StatementBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.StatementBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.StatementBalance}
 */
proto.reportpb.StatementBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBalanceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCashBalance(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdLongMarketValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdShortMarketValue(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdEquity(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdAdjustedBalance(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdCashBalance(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdLongMarketValue(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdShortMarketValue(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdEquity(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSdAdjustedBalance(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNextSdCashBalance(value);
      break;
    case 22:
      var value = new reportpb_balance_pb.Balance;
      reader.readMessage(value,reportpb_balance_pb.Balance.deserializeBinaryFromReader);
      msg.setBalance(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketPrice(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setCostPrice(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setUnrealized(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarkedAtSwapRate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.StatementBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.StatementBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.StatementBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.StatementBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBalanceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getTdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getTdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTdEquity();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getTdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSdLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getSdShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getSdEquity();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSdAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNextSdCashBalance();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getBalance();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      reportpb_balance_pb.Balance.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getMarketPrice();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getCostPrice();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getUnrealized();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getMarkedAtSwapRate();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
};


/**
 * optional string balance_id = 1;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getBalanceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setBalanceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_designator = 7;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string broker = 8;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string type = 9;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional google.type.Date system_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.StatementBalance.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.StatementBalance} returns this
*/
proto.reportpb.StatementBalance.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementBalance.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string td_cash_balance = 11;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string td_long_market_value = 12;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string td_short_market_value = 13;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string td_equity = 14;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string td_adjusted_balance = 15;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string sd_cash_balance = 16;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string sd_long_market_value = 17;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSdLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSdLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string sd_short_market_value = 18;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSdShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSdShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string sd_equity = 19;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSdEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSdEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string sd_adjusted_balance = 20;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSdAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSdAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string next_sd_cash_balance = 21;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getNextSdCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setNextSdCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional Balance balance = 22;
 * @return {?proto.reportpb.Balance}
 */
proto.reportpb.StatementBalance.prototype.getBalance = function() {
  return /** @type{?proto.reportpb.Balance} */ (
    jspb.Message.getWrapperField(this, reportpb_balance_pb.Balance, 22));
};


/**
 * @param {?proto.reportpb.Balance|undefined} value
 * @return {!proto.reportpb.StatementBalance} returns this
*/
proto.reportpb.StatementBalance.prototype.setBalance = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.clearBalance = function() {
  return this.setBalance(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.StatementBalance.prototype.hasBalance = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string symbol = 23;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string description = 24;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string qty = 25;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string market_price = 26;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getMarketPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setMarketPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string market_value = 27;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string cost_price = 28;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getCostPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setCostPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string unrealized = 29;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getUnrealized = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setUnrealized = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string td_cost_basis = 30;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string marked_at_swap_rate = 31;
 * @return {string}
 */
proto.reportpb.StatementBalance.prototype.getMarkedAtSwapRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.StatementBalance} returns this
 */
proto.reportpb.StatementBalance.prototype.setMarkedAtSwapRate = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListStatementRequest}
 */
proto.reportpb.ListStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListStatementRequest;
  return proto.reportpb.ListStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListStatementRequest}
 */
proto.reportpb.ListStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 8:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
};


/**
 * optional string month = 1;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.reportpb.ListStatementRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string account_id = 7;
 * @return {string}
 */
proto.reportpb.ListStatementRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional utilspb.Pagination pagination = 8;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListStatementRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 8));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListStatementRequest} returns this
*/
proto.reportpb.ListStatementRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListStatementRequest} returns this
 */
proto.reportpb.ListStatementRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListStatementRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 8) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListStatementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListStatementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListStatementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListStatementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListStatementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statementsList: jspb.Message.toObjectList(msg.getStatementsList(),
    proto.reportpb.Statement.toObject, includeInstance),
    totalRows: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListStatementResponse}
 */
proto.reportpb.ListStatementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListStatementResponse;
  return proto.reportpb.ListStatementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListStatementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListStatementResponse}
 */
proto.reportpb.ListStatementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Statement;
      reader.readMessage(value,proto.reportpb.Statement.deserializeBinaryFromReader);
      msg.addStatements(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListStatementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListStatementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListStatementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListStatementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Statement.serializeBinaryToWriter
    );
  }
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated Statement statements = 1;
 * @return {!Array<!proto.reportpb.Statement>}
 */
proto.reportpb.ListStatementResponse.prototype.getStatementsList = function() {
  return /** @type{!Array<!proto.reportpb.Statement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Statement, 1));
};


/**
 * @param {!Array<!proto.reportpb.Statement>} value
 * @return {!proto.reportpb.ListStatementResponse} returns this
*/
proto.reportpb.ListStatementResponse.prototype.setStatementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Statement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Statement}
 */
proto.reportpb.ListStatementResponse.prototype.addStatements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Statement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListStatementResponse} returns this
 */
proto.reportpb.ListStatementResponse.prototype.clearStatementsList = function() {
  return this.setStatementsList([]);
};


/**
 * optional uint32 total_rows = 2;
 * @return {number}
 */
proto.reportpb.ListStatementResponse.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListStatementResponse} returns this
 */
proto.reportpb.ListStatementResponse.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.DownloadStatementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.DownloadStatementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.DownloadStatementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadStatementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    month: jspb.Message.getFieldWithDefault(msg, 1, ""),
    year: jspb.Message.getFieldWithDefault(msg, 2, 0),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.DownloadStatementRequest}
 */
proto.reportpb.DownloadStatementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.DownloadStatementRequest;
  return proto.reportpb.DownloadStatementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.DownloadStatementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.DownloadStatementRequest}
 */
proto.reportpb.DownloadStatementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMonth(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setYear(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.DownloadStatementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.DownloadStatementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.DownloadStatementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadStatementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMonth();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getYear();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string month = 1;
 * @return {string}
 */
proto.reportpb.DownloadStatementRequest.prototype.getMonth = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadStatementRequest} returns this
 */
proto.reportpb.DownloadStatementRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 year = 2;
 * @return {number}
 */
proto.reportpb.DownloadStatementRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.DownloadStatementRequest} returns this
 */
proto.reportpb.DownloadStatementRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.DownloadStatementRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadStatementRequest} returns this
 */
proto.reportpb.DownloadStatementRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.DownloadStatementRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadStatementRequest} returns this
 */
proto.reportpb.DownloadStatementRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_id = 5;
 * @return {string}
 */
proto.reportpb.DownloadStatementRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadStatementRequest} returns this
 */
proto.reportpb.DownloadStatementRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.reportpb);
