// source: reorgpb/split.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.ListSplitRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListSplitResponse', null, global);
goog.exportSymbol('proto.reorgpb.Split', null, global);
goog.exportSymbol('proto.reorgpb.UpdateSplitRequest', null, global);
goog.exportSymbol('proto.reorgpb.UpdateSplitResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.Split = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.Split, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.Split.displayName = 'proto.reorgpb.Split';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListSplitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListSplitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListSplitRequest.displayName = 'proto.reorgpb.ListSplitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListSplitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListSplitResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListSplitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListSplitResponse.displayName = 'proto.reorgpb.ListSplitResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateSplitRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateSplitRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateSplitRequest.displayName = 'proto.reorgpb.UpdateSplitRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateSplitResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateSplitResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateSplitResponse.displayName = 'proto.reorgpb.UpdateSplitResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.Split.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.Split.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.Split} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Split.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    targetOriginalCusip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    initiatingSymbol: jspb.Message.getFieldWithDefault(msg, 6, ""),
    initiatingOriginalCusip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    newRate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oldRate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    splitType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    recordDate: (f = msg.getRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    netCash: jspb.Message.getFieldWithDefault(msg, 15, ""),
    netQty: jspb.Message.getFieldWithDefault(msg, 16, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 17, false),
    pendingTrnsCount: jspb.Message.getFieldWithDefault(msg, 18, 0),
    detail: jspb.Message.getFieldWithDefault(msg, 19, ""),
    netPayCash: jspb.Message.getFieldWithDefault(msg, 20, ""),
    netPayQty: jspb.Message.getFieldWithDefault(msg, 21, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.Split}
 */
proto.reorgpb.Split.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.Split;
  return proto.reorgpb.Split.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.Split} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.Split}
 */
proto.reorgpb.Split.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOriginalCusip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingSymbol(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingOriginalCusip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCash(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSplitType(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setRecordDate(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPositionDate(value);
      break;
    case 14:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetCash(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetQty(value);
      break;
    case 17:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingTrnsCount(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayCash(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.Split.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.Split.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.Split} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Split.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTargetOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInitiatingSymbol();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInitiatingOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOldRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSplitType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getRecordDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getNetCash();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNetQty();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      17,
      f
    );
  }
  f = message.getPendingTrnsCount();
  if (f !== 0) {
    writer.writeInt32(
      18,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getNetPayCash();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getNetPayQty();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Split.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Split} returns this
*/
proto.reorgpb.Split.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date expiration_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Split.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Split} returns this
*/
proto.reorgpb.Split.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string target_symbol = 4;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string target_original_cusip = 5;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getTargetOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setTargetOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string initiating_symbol = 6;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getInitiatingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setInitiatingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string initiating_original_cusip = 7;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getInitiatingOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setInitiatingOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cash = 8;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setCash = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string new_rate = 9;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string old_rate = 10;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getOldRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setOldRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string split_type = 11;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getSplitType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setSplitType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.type.Date record_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Split.prototype.getRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Split} returns this
*/
proto.reorgpb.Split.prototype.setRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.clearRecordDate = function() {
  return this.setRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.hasRecordDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.type.Date position_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Split.prototype.getPositionDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Split} returns this
*/
proto.reorgpb.Split.prototype.setPositionDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.clearPositionDate = function() {
  return this.setPositionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.hasPositionDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional google.type.Date process_date = 14;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Split.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 14));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Split} returns this
*/
proto.reorgpb.Split.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional string net_cash = 15;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getNetCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNetCash = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string net_qty = 16;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getNetQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNetQty = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional bool no_cil = 17;
 * @return {boolean}
 */
proto.reorgpb.Split.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 17, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 17, value);
};


/**
 * optional int32 pending_trns_count = 18;
 * @return {number}
 */
proto.reorgpb.Split.prototype.getPendingTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setPendingTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional string detail = 19;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string net_pay_cash = 20;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getNetPayCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNetPayCash = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string net_pay_qty = 21;
 * @return {string}
 */
proto.reorgpb.Split.prototype.getNetPayQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Split} returns this
 */
proto.reorgpb.Split.prototype.setNetPayQty = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListSplitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListSplitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListSplitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSplitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    pendingTrnsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListSplitRequest}
 */
proto.reorgpb.ListSplitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListSplitRequest;
  return proto.reorgpb.ListSplitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListSplitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListSplitRequest}
 */
proto.reorgpb.ListSplitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingTrnsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListSplitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListSplitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListSplitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSplitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPendingTrnsOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string target_symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListSplitRequest.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListSplitRequest} returns this
 */
proto.reorgpb.ListSplitRequest.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListSplitRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListSplitRequest} returns this
*/
proto.reorgpb.ListSplitRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListSplitRequest} returns this
 */
proto.reorgpb.ListSplitRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListSplitRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListSplitRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListSplitRequest} returns this
*/
proto.reorgpb.ListSplitRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListSplitRequest} returns this
 */
proto.reorgpb.ListSplitRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListSplitRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_trns_only = 4;
 * @return {boolean}
 */
proto.reorgpb.ListSplitRequest.prototype.getPendingTrnsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.ListSplitRequest} returns this
 */
proto.reorgpb.ListSplitRequest.prototype.setPendingTrnsOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListSplitResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListSplitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListSplitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListSplitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSplitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    splitsList: jspb.Message.toObjectList(msg.getSplitsList(),
    proto.reorgpb.Split.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListSplitResponse}
 */
proto.reorgpb.ListSplitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListSplitResponse;
  return proto.reorgpb.ListSplitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListSplitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListSplitResponse}
 */
proto.reorgpb.ListSplitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Split;
      reader.readMessage(value,proto.reorgpb.Split.deserializeBinaryFromReader);
      msg.addSplits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListSplitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListSplitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListSplitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSplitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplitsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.Split.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Split splits = 1;
 * @return {!Array<!proto.reorgpb.Split>}
 */
proto.reorgpb.ListSplitResponse.prototype.getSplitsList = function() {
  return /** @type{!Array<!proto.reorgpb.Split>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.Split, 1));
};


/**
 * @param {!Array<!proto.reorgpb.Split>} value
 * @return {!proto.reorgpb.ListSplitResponse} returns this
*/
proto.reorgpb.ListSplitResponse.prototype.setSplitsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.Split=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.Split}
 */
proto.reorgpb.ListSplitResponse.prototype.addSplits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.Split, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListSplitResponse} returns this
 */
proto.reorgpb.ListSplitResponse.prototype.clearSplitsList = function() {
  return this.setSplitsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateSplitRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateSplitRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateSplitRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSplitRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    newRate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    oldRate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateSplitRequest}
 */
proto.reorgpb.UpdateSplitRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateSplitRequest;
  return proto.reorgpb.UpdateSplitRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateSplitRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateSplitRequest}
 */
proto.reorgpb.UpdateSplitRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateSplitRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateSplitRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateSplitRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSplitRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOldRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.UpdateSplitRequest.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
 */
proto.reorgpb.UpdateSplitRequest.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool no_cil = 2;
 * @return {boolean}
 */
proto.reorgpb.UpdateSplitRequest.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
 */
proto.reorgpb.UpdateSplitRequest.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string new_rate = 3;
 * @return {string}
 */
proto.reorgpb.UpdateSplitRequest.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
 */
proto.reorgpb.UpdateSplitRequest.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string old_rate = 4;
 * @return {string}
 */
proto.reorgpb.UpdateSplitRequest.prototype.getOldRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
 */
proto.reorgpb.UpdateSplitRequest.prototype.setOldRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date process_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.UpdateSplitRequest.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
*/
proto.reorgpb.UpdateSplitRequest.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateSplitRequest} returns this
 */
proto.reorgpb.UpdateSplitRequest.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateSplitRequest.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateSplitResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateSplitResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateSplitResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSplitResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    split: (f = msg.getSplit()) && proto.reorgpb.Split.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateSplitResponse}
 */
proto.reorgpb.UpdateSplitResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateSplitResponse;
  return proto.reorgpb.UpdateSplitResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateSplitResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateSplitResponse}
 */
proto.reorgpb.UpdateSplitResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Split;
      reader.readMessage(value,proto.reorgpb.Split.deserializeBinaryFromReader);
      msg.setSplit(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateSplitResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateSplitResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateSplitResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSplitResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSplit();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.Split.serializeBinaryToWriter
    );
  }
};


/**
 * optional Split split = 1;
 * @return {?proto.reorgpb.Split}
 */
proto.reorgpb.UpdateSplitResponse.prototype.getSplit = function() {
  return /** @type{?proto.reorgpb.Split} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.Split, 1));
};


/**
 * @param {?proto.reorgpb.Split|undefined} value
 * @return {!proto.reorgpb.UpdateSplitResponse} returns this
*/
proto.reorgpb.UpdateSplitResponse.prototype.setSplit = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateSplitResponse} returns this
 */
proto.reorgpb.UpdateSplitResponse.prototype.clearSplit = function() {
  return this.setSplit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateSplitResponse.prototype.hasSplit = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.reorgpb);
