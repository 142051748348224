/**
 * @fileoverview gRPC-Web generated client stub for commonpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.commonpb = require('./list_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.ListServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.commonpb.ListServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ReadPrimaryOwnerRequest,
 *   !proto.commonpb.ReadPrimaryOwnerResponse>}
 */
const methodDescriptor_ListService_ReadPrimaryOwner = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ReadPrimaryOwner',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ReadPrimaryOwnerRequest,
  proto.commonpb.ReadPrimaryOwnerResponse,
  /**
   * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ReadPrimaryOwnerResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.ReadPrimaryOwnerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ReadPrimaryOwnerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.readPrimaryOwner =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ReadPrimaryOwner',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadPrimaryOwner,
      callback);
};


/**
 * @param {!proto.commonpb.ReadPrimaryOwnerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ReadPrimaryOwnerResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.readPrimaryOwner =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ReadPrimaryOwner',
      request,
      metadata || {},
      methodDescriptor_ListService_ReadPrimaryOwner);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListAccountRequest,
 *   !proto.commonpb.ListAccountResponse>}
 */
const methodDescriptor_ListService_ListAccount = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListAccountRequest,
  proto.commonpb.ListAccountResponse,
  /**
   * @param {!proto.commonpb.ListAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.ListAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListAccount,
      callback);
};


/**
 * @param {!proto.commonpb.ListAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListBankAccountRequest,
 *   !proto.commonpb.ListBankAccountResponse>}
 */
const methodDescriptor_ListService_ListBankAccount = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListBankAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListBankAccountRequest,
  proto.commonpb.ListBankAccountResponse,
  /**
   * @param {!proto.commonpb.ListBankAccountRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListBankAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.ListBankAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListBankAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listBankAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListBankAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankAccount,
      callback);
};


/**
 * @param {!proto.commonpb.ListBankAccountRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListBankAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listBankAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListBankAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_ListBankAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.ListSecurityRequest,
 *   !proto.commonpb.ListSecurityResponse>}
 */
const methodDescriptor_ListService_ListSecurity = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/ListSecurity',
  grpc.web.MethodType.UNARY,
  proto.commonpb.ListSecurityRequest,
  proto.commonpb.ListSecurityResponse,
  /**
   * @param {!proto.commonpb.ListSecurityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.ListSecurityResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.ListSecurityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.ListSecurityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.ListSecurityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.listSecurity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/ListSecurity',
      request,
      metadata || {},
      methodDescriptor_ListService_ListSecurity,
      callback);
};


/**
 * @param {!proto.commonpb.ListSecurityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.ListSecurityResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.listSecurity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/ListSecurity',
      request,
      metadata || {},
      methodDescriptor_ListService_ListSecurity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyLoadAccountResponse>}
 */
const methodDescriptor_ListService_LazyLoadAccount = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/LazyLoadAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyLoadAccountResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.LazyLoadAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.lazyLoadAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadAccount,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.lazyLoadAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyLoadSecurityResponse>}
 */
const methodDescriptor_ListService_LazyLoadSecurity = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/LazyLoadSecurity',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyLoadSecurityResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadSecurityResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.LazyLoadSecurityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadSecurityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.lazyLoadSecurity =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadSecurity',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadSecurity,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadSecurityResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.lazyLoadSecurity =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadSecurity',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadSecurity);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyLoadAccountResponse>}
 */
const methodDescriptor_ListService_LazyLoadClientAccount = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/LazyLoadClientAccount',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyLoadAccountResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadAccountResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.LazyLoadAccountResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadAccountResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.lazyLoadClientAccount =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadClientAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadClientAccount,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadAccountResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.lazyLoadClientAccount =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadClientAccount',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadClientAccount);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyLoadCorrespondentResponse>}
 */
const methodDescriptor_ListService_LazyLoadCorrespondent = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/LazyLoadCorrespondent',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyLoadCorrespondentResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadCorrespondentResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.LazyLoadCorrespondentResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadCorrespondentResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.lazyLoadCorrespondent =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadCorrespondent',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadCorrespondent,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadCorrespondentResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.lazyLoadCorrespondent =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadCorrespondent',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadCorrespondent);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.commonpb.LazyLoadRequest,
 *   !proto.commonpb.LazyLoadCurrencyResponse>}
 */
const methodDescriptor_ListService_LazyLoadCurrency = new grpc.web.MethodDescriptor(
  '/commonpb.ListService/LazyLoadCurrency',
  grpc.web.MethodType.UNARY,
  proto.commonpb.LazyLoadRequest,
  proto.commonpb.LazyLoadCurrencyResponse,
  /**
   * @param {!proto.commonpb.LazyLoadRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.commonpb.LazyLoadCurrencyResponse.deserializeBinary
);


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.LazyLoadCurrencyResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.LazyLoadCurrencyResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.commonpb.ListServiceClient.prototype.lazyLoadCurrency =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadCurrency',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadCurrency,
      callback);
};


/**
 * @param {!proto.commonpb.LazyLoadRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.LazyLoadCurrencyResponse>}
 *     Promise that resolves to the response
 */
proto.commonpb.ListServicePromiseClient.prototype.lazyLoadCurrency =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/commonpb.ListService/LazyLoadCurrency',
      request,
      metadata || {},
      methodDescriptor_ListService_LazyLoadCurrency);
};


module.exports = proto.commonpb;

