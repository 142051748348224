// source: trnspb/pendingtrns.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var trnspb_transaction_pb = require('../trnspb/transaction_pb.js');
goog.object.extend(proto, trnspb_transaction_pb);
goog.exportSymbol('proto.trnspb.DeletePendingTrnsRequest', null, global);
goog.exportSymbol('proto.trnspb.DeletePendingTrnsResponse', null, global);
goog.exportSymbol('proto.trnspb.ExecutePendingTrnsRequest', null, global);
goog.exportSymbol('proto.trnspb.ExecutePendingTrnsResponse', null, global);
goog.exportSymbol('proto.trnspb.ListPendingTrnsRequest', null, global);
goog.exportSymbol('proto.trnspb.ListPendingTrnsResponse', null, global);
goog.exportSymbol('proto.trnspb.PendingTrns', null, global);
goog.exportSymbol('proto.trnspb.PendingTrnsApplyCIL', null, global);
goog.exportSymbol('proto.trnspb.PendingTrnsDateFilter', null, global);
goog.exportSymbol('proto.trnspb.PendingTrnsFilters', null, global);
goog.exportSymbol('proto.trnspb.ReadPendingTrnsRequest', null, global);
goog.exportSymbol('proto.trnspb.ReadPendingTrnsResponse', null, global);
goog.exportSymbol('proto.trnspb.TrnsAudit', null, global);
goog.exportSymbol('proto.trnspb.TrnsAudits', null, global);
goog.exportSymbol('proto.trnspb.UpdatePendingTrnsApplyCILRResponse', null, global);
goog.exportSymbol('proto.trnspb.UpdatePendingTrnsApplyCILRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.PendingTrns = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.PendingTrns, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.PendingTrns.displayName = 'proto.trnspb.PendingTrns';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ListPendingTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ListPendingTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ListPendingTrnsRequest.displayName = 'proto.trnspb.ListPendingTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.TrnsAudits = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.TrnsAudits.repeatedFields_, null);
};
goog.inherits(proto.trnspb.TrnsAudits, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.TrnsAudits.displayName = 'proto.trnspb.TrnsAudits';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.TrnsAudit = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.TrnsAudit, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.TrnsAudit.displayName = 'proto.trnspb.TrnsAudit';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ReadPendingTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ReadPendingTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ReadPendingTrnsRequest.displayName = 'proto.trnspb.ReadPendingTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ListPendingTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.ListPendingTrnsResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.ListPendingTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ListPendingTrnsResponse.displayName = 'proto.trnspb.ListPendingTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ReadPendingTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ReadPendingTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ReadPendingTrnsResponse.displayName = 'proto.trnspb.ReadPendingTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.DeletePendingTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.DeletePendingTrnsRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.DeletePendingTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.DeletePendingTrnsRequest.displayName = 'proto.trnspb.DeletePendingTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.PendingTrnsFilters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.PendingTrnsFilters.repeatedFields_, null);
};
goog.inherits(proto.trnspb.PendingTrnsFilters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.PendingTrnsFilters.displayName = 'proto.trnspb.PendingTrnsFilters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.PendingTrnsDateFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.PendingTrnsDateFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.PendingTrnsDateFilter.displayName = 'proto.trnspb.PendingTrnsDateFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.DeletePendingTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.DeletePendingTrnsResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.DeletePendingTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.DeletePendingTrnsResponse.displayName = 'proto.trnspb.DeletePendingTrnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.PendingTrnsApplyCIL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.PendingTrnsApplyCIL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.PendingTrnsApplyCIL.displayName = 'proto.trnspb.PendingTrnsApplyCIL';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.UpdatePendingTrnsApplyCILRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.UpdatePendingTrnsApplyCILRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.UpdatePendingTrnsApplyCILRequest.displayName = 'proto.trnspb.UpdatePendingTrnsApplyCILRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.UpdatePendingTrnsApplyCILRResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.UpdatePendingTrnsApplyCILRResponse.displayName = 'proto.trnspb.UpdatePendingTrnsApplyCILRResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ExecutePendingTrnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ExecutePendingTrnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ExecutePendingTrnsRequest.displayName = 'proto.trnspb.ExecutePendingTrnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ExecutePendingTrnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ExecutePendingTrnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ExecutePendingTrnsResponse.displayName = 'proto.trnspb.ExecutePendingTrnsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.PendingTrns.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.PendingTrns.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.PendingTrns} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrns.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 4, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeAt: (f = msg.getTradeAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    side: jspb.Message.getFieldWithDefault(msg, 9, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 10, ""),
    price: jspb.Message.getFieldWithDefault(msg, 11, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    batchNo: jspb.Message.getFieldWithDefault(msg, 13, ""),
    previousTrnId: jspb.Message.getFieldWithDefault(msg, 14, ""),
    status: jspb.Message.getFieldWithDefault(msg, 15, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 16, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 19, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 20, ""),
    description: jspb.Message.getFieldWithDefault(msg, 21, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 22, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 23, ""),
    contraAccountId: jspb.Message.getFieldWithDefault(msg, 24, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 25, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 26, ""),
    executionId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 28, ""),
    executionVenue: jspb.Message.getFieldWithDefault(msg, 29, ""),
    leavesQty: jspb.Message.getFieldWithDefault(msg, 30, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 31, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 32, ""),
    contraAccountDesignator: jspb.Message.getFieldWithDefault(msg, 33, ""),
    contraCorrespondent: jspb.Message.getFieldWithDefault(msg, 34, ""),
    parentId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    refId: jspb.Message.getFieldWithDefault(msg, 36, ""),
    entrySubType: jspb.Message.getFieldWithDefault(msg, 37, ""),
    cumQty: jspb.Message.getFieldWithDefault(msg, 38, ""),
    orderType: jspb.Message.getFieldWithDefault(msg, 39, ""),
    tif: jspb.Message.getFieldWithDefault(msg, 40, ""),
    clientOrderId: jspb.Message.getFieldWithDefault(msg, 41, ""),
    executingBrokerMpid: jspb.Message.getFieldWithDefault(msg, 42, ""),
    contraSymbol: jspb.Message.getFieldWithDefault(msg, 43, ""),
    swapRate: jspb.Message.getFieldWithDefault(msg, 44, ""),
    swapRateAt: (f = msg.getSwapRateAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    localPrice: jspb.Message.getFieldWithDefault(msg, 47, ""),
    localNetAmt: jspb.Message.getFieldWithDefault(msg, 48, ""),
    localGrossAmt: jspb.Message.getFieldWithDefault(msg, 49, ""),
    swapFeeBpsCorr: jspb.Message.getFieldWithDefault(msg, 50, ""),
    swapFeeBpsAlpa: jspb.Message.getFieldWithDefault(msg, 51, ""),
    metadata: (f = msg.getMetadata()) && trnspb_transaction_pb.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.PendingTrns}
 */
proto.trnspb.PendingTrns.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.PendingTrns;
  return proto.trnspb.PendingTrns.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.PendingTrns} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.PendingTrns}
 */
proto.trnspb.PendingTrns.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTradeAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 12:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousTrnId(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 17:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountId(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionVenue(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeavesQty(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountDesignator(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraCorrespondent(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefId(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntrySubType(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setCumQty(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderType(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setTif(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrderId(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingBrokerMpid(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSymbol(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapRate(value);
      break;
    case 45:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSwapRateAt(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalPrice(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalNetAmt(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalGrossAmt(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsCorr(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsAlpa(value);
      break;
    case 52:
      var value = new trnspb_transaction_pb.Metadata;
      reader.readMessage(value,trnspb_transaction_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.PendingTrns.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.PendingTrns.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.PendingTrns} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrns.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getPreviousTrnId();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getContraAccountId();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getExecutionVenue();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getLeavesQty();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getContraAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getContraCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getParentId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getEntrySubType();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getCumQty();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getOrderType();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getTif();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getClientOrderId();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getExecutingBrokerMpid();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getContraSymbol();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getSwapRate();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getSwapRateAt();
  if (f != null) {
    writer.writeMessage(
      45,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocalPrice();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getLocalNetAmt();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getLocalGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getSwapFeeBpsCorr();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getSwapFeeBpsAlpa();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      52,
      f,
      trnspb_transaction_pb.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stage_id = 1;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getStageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setStageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string entry_type = 4;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date system_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.PendingTrns.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date trade_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.PendingTrns.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp trade_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.PendingTrns.prototype.getTradeAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setTradeAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearTradeAt = function() {
  return this.setTradeAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasTradeAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string side = 9;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string qty = 10;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string price = 11;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 12;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.PendingTrns.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 12));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional string batch_no = 13;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string previous_trn_id = 14;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getPreviousTrnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setPreviousTrnId = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string status = 15;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string contra_account_no = 16;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional google.type.Date settle_date = 17;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.PendingTrns.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 17));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional string gross_amt = 18;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string net_amt = 19;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string fees = 20;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string description = 21;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string created_by = 22;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string account_id = 23;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string contra_account_id = 24;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getContraAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setContraAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string original_cusip = 25;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string error_message = 26;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string execution_id = 27;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string external_id = 28;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string execution_venue = 29;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getExecutionVenue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setExecutionVenue = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string leaves_qty = 30;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getLeavesQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setLeavesQty = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string order_id = 31;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string vendor = 32;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string contra_account_designator = 33;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getContraAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setContraAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string contra_correspondent = 34;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getContraCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setContraCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string parent_id = 35;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getParentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setParentId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string ref_id = 36;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getRefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setRefId = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string entry_sub_type = 37;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getEntrySubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setEntrySubType = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string cum_qty = 38;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getCumQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setCumQty = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string order_type = 39;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getOrderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string tif = 40;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getTif = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setTif = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string client_order_id = 41;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getClientOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setClientOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string executing_broker_mpid = 42;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getExecutingBrokerMpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setExecutingBrokerMpid = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string contra_symbol = 43;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getContraSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setContraSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string swap_rate = 44;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getSwapRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setSwapRate = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional google.protobuf.Timestamp swap_rate_at = 45;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.PendingTrns.prototype.getSwapRateAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 45));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setSwapRateAt = function(value) {
  return jspb.Message.setWrapperField(this, 45, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearSwapRateAt = function() {
  return this.setSwapRateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasSwapRateAt = function() {
  return jspb.Message.getField(this, 45) != null;
};


/**
 * optional string local_price = 47;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getLocalPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setLocalPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string local_net_amt = 48;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getLocalNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setLocalNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string local_gross_amt = 49;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getLocalGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setLocalGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string swap_fee_bps_corr = 50;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getSwapFeeBpsCorr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setSwapFeeBpsCorr = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string swap_fee_bps_alpa = 51;
 * @return {string}
 */
proto.trnspb.PendingTrns.prototype.getSwapFeeBpsAlpa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.setSwapFeeBpsAlpa = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional Metadata metadata = 52;
 * @return {?proto.trnspb.Metadata}
 */
proto.trnspb.PendingTrns.prototype.getMetadata = function() {
  return /** @type{?proto.trnspb.Metadata} */ (
    jspb.Message.getWrapperField(this, trnspb_transaction_pb.Metadata, 52));
};


/**
 * @param {?proto.trnspb.Metadata|undefined} value
 * @return {!proto.trnspb.PendingTrns} returns this
*/
proto.trnspb.PendingTrns.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 52, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrns} returns this
 */
proto.trnspb.PendingTrns.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrns.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 52) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ListPendingTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ListPendingTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListPendingTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    entrySubType: jspb.Message.getFieldWithDefault(msg, 10, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 11, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ListPendingTrnsRequest}
 */
proto.trnspb.ListPendingTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ListPendingTrnsRequest;
  return proto.trnspb.ListPendingTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ListPendingTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ListPendingTrnsRequest}
 */
proto.trnspb.ListPendingTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntrySubType(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ListPendingTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ListPendingTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListPendingTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getEntrySubType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_account_no = 3;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol = 4;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string date_type = 6;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.type.Date from_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
*/
proto.trnspb.ListPendingTrnsRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.type.Date to_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
*/
proto.trnspb.ListPendingTrnsRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string entry_type = 9;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string entry_sub_type = 10;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getEntrySubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setEntrySubType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string batch_no = 11;
 * @return {string}
 */
proto.trnspb.ListPendingTrnsRequest.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ListPendingTrnsRequest} returns this
 */
proto.trnspb.ListPendingTrnsRequest.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.TrnsAudits.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.TrnsAudits.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.TrnsAudits.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.TrnsAudits} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TrnsAudits.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsAuditsList: jspb.Message.toObjectList(msg.getTrnsAuditsList(),
    proto.trnspb.TrnsAudit.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.TrnsAudits}
 */
proto.trnspb.TrnsAudits.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.TrnsAudits;
  return proto.trnspb.TrnsAudits.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.TrnsAudits} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.TrnsAudits}
 */
proto.trnspb.TrnsAudits.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TrnsAudit;
      reader.readMessage(value,proto.trnspb.TrnsAudit.deserializeBinaryFromReader);
      msg.addTrnsAudits(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.TrnsAudits.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.TrnsAudits.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.TrnsAudits} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TrnsAudits.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsAuditsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TrnsAudit.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TrnsAudit trns_audits = 1;
 * @return {!Array<!proto.trnspb.TrnsAudit>}
 */
proto.trnspb.TrnsAudits.prototype.getTrnsAuditsList = function() {
  return /** @type{!Array<!proto.trnspb.TrnsAudit>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TrnsAudit, 1));
};


/**
 * @param {!Array<!proto.trnspb.TrnsAudit>} value
 * @return {!proto.trnspb.TrnsAudits} returns this
*/
proto.trnspb.TrnsAudits.prototype.setTrnsAuditsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TrnsAudit=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TrnsAudit}
 */
proto.trnspb.TrnsAudits.prototype.addTrnsAudits = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TrnsAudit, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.TrnsAudits} returns this
 */
proto.trnspb.TrnsAudits.prototype.clearTrnsAuditsList = function() {
  return this.setTrnsAuditsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.TrnsAudit.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.TrnsAudit.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.TrnsAudit} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TrnsAudit.toObject = function(includeInstance, msg) {
  var f, obj = {
    eventId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    at: (f = msg.getAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    trns: (f = msg.getTrns()) && proto.trnspb.PendingTrns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.TrnsAudit}
 */
proto.trnspb.TrnsAudit.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.TrnsAudit;
  return proto.trnspb.TrnsAudit.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.TrnsAudit} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.TrnsAudit}
 */
proto.trnspb.TrnsAudit.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEventId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAt(value);
      break;
    case 3:
      var value = new proto.trnspb.PendingTrns;
      reader.readMessage(value,proto.trnspb.PendingTrns.deserializeBinaryFromReader);
      msg.setTrns(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.TrnsAudit.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.TrnsAudit.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.TrnsAudit} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TrnsAudit.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEventId();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getTrns();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.trnspb.PendingTrns.serializeBinaryToWriter
    );
  }
};


/**
 * optional int64 event_id = 1;
 * @return {number}
 */
proto.trnspb.TrnsAudit.prototype.getEventId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.trnspb.TrnsAudit} returns this
 */
proto.trnspb.TrnsAudit.prototype.setEventId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.TrnsAudit.prototype.getAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.TrnsAudit} returns this
*/
proto.trnspb.TrnsAudit.prototype.setAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.TrnsAudit} returns this
 */
proto.trnspb.TrnsAudit.prototype.clearAt = function() {
  return this.setAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.TrnsAudit.prototype.hasAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional PendingTrns trns = 3;
 * @return {?proto.trnspb.PendingTrns}
 */
proto.trnspb.TrnsAudit.prototype.getTrns = function() {
  return /** @type{?proto.trnspb.PendingTrns} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrns, 3));
};


/**
 * @param {?proto.trnspb.PendingTrns|undefined} value
 * @return {!proto.trnspb.TrnsAudit} returns this
*/
proto.trnspb.TrnsAudit.prototype.setTrns = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.TrnsAudit} returns this
 */
proto.trnspb.TrnsAudit.prototype.clearTrns = function() {
  return this.setTrns(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.TrnsAudit.prototype.hasTrns = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ReadPendingTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ReadPendingTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ReadPendingTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadPendingTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ReadPendingTrnsRequest}
 */
proto.trnspb.ReadPendingTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ReadPendingTrnsRequest;
  return proto.trnspb.ReadPendingTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ReadPendingTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ReadPendingTrnsRequest}
 */
proto.trnspb.ReadPendingTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ReadPendingTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ReadPendingTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ReadPendingTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadPendingTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string stage_id = 1;
 * @return {string}
 */
proto.trnspb.ReadPendingTrnsRequest.prototype.getStageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ReadPendingTrnsRequest} returns this
 */
proto.trnspb.ReadPendingTrnsRequest.prototype.setStageId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.ListPendingTrnsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ListPendingTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ListPendingTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ListPendingTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListPendingTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingTransactionsList: jspb.Message.toObjectList(msg.getPendingTransactionsList(),
    proto.trnspb.PendingTrns.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ListPendingTrnsResponse}
 */
proto.trnspb.ListPendingTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ListPendingTrnsResponse;
  return proto.trnspb.ListPendingTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ListPendingTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ListPendingTrnsResponse}
 */
proto.trnspb.ListPendingTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingTrns;
      reader.readMessage(value,proto.trnspb.PendingTrns.deserializeBinaryFromReader);
      msg.addPendingTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ListPendingTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ListPendingTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ListPendingTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListPendingTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.PendingTrns.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingTrns pending_transactions = 1;
 * @return {!Array<!proto.trnspb.PendingTrns>}
 */
proto.trnspb.ListPendingTrnsResponse.prototype.getPendingTransactionsList = function() {
  return /** @type{!Array<!proto.trnspb.PendingTrns>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.PendingTrns, 1));
};


/**
 * @param {!Array<!proto.trnspb.PendingTrns>} value
 * @return {!proto.trnspb.ListPendingTrnsResponse} returns this
*/
proto.trnspb.ListPendingTrnsResponse.prototype.setPendingTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.PendingTrns=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.PendingTrns}
 */
proto.trnspb.ListPendingTrnsResponse.prototype.addPendingTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.PendingTrns, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.ListPendingTrnsResponse} returns this
 */
proto.trnspb.ListPendingTrnsResponse.prototype.clearPendingTransactionsList = function() {
  return this.setPendingTransactionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ReadPendingTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ReadPendingTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ReadPendingTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadPendingTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingTransaction: (f = msg.getPendingTransaction()) && proto.trnspb.PendingTrns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ReadPendingTrnsResponse}
 */
proto.trnspb.ReadPendingTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ReadPendingTrnsResponse;
  return proto.trnspb.ReadPendingTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ReadPendingTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ReadPendingTrnsResponse}
 */
proto.trnspb.ReadPendingTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingTrns;
      reader.readMessage(value,proto.trnspb.PendingTrns.deserializeBinaryFromReader);
      msg.setPendingTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ReadPendingTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ReadPendingTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ReadPendingTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadPendingTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.trnspb.PendingTrns.serializeBinaryToWriter
    );
  }
};


/**
 * optional PendingTrns pending_transaction = 1;
 * @return {?proto.trnspb.PendingTrns}
 */
proto.trnspb.ReadPendingTrnsResponse.prototype.getPendingTransaction = function() {
  return /** @type{?proto.trnspb.PendingTrns} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrns, 1));
};


/**
 * @param {?proto.trnspb.PendingTrns|undefined} value
 * @return {!proto.trnspb.ReadPendingTrnsResponse} returns this
*/
proto.trnspb.ReadPendingTrnsResponse.prototype.setPendingTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.ReadPendingTrnsResponse} returns this
 */
proto.trnspb.ReadPendingTrnsResponse.prototype.clearPendingTransaction = function() {
  return this.setPendingTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.ReadPendingTrnsResponse.prototype.hasPendingTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.DeletePendingTrnsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.DeletePendingTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.DeletePendingTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.DeletePendingTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    filters: (f = msg.getFilters()) && proto.trnspb.PendingTrnsFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.DeletePendingTrnsRequest}
 */
proto.trnspb.DeletePendingTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.DeletePendingTrnsRequest;
  return proto.trnspb.DeletePendingTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.DeletePendingTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.DeletePendingTrnsRequest}
 */
proto.trnspb.DeletePendingTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStageId(value);
      break;
    case 2:
      var value = new proto.trnspb.PendingTrnsFilters;
      reader.readMessage(value,proto.trnspb.PendingTrnsFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.DeletePendingTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.DeletePendingTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.DeletePendingTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.trnspb.PendingTrnsFilters.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string stage_id = 1;
 * @return {!Array<string>}
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.getStageIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.DeletePendingTrnsRequest} returns this
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.setStageIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.DeletePendingTrnsRequest} returns this
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.addStageId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.DeletePendingTrnsRequest} returns this
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.clearStageIdList = function() {
  return this.setStageIdList([]);
};


/**
 * optional PendingTrnsFilters filters = 2;
 * @return {?proto.trnspb.PendingTrnsFilters}
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.getFilters = function() {
  return /** @type{?proto.trnspb.PendingTrnsFilters} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrnsFilters, 2));
};


/**
 * @param {?proto.trnspb.PendingTrnsFilters|undefined} value
 * @return {!proto.trnspb.DeletePendingTrnsRequest} returns this
*/
proto.trnspb.DeletePendingTrnsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.DeletePendingTrnsRequest} returns this
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.DeletePendingTrnsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.PendingTrnsFilters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.PendingTrnsFilters.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.PendingTrnsFilters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.PendingTrnsFilters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsFilters.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    date: (f = msg.getDate()) && proto.trnspb.PendingTrnsDateFilter.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.PendingTrnsFilters}
 */
proto.trnspb.PendingTrnsFilters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.PendingTrnsFilters;
  return proto.trnspb.PendingTrnsFilters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.PendingTrnsFilters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.PendingTrnsFilters}
 */
proto.trnspb.PendingTrnsFilters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStageIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 7:
      var value = new proto.trnspb.PendingTrnsDateFilter;
      reader.readMessage(value,proto.trnspb.PendingTrnsDateFilter.deserializeBinaryFromReader);
      msg.setDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.PendingTrnsFilters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.PendingTrnsFilters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.PendingTrnsFilters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsFilters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.trnspb.PendingTrnsDateFilter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string stage_ids = 1;
 * @return {!Array<string>}
 */
proto.trnspb.PendingTrnsFilters.prototype.getStageIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setStageIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.addStageIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.clearStageIdsList = function() {
  return this.setStageIdsList([]);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.trnspb.PendingTrnsFilters.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.trnspb.PendingTrnsFilters.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contra_account_no = 4;
 * @return {string}
 */
proto.trnspb.PendingTrnsFilters.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol = 5;
 * @return {string}
 */
proto.trnspb.PendingTrnsFilters.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string entry_type = 6;
 * @return {string}
 */
proto.trnspb.PendingTrnsFilters.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional PendingTrnsDateFilter date = 7;
 * @return {?proto.trnspb.PendingTrnsDateFilter}
 */
proto.trnspb.PendingTrnsFilters.prototype.getDate = function() {
  return /** @type{?proto.trnspb.PendingTrnsDateFilter} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrnsDateFilter, 7));
};


/**
 * @param {?proto.trnspb.PendingTrnsDateFilter|undefined} value
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
*/
proto.trnspb.PendingTrnsFilters.prototype.setDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrnsFilters} returns this
 */
proto.trnspb.PendingTrnsFilters.prototype.clearDate = function() {
  return this.setDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrnsFilters.prototype.hasDate = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.PendingTrnsDateFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.PendingTrnsDateFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsDateFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, ""),
    from: (f = msg.getFrom()) && google_type_date_pb.Date.toObject(includeInstance, f),
    to: (f = msg.getTo()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.PendingTrnsDateFilter}
 */
proto.trnspb.PendingTrnsDateFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.PendingTrnsDateFilter;
  return proto.trnspb.PendingTrnsDateFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.PendingTrnsDateFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.PendingTrnsDateFilter}
 */
proto.trnspb.PendingTrnsDateFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFrom(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.PendingTrnsDateFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.PendingTrnsDateFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsDateFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFrom();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTo();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string type = 1;
 * @return {string}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsDateFilter} returns this
 */
proto.trnspb.PendingTrnsDateFilter.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from = 2;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.getFrom = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.PendingTrnsDateFilter} returns this
*/
proto.trnspb.PendingTrnsDateFilter.prototype.setFrom = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrnsDateFilter} returns this
 */
proto.trnspb.PendingTrnsDateFilter.prototype.clearFrom = function() {
  return this.setFrom(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.hasFrom = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to = 3;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.getTo = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.PendingTrnsDateFilter} returns this
*/
proto.trnspb.PendingTrnsDateFilter.prototype.setTo = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.PendingTrnsDateFilter} returns this
 */
proto.trnspb.PendingTrnsDateFilter.prototype.clearTo = function() {
  return this.setTo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.PendingTrnsDateFilter.prototype.hasTo = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.DeletePendingTrnsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.DeletePendingTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.DeletePendingTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.DeletePendingTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    stageIdList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    deletedCount: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.DeletePendingTrnsResponse}
 */
proto.trnspb.DeletePendingTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.DeletePendingTrnsResponse;
  return proto.trnspb.DeletePendingTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.DeletePendingTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.DeletePendingTrnsResponse}
 */
proto.trnspb.DeletePendingTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addStageId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDeletedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.DeletePendingTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.DeletePendingTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.DeletePendingTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStageIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getDeletedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * repeated string stage_id = 1;
 * @return {!Array<string>}
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.getStageIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.DeletePendingTrnsResponse} returns this
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.setStageIdList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.DeletePendingTrnsResponse} returns this
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.addStageId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.DeletePendingTrnsResponse} returns this
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.clearStageIdList = function() {
  return this.setStageIdList([]);
};


/**
 * optional int64 deleted_count = 2;
 * @return {number}
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.getDeletedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.trnspb.DeletePendingTrnsResponse} returns this
 */
proto.trnspb.DeletePendingTrnsResponse.prototype.setDeletedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.PendingTrnsApplyCIL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.PendingTrnsApplyCIL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsApplyCIL.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    price: jspb.Message.getFieldWithDefault(msg, 2, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 4, ""),
    stageId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.PendingTrnsApplyCIL}
 */
proto.trnspb.PendingTrnsApplyCIL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.PendingTrnsApplyCIL;
  return proto.trnspb.PendingTrnsApplyCIL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.PendingTrnsApplyCIL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.PendingTrnsApplyCIL}
 */
proto.trnspb.PendingTrnsApplyCIL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStageId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.PendingTrnsApplyCIL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.PendingTrnsApplyCIL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingTrnsApplyCIL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStageId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string price = 2;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gross_amt = 3;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string net_amt = 4;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string stage_id = 5;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getStageId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setStageId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string qty = 6;
 * @return {string}
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingTrnsApplyCIL} returns this
 */
proto.trnspb.PendingTrnsApplyCIL.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.UpdatePendingTrnsApplyCILRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    applyCilList: jspb.Message.toObjectList(msg.getApplyCilList(),
    proto.trnspb.PendingTrnsApplyCIL.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRequest}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.UpdatePendingTrnsApplyCILRequest;
  return proto.trnspb.UpdatePendingTrnsApplyCILRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRequest}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingTrnsApplyCIL;
      reader.readMessage(value,proto.trnspb.PendingTrnsApplyCIL.deserializeBinaryFromReader);
      msg.addApplyCil(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.UpdatePendingTrnsApplyCILRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getApplyCilList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.PendingTrnsApplyCIL.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingTrnsApplyCIL apply_cil = 1;
 * @return {!Array<!proto.trnspb.PendingTrnsApplyCIL>}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.getApplyCilList = function() {
  return /** @type{!Array<!proto.trnspb.PendingTrnsApplyCIL>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.PendingTrnsApplyCIL, 1));
};


/**
 * @param {!Array<!proto.trnspb.PendingTrnsApplyCIL>} value
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRequest} returns this
*/
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.setApplyCilList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.PendingTrnsApplyCIL=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.PendingTrnsApplyCIL}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.addApplyCil = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.PendingTrnsApplyCIL, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRequest} returns this
 */
proto.trnspb.UpdatePendingTrnsApplyCILRequest.prototype.clearApplyCilList = function() {
  return this.setApplyCilList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.UpdatePendingTrnsApplyCILRResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingTransaction: (f = msg.getPendingTransaction()) && proto.trnspb.PendingTrns.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.UpdatePendingTrnsApplyCILRResponse;
  return proto.trnspb.UpdatePendingTrnsApplyCILRResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingTrns;
      reader.readMessage(value,proto.trnspb.PendingTrns.deserializeBinaryFromReader);
      msg.setPendingTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.UpdatePendingTrnsApplyCILRResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.trnspb.PendingTrns.serializeBinaryToWriter
    );
  }
};


/**
 * optional PendingTrns pending_transaction = 1;
 * @return {?proto.trnspb.PendingTrns}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.getPendingTransaction = function() {
  return /** @type{?proto.trnspb.PendingTrns} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrns, 1));
};


/**
 * @param {?proto.trnspb.PendingTrns|undefined} value
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse} returns this
*/
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.setPendingTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.UpdatePendingTrnsApplyCILRResponse} returns this
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.clearPendingTransaction = function() {
  return this.setPendingTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.UpdatePendingTrnsApplyCILRResponse.prototype.hasPendingTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ExecutePendingTrnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ExecutePendingTrnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ExecutePendingTrnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ExecutePendingTrnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filters: (f = msg.getFilters()) && proto.trnspb.PendingTrnsFilters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ExecutePendingTrnsRequest}
 */
proto.trnspb.ExecutePendingTrnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ExecutePendingTrnsRequest;
  return proto.trnspb.ExecutePendingTrnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ExecutePendingTrnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ExecutePendingTrnsRequest}
 */
proto.trnspb.ExecutePendingTrnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingTrnsFilters;
      reader.readMessage(value,proto.trnspb.PendingTrnsFilters.deserializeBinaryFromReader);
      msg.setFilters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ExecutePendingTrnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ExecutePendingTrnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ExecutePendingTrnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ExecutePendingTrnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilters();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.trnspb.PendingTrnsFilters.serializeBinaryToWriter
    );
  }
};


/**
 * optional PendingTrnsFilters filters = 1;
 * @return {?proto.trnspb.PendingTrnsFilters}
 */
proto.trnspb.ExecutePendingTrnsRequest.prototype.getFilters = function() {
  return /** @type{?proto.trnspb.PendingTrnsFilters} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.PendingTrnsFilters, 1));
};


/**
 * @param {?proto.trnspb.PendingTrnsFilters|undefined} value
 * @return {!proto.trnspb.ExecutePendingTrnsRequest} returns this
*/
proto.trnspb.ExecutePendingTrnsRequest.prototype.setFilters = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.ExecutePendingTrnsRequest} returns this
 */
proto.trnspb.ExecutePendingTrnsRequest.prototype.clearFilters = function() {
  return this.setFilters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.ExecutePendingTrnsRequest.prototype.hasFilters = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ExecutePendingTrnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ExecutePendingTrnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ExecutePendingTrnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    batchNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    executedCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    rejectedCount: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ExecutePendingTrnsResponse}
 */
proto.trnspb.ExecutePendingTrnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ExecutePendingTrnsResponse;
  return proto.trnspb.ExecutePendingTrnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ExecutePendingTrnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ExecutePendingTrnsResponse}
 */
proto.trnspb.ExecutePendingTrnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExecutedCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRejectedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ExecutePendingTrnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ExecutePendingTrnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ExecutePendingTrnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExecutedCount();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRejectedCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional string batch_no = 1;
 * @return {string}
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ExecutePendingTrnsResponse} returns this
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 executed_count = 2;
 * @return {number}
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.getExecutedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.trnspb.ExecutePendingTrnsResponse} returns this
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.setExecutedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 rejected_count = 3;
 * @return {number}
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.getRejectedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.trnspb.ExecutePendingTrnsResponse} returns this
 */
proto.trnspb.ExecutePendingTrnsResponse.prototype.setRejectedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


goog.object.extend(exports, proto.trnspb);
