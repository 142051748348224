// source: reportpb/ssr.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var usrpb_administrator_pb = require('../usrpb/administrator_pb.js');
goog.object.extend(proto, usrpb_administrator_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.ClearSsrReviewRequest', null, global);
goog.exportSymbol('proto.reportpb.ClearSsrReviewResponse', null, global);
goog.exportSymbol('proto.reportpb.CreateSsrReportRequest', null, global);
goog.exportSymbol('proto.reportpb.CreateSsrReportResponse', null, global);
goog.exportSymbol('proto.reportpb.ExecuteSsrQueryRequest', null, global);
goog.exportSymbol('proto.reportpb.GenerateReviewedFileRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrConfigRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrConfigResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSsrErrorRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrErrorResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSsrExecutionRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrExecutionResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSsrReportRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrReportResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSsrRowRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSsrRowResponse', null, global);
goog.exportSymbol('proto.reportpb.ReviewSsrRowRequest', null, global);
goog.exportSymbol('proto.reportpb.ReviewSsrRowRequest.RowComment', null, global);
goog.exportSymbol('proto.reportpb.ReviewSsrRowResponse', null, global);
goog.exportSymbol('proto.reportpb.SsrConfig', null, global);
goog.exportSymbol('proto.reportpb.SsrError', null, global);
goog.exportSymbol('proto.reportpb.SsrExecution', null, global);
goog.exportSymbol('proto.reportpb.SsrFileChunkResponse', null, global);
goog.exportSymbol('proto.reportpb.SsrReport', null, global);
goog.exportSymbol('proto.reportpb.SsrRow', null, global);
goog.exportSymbol('proto.reportpb.TriggerSsrReportRequest', null, global);
goog.exportSymbol('proto.reportpb.TriggerSsrReportResponse', null, global);
goog.exportSymbol('proto.reportpb.UpdateSsrExecutionRequest', null, global);
goog.exportSymbol('proto.reportpb.UpdateSsrExecutionResponse', null, global);
goog.exportSymbol('proto.reportpb.UpdateSsrReportRequest', null, global);
goog.exportSymbol('proto.reportpb.UpdateSsrReportResponse', null, global);
goog.exportSymbol('proto.reportpb.UploadReviewedFileRequest', null, global);
goog.exportSymbol('proto.reportpb.UploadReviewedFileResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrReport = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SsrReport, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrReport.displayName = 'proto.reportpb.SsrReport';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrConfig = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SsrConfig, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrConfig.displayName = 'proto.reportpb.SsrConfig';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrExecution = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.SsrExecution.repeatedFields_, null);
};
goog.inherits(proto.reportpb.SsrExecution, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrExecution.displayName = 'proto.reportpb.SsrExecution';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrRow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.SsrRow.repeatedFields_, null);
};
goog.inherits(proto.reportpb.SsrRow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrRow.displayName = 'proto.reportpb.SsrRow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrError = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SsrError, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrError.displayName = 'proto.reportpb.SsrError';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrReportRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrReportRequest.displayName = 'proto.reportpb.ListSsrReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrReportResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrReportResponse.displayName = 'proto.reportpb.ListSsrReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrConfigRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSsrConfigRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrConfigRequest.displayName = 'proto.reportpb.ListSsrConfigRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrConfigResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrConfigResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrConfigResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrConfigResponse.displayName = 'proto.reportpb.ListSsrConfigResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrExecutionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrExecutionRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrExecutionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrExecutionRequest.displayName = 'proto.reportpb.ListSsrExecutionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrExecutionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrExecutionResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrExecutionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrExecutionResponse.displayName = 'proto.reportpb.ListSsrExecutionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrRowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSsrRowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrRowRequest.displayName = 'proto.reportpb.ListSsrRowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrRowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrRowResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrRowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrRowResponse.displayName = 'proto.reportpb.ListSsrRowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrErrorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSsrErrorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrErrorRequest.displayName = 'proto.reportpb.ListSsrErrorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSsrErrorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSsrErrorResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSsrErrorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSsrErrorResponse.displayName = 'proto.reportpb.ListSsrErrorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SsrFileChunkResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SsrFileChunkResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SsrFileChunkResponse.displayName = 'proto.reportpb.SsrFileChunkResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ExecuteSsrQueryRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ExecuteSsrQueryRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ExecuteSsrQueryRequest.displayName = 'proto.reportpb.ExecuteSsrQueryRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.CreateSsrReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.CreateSsrReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.CreateSsrReportRequest.displayName = 'proto.reportpb.CreateSsrReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.CreateSsrReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.CreateSsrReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.CreateSsrReportResponse.displayName = 'proto.reportpb.CreateSsrReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UpdateSsrReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UpdateSsrReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UpdateSsrReportRequest.displayName = 'proto.reportpb.UpdateSsrReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UpdateSsrReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UpdateSsrReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UpdateSsrReportResponse.displayName = 'proto.reportpb.UpdateSsrReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.TriggerSsrReportRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.TriggerSsrReportRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.TriggerSsrReportRequest.displayName = 'proto.reportpb.TriggerSsrReportRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.TriggerSsrReportResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.TriggerSsrReportResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.TriggerSsrReportResponse.displayName = 'proto.reportpb.TriggerSsrReportResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UpdateSsrExecutionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UpdateSsrExecutionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UpdateSsrExecutionRequest.displayName = 'proto.reportpb.UpdateSsrExecutionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UpdateSsrExecutionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UpdateSsrExecutionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UpdateSsrExecutionResponse.displayName = 'proto.reportpb.UpdateSsrExecutionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ReviewSsrRowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ReviewSsrRowRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ReviewSsrRowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ReviewSsrRowRequest.displayName = 'proto.reportpb.ReviewSsrRowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ReviewSsrRowRequest.RowComment = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ReviewSsrRowRequest.RowComment, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ReviewSsrRowRequest.RowComment.displayName = 'proto.reportpb.ReviewSsrRowRequest.RowComment';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ReviewSsrRowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ReviewSsrRowResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ReviewSsrRowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ReviewSsrRowResponse.displayName = 'proto.reportpb.ReviewSsrRowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ClearSsrReviewRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ClearSsrReviewRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ClearSsrReviewRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ClearSsrReviewRequest.displayName = 'proto.reportpb.ClearSsrReviewRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ClearSsrReviewResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ClearSsrReviewResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ClearSsrReviewResponse.displayName = 'proto.reportpb.ClearSsrReviewResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.GenerateReviewedFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.GenerateReviewedFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.GenerateReviewedFileRequest.displayName = 'proto.reportpb.GenerateReviewedFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UploadReviewedFileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UploadReviewedFileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UploadReviewedFileRequest.displayName = 'proto.reportpb.UploadReviewedFileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.UploadReviewedFileResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.UploadReviewedFileResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.UploadReviewedFileResponse.displayName = 'proto.reportpb.UploadReviewedFileResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrReport.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrReport.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrReport} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrReport.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    config: (f = msg.getConfig()) && proto.reportpb.SsrConfig.toObject(includeInstance, f),
    pendingReviewCount: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrReport}
 */
proto.reportpb.SsrReport.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrReport;
  return proto.reportpb.SsrReport.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrReport} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrReport}
 */
proto.reportpb.SsrReport.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = new proto.reportpb.SsrConfig;
      reader.readMessage(value,proto.reportpb.SsrConfig.deserializeBinaryFromReader);
      msg.setConfig(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingReviewCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrReport.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrReport.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrReport} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrReport.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getConfig();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.reportpb.SsrConfig.serializeBinaryToWriter
    );
  }
  f = message.getPendingReviewCount();
  if (f !== 0) {
    writer.writeInt32(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reportpb.SsrReport.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrReport} returns this
 */
proto.reportpb.SsrReport.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrReport.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrReport} returns this
*/
proto.reportpb.SsrReport.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrReport} returns this
 */
proto.reportpb.SsrReport.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrReport.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.reportpb.SsrReport.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrReport} returns this
 */
proto.reportpb.SsrReport.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SsrConfig config = 4;
 * @return {?proto.reportpb.SsrConfig}
 */
proto.reportpb.SsrReport.prototype.getConfig = function() {
  return /** @type{?proto.reportpb.SsrConfig} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrConfig, 4));
};


/**
 * @param {?proto.reportpb.SsrConfig|undefined} value
 * @return {!proto.reportpb.SsrReport} returns this
*/
proto.reportpb.SsrReport.prototype.setConfig = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrReport} returns this
 */
proto.reportpb.SsrReport.prototype.clearConfig = function() {
  return this.setConfig(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrReport.prototype.hasConfig = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 pending_review_count = 5;
 * @return {number}
 */
proto.reportpb.SsrReport.prototype.getPendingReviewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrReport} returns this
 */
proto.reportpb.SsrReport.prototype.setPendingReviewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrConfig.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrConfig.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrConfig} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrConfig.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    ownerId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    owner: (f = msg.getOwner()) && usrpb_administrator_pb.Administrator.toObject(includeInstance, f),
    creatorId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    creator: (f = msg.getCreator()) && usrpb_administrator_pb.Administrator.toObject(includeInstance, f),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 8, ""),
    query: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, ""),
    requireReview: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    reportDirectory: jspb.Message.getFieldWithDefault(msg, 12, ""),
    reviewDirectory: jspb.Message.getFieldWithDefault(msg, 13, ""),
    scheduleType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    scheduleDescription: jspb.Message.getFieldWithDefault(msg, 15, ""),
    scheduleMeta: jspb.Message.getFieldWithDefault(msg, 16, ""),
    conditionalQuery: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrConfig}
 */
proto.reportpb.SsrConfig.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrConfig;
  return proto.reportpb.SsrConfig.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrConfig} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrConfig}
 */
proto.reportpb.SsrConfig.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setOwnerId(value);
      break;
    case 4:
      var value = new usrpb_administrator_pb.Administrator;
      reader.readMessage(value,usrpb_administrator_pb.Administrator.deserializeBinaryFromReader);
      msg.setOwner(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatorId(value);
      break;
    case 6:
      var value = new usrpb_administrator_pb.Administrator;
      reader.readMessage(value,usrpb_administrator_pb.Administrator.deserializeBinaryFromReader);
      msg.setCreator(value);
      break;
    case 7:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequireReview(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportDirectory(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewDirectory(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleDescription(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setScheduleMeta(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setConditionalQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrConfig.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrConfig.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrConfig} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrConfig.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getOwnerId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOwner();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      usrpb_administrator_pb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getCreatorId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCreator();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      usrpb_administrator_pb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRequireReview();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getReportDirectory();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReviewDirectory();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getScheduleType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getScheduleDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getScheduleMeta();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getConditionalQuery();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string report_id = 2;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string owner_id = 3;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getOwnerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setOwnerId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional usrpb.Administrator owner = 4;
 * @return {?proto.usrpb.Administrator}
 */
proto.reportpb.SsrConfig.prototype.getOwner = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, usrpb_administrator_pb.Administrator, 4));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.reportpb.SsrConfig} returns this
*/
proto.reportpb.SsrConfig.prototype.setOwner = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.clearOwner = function() {
  return this.setOwner(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrConfig.prototype.hasOwner = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string creator_id = 5;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getCreatorId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setCreatorId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional usrpb.Administrator creator = 6;
 * @return {?proto.usrpb.Administrator}
 */
proto.reportpb.SsrConfig.prototype.getCreator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, usrpb_administrator_pb.Administrator, 6));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.reportpb.SsrConfig} returns this
*/
proto.reportpb.SsrConfig.prototype.setCreator = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.clearCreator = function() {
  return this.setCreator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrConfig.prototype.hasCreator = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.protobuf.Timestamp created_at = 7;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrConfig.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 7));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrConfig} returns this
*/
proto.reportpb.SsrConfig.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrConfig.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string name = 8;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string query = 9;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional bool require_review = 11;
 * @return {boolean}
 */
proto.reportpb.SsrConfig.prototype.getRequireReview = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setRequireReview = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * optional string report_directory = 12;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getReportDirectory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setReportDirectory = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string review_directory = 13;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getReviewDirectory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setReviewDirectory = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string schedule_type = 14;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getScheduleType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setScheduleType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string schedule_description = 15;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getScheduleDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setScheduleDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string schedule_meta = 16;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getScheduleMeta = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setScheduleMeta = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string conditional_query = 17;
 * @return {string}
 */
proto.reportpb.SsrConfig.prototype.getConditionalQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrConfig} returns this
 */
proto.reportpb.SsrConfig.prototype.setConditionalQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.SsrExecution.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrExecution.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrExecution.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrExecution} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrExecution.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reportId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    configId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statusMessage: jspb.Message.getFieldWithDefault(msg, 6, ""),
    headersList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    reportUploadedAt: (f = msg.getReportUploadedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reportDomain: jspb.Message.getFieldWithDefault(msg, 9, ""),
    reportPath: jspb.Message.getFieldWithDefault(msg, 10, ""),
    reviewUploadedAt: (f = msg.getReviewUploadedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reviewUploaderId: jspb.Message.getFieldWithDefault(msg, 12, ""),
    reviewUploader: (f = msg.getReviewUploader()) && usrpb_administrator_pb.Administrator.toObject(includeInstance, f),
    reviewDomain: jspb.Message.getFieldWithDefault(msg, 14, ""),
    reviewPath: jspb.Message.getFieldWithDefault(msg, 15, ""),
    startedAt: (f = msg.getStartedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    endedAt: (f = msg.getEndedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    durationInMs: jspb.Message.getFieldWithDefault(msg, 18, 0),
    rowCount: jspb.Message.getFieldWithDefault(msg, 19, 0),
    reviewCount: jspb.Message.getFieldWithDefault(msg, 20, 0),
    reviewComment: jspb.Message.getFieldWithDefault(msg, 21, ""),
    reviewCommenterId: jspb.Message.getFieldWithDefault(msg, 22, ""),
    reviewCommenter: (f = msg.getReviewCommenter()) && usrpb_administrator_pb.Administrator.toObject(includeInstance, f),
    dataClearedAt: (f = msg.getDataClearedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrExecution}
 */
proto.reportpb.SsrExecution.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrExecution;
  return proto.reportpb.SsrExecution.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrExecution} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrExecution}
 */
proto.reportpb.SsrExecution.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatusMessage(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addHeaders(value);
      break;
    case 8:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReportUploadedAt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportDomain(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportPath(value);
      break;
    case 11:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReviewUploadedAt(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewUploaderId(value);
      break;
    case 13:
      var value = new usrpb_administrator_pb.Administrator;
      reader.readMessage(value,usrpb_administrator_pb.Administrator.deserializeBinaryFromReader);
      msg.setReviewUploader(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewDomain(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewPath(value);
      break;
    case 16:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStartedAt(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEndedAt(value);
      break;
    case 18:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setDurationInMs(value);
      break;
    case 19:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowCount(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setReviewCount(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewComment(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewCommenterId(value);
      break;
    case 23:
      var value = new usrpb_administrator_pb.Administrator;
      reader.readMessage(value,usrpb_administrator_pb.Administrator.deserializeBinaryFromReader);
      msg.setReviewCommenter(value);
      break;
    case 24:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setDataClearedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrExecution.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrExecution.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrExecution} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrExecution.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusMessage();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getReportUploadedAt();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportDomain();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getReportPath();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getReviewUploadedAt();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReviewUploaderId();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getReviewUploader();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      usrpb_administrator_pb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getReviewDomain();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getReviewPath();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getStartedAt();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEndedAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getDurationInMs();
  if (f !== 0) {
    writer.writeInt64(
      18,
      f
    );
  }
  f = message.getRowCount();
  if (f !== 0) {
    writer.writeInt64(
      19,
      f
    );
  }
  f = message.getReviewCount();
  if (f !== 0) {
    writer.writeInt64(
      20,
      f
    );
  }
  f = message.getReviewComment();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getReviewCommenterId();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getReviewCommenter();
  if (f != null) {
    writer.writeMessage(
      23,
      f,
      usrpb_administrator_pb.Administrator.serializeBinaryToWriter
    );
  }
  f = message.getDataClearedAt();
  if (f != null) {
    writer.writeMessage(
      24,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string report_id = 2;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string config_id = 3;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string status = 5;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string status_message = 6;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getStatusMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setStatusMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated string headers = 7;
 * @return {!Array<string>}
 */
proto.reportpb.SsrExecution.prototype.getHeadersList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setHeadersList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.addHeaders = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};


/**
 * optional google.protobuf.Timestamp report_uploaded_at = 8;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getReportUploadedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 8));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setReportUploadedAt = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearReportUploadedAt = function() {
  return this.setReportUploadedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasReportUploadedAt = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional string report_domain = 9;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReportDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReportDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string report_path = 10;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReportPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReportPath = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.protobuf.Timestamp review_uploaded_at = 11;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getReviewUploadedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 11));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setReviewUploadedAt = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearReviewUploadedAt = function() {
  return this.setReviewUploadedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasReviewUploadedAt = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string review_uploader_id = 12;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReviewUploaderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewUploaderId = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional usrpb.Administrator review_uploader = 13;
 * @return {?proto.usrpb.Administrator}
 */
proto.reportpb.SsrExecution.prototype.getReviewUploader = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, usrpb_administrator_pb.Administrator, 13));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setReviewUploader = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearReviewUploader = function() {
  return this.setReviewUploader(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasReviewUploader = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string review_domain = 14;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReviewDomain = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewDomain = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string review_path = 15;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReviewPath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewPath = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional google.protobuf.Timestamp started_at = 16;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getStartedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 16));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setStartedAt = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearStartedAt = function() {
  return this.setStartedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasStartedAt = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp ended_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getEndedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setEndedAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearEndedAt = function() {
  return this.setEndedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasEndedAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional int64 duration_in_ms = 18;
 * @return {number}
 */
proto.reportpb.SsrExecution.prototype.getDurationInMs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 18, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setDurationInMs = function(value) {
  return jspb.Message.setProto3IntField(this, 18, value);
};


/**
 * optional int64 row_count = 19;
 * @return {number}
 */
proto.reportpb.SsrExecution.prototype.getRowCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 19, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setRowCount = function(value) {
  return jspb.Message.setProto3IntField(this, 19, value);
};


/**
 * optional int64 review_count = 20;
 * @return {number}
 */
proto.reportpb.SsrExecution.prototype.getReviewCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewCount = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional string review_comment = 21;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReviewComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewComment = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string review_commenter_id = 22;
 * @return {string}
 */
proto.reportpb.SsrExecution.prototype.getReviewCommenterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.setReviewCommenterId = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional usrpb.Administrator review_commenter = 23;
 * @return {?proto.usrpb.Administrator}
 */
proto.reportpb.SsrExecution.prototype.getReviewCommenter = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, usrpb_administrator_pb.Administrator, 23));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setReviewCommenter = function(value) {
  return jspb.Message.setWrapperField(this, 23, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearReviewCommenter = function() {
  return this.setReviewCommenter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasReviewCommenter = function() {
  return jspb.Message.getField(this, 23) != null;
};


/**
 * optional google.protobuf.Timestamp data_cleared_at = 24;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrExecution.prototype.getDataClearedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 24));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrExecution} returns this
*/
proto.reportpb.SsrExecution.prototype.setDataClearedAt = function(value) {
  return jspb.Message.setWrapperField(this, 24, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrExecution} returns this
 */
proto.reportpb.SsrExecution.prototype.clearDataClearedAt = function() {
  return this.setDataClearedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrExecution.prototype.hasDataClearedAt = function() {
  return jspb.Message.getField(this, 24) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.SsrRow.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrRow.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrRow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrRow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrRow.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    rowNumber: jspb.Message.getFieldWithDefault(msg, 2, 0),
    recordsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    reviewedAt: (f = msg.getReviewedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reviewComment: jspb.Message.getFieldWithDefault(msg, 5, ""),
    reviewerId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    reviewer: (f = msg.getReviewer()) && usrpb_administrator_pb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrRow}
 */
proto.reportpb.SsrRow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrRow;
  return proto.reportpb.SsrRow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrRow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrRow}
 */
proto.reportpb.SsrRow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRowNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRecords(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setReviewedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewComment(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerId(value);
      break;
    case 7:
      var value = new usrpb_administrator_pb.Administrator;
      reader.readMessage(value,usrpb_administrator_pb.Administrator.deserializeBinaryFromReader);
      msg.setReviewer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrRow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrRow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrRow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrRow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRowNumber();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getRecordsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getReviewedAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReviewComment();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getReviewerId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getReviewer();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      usrpb_administrator_pb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reportpb.SsrRow.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 row_number = 2;
 * @return {number}
 */
proto.reportpb.SsrRow.prototype.getRowNumber = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.setRowNumber = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated string records = 3;
 * @return {!Array<string>}
 */
proto.reportpb.SsrRow.prototype.getRecordsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.setRecordsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.addRecords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.clearRecordsList = function() {
  return this.setRecordsList([]);
};


/**
 * optional google.protobuf.Timestamp reviewed_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrRow.prototype.getReviewedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrRow} returns this
*/
proto.reportpb.SsrRow.prototype.setReviewedAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.clearReviewedAt = function() {
  return this.setReviewedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrRow.prototype.hasReviewedAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string review_comment = 5;
 * @return {string}
 */
proto.reportpb.SsrRow.prototype.getReviewComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.setReviewComment = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string reviewer_id = 6;
 * @return {string}
 */
proto.reportpb.SsrRow.prototype.getReviewerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.setReviewerId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional usrpb.Administrator reviewer = 7;
 * @return {?proto.usrpb.Administrator}
 */
proto.reportpb.SsrRow.prototype.getReviewer = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, usrpb_administrator_pb.Administrator, 7));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.reportpb.SsrRow} returns this
*/
proto.reportpb.SsrRow.prototype.setReviewer = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrRow} returns this
 */
proto.reportpb.SsrRow.prototype.clearReviewer = function() {
  return this.setReviewer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrRow.prototype.hasReviewer = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrError.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrError.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrError} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrError.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, ""),
    message: jspb.Message.getFieldWithDefault(msg, 4, ""),
    reportId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    configId: jspb.Message.getFieldWithDefault(msg, 6, ""),
    executionId: jspb.Message.getFieldWithDefault(msg, 7, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrError}
 */
proto.reportpb.SsrError.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrError;
  return proto.reportpb.SsrError.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrError} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrError}
 */
proto.reportpb.SsrError.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrError.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrError.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrError} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrError.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMessage();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.SsrError.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.SsrError} returns this
*/
proto.reportpb.SsrError.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SsrError.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string type = 3;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string message = 4;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string report_id = 5;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string config_id = 6;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string execution_id = 7;
 * @return {string}
 */
proto.reportpb.SsrError.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SsrError} returns this
 */
proto.reportpb.SsrError.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrReportRequest.repeatedFields_ = [2,3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    statusList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    reportIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrReportRequest}
 */
proto.reportpb.ListSsrReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrReportRequest;
  return proto.reportpb.ListSsrReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrReportRequest}
 */
proto.reportpb.ListSsrReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addStatus(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addReportIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getReportIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional utilspb.Pagination pagination = 1;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListSsrReportRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 1));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
*/
proto.reportpb.ListSsrReportRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrReportRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string status = 2;
 * @return {!Array<string>}
 */
proto.reportpb.ListSsrReportRequest.prototype.getStatusList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.setStatusList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.addStatus = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated string report_ids = 3;
 * @return {!Array<string>}
 */
proto.reportpb.ListSsrReportRequest.prototype.getReportIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.setReportIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.addReportIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrReportRequest} returns this
 */
proto.reportpb.ListSsrReportRequest.prototype.clearReportIdsList = function() {
  return this.setReportIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrReportResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportsList: jspb.Message.toObjectList(msg.getReportsList(),
    proto.reportpb.SsrReport.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrReportResponse}
 */
proto.reportpb.ListSsrReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrReportResponse;
  return proto.reportpb.ListSsrReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrReportResponse}
 */
proto.reportpb.ListSsrReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrReport;
      reader.readMessage(value,proto.reportpb.SsrReport.deserializeBinaryFromReader);
      msg.addReports(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrReport.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrReport reports = 1;
 * @return {!Array<!proto.reportpb.SsrReport>}
 */
proto.reportpb.ListSsrReportResponse.prototype.getReportsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrReport>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrReport, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrReport>} value
 * @return {!proto.reportpb.ListSsrReportResponse} returns this
*/
proto.reportpb.ListSsrReportResponse.prototype.setReportsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrReport=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrReport}
 */
proto.reportpb.ListSsrReportResponse.prototype.addReports = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrReport, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrReportResponse} returns this
 */
proto.reportpb.ListSsrReportResponse.prototype.clearReportsList = function() {
  return this.setReportsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrConfigRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrConfigRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrConfigRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrConfigRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    reportId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrConfigRequest}
 */
proto.reportpb.ListSsrConfigRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrConfigRequest;
  return proto.reportpb.ListSsrConfigRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrConfigRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrConfigRequest}
 */
proto.reportpb.ListSsrConfigRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrConfigRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrConfigRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrConfigRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrConfigRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional utilspb.Pagination pagination = 1;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListSsrConfigRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 1));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListSsrConfigRequest} returns this
*/
proto.reportpb.ListSsrConfigRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrConfigRequest} returns this
 */
proto.reportpb.ListSsrConfigRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrConfigRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string report_id = 2;
 * @return {string}
 */
proto.reportpb.ListSsrConfigRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSsrConfigRequest} returns this
 */
proto.reportpb.ListSsrConfigRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrConfigResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrConfigResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrConfigResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrConfigResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrConfigResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    configsList: jspb.Message.toObjectList(msg.getConfigsList(),
    proto.reportpb.SsrConfig.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrConfigResponse}
 */
proto.reportpb.ListSsrConfigResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrConfigResponse;
  return proto.reportpb.ListSsrConfigResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrConfigResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrConfigResponse}
 */
proto.reportpb.ListSsrConfigResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrConfig;
      reader.readMessage(value,proto.reportpb.SsrConfig.deserializeBinaryFromReader);
      msg.addConfigs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrConfigResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrConfigResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrConfigResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrConfigResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConfigsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrConfig.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrConfig configs = 1;
 * @return {!Array<!proto.reportpb.SsrConfig>}
 */
proto.reportpb.ListSsrConfigResponse.prototype.getConfigsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrConfig>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrConfig, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrConfig>} value
 * @return {!proto.reportpb.ListSsrConfigResponse} returns this
*/
proto.reportpb.ListSsrConfigResponse.prototype.setConfigsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrConfig=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrConfig}
 */
proto.reportpb.ListSsrConfigResponse.prototype.addConfigs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrConfig, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrConfigResponse} returns this
 */
proto.reportpb.ListSsrConfigResponse.prototype.clearConfigsList = function() {
  return this.setConfigsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrExecutionRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrExecutionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrExecutionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrExecutionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    reportId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    configId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    executionIdsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrExecutionRequest}
 */
proto.reportpb.ListSsrExecutionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrExecutionRequest;
  return proto.reportpb.ListSsrExecutionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrExecutionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrExecutionRequest}
 */
proto.reportpb.ListSsrExecutionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setConfigId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addExecutionIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrExecutionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrExecutionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrExecutionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getConfigId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExecutionIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
};


/**
 * optional utilspb.Pagination pagination = 1;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 1));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
*/
proto.reportpb.ListSsrExecutionRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string report_id = 2;
 * @return {string}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string config_id = 3;
 * @return {string}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.getConfigId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.setConfigId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * repeated string execution_ids = 4;
 * @return {!Array<string>}
 */
proto.reportpb.ListSsrExecutionRequest.prototype.getExecutionIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.setExecutionIdsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.addExecutionIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrExecutionRequest} returns this
 */
proto.reportpb.ListSsrExecutionRequest.prototype.clearExecutionIdsList = function() {
  return this.setExecutionIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrExecutionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrExecutionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrExecutionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrExecutionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrExecutionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionsList: jspb.Message.toObjectList(msg.getExecutionsList(),
    proto.reportpb.SsrExecution.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrExecutionResponse}
 */
proto.reportpb.ListSsrExecutionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrExecutionResponse;
  return proto.reportpb.ListSsrExecutionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrExecutionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrExecutionResponse}
 */
proto.reportpb.ListSsrExecutionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrExecution;
      reader.readMessage(value,proto.reportpb.SsrExecution.deserializeBinaryFromReader);
      msg.addExecutions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrExecutionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrExecutionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrExecutionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrExecutionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrExecution.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrExecution executions = 1;
 * @return {!Array<!proto.reportpb.SsrExecution>}
 */
proto.reportpb.ListSsrExecutionResponse.prototype.getExecutionsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrExecution>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrExecution, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrExecution>} value
 * @return {!proto.reportpb.ListSsrExecutionResponse} returns this
*/
proto.reportpb.ListSsrExecutionResponse.prototype.setExecutionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrExecution=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrExecution}
 */
proto.reportpb.ListSsrExecutionResponse.prototype.addExecutions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrExecution, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrExecutionResponse} returns this
 */
proto.reportpb.ListSsrExecutionResponse.prototype.clearExecutionsList = function() {
  return this.setExecutionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrRowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrRowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrRowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrRowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    executionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrRowRequest}
 */
proto.reportpb.ListSsrRowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrRowRequest;
  return proto.reportpb.ListSsrRowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrRowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrRowRequest}
 */
proto.reportpb.ListSsrRowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrRowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrRowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrRowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrRowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional utilspb.Pagination pagination = 1;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListSsrRowRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 1));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListSsrRowRequest} returns this
*/
proto.reportpb.ListSsrRowRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrRowRequest} returns this
 */
proto.reportpb.ListSsrRowRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrRowRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string execution_id = 2;
 * @return {string}
 */
proto.reportpb.ListSsrRowRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSsrRowRequest} returns this
 */
proto.reportpb.ListSsrRowRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrRowResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrRowResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrRowResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrRowResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrRowResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.reportpb.SsrRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrRowResponse}
 */
proto.reportpb.ListSsrRowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrRowResponse;
  return proto.reportpb.ListSsrRowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrRowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrRowResponse}
 */
proto.reportpb.ListSsrRowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrRow;
      reader.readMessage(value,proto.reportpb.SsrRow.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrRowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrRowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrRowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrRowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrRow rows = 1;
 * @return {!Array<!proto.reportpb.SsrRow>}
 */
proto.reportpb.ListSsrRowResponse.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrRow, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrRow>} value
 * @return {!proto.reportpb.ListSsrRowResponse} returns this
*/
proto.reportpb.ListSsrRowResponse.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrRow}
 */
proto.reportpb.ListSsrRowResponse.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrRowResponse} returns this
 */
proto.reportpb.ListSsrRowResponse.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrErrorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrErrorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrErrorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrErrorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    beforeTime: (f = msg.getBeforeTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    afterTime: (f = msg.getAfterTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    reportId: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrErrorRequest}
 */
proto.reportpb.ListSsrErrorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrErrorRequest;
  return proto.reportpb.ListSsrErrorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrErrorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrErrorRequest}
 */
proto.reportpb.ListSsrErrorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setBeforeTime(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setAfterTime(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrErrorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrErrorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrErrorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrErrorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getBeforeTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAfterTime();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional utilspb.Pagination pagination = 1;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListSsrErrorRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 1));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
*/
proto.reportpb.ListSsrErrorRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
 */
proto.reportpb.ListSsrErrorRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrErrorRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp before_time = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.ListSsrErrorRequest.prototype.getBeforeTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
*/
proto.reportpb.ListSsrErrorRequest.prototype.setBeforeTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
 */
proto.reportpb.ListSsrErrorRequest.prototype.clearBeforeTime = function() {
  return this.setBeforeTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrErrorRequest.prototype.hasBeforeTime = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp after_time = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.ListSsrErrorRequest.prototype.getAfterTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
*/
proto.reportpb.ListSsrErrorRequest.prototype.setAfterTime = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
 */
proto.reportpb.ListSsrErrorRequest.prototype.clearAfterTime = function() {
  return this.setAfterTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSsrErrorRequest.prototype.hasAfterTime = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string report_id = 4;
 * @return {string}
 */
proto.reportpb.ListSsrErrorRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSsrErrorRequest} returns this
 */
proto.reportpb.ListSsrErrorRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSsrErrorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSsrErrorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSsrErrorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSsrErrorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrErrorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    errorsList: jspb.Message.toObjectList(msg.getErrorsList(),
    proto.reportpb.SsrError.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSsrErrorResponse}
 */
proto.reportpb.ListSsrErrorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSsrErrorResponse;
  return proto.reportpb.ListSsrErrorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSsrErrorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSsrErrorResponse}
 */
proto.reportpb.ListSsrErrorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrError;
      reader.readMessage(value,proto.reportpb.SsrError.deserializeBinaryFromReader);
      msg.addErrors(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSsrErrorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSsrErrorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSsrErrorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSsrErrorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getErrorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrError.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrError errors = 1;
 * @return {!Array<!proto.reportpb.SsrError>}
 */
proto.reportpb.ListSsrErrorResponse.prototype.getErrorsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrError>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrError, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrError>} value
 * @return {!proto.reportpb.ListSsrErrorResponse} returns this
*/
proto.reportpb.ListSsrErrorResponse.prototype.setErrorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrError=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrError}
 */
proto.reportpb.ListSsrErrorResponse.prototype.addErrors = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrError, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSsrErrorResponse} returns this
 */
proto.reportpb.ListSsrErrorResponse.prototype.clearErrorsList = function() {
  return this.setErrorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SsrFileChunkResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SsrFileChunkResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SsrFileChunkResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrFileChunkResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    chunk: msg.getChunk_asB64(),
    index: jspb.Message.getFieldWithDefault(msg, 2, 0),
    isLast: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SsrFileChunkResponse}
 */
proto.reportpb.SsrFileChunkResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SsrFileChunkResponse;
  return proto.reportpb.SsrFileChunkResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SsrFileChunkResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SsrFileChunkResponse}
 */
proto.reportpb.SsrFileChunkResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setChunk(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIndex(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsLast(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SsrFileChunkResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SsrFileChunkResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SsrFileChunkResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SsrFileChunkResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChunk_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getIsLast();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional bytes chunk = 1;
 * @return {string}
 */
proto.reportpb.SsrFileChunkResponse.prototype.getChunk = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes chunk = 1;
 * This is a type-conversion wrapper around `getChunk()`
 * @return {string}
 */
proto.reportpb.SsrFileChunkResponse.prototype.getChunk_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getChunk()));
};


/**
 * optional bytes chunk = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getChunk()`
 * @return {!Uint8Array}
 */
proto.reportpb.SsrFileChunkResponse.prototype.getChunk_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getChunk()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.reportpb.SsrFileChunkResponse} returns this
 */
proto.reportpb.SsrFileChunkResponse.prototype.setChunk = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional int64 index = 2;
 * @return {number}
 */
proto.reportpb.SsrFileChunkResponse.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.SsrFileChunkResponse} returns this
 */
proto.reportpb.SsrFileChunkResponse.prototype.setIndex = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional bool is_last = 3;
 * @return {boolean}
 */
proto.reportpb.SsrFileChunkResponse.prototype.getIsLast = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.SsrFileChunkResponse} returns this
 */
proto.reportpb.SsrFileChunkResponse.prototype.setIsLast = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ExecuteSsrQueryRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ExecuteSsrQueryRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ExecuteSsrQueryRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ExecuteSsrQueryRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    query: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ExecuteSsrQueryRequest}
 */
proto.reportpb.ExecuteSsrQueryRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ExecuteSsrQueryRequest;
  return proto.reportpb.ExecuteSsrQueryRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ExecuteSsrQueryRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ExecuteSsrQueryRequest}
 */
proto.reportpb.ExecuteSsrQueryRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ExecuteSsrQueryRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ExecuteSsrQueryRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ExecuteSsrQueryRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ExecuteSsrQueryRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string query = 1;
 * @return {string}
 */
proto.reportpb.ExecuteSsrQueryRequest.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ExecuteSsrQueryRequest} returns this
 */
proto.reportpb.ExecuteSsrQueryRequest.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.CreateSsrReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.CreateSsrReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.CreateSsrReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CreateSsrReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && proto.reportpb.SsrReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.CreateSsrReportRequest}
 */
proto.reportpb.CreateSsrReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.CreateSsrReportRequest;
  return proto.reportpb.CreateSsrReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.CreateSsrReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.CreateSsrReportRequest}
 */
proto.reportpb.CreateSsrReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrReport;
      reader.readMessage(value,proto.reportpb.SsrReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.CreateSsrReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.CreateSsrReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.CreateSsrReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CreateSsrReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrReport report = 1;
 * @return {?proto.reportpb.SsrReport}
 */
proto.reportpb.CreateSsrReportRequest.prototype.getReport = function() {
  return /** @type{?proto.reportpb.SsrReport} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrReport, 1));
};


/**
 * @param {?proto.reportpb.SsrReport|undefined} value
 * @return {!proto.reportpb.CreateSsrReportRequest} returns this
*/
proto.reportpb.CreateSsrReportRequest.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.CreateSsrReportRequest} returns this
 */
proto.reportpb.CreateSsrReportRequest.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.CreateSsrReportRequest.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.CreateSsrReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.CreateSsrReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.CreateSsrReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CreateSsrReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && proto.reportpb.SsrReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.CreateSsrReportResponse}
 */
proto.reportpb.CreateSsrReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.CreateSsrReportResponse;
  return proto.reportpb.CreateSsrReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.CreateSsrReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.CreateSsrReportResponse}
 */
proto.reportpb.CreateSsrReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrReport;
      reader.readMessage(value,proto.reportpb.SsrReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.CreateSsrReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.CreateSsrReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.CreateSsrReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.CreateSsrReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrReport report = 1;
 * @return {?proto.reportpb.SsrReport}
 */
proto.reportpb.CreateSsrReportResponse.prototype.getReport = function() {
  return /** @type{?proto.reportpb.SsrReport} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrReport, 1));
};


/**
 * @param {?proto.reportpb.SsrReport|undefined} value
 * @return {!proto.reportpb.CreateSsrReportResponse} returns this
*/
proto.reportpb.CreateSsrReportResponse.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.CreateSsrReportResponse} returns this
 */
proto.reportpb.CreateSsrReportResponse.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.CreateSsrReportResponse.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UpdateSsrReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UpdateSsrReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UpdateSsrReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && proto.reportpb.SsrReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UpdateSsrReportRequest}
 */
proto.reportpb.UpdateSsrReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UpdateSsrReportRequest;
  return proto.reportpb.UpdateSsrReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UpdateSsrReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UpdateSsrReportRequest}
 */
proto.reportpb.UpdateSsrReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrReport;
      reader.readMessage(value,proto.reportpb.SsrReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UpdateSsrReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UpdateSsrReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UpdateSsrReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrReport report = 1;
 * @return {?proto.reportpb.SsrReport}
 */
proto.reportpb.UpdateSsrReportRequest.prototype.getReport = function() {
  return /** @type{?proto.reportpb.SsrReport} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrReport, 1));
};


/**
 * @param {?proto.reportpb.SsrReport|undefined} value
 * @return {!proto.reportpb.UpdateSsrReportRequest} returns this
*/
proto.reportpb.UpdateSsrReportRequest.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.UpdateSsrReportRequest} returns this
 */
proto.reportpb.UpdateSsrReportRequest.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.UpdateSsrReportRequest.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UpdateSsrReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UpdateSsrReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UpdateSsrReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    report: (f = msg.getReport()) && proto.reportpb.SsrReport.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UpdateSsrReportResponse}
 */
proto.reportpb.UpdateSsrReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UpdateSsrReportResponse;
  return proto.reportpb.UpdateSsrReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UpdateSsrReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UpdateSsrReportResponse}
 */
proto.reportpb.UpdateSsrReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrReport;
      reader.readMessage(value,proto.reportpb.SsrReport.deserializeBinaryFromReader);
      msg.setReport(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UpdateSsrReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UpdateSsrReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UpdateSsrReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReport();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrReport.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrReport report = 1;
 * @return {?proto.reportpb.SsrReport}
 */
proto.reportpb.UpdateSsrReportResponse.prototype.getReport = function() {
  return /** @type{?proto.reportpb.SsrReport} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrReport, 1));
};


/**
 * @param {?proto.reportpb.SsrReport|undefined} value
 * @return {!proto.reportpb.UpdateSsrReportResponse} returns this
*/
proto.reportpb.UpdateSsrReportResponse.prototype.setReport = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.UpdateSsrReportResponse} returns this
 */
proto.reportpb.UpdateSsrReportResponse.prototype.clearReport = function() {
  return this.setReport(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.UpdateSsrReportResponse.prototype.hasReport = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.TriggerSsrReportRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.TriggerSsrReportRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.TriggerSsrReportRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TriggerSsrReportRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    reportId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.TriggerSsrReportRequest}
 */
proto.reportpb.TriggerSsrReportRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.TriggerSsrReportRequest;
  return proto.reportpb.TriggerSsrReportRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.TriggerSsrReportRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.TriggerSsrReportRequest}
 */
proto.reportpb.TriggerSsrReportRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setReportId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.TriggerSsrReportRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.TriggerSsrReportRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.TriggerSsrReportRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TriggerSsrReportRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReportId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string report_id = 1;
 * @return {string}
 */
proto.reportpb.TriggerSsrReportRequest.prototype.getReportId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.TriggerSsrReportRequest} returns this
 */
proto.reportpb.TriggerSsrReportRequest.prototype.setReportId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.TriggerSsrReportResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.TriggerSsrReportResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.TriggerSsrReportResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TriggerSsrReportResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    execution: (f = msg.getExecution()) && proto.reportpb.SsrExecution.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.TriggerSsrReportResponse}
 */
proto.reportpb.TriggerSsrReportResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.TriggerSsrReportResponse;
  return proto.reportpb.TriggerSsrReportResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.TriggerSsrReportResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.TriggerSsrReportResponse}
 */
proto.reportpb.TriggerSsrReportResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrExecution;
      reader.readMessage(value,proto.reportpb.SsrExecution.deserializeBinaryFromReader);
      msg.setExecution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.TriggerSsrReportResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.TriggerSsrReportResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.TriggerSsrReportResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.TriggerSsrReportResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecution();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrExecution.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrExecution execution = 1;
 * @return {?proto.reportpb.SsrExecution}
 */
proto.reportpb.TriggerSsrReportResponse.prototype.getExecution = function() {
  return /** @type{?proto.reportpb.SsrExecution} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrExecution, 1));
};


/**
 * @param {?proto.reportpb.SsrExecution|undefined} value
 * @return {!proto.reportpb.TriggerSsrReportResponse} returns this
*/
proto.reportpb.TriggerSsrReportResponse.prototype.setExecution = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.TriggerSsrReportResponse} returns this
 */
proto.reportpb.TriggerSsrReportResponse.prototype.clearExecution = function() {
  return this.setExecution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.TriggerSsrReportResponse.prototype.hasExecution = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UpdateSsrExecutionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UpdateSsrExecutionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrExecutionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reviewComment: jspb.Message.getFieldWithDefault(msg, 2, ""),
    reviewCommenterId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    clearReviewComment: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UpdateSsrExecutionRequest}
 */
proto.reportpb.UpdateSsrExecutionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UpdateSsrExecutionRequest;
  return proto.reportpb.UpdateSsrExecutionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UpdateSsrExecutionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UpdateSsrExecutionRequest}
 */
proto.reportpb.UpdateSsrExecutionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewComment(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewCommenterId(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setClearReviewComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UpdateSsrExecutionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UpdateSsrExecutionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrExecutionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReviewComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReviewCommenterId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getClearReviewComment();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string execution_id = 1;
 * @return {string}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.UpdateSsrExecutionRequest} returns this
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string review_comment = 2;
 * @return {string}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.getReviewComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.UpdateSsrExecutionRequest} returns this
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.setReviewComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string review_commenter_id = 3;
 * @return {string}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.getReviewCommenterId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.UpdateSsrExecutionRequest} returns this
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.setReviewCommenterId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool clear_review_comment = 4;
 * @return {boolean}
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.getClearReviewComment = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reportpb.UpdateSsrExecutionRequest} returns this
 */
proto.reportpb.UpdateSsrExecutionRequest.prototype.setClearReviewComment = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UpdateSsrExecutionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UpdateSsrExecutionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UpdateSsrExecutionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrExecutionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    execution: (f = msg.getExecution()) && proto.reportpb.SsrExecution.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UpdateSsrExecutionResponse}
 */
proto.reportpb.UpdateSsrExecutionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UpdateSsrExecutionResponse;
  return proto.reportpb.UpdateSsrExecutionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UpdateSsrExecutionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UpdateSsrExecutionResponse}
 */
proto.reportpb.UpdateSsrExecutionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrExecution;
      reader.readMessage(value,proto.reportpb.SsrExecution.deserializeBinaryFromReader);
      msg.setExecution(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UpdateSsrExecutionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UpdateSsrExecutionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UpdateSsrExecutionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UpdateSsrExecutionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecution();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reportpb.SsrExecution.serializeBinaryToWriter
    );
  }
};


/**
 * optional SsrExecution execution = 1;
 * @return {?proto.reportpb.SsrExecution}
 */
proto.reportpb.UpdateSsrExecutionResponse.prototype.getExecution = function() {
  return /** @type{?proto.reportpb.SsrExecution} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.SsrExecution, 1));
};


/**
 * @param {?proto.reportpb.SsrExecution|undefined} value
 * @return {!proto.reportpb.UpdateSsrExecutionResponse} returns this
*/
proto.reportpb.UpdateSsrExecutionResponse.prototype.setExecution = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.UpdateSsrExecutionResponse} returns this
 */
proto.reportpb.UpdateSsrExecutionResponse.prototype.clearExecution = function() {
  return this.setExecution(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.UpdateSsrExecutionResponse.prototype.hasExecution = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ReviewSsrRowRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ReviewSsrRowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ReviewSsrRowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reviewerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rowCommentsList: jspb.Message.toObjectList(msg.getRowCommentsList(),
    proto.reportpb.ReviewSsrRowRequest.RowComment.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ReviewSsrRowRequest}
 */
proto.reportpb.ReviewSsrRowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ReviewSsrRowRequest;
  return proto.reportpb.ReviewSsrRowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ReviewSsrRowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ReviewSsrRowRequest}
 */
proto.reportpb.ReviewSsrRowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerId(value);
      break;
    case 3:
      var value = new proto.reportpb.ReviewSsrRowRequest.RowComment;
      reader.readMessage(value,proto.reportpb.ReviewSsrRowRequest.RowComment.deserializeBinaryFromReader);
      msg.addRowComments(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ReviewSsrRowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ReviewSsrRowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReviewerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRowCommentsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.reportpb.ReviewSsrRowRequest.RowComment.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ReviewSsrRowRequest.RowComment.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ReviewSsrRowRequest.RowComment} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ReviewSsrRowRequest.RowComment}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ReviewSsrRowRequest.RowComment;
  return proto.reportpb.ReviewSsrRowRequest.RowComment.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ReviewSsrRowRequest.RowComment} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ReviewSsrRowRequest.RowComment}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRowId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ReviewSsrRowRequest.RowComment.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ReviewSsrRowRequest.RowComment} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string row_id = 1;
 * @return {string}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.getRowId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ReviewSsrRowRequest.RowComment} returns this
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.setRowId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string comment = 2;
 * @return {string}
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ReviewSsrRowRequest.RowComment} returns this
 */
proto.reportpb.ReviewSsrRowRequest.RowComment.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string execution_id = 1;
 * @return {string}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ReviewSsrRowRequest} returns this
 */
proto.reportpb.ReviewSsrRowRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reviewer_id = 2;
 * @return {string}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.getReviewerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ReviewSsrRowRequest} returns this
 */
proto.reportpb.ReviewSsrRowRequest.prototype.setReviewerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated RowComment row_comments = 3;
 * @return {!Array<!proto.reportpb.ReviewSsrRowRequest.RowComment>}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.getRowCommentsList = function() {
  return /** @type{!Array<!proto.reportpb.ReviewSsrRowRequest.RowComment>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.ReviewSsrRowRequest.RowComment, 3));
};


/**
 * @param {!Array<!proto.reportpb.ReviewSsrRowRequest.RowComment>} value
 * @return {!proto.reportpb.ReviewSsrRowRequest} returns this
*/
proto.reportpb.ReviewSsrRowRequest.prototype.setRowCommentsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.reportpb.ReviewSsrRowRequest.RowComment=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ReviewSsrRowRequest.RowComment}
 */
proto.reportpb.ReviewSsrRowRequest.prototype.addRowComments = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.reportpb.ReviewSsrRowRequest.RowComment, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ReviewSsrRowRequest} returns this
 */
proto.reportpb.ReviewSsrRowRequest.prototype.clearRowCommentsList = function() {
  return this.setRowCommentsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ReviewSsrRowResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ReviewSsrRowResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ReviewSsrRowResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ReviewSsrRowResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsList: jspb.Message.toObjectList(msg.getRowsList(),
    proto.reportpb.SsrRow.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ReviewSsrRowResponse}
 */
proto.reportpb.ReviewSsrRowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ReviewSsrRowResponse;
  return proto.reportpb.ReviewSsrRowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ReviewSsrRowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ReviewSsrRowResponse}
 */
proto.reportpb.ReviewSsrRowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SsrRow;
      reader.readMessage(value,proto.reportpb.SsrRow.deserializeBinaryFromReader);
      msg.addRows(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ReviewSsrRowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ReviewSsrRowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ReviewSsrRowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ReviewSsrRowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SsrRow.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SsrRow rows = 1;
 * @return {!Array<!proto.reportpb.SsrRow>}
 */
proto.reportpb.ReviewSsrRowResponse.prototype.getRowsList = function() {
  return /** @type{!Array<!proto.reportpb.SsrRow>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SsrRow, 1));
};


/**
 * @param {!Array<!proto.reportpb.SsrRow>} value
 * @return {!proto.reportpb.ReviewSsrRowResponse} returns this
*/
proto.reportpb.ReviewSsrRowResponse.prototype.setRowsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SsrRow=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SsrRow}
 */
proto.reportpb.ReviewSsrRowResponse.prototype.addRows = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SsrRow, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ReviewSsrRowResponse} returns this
 */
proto.reportpb.ReviewSsrRowResponse.prototype.clearRowsList = function() {
  return this.setRowsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ClearSsrReviewRequest.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ClearSsrReviewRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ClearSsrReviewRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ClearSsrReviewRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ClearSsrReviewRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    reviewerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    rowIdsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ClearSsrReviewRequest}
 */
proto.reportpb.ClearSsrReviewRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ClearSsrReviewRequest;
  return proto.reportpb.ClearSsrReviewRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ClearSsrReviewRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ClearSsrReviewRequest}
 */
proto.reportpb.ClearSsrReviewRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setReviewerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addRowIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ClearSsrReviewRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ClearSsrReviewRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ClearSsrReviewRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ClearSsrReviewRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getReviewerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRowIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
};


/**
 * optional string execution_id = 1;
 * @return {string}
 */
proto.reportpb.ClearSsrReviewRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ClearSsrReviewRequest} returns this
 */
proto.reportpb.ClearSsrReviewRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string reviewer_id = 2;
 * @return {string}
 */
proto.reportpb.ClearSsrReviewRequest.prototype.getReviewerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ClearSsrReviewRequest} returns this
 */
proto.reportpb.ClearSsrReviewRequest.prototype.setReviewerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string row_ids = 3;
 * @return {!Array<string>}
 */
proto.reportpb.ClearSsrReviewRequest.prototype.getRowIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ClearSsrReviewRequest} returns this
 */
proto.reportpb.ClearSsrReviewRequest.prototype.setRowIdsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ClearSsrReviewRequest} returns this
 */
proto.reportpb.ClearSsrReviewRequest.prototype.addRowIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ClearSsrReviewRequest} returns this
 */
proto.reportpb.ClearSsrReviewRequest.prototype.clearRowIdsList = function() {
  return this.setRowIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ClearSsrReviewResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ClearSsrReviewResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ClearSsrReviewResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ClearSsrReviewResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ClearSsrReviewResponse}
 */
proto.reportpb.ClearSsrReviewResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ClearSsrReviewResponse;
  return proto.reportpb.ClearSsrReviewResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ClearSsrReviewResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ClearSsrReviewResponse}
 */
proto.reportpb.ClearSsrReviewResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ClearSsrReviewResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ClearSsrReviewResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ClearSsrReviewResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ClearSsrReviewResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.GenerateReviewedFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.GenerateReviewedFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.GenerateReviewedFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uploaderId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.GenerateReviewedFileRequest}
 */
proto.reportpb.GenerateReviewedFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.GenerateReviewedFileRequest;
  return proto.reportpb.GenerateReviewedFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.GenerateReviewedFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.GenerateReviewedFileRequest}
 */
proto.reportpb.GenerateReviewedFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploaderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.GenerateReviewedFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.GenerateReviewedFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.GenerateReviewedFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploaderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string execution_id = 1;
 * @return {string}
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.GenerateReviewedFileRequest} returns this
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uploader_id = 2;
 * @return {string}
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.getUploaderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.GenerateReviewedFileRequest} returns this
 */
proto.reportpb.GenerateReviewedFileRequest.prototype.setUploaderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UploadReviewedFileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UploadReviewedFileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UploadReviewedFileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UploadReviewedFileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    uploaderId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UploadReviewedFileRequest}
 */
proto.reportpb.UploadReviewedFileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UploadReviewedFileRequest;
  return proto.reportpb.UploadReviewedFileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UploadReviewedFileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UploadReviewedFileRequest}
 */
proto.reportpb.UploadReviewedFileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUploaderId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UploadReviewedFileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UploadReviewedFileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UploadReviewedFileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UploadReviewedFileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUploaderId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string execution_id = 1;
 * @return {string}
 */
proto.reportpb.UploadReviewedFileRequest.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.UploadReviewedFileRequest} returns this
 */
proto.reportpb.UploadReviewedFileRequest.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string uploader_id = 2;
 * @return {string}
 */
proto.reportpb.UploadReviewedFileRequest.prototype.getUploaderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.UploadReviewedFileRequest} returns this
 */
proto.reportpb.UploadReviewedFileRequest.prototype.setUploaderId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.UploadReviewedFileResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.UploadReviewedFileResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.UploadReviewedFileResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UploadReviewedFileResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.UploadReviewedFileResponse}
 */
proto.reportpb.UploadReviewedFileResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.UploadReviewedFileResponse;
  return proto.reportpb.UploadReviewedFileResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.UploadReviewedFileResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.UploadReviewedFileResponse}
 */
proto.reportpb.UploadReviewedFileResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.UploadReviewedFileResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.UploadReviewedFileResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.UploadReviewedFileResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.UploadReviewedFileResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.reportpb);
