/**
 * @fileoverview gRPC-Web generated client stub for irspb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.irspb = require('./notice_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.irspb.NoticeServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.irspb.NoticeServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.irspb.CreateNoticeRequest,
 *   !proto.irspb.CreateNoticeResponse>}
 */
const methodDescriptor_NoticeService_CreateNotice = new grpc.web.MethodDescriptor(
  '/irspb.NoticeService/CreateNotice',
  grpc.web.MethodType.UNARY,
  proto.irspb.CreateNoticeRequest,
  proto.irspb.CreateNoticeResponse,
  /**
   * @param {!proto.irspb.CreateNoticeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.irspb.CreateNoticeResponse.deserializeBinary
);


/**
 * @param {!proto.irspb.CreateNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.irspb.CreateNoticeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.irspb.CreateNoticeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.irspb.NoticeServiceClient.prototype.createNotice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/irspb.NoticeService/CreateNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_CreateNotice,
      callback);
};


/**
 * @param {!proto.irspb.CreateNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.irspb.CreateNoticeResponse>}
 *     Promise that resolves to the response
 */
proto.irspb.NoticeServicePromiseClient.prototype.createNotice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/irspb.NoticeService/CreateNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_CreateNotice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.irspb.DeactivateNoticeRequest,
 *   !proto.irspb.DeactivateNoticeResponse>}
 */
const methodDescriptor_NoticeService_DeactivateNotice = new grpc.web.MethodDescriptor(
  '/irspb.NoticeService/DeactivateNotice',
  grpc.web.MethodType.UNARY,
  proto.irspb.DeactivateNoticeRequest,
  proto.irspb.DeactivateNoticeResponse,
  /**
   * @param {!proto.irspb.DeactivateNoticeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.irspb.DeactivateNoticeResponse.deserializeBinary
);


/**
 * @param {!proto.irspb.DeactivateNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.irspb.DeactivateNoticeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.irspb.DeactivateNoticeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.irspb.NoticeServiceClient.prototype.deactivateNotice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/irspb.NoticeService/DeactivateNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_DeactivateNotice,
      callback);
};


/**
 * @param {!proto.irspb.DeactivateNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.irspb.DeactivateNoticeResponse>}
 *     Promise that resolves to the response
 */
proto.irspb.NoticeServicePromiseClient.prototype.deactivateNotice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/irspb.NoticeService/DeactivateNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_DeactivateNotice);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.irspb.ListNoticeRequest,
 *   !proto.irspb.ListNoticeResponse>}
 */
const methodDescriptor_NoticeService_ListNotice = new grpc.web.MethodDescriptor(
  '/irspb.NoticeService/ListNotice',
  grpc.web.MethodType.UNARY,
  proto.irspb.ListNoticeRequest,
  proto.irspb.ListNoticeResponse,
  /**
   * @param {!proto.irspb.ListNoticeRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.irspb.ListNoticeResponse.deserializeBinary
);


/**
 * @param {!proto.irspb.ListNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.irspb.ListNoticeResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.irspb.ListNoticeResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.irspb.NoticeServiceClient.prototype.listNotice =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/irspb.NoticeService/ListNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_ListNotice,
      callback);
};


/**
 * @param {!proto.irspb.ListNoticeRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.irspb.ListNoticeResponse>}
 *     Promise that resolves to the response
 */
proto.irspb.NoticeServicePromiseClient.prototype.listNotice =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/irspb.NoticeService/ListNotice',
      request,
      metadata || {},
      methodDescriptor_NoticeService_ListNotice);
};


module.exports = proto.irspb;

