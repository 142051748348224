// source: reorgpb/dividend.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.Dividend', null, global);
goog.exportSymbol('proto.reorgpb.ListDividendRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListDividendResponse', null, global);
goog.exportSymbol('proto.reorgpb.UpdateDividendRequest', null, global);
goog.exportSymbol('proto.reorgpb.UpdateDividendResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.Dividend = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.Dividend, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.Dividend.displayName = 'proto.reorgpb.Dividend';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListDividendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListDividendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListDividendRequest.displayName = 'proto.reorgpb.ListDividendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListDividendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListDividendResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListDividendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListDividendResponse.displayName = 'proto.reorgpb.ListDividendResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateDividendRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateDividendRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateDividendRequest.displayName = 'proto.reorgpb.UpdateDividendRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateDividendResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateDividendResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateDividendResponse.displayName = 'proto.reorgpb.UpdateDividendResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.Dividend.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.Dividend.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.Dividend} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Dividend.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    type: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cashRate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    qtyRate: jspb.Message.getFieldWithDefault(msg, 7, ""),
    recordDate: (f = msg.getRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    payableDate: (f = msg.getPayableDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    declarationDate: (f = msg.getDeclarationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    netCash: jspb.Message.getFieldWithDefault(msg, 14, ""),
    netQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
    pendingTrnsCount: jspb.Message.getFieldWithDefault(msg, 16, 0),
    taxRate: jspb.Message.getFieldWithDefault(msg, 17, ""),
    dtccCashRate: jspb.Message.getFieldWithDefault(msg, 18, ""),
    taxCountry: jspb.Message.getFieldWithDefault(msg, 19, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    detail: jspb.Message.getFieldWithDefault(msg, 21, ""),
    netPayCash: jspb.Message.getFieldWithDefault(msg, 22, ""),
    netPayQty: jspb.Message.getFieldWithDefault(msg, 23, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.Dividend}
 */
proto.reorgpb.Dividend.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.Dividend;
  return proto.reorgpb.Dividend.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.Dividend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.Dividend}
 */
proto.reorgpb.Dividend.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashRate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setQtyRate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setRecordDate(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPayableDate(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeclarationDate(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPositionDate(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetCash(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetQty(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingTrnsCount(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxRate(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtccCashRate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setTaxCountry(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayCash(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.Dividend.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.Dividend.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.Dividend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Dividend.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCashRate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getQtyRate();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRecordDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPayableDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getDeclarationDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getNetCash();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNetQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getPendingTrnsCount();
  if (f !== 0) {
    writer.writeInt32(
      16,
      f
    );
  }
  f = message.getTaxRate();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDtccCashRate();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getTaxCountry();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getNetPayCash();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getNetPayQty();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string original_cusip = 4;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string type = 5;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cash_rate = 6;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getCashRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setCashRate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string qty_rate = 7;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getQtyRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setQtyRate = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date record_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearRecordDate = function() {
  return this.setRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasRecordDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.type.Date payable_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getPayableDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setPayableDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearPayableDate = function() {
  return this.setPayableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasPayableDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.Date expiration_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.type.Date declaration_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getDeclarationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setDeclarationDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearDeclarationDate = function() {
  return this.setDeclarationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasDeclarationDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.type.Date position_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getPositionDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setPositionDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearPositionDate = function() {
  return this.setPositionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasPositionDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.type.Date process_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Dividend.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Dividend} returns this
*/
proto.reorgpb.Dividend.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string net_cash = 14;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getNetCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setNetCash = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string net_qty = 15;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getNetQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setNetQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional int32 pending_trns_count = 16;
 * @return {number}
 */
proto.reorgpb.Dividend.prototype.getPendingTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setPendingTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string tax_rate = 17;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getTaxRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setTaxRate = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string dtcc_cash_rate = 18;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getDtccCashRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setDtccCashRate = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string tax_country = 19;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getTaxCountry = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setTaxCountry = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional bool no_cil = 20;
 * @return {boolean}
 */
proto.reorgpb.Dividend.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional string detail = 21;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string net_pay_cash = 22;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getNetPayCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setNetPayCash = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string net_pay_qty = 23;
 * @return {string}
 */
proto.reorgpb.Dividend.prototype.getNetPayQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Dividend} returns this
 */
proto.reorgpb.Dividend.prototype.setNetPayQty = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListDividendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListDividendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListDividendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListDividendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    pendingTrnsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListDividendRequest}
 */
proto.reorgpb.ListDividendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListDividendRequest;
  return proto.reorgpb.ListDividendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListDividendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListDividendRequest}
 */
proto.reorgpb.ListDividendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingTrnsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListDividendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListDividendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListDividendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListDividendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPendingTrnsOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListDividendRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListDividendRequest} returns this
 */
proto.reorgpb.ListDividendRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListDividendRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListDividendRequest} returns this
*/
proto.reorgpb.ListDividendRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListDividendRequest} returns this
 */
proto.reorgpb.ListDividendRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListDividendRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListDividendRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListDividendRequest} returns this
*/
proto.reorgpb.ListDividendRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListDividendRequest} returns this
 */
proto.reorgpb.ListDividendRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListDividendRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_trns_only = 4;
 * @return {boolean}
 */
proto.reorgpb.ListDividendRequest.prototype.getPendingTrnsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.ListDividendRequest} returns this
 */
proto.reorgpb.ListDividendRequest.prototype.setPendingTrnsOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListDividendResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListDividendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListDividendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListDividendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListDividendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dividendsList: jspb.Message.toObjectList(msg.getDividendsList(),
    proto.reorgpb.Dividend.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListDividendResponse}
 */
proto.reorgpb.ListDividendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListDividendResponse;
  return proto.reorgpb.ListDividendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListDividendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListDividendResponse}
 */
proto.reorgpb.ListDividendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Dividend;
      reader.readMessage(value,proto.reorgpb.Dividend.deserializeBinaryFromReader);
      msg.addDividends(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListDividendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListDividendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListDividendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListDividendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDividendsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.Dividend.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Dividend dividends = 1;
 * @return {!Array<!proto.reorgpb.Dividend>}
 */
proto.reorgpb.ListDividendResponse.prototype.getDividendsList = function() {
  return /** @type{!Array<!proto.reorgpb.Dividend>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.Dividend, 1));
};


/**
 * @param {!Array<!proto.reorgpb.Dividend>} value
 * @return {!proto.reorgpb.ListDividendResponse} returns this
*/
proto.reorgpb.ListDividendResponse.prototype.setDividendsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.Dividend=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.Dividend}
 */
proto.reorgpb.ListDividendResponse.prototype.addDividends = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.Dividend, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListDividendResponse} returns this
 */
proto.reorgpb.ListDividendResponse.prototype.clearDividendsList = function() {
  return this.setDividendsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateDividendRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateDividendRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateDividendRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateDividendRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    dtccCashRate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateDividendRequest}
 */
proto.reorgpb.UpdateDividendRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateDividendRequest;
  return proto.reorgpb.UpdateDividendRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateDividendRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateDividendRequest}
 */
proto.reorgpb.UpdateDividendRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtccCashRate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateDividendRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateDividendRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateDividendRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateDividendRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getDtccCashRate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.UpdateDividendRequest.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateDividendRequest} returns this
 */
proto.reorgpb.UpdateDividendRequest.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool no_cil = 2;
 * @return {boolean}
 */
proto.reorgpb.UpdateDividendRequest.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.UpdateDividendRequest} returns this
 */
proto.reorgpb.UpdateDividendRequest.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string dtcc_cash_rate = 3;
 * @return {string}
 */
proto.reorgpb.UpdateDividendRequest.prototype.getDtccCashRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateDividendRequest} returns this
 */
proto.reorgpb.UpdateDividendRequest.prototype.setDtccCashRate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.Date process_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.UpdateDividendRequest.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.UpdateDividendRequest} returns this
*/
proto.reorgpb.UpdateDividendRequest.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateDividendRequest} returns this
 */
proto.reorgpb.UpdateDividendRequest.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateDividendRequest.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateDividendResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateDividendResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateDividendResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateDividendResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dividend: (f = msg.getDividend()) && proto.reorgpb.Dividend.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateDividendResponse}
 */
proto.reorgpb.UpdateDividendResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateDividendResponse;
  return proto.reorgpb.UpdateDividendResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateDividendResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateDividendResponse}
 */
proto.reorgpb.UpdateDividendResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Dividend;
      reader.readMessage(value,proto.reorgpb.Dividend.deserializeBinaryFromReader);
      msg.setDividend(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateDividendResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateDividendResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateDividendResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateDividendResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDividend();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.Dividend.serializeBinaryToWriter
    );
  }
};


/**
 * optional Dividend dividend = 1;
 * @return {?proto.reorgpb.Dividend}
 */
proto.reorgpb.UpdateDividendResponse.prototype.getDividend = function() {
  return /** @type{?proto.reorgpb.Dividend} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.Dividend, 1));
};


/**
 * @param {?proto.reorgpb.Dividend|undefined} value
 * @return {!proto.reorgpb.UpdateDividendResponse} returns this
*/
proto.reorgpb.UpdateDividendResponse.prototype.setDividend = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateDividendResponse} returns this
 */
proto.reorgpb.UpdateDividendResponse.prototype.clearDividend = function() {
  return this.setDividend(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateDividendResponse.prototype.hasDividend = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.reorgpb);
