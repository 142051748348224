// source: trnspb/transaction.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.trnspb.BatchCancelTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchCancelTransactionResponse', null, global);
goog.exportSymbol('proto.trnspb.BatchCorrectTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchCorrectTransactionResponse', null, global);
goog.exportSymbol('proto.trnspb.BatchCreateTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchCreateTransactionResponse', null, global);
goog.exportSymbol('proto.trnspb.BatchMovePositionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchProcessPendingTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.BatchProcessPendingTransactionResponse', null, global);
goog.exportSymbol('proto.trnspb.CancelTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.FxCustodyBankAccounts', null, global);
goog.exportSymbol('proto.trnspb.GetFeeRequest', null, global);
goog.exportSymbol('proto.trnspb.GetFeeResponse', null, global);
goog.exportSymbol('proto.trnspb.Hierarchy', null, global);
goog.exportSymbol('proto.trnspb.ListFeesRequest', null, global);
goog.exportSymbol('proto.trnspb.ListFeesResponse', null, global);
goog.exportSymbol('proto.trnspb.Metadata', null, global);
goog.exportSymbol('proto.trnspb.MovePositionData', null, global);
goog.exportSymbol('proto.trnspb.PendingAnnouncement', null, global);
goog.exportSymbol('proto.trnspb.ReadTransactionRequest', null, global);
goog.exportSymbol('proto.trnspb.ReadTransactionResponse', null, global);
goog.exportSymbol('proto.trnspb.Transaction', null, global);
goog.exportSymbol('proto.trnspb.TransactionFee', null, global);
goog.exportSymbol('proto.trnspb.TransactionResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.TransactionFee = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.TransactionFee, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.TransactionFee.displayName = 'proto.trnspb.TransactionFee';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.Transaction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.Transaction.repeatedFields_, null);
};
goog.inherits(proto.trnspb.Transaction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.Transaction.displayName = 'proto.trnspb.Transaction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.TransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.TransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.TransactionResponse.displayName = 'proto.trnspb.TransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.CancelTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.CancelTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.CancelTransactionRequest.displayName = 'proto.trnspb.CancelTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCancelTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCancelTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCancelTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCancelTransactionRequest.displayName = 'proto.trnspb.BatchCancelTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCancelTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCancelTransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCancelTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCancelTransactionResponse.displayName = 'proto.trnspb.BatchCancelTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ReadTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ReadTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ReadTransactionRequest.displayName = 'proto.trnspb.ReadTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ReadTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ReadTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ReadTransactionResponse.displayName = 'proto.trnspb.ReadTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCreateTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCreateTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCreateTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCreateTransactionRequest.displayName = 'proto.trnspb.BatchCreateTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCreateTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCreateTransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCreateTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCreateTransactionResponse.displayName = 'proto.trnspb.BatchCreateTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCorrectTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCorrectTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCorrectTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCorrectTransactionRequest.displayName = 'proto.trnspb.BatchCorrectTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchCorrectTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchCorrectTransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchCorrectTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchCorrectTransactionResponse.displayName = 'proto.trnspb.BatchCorrectTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.GetFeeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.GetFeeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.GetFeeRequest.displayName = 'proto.trnspb.GetFeeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.GetFeeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.GetFeeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.GetFeeResponse.displayName = 'proto.trnspb.GetFeeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ListFeesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.ListFeesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ListFeesRequest.displayName = 'proto.trnspb.ListFeesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.ListFeesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.ListFeesResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.ListFeesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.ListFeesResponse.displayName = 'proto.trnspb.ListFeesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchProcessPendingTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchProcessPendingTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchProcessPendingTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchProcessPendingTransactionRequest.displayName = 'proto.trnspb.BatchProcessPendingTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchProcessPendingTransactionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchProcessPendingTransactionResponse.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchProcessPendingTransactionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchProcessPendingTransactionResponse.displayName = 'proto.trnspb.BatchProcessPendingTransactionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.MovePositionData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.MovePositionData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.MovePositionData.displayName = 'proto.trnspb.MovePositionData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchMovePositionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchMovePositionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchMovePositionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchMovePositionRequest.displayName = 'proto.trnspb.BatchMovePositionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.PendingAnnouncement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.PendingAnnouncement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.PendingAnnouncement.displayName = 'proto.trnspb.PendingAnnouncement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.repeatedFields_, null);
};
goog.inherits(proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.displayName = 'proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.Hierarchy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.trnspb.Hierarchy.repeatedFields_, null);
};
goog.inherits(proto.trnspb.Hierarchy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.Hierarchy.displayName = 'proto.trnspb.Hierarchy';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.FxCustodyBankAccounts = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.FxCustodyBankAccounts, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.FxCustodyBankAccounts.displayName = 'proto.trnspb.FxCustodyBankAccounts';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.trnspb.Metadata = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.trnspb.Metadata, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.trnspb.Metadata.displayName = 'proto.trnspb.Metadata';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.TransactionFee.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.TransactionFee.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.TransactionFee} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TransactionFee.toObject = function(includeInstance, msg) {
  var f, obj = {
    feeType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fee: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.TransactionFee}
 */
proto.trnspb.TransactionFee.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.TransactionFee;
  return proto.trnspb.TransactionFee.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.TransactionFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.TransactionFee}
 */
proto.trnspb.TransactionFee.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFeeType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.TransactionFee.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.TransactionFee.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.TransactionFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TransactionFee.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeeType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string fee_type = 1;
 * @return {string}
 */
proto.trnspb.TransactionFee.prototype.getFeeType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionFee} returns this
 */
proto.trnspb.TransactionFee.prototype.setFeeType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string fee = 2;
 * @return {string}
 */
proto.trnspb.TransactionFee.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionFee} returns this
 */
proto.trnspb.TransactionFee.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.Transaction.repeatedFields_ = [23];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.Transaction.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.Transaction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.Transaction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Transaction.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    tradeAt: (f = msg.getTradeAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 8, ""),
    side: jspb.Message.getFieldWithDefault(msg, 9, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 10, ""),
    price: jspb.Message.getFieldWithDefault(msg, 11, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 12, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 13, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    description: jspb.Message.getFieldWithDefault(msg, 15, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 16, ""),
    executionId: jspb.Message.getFieldWithDefault(msg, 17, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 18, ""),
    executingVenue: jspb.Message.getFieldWithDefault(msg, 19, ""),
    leavesQty: jspb.Message.getFieldWithDefault(msg, 20, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 21, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 22, ""),
    transactionFeesList: jspb.Message.toObjectList(msg.getTransactionFeesList(),
    proto.trnspb.TransactionFee.toObject, includeInstance),
    batchNo: jspb.Message.getFieldWithDefault(msg, 24, ""),
    contraCorrespondent: jspb.Message.getFieldWithDefault(msg, 25, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 26, ""),
    contraAccountId: jspb.Message.getFieldWithDefault(msg, 27, ""),
    contraAccountDesignator: jspb.Message.getFieldWithDefault(msg, 28, ""),
    orderCapacity: jspb.Message.getFieldWithDefault(msg, 29, ""),
    refId: jspb.Message.getFieldWithDefault(msg, 30, ""),
    entrySubType: jspb.Message.getFieldWithDefault(msg, 31, ""),
    cumQty: jspb.Message.getFieldWithDefault(msg, 32, ""),
    orderType: jspb.Message.getFieldWithDefault(msg, 33, ""),
    tif: jspb.Message.getFieldWithDefault(msg, 34, ""),
    clientOrderId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 36, ""),
    executingBrokerMpid: jspb.Message.getFieldWithDefault(msg, 37, ""),
    contraSymbol: jspb.Message.getFieldWithDefault(msg, 38, ""),
    swapRate: jspb.Message.getFieldWithDefault(msg, 39, ""),
    swapRateAt: (f = msg.getSwapRateAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    localPrice: jspb.Message.getFieldWithDefault(msg, 42, ""),
    localNetAmt: jspb.Message.getFieldWithDefault(msg, 43, ""),
    customerSuspenseAccount: jspb.Message.getFieldWithDefault(msg, 44, ""),
    localGrossAmt: jspb.Message.getFieldWithDefault(msg, 45, ""),
    swapFeeBpsCorr: jspb.Message.getFieldWithDefault(msg, 46, ""),
    swapFeeBpsAlpa: jspb.Message.getFieldWithDefault(msg, 47, ""),
    metadata: (f = msg.getMetadata()) && proto.trnspb.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.Transaction}
 */
proto.trnspb.Transaction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.Transaction;
  return proto.trnspb.Transaction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.Transaction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.Transaction}
 */
proto.trnspb.Transaction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 6:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTradeAt(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingVenue(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeavesQty(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 23:
      var value = new proto.trnspb.TransactionFee;
      reader.readMessage(value,proto.trnspb.TransactionFee.deserializeBinaryFromReader);
      msg.addTransactionFees(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraCorrespondent(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountId(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountDesignator(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderCapacity(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntrySubType(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCumQty(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderType(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setTif(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrderId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingBrokerMpid(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSymbol(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapRate(value);
      break;
    case 40:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSwapRateAt(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalPrice(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalNetAmt(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerSuspenseAccount(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalGrossAmt(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsCorr(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsAlpa(value);
      break;
    case 48:
      var value = new proto.trnspb.Metadata;
      reader.readMessage(value,proto.trnspb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.Transaction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.Transaction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.Transaction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Transaction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTradeAt();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getExecutingVenue();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getLeavesQty();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getTransactionFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      23,
      f,
      proto.trnspb.TransactionFee.serializeBinaryToWriter
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getContraCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getContraAccountId();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getContraAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getOrderCapacity();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getEntrySubType();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getCumQty();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getOrderType();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getTif();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getClientOrderId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 36));
  if (f != null) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getExecutingBrokerMpid();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getContraSymbol();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getSwapRate();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getSwapRateAt();
  if (f != null) {
    writer.writeMessage(
      40,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocalPrice();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getLocalNetAmt();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 44));
  if (f != null) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getLocalGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getSwapFeeBpsCorr();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getSwapFeeBpsAlpa();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      48,
      f,
      proto.trnspb.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string contra_account_no = 4;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol = 5;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.protobuf.Timestamp trade_at = 6;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.Transaction.prototype.getTradeAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 6));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.Transaction} returns this
*/
proto.trnspb.Transaction.prototype.setTradeAt = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearTradeAt = function() {
  return this.setTradeAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasTradeAt = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.Date settle_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.Transaction.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.Transaction} returns this
*/
proto.trnspb.Transaction.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional string entry_type = 8;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string side = 9;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string qty = 10;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string price = 11;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string gross_amt = 12;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string fees = 13;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string net_amt = 14;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string description = 15;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string created_by = 16;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string execution_id = 17;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string external_id = 18;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string executing_venue = 19;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getExecutingVenue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setExecutingVenue = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string leaves_qty = 20;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getLeavesQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setLeavesQty = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string order_id = 21;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string vendor = 22;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * repeated TransactionFee transaction_fees = 23;
 * @return {!Array<!proto.trnspb.TransactionFee>}
 */
proto.trnspb.Transaction.prototype.getTransactionFeesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionFee, 23));
};


/**
 * @param {!Array<!proto.trnspb.TransactionFee>} value
 * @return {!proto.trnspb.Transaction} returns this
*/
proto.trnspb.Transaction.prototype.setTransactionFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 23, value);
};


/**
 * @param {!proto.trnspb.TransactionFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionFee}
 */
proto.trnspb.Transaction.prototype.addTransactionFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 23, opt_value, proto.trnspb.TransactionFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearTransactionFeesList = function() {
  return this.setTransactionFeesList([]);
};


/**
 * optional string batch_no = 24;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string contra_correspondent = 25;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getContraCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setContraCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string account_id = 26;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string contra_account_id = 27;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getContraAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setContraAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string contra_account_designator = 28;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getContraAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setContraAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string order_capacity = 29;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getOrderCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setOrderCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string ref_id = 30;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getRefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setRefId = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string entry_sub_type = 31;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getEntrySubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setEntrySubType = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string cum_qty = 32;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getCumQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setCumQty = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string order_type = 33;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getOrderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string tif = 34;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getTif = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setTif = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string client_order_id = 35;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getClientOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setClientOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string cusip = 36;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setCusip = function(value) {
  return jspb.Message.setField(this, 36, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearCusip = function() {
  return jspb.Message.setField(this, 36, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasCusip = function() {
  return jspb.Message.getField(this, 36) != null;
};


/**
 * optional string executing_broker_mpid = 37;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getExecutingBrokerMpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setExecutingBrokerMpid = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string contra_symbol = 38;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getContraSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setContraSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string swap_rate = 39;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getSwapRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setSwapRate = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional google.protobuf.Timestamp swap_rate_at = 40;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.trnspb.Transaction.prototype.getSwapRateAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 40));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.trnspb.Transaction} returns this
*/
proto.trnspb.Transaction.prototype.setSwapRateAt = function(value) {
  return jspb.Message.setWrapperField(this, 40, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearSwapRateAt = function() {
  return this.setSwapRateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasSwapRateAt = function() {
  return jspb.Message.getField(this, 40) != null;
};


/**
 * optional string local_price = 42;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getLocalPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setLocalPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string local_net_amt = 43;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getLocalNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setLocalNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string customer_suspense_account = 44;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getCustomerSuspenseAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setCustomerSuspenseAccount = function(value) {
  return jspb.Message.setField(this, 44, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearCustomerSuspenseAccount = function() {
  return jspb.Message.setField(this, 44, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasCustomerSuspenseAccount = function() {
  return jspb.Message.getField(this, 44) != null;
};


/**
 * optional string local_gross_amt = 45;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getLocalGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setLocalGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string swap_fee_bps_corr = 46;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getSwapFeeBpsCorr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setSwapFeeBpsCorr = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string swap_fee_bps_alpa = 47;
 * @return {string}
 */
proto.trnspb.Transaction.prototype.getSwapFeeBpsAlpa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.setSwapFeeBpsAlpa = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional Metadata metadata = 48;
 * @return {?proto.trnspb.Metadata}
 */
proto.trnspb.Transaction.prototype.getMetadata = function() {
  return /** @type{?proto.trnspb.Metadata} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.Metadata, 48));
};


/**
 * @param {?proto.trnspb.Metadata|undefined} value
 * @return {!proto.trnspb.Transaction} returns this
*/
proto.trnspb.Transaction.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 48, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Transaction} returns this
 */
proto.trnspb.Transaction.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Transaction.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 48) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.TransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.TransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.TransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    errorMessage: jspb.Message.getFieldWithDefault(msg, 2, ""),
    status: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    baseTrnsId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 7, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.TransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.TransactionResponse;
  return proto.trnspb.TransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.TransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.TransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setErrorMessage(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBaseTrnsId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.TransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.TransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.TransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.TransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getErrorMessage();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBaseTrnsId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string error_message = 2;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getErrorMessage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setErrorMessage = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string status = 3;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string external_id = 4;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string base_trns_id = 5;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getBaseTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setBaseTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_no = 6;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string contra_account_no = 7;
 * @return {string}
 */
proto.trnspb.TransactionResponse.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional google.type.Date system_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.TransactionResponse.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.TransactionResponse} returns this
*/
proto.trnspb.TransactionResponse.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.TransactionResponse.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.type.Date settle_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.trnspb.TransactionResponse.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.trnspb.TransactionResponse} returns this
*/
proto.trnspb.TransactionResponse.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.TransactionResponse} returns this
 */
proto.trnspb.TransactionResponse.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.TransactionResponse.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.CancelTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.CancelTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.CancelTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.CancelTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.CancelTransactionRequest}
 */
proto.trnspb.CancelTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.CancelTransactionRequest;
  return proto.trnspb.CancelTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.CancelTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.CancelTransactionRequest}
 */
proto.trnspb.CancelTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.CancelTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.CancelTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.CancelTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.CancelTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.trnspb.CancelTransactionRequest.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.CancelTransactionRequest} returns this
 */
proto.trnspb.CancelTransactionRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_id = 2;
 * @return {string}
 */
proto.trnspb.CancelTransactionRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.CancelTransactionRequest} returns this
 */
proto.trnspb.CancelTransactionRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCancelTransactionRequest.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCancelTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCancelTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCancelTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsIdsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    externalIdsList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCancelTransactionRequest}
 */
proto.trnspb.BatchCancelTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCancelTransactionRequest;
  return proto.trnspb.BatchCancelTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCancelTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCancelTransactionRequest}
 */
proto.trnspb.BatchCancelTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTrnsIds(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addExternalIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCancelTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCancelTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCancelTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getExternalIdsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * repeated string trns_ids = 1;
 * @return {!Array<string>}
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.getTrnsIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.setTrnsIdsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.addTrnsIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.clearTrnsIdsList = function() {
  return this.setTrnsIdsList([]);
};


/**
 * repeated string external_ids = 2;
 * @return {!Array<string>}
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.getExternalIdsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.setExternalIdsList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.addExternalIds = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCancelTransactionRequest} returns this
 */
proto.trnspb.BatchCancelTransactionRequest.prototype.clearExternalIdsList = function() {
  return this.setExternalIdsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCancelTransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCancelTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCancelTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCancelTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCancelTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionResponsesList: jspb.Message.toObjectList(msg.getTransactionResponsesList(),
    proto.trnspb.TransactionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCancelTransactionResponse}
 */
proto.trnspb.BatchCancelTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCancelTransactionResponse;
  return proto.trnspb.BatchCancelTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCancelTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCancelTransactionResponse}
 */
proto.trnspb.BatchCancelTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TransactionResponse;
      reader.readMessage(value,proto.trnspb.TransactionResponse.deserializeBinaryFromReader);
      msg.addTransactionResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCancelTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCancelTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCancelTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCancelTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionResponse transaction_responses = 1;
 * @return {!Array<!proto.trnspb.TransactionResponse>}
 */
proto.trnspb.BatchCancelTransactionResponse.prototype.getTransactionResponsesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionResponse, 1));
};


/**
 * @param {!Array<!proto.trnspb.TransactionResponse>} value
 * @return {!proto.trnspb.BatchCancelTransactionResponse} returns this
*/
proto.trnspb.BatchCancelTransactionResponse.prototype.setTransactionResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TransactionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.BatchCancelTransactionResponse.prototype.addTransactionResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TransactionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCancelTransactionResponse} returns this
 */
proto.trnspb.BatchCancelTransactionResponse.prototype.clearTransactionResponsesList = function() {
  return this.setTransactionResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ReadTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ReadTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ReadTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ReadTransactionRequest}
 */
proto.trnspb.ReadTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ReadTransactionRequest;
  return proto.trnspb.ReadTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ReadTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ReadTransactionRequest}
 */
proto.trnspb.ReadTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ReadTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ReadTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ReadTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.trnspb.ReadTransactionRequest.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ReadTransactionRequest} returns this
 */
proto.trnspb.ReadTransactionRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.trnspb.ReadTransactionRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.ReadTransactionRequest} returns this
 */
proto.trnspb.ReadTransactionRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ReadTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ReadTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ReadTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transaction: (f = msg.getTransaction()) && proto.trnspb.Transaction.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ReadTransactionResponse}
 */
proto.trnspb.ReadTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ReadTransactionResponse;
  return proto.trnspb.ReadTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ReadTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ReadTransactionResponse}
 */
proto.trnspb.ReadTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.Transaction;
      reader.readMessage(value,proto.trnspb.Transaction.deserializeBinaryFromReader);
      msg.setTransaction(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ReadTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ReadTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ReadTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ReadTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransaction();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.trnspb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * optional Transaction transaction = 1;
 * @return {?proto.trnspb.Transaction}
 */
proto.trnspb.ReadTransactionResponse.prototype.getTransaction = function() {
  return /** @type{?proto.trnspb.Transaction} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.Transaction, 1));
};


/**
 * @param {?proto.trnspb.Transaction|undefined} value
 * @return {!proto.trnspb.ReadTransactionResponse} returns this
*/
proto.trnspb.ReadTransactionResponse.prototype.setTransaction = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.ReadTransactionResponse} returns this
 */
proto.trnspb.ReadTransactionResponse.prototype.clearTransaction = function() {
  return this.setTransaction(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.ReadTransactionResponse.prototype.hasTransaction = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCreateTransactionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCreateTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCreateTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCreateTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.trnspb.Transaction.toObject, includeInstance),
    allOrNone: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    instrumentOn: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCreateTransactionRequest}
 */
proto.trnspb.BatchCreateTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCreateTransactionRequest;
  return proto.trnspb.BatchCreateTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCreateTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCreateTransactionRequest}
 */
proto.trnspb.BatchCreateTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.Transaction;
      reader.readMessage(value,proto.trnspb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllOrNone(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setInstrumentOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCreateTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCreateTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCreateTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getAllOrNone();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getInstrumentOn();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.trnspb.Transaction>}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.trnspb.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.Transaction, 1));
};


/**
 * @param {!Array<!proto.trnspb.Transaction>} value
 * @return {!proto.trnspb.BatchCreateTransactionRequest} returns this
*/
proto.trnspb.BatchCreateTransactionRequest.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.Transaction}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCreateTransactionRequest} returns this
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional bool all_or_none = 2;
 * @return {boolean}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.getAllOrNone = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trnspb.BatchCreateTransactionRequest} returns this
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.setAllOrNone = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional bool instrument_on = 3;
 * @return {boolean}
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.getInstrumentOn = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trnspb.BatchCreateTransactionRequest} returns this
 */
proto.trnspb.BatchCreateTransactionRequest.prototype.setInstrumentOn = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCreateTransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCreateTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCreateTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCreateTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCreateTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionResponsesList: jspb.Message.toObjectList(msg.getTransactionResponsesList(),
    proto.trnspb.TransactionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCreateTransactionResponse}
 */
proto.trnspb.BatchCreateTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCreateTransactionResponse;
  return proto.trnspb.BatchCreateTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCreateTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCreateTransactionResponse}
 */
proto.trnspb.BatchCreateTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TransactionResponse;
      reader.readMessage(value,proto.trnspb.TransactionResponse.deserializeBinaryFromReader);
      msg.addTransactionResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCreateTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCreateTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCreateTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCreateTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionResponse transaction_responses = 1;
 * @return {!Array<!proto.trnspb.TransactionResponse>}
 */
proto.trnspb.BatchCreateTransactionResponse.prototype.getTransactionResponsesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionResponse, 1));
};


/**
 * @param {!Array<!proto.trnspb.TransactionResponse>} value
 * @return {!proto.trnspb.BatchCreateTransactionResponse} returns this
*/
proto.trnspb.BatchCreateTransactionResponse.prototype.setTransactionResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TransactionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.BatchCreateTransactionResponse.prototype.addTransactionResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TransactionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCreateTransactionResponse} returns this
 */
proto.trnspb.BatchCreateTransactionResponse.prototype.clearTransactionResponsesList = function() {
  return this.setTransactionResponsesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCorrectTransactionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCorrectTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCorrectTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCorrectTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.trnspb.Transaction.toObject, includeInstance),
    skipCancelEventTrigger: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCorrectTransactionRequest}
 */
proto.trnspb.BatchCorrectTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCorrectTransactionRequest;
  return proto.trnspb.BatchCorrectTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCorrectTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCorrectTransactionRequest}
 */
proto.trnspb.BatchCorrectTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.Transaction;
      reader.readMessage(value,proto.trnspb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipCancelEventTrigger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCorrectTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCorrectTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCorrectTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.Transaction.serializeBinaryToWriter
    );
  }
  f = message.getSkipCancelEventTrigger();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.trnspb.Transaction>}
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.trnspb.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.Transaction, 1));
};


/**
 * @param {!Array<!proto.trnspb.Transaction>} value
 * @return {!proto.trnspb.BatchCorrectTransactionRequest} returns this
*/
proto.trnspb.BatchCorrectTransactionRequest.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.Transaction}
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCorrectTransactionRequest} returns this
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};


/**
 * optional bool skip_cancel_event_trigger = 2;
 * @return {boolean}
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.getSkipCancelEventTrigger = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trnspb.BatchCorrectTransactionRequest} returns this
 */
proto.trnspb.BatchCorrectTransactionRequest.prototype.setSkipCancelEventTrigger = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchCorrectTransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchCorrectTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchCorrectTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchCorrectTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCorrectTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionResponsesList: jspb.Message.toObjectList(msg.getTransactionResponsesList(),
    proto.trnspb.TransactionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchCorrectTransactionResponse}
 */
proto.trnspb.BatchCorrectTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchCorrectTransactionResponse;
  return proto.trnspb.BatchCorrectTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchCorrectTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchCorrectTransactionResponse}
 */
proto.trnspb.BatchCorrectTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TransactionResponse;
      reader.readMessage(value,proto.trnspb.TransactionResponse.deserializeBinaryFromReader);
      msg.addTransactionResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchCorrectTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchCorrectTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchCorrectTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchCorrectTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionResponse transaction_responses = 1;
 * @return {!Array<!proto.trnspb.TransactionResponse>}
 */
proto.trnspb.BatchCorrectTransactionResponse.prototype.getTransactionResponsesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionResponse, 1));
};


/**
 * @param {!Array<!proto.trnspb.TransactionResponse>} value
 * @return {!proto.trnspb.BatchCorrectTransactionResponse} returns this
*/
proto.trnspb.BatchCorrectTransactionResponse.prototype.setTransactionResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TransactionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.BatchCorrectTransactionResponse.prototype.addTransactionResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TransactionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchCorrectTransactionResponse} returns this
 */
proto.trnspb.BatchCorrectTransactionResponse.prototype.clearTransactionResponsesList = function() {
  return this.setTransactionResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.GetFeeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.GetFeeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.GetFeeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.GetFeeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.GetFeeRequest}
 */
proto.trnspb.GetFeeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.GetFeeRequest;
  return proto.trnspb.GetFeeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.GetFeeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.GetFeeRequest}
 */
proto.trnspb.GetFeeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.GetFeeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.GetFeeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.GetFeeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.GetFeeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.trnspb.GetFeeRequest.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.GetFeeRequest} returns this
 */
proto.trnspb.GetFeeRequest.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.GetFeeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.GetFeeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.GetFeeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.GetFeeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    fee: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.GetFeeResponse}
 */
proto.trnspb.GetFeeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.GetFeeResponse;
  return proto.trnspb.GetFeeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.GetFeeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.GetFeeResponse}
 */
proto.trnspb.GetFeeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFee(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.GetFeeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.GetFeeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.GetFeeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.GetFeeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFee();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string fee = 1;
 * @return {string}
 */
proto.trnspb.GetFeeResponse.prototype.getFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.GetFeeResponse} returns this
 */
proto.trnspb.GetFeeResponse.prototype.setFee = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ListFeesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ListFeesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ListFeesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListFeesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ListFeesRequest}
 */
proto.trnspb.ListFeesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ListFeesRequest;
  return proto.trnspb.ListFeesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ListFeesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ListFeesRequest}
 */
proto.trnspb.ListFeesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ListFeesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ListFeesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ListFeesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListFeesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.ListFeesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.ListFeesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.ListFeesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.ListFeesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListFeesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    feesList: jspb.Message.toObjectList(msg.getFeesList(),
    proto.trnspb.TransactionFee.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.ListFeesResponse}
 */
proto.trnspb.ListFeesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.ListFeesResponse;
  return proto.trnspb.ListFeesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.ListFeesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.ListFeesResponse}
 */
proto.trnspb.ListFeesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TransactionFee;
      reader.readMessage(value,proto.trnspb.TransactionFee.deserializeBinaryFromReader);
      msg.addFees(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.ListFeesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.ListFeesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.ListFeesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.ListFeesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TransactionFee.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionFee fees = 1;
 * @return {!Array<!proto.trnspb.TransactionFee>}
 */
proto.trnspb.ListFeesResponse.prototype.getFeesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionFee>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionFee, 1));
};


/**
 * @param {!Array<!proto.trnspb.TransactionFee>} value
 * @return {!proto.trnspb.ListFeesResponse} returns this
*/
proto.trnspb.ListFeesResponse.prototype.setFeesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TransactionFee=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionFee}
 */
proto.trnspb.ListFeesResponse.prototype.addFees = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TransactionFee, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.ListFeesResponse} returns this
 */
proto.trnspb.ListFeesResponse.prototype.clearFeesList = function() {
  return this.setFeesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchProcessPendingTransactionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchProcessPendingTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchProcessPendingTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    transactionsList: jspb.Message.toObjectList(msg.getTransactionsList(),
    proto.trnspb.Transaction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchProcessPendingTransactionRequest}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchProcessPendingTransactionRequest;
  return proto.trnspb.BatchProcessPendingTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchProcessPendingTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchProcessPendingTransactionRequest}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.Transaction;
      reader.readMessage(value,proto.trnspb.Transaction.deserializeBinaryFromReader);
      msg.addTransactions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchProcessPendingTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchProcessPendingTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTransactionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.Transaction.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Transaction transactions = 1;
 * @return {!Array<!proto.trnspb.Transaction>}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.getTransactionsList = function() {
  return /** @type{!Array<!proto.trnspb.Transaction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.Transaction, 1));
};


/**
 * @param {!Array<!proto.trnspb.Transaction>} value
 * @return {!proto.trnspb.BatchProcessPendingTransactionRequest} returns this
*/
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.setTransactionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.Transaction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.Transaction}
 */
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.addTransactions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.Transaction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchProcessPendingTransactionRequest} returns this
 */
proto.trnspb.BatchProcessPendingTransactionRequest.prototype.clearTransactionsList = function() {
  return this.setTransactionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchProcessPendingTransactionResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchProcessPendingTransactionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchProcessPendingTransactionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingTransactionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responsesList: jspb.Message.toObjectList(msg.getResponsesList(),
    proto.trnspb.TransactionResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchProcessPendingTransactionResponse}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchProcessPendingTransactionResponse;
  return proto.trnspb.BatchProcessPendingTransactionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchProcessPendingTransactionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchProcessPendingTransactionResponse}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.TransactionResponse;
      reader.readMessage(value,proto.trnspb.TransactionResponse.deserializeBinaryFromReader);
      msg.addResponses(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchProcessPendingTransactionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchProcessPendingTransactionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingTransactionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponsesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.TransactionResponse.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TransactionResponse responses = 1;
 * @return {!Array<!proto.trnspb.TransactionResponse>}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.getResponsesList = function() {
  return /** @type{!Array<!proto.trnspb.TransactionResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.TransactionResponse, 1));
};


/**
 * @param {!Array<!proto.trnspb.TransactionResponse>} value
 * @return {!proto.trnspb.BatchProcessPendingTransactionResponse} returns this
*/
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.setResponsesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.TransactionResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.TransactionResponse}
 */
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.addResponses = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.TransactionResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchProcessPendingTransactionResponse} returns this
 */
proto.trnspb.BatchProcessPendingTransactionResponse.prototype.clearResponsesList = function() {
  return this.setResponsesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.MovePositionData.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.MovePositionData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.MovePositionData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.MovePositionData.toObject = function(includeInstance, msg) {
  var f, obj = {
    fromAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    toAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    fromContraAccountDesignator: jspb.Message.getFieldWithDefault(msg, 3, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.MovePositionData}
 */
proto.trnspb.MovePositionData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.MovePositionData;
  return proto.trnspb.MovePositionData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.MovePositionData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.MovePositionData}
 */
proto.trnspb.MovePositionData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setToAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFromContraAccountDesignator(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.MovePositionData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.MovePositionData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.MovePositionData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.MovePositionData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFromAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getToAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFromContraAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string from_account_no = 1;
 * @return {string}
 */
proto.trnspb.MovePositionData.prototype.getFromAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.MovePositionData} returns this
 */
proto.trnspb.MovePositionData.prototype.setFromAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string to_account_no = 2;
 * @return {string}
 */
proto.trnspb.MovePositionData.prototype.getToAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.MovePositionData} returns this
 */
proto.trnspb.MovePositionData.prototype.setToAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string from_contra_account_designator = 3;
 * @return {string}
 */
proto.trnspb.MovePositionData.prototype.getFromContraAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.MovePositionData} returns this
 */
proto.trnspb.MovePositionData.prototype.setFromContraAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string qty = 4;
 * @return {string}
 */
proto.trnspb.MovePositionData.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.MovePositionData} returns this
 */
proto.trnspb.MovePositionData.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol = 5;
 * @return {string}
 */
proto.trnspb.MovePositionData.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.MovePositionData} returns this
 */
proto.trnspb.MovePositionData.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchMovePositionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchMovePositionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchMovePositionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchMovePositionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchMovePositionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    movePositionsList: jspb.Message.toObjectList(msg.getMovePositionsList(),
    proto.trnspb.MovePositionData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchMovePositionRequest}
 */
proto.trnspb.BatchMovePositionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchMovePositionRequest;
  return proto.trnspb.BatchMovePositionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchMovePositionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchMovePositionRequest}
 */
proto.trnspb.BatchMovePositionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.MovePositionData;
      reader.readMessage(value,proto.trnspb.MovePositionData.deserializeBinaryFromReader);
      msg.addMovePositions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchMovePositionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchMovePositionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchMovePositionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchMovePositionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMovePositionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.MovePositionData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MovePositionData move_positions = 1;
 * @return {!Array<!proto.trnspb.MovePositionData>}
 */
proto.trnspb.BatchMovePositionRequest.prototype.getMovePositionsList = function() {
  return /** @type{!Array<!proto.trnspb.MovePositionData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.MovePositionData, 1));
};


/**
 * @param {!Array<!proto.trnspb.MovePositionData>} value
 * @return {!proto.trnspb.BatchMovePositionRequest} returns this
*/
proto.trnspb.BatchMovePositionRequest.prototype.setMovePositionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.MovePositionData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.MovePositionData}
 */
proto.trnspb.BatchMovePositionRequest.prototype.addMovePositions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.MovePositionData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchMovePositionRequest} returns this
 */
proto.trnspb.BatchMovePositionRequest.prototype.clearMovePositionsList = function() {
  return this.setMovePositionsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.PendingAnnouncement.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.PendingAnnouncement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.PendingAnnouncement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingAnnouncement.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.PendingAnnouncement}
 */
proto.trnspb.PendingAnnouncement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.PendingAnnouncement;
  return proto.trnspb.PendingAnnouncement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.PendingAnnouncement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.PendingAnnouncement}
 */
proto.trnspb.PendingAnnouncement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.PendingAnnouncement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.PendingAnnouncement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.PendingAnnouncement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.PendingAnnouncement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.trnspb.PendingAnnouncement.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingAnnouncement} returns this
 */
proto.trnspb.PendingAnnouncement.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string entry_type = 2;
 * @return {string}
 */
proto.trnspb.PendingAnnouncement.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.PendingAnnouncement} returns this
 */
proto.trnspb.PendingAnnouncement.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pendingAnnouncementsList: jspb.Message.toObjectList(msg.getPendingAnnouncementsList(),
    proto.trnspb.PendingAnnouncement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest;
  return proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.trnspb.PendingAnnouncement;
      reader.readMessage(value,proto.trnspb.PendingAnnouncement.deserializeBinaryFromReader);
      msg.addPendingAnnouncements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPendingAnnouncementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.trnspb.PendingAnnouncement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated PendingAnnouncement pending_announcements = 1;
 * @return {!Array<!proto.trnspb.PendingAnnouncement>}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.getPendingAnnouncementsList = function() {
  return /** @type{!Array<!proto.trnspb.PendingAnnouncement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.trnspb.PendingAnnouncement, 1));
};


/**
 * @param {!Array<!proto.trnspb.PendingAnnouncement>} value
 * @return {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest} returns this
*/
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.setPendingAnnouncementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.trnspb.PendingAnnouncement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.trnspb.PendingAnnouncement}
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.addPendingAnnouncements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.trnspb.PendingAnnouncement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest} returns this
 */
proto.trnspb.BatchProcessPendingAnnouncementTransactionRequest.prototype.clearPendingAnnouncementsList = function() {
  return this.setPendingAnnouncementsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.trnspb.Hierarchy.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.Hierarchy.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.Hierarchy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.Hierarchy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Hierarchy.toObject = function(includeInstance, msg) {
  var f, obj = {
    parent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    childrenList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.Hierarchy}
 */
proto.trnspb.Hierarchy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.Hierarchy;
  return proto.trnspb.Hierarchy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.Hierarchy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.Hierarchy}
 */
proto.trnspb.Hierarchy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setParent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addChildren(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.Hierarchy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.Hierarchy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.Hierarchy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Hierarchy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getChildrenList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
};


/**
 * optional string parent = 1;
 * @return {string}
 */
proto.trnspb.Hierarchy.prototype.getParent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Hierarchy} returns this
 */
proto.trnspb.Hierarchy.prototype.setParent = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Hierarchy} returns this
 */
proto.trnspb.Hierarchy.prototype.clearParent = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Hierarchy.prototype.hasParent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * repeated string children = 2;
 * @return {!Array<string>}
 */
proto.trnspb.Hierarchy.prototype.getChildrenList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.trnspb.Hierarchy} returns this
 */
proto.trnspb.Hierarchy.prototype.setChildrenList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.trnspb.Hierarchy} returns this
 */
proto.trnspb.Hierarchy.prototype.addChildren = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.trnspb.Hierarchy} returns this
 */
proto.trnspb.Hierarchy.prototype.clearChildrenList = function() {
  return this.setChildrenList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.FxCustodyBankAccounts.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.FxCustodyBankAccounts.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.FxCustodyBankAccounts} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.FxCustodyBankAccounts.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNonUsd: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountUsd: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.FxCustodyBankAccounts}
 */
proto.trnspb.FxCustodyBankAccounts.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.FxCustodyBankAccounts;
  return proto.trnspb.FxCustodyBankAccounts.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.FxCustodyBankAccounts} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.FxCustodyBankAccounts}
 */
proto.trnspb.FxCustodyBankAccounts.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNonUsd(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountUsd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.FxCustodyBankAccounts.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.FxCustodyBankAccounts.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.FxCustodyBankAccounts} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.FxCustodyBankAccounts.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNonUsd();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountUsd();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string account_non_usd = 1;
 * @return {string}
 */
proto.trnspb.FxCustodyBankAccounts.prototype.getAccountNonUsd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.FxCustodyBankAccounts} returns this
 */
proto.trnspb.FxCustodyBankAccounts.prototype.setAccountNonUsd = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_usd = 2;
 * @return {string}
 */
proto.trnspb.FxCustodyBankAccounts.prototype.getAccountUsd = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.FxCustodyBankAccounts} returns this
 */
proto.trnspb.FxCustodyBankAccounts.prototype.setAccountUsd = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.trnspb.Metadata.prototype.toObject = function(opt_includeInstance) {
  return proto.trnspb.Metadata.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.trnspb.Metadata} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Metadata.toObject = function(includeInstance, msg) {
  var f, obj = {
    annotation: (f = msg.getAnnotation()) && google_protobuf_struct_pb.Struct.toObject(includeInstance, f),
    toOpen: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    hierarchy: (f = msg.getHierarchy()) && proto.trnspb.Hierarchy.toObject(includeInstance, f),
    localFees: jspb.Message.getFieldWithDefault(msg, 4, ""),
    payableTo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fxCustodyBankAccounts: (f = msg.getFxCustodyBankAccounts()) && proto.trnspb.FxCustodyBankAccounts.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.trnspb.Metadata}
 */
proto.trnspb.Metadata.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.trnspb.Metadata;
  return proto.trnspb.Metadata.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.trnspb.Metadata} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.trnspb.Metadata}
 */
proto.trnspb.Metadata.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_struct_pb.Struct;
      reader.readMessage(value,google_protobuf_struct_pb.Struct.deserializeBinaryFromReader);
      msg.setAnnotation(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setToOpen(value);
      break;
    case 3:
      var value = new proto.trnspb.Hierarchy;
      reader.readMessage(value,proto.trnspb.Hierarchy.deserializeBinaryFromReader);
      msg.setHierarchy(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalFees(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setPayableTo(value);
      break;
    case 6:
      var value = new proto.trnspb.FxCustodyBankAccounts;
      reader.readMessage(value,proto.trnspb.FxCustodyBankAccounts.deserializeBinaryFromReader);
      msg.setFxCustodyBankAccounts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.trnspb.Metadata.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.trnspb.Metadata.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.trnspb.Metadata} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.trnspb.Metadata.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnotation();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_struct_pb.Struct.serializeBinaryToWriter
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getHierarchy();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.trnspb.Hierarchy.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeString(
      4,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFxCustodyBankAccounts();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.trnspb.FxCustodyBankAccounts.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Struct annotation = 1;
 * @return {?proto.google.protobuf.Struct}
 */
proto.trnspb.Metadata.prototype.getAnnotation = function() {
  return /** @type{?proto.google.protobuf.Struct} */ (
    jspb.Message.getWrapperField(this, google_protobuf_struct_pb.Struct, 1));
};


/**
 * @param {?proto.google.protobuf.Struct|undefined} value
 * @return {!proto.trnspb.Metadata} returns this
*/
proto.trnspb.Metadata.prototype.setAnnotation = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearAnnotation = function() {
  return this.setAnnotation(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasAnnotation = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bool to_open = 2;
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.getToOpen = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.setToOpen = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearToOpen = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasToOpen = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Hierarchy hierarchy = 3;
 * @return {?proto.trnspb.Hierarchy}
 */
proto.trnspb.Metadata.prototype.getHierarchy = function() {
  return /** @type{?proto.trnspb.Hierarchy} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.Hierarchy, 3));
};


/**
 * @param {?proto.trnspb.Hierarchy|undefined} value
 * @return {!proto.trnspb.Metadata} returns this
*/
proto.trnspb.Metadata.prototype.setHierarchy = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearHierarchy = function() {
  return this.setHierarchy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasHierarchy = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string local_fees = 4;
 * @return {string}
 */
proto.trnspb.Metadata.prototype.getLocalFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.setLocalFees = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearLocalFees = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasLocalFees = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string payable_to = 5;
 * @return {string}
 */
proto.trnspb.Metadata.prototype.getPayableTo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.setPayableTo = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearPayableTo = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasPayableTo = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional FxCustodyBankAccounts fx_custody_bank_accounts = 6;
 * @return {?proto.trnspb.FxCustodyBankAccounts}
 */
proto.trnspb.Metadata.prototype.getFxCustodyBankAccounts = function() {
  return /** @type{?proto.trnspb.FxCustodyBankAccounts} */ (
    jspb.Message.getWrapperField(this, proto.trnspb.FxCustodyBankAccounts, 6));
};


/**
 * @param {?proto.trnspb.FxCustodyBankAccounts|undefined} value
 * @return {!proto.trnspb.Metadata} returns this
*/
proto.trnspb.Metadata.prototype.setFxCustodyBankAccounts = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.trnspb.Metadata} returns this
 */
proto.trnspb.Metadata.prototype.clearFxCustodyBankAccounts = function() {
  return this.setFxCustodyBankAccounts(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.trnspb.Metadata.prototype.hasFxCustodyBankAccounts = function() {
  return jspb.Message.getField(this, 6) != null;
};


goog.object.extend(exports, proto.trnspb);
