// source: reconpb/executionrecon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reconpb.ExecutionRecon', null, global);
goog.exportSymbol('proto.reconpb.ListExecutionReconRequest', null, global);
goog.exportSymbol('proto.reconpb.ListExecutionReconResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ExecutionRecon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.ExecutionRecon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ExecutionRecon.displayName = 'proto.reconpb.ExecutionRecon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListExecutionReconRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reconpb.ListExecutionReconRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListExecutionReconRequest.displayName = 'proto.reconpb.ListExecutionReconRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reconpb.ListExecutionReconResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reconpb.ListExecutionReconResponse.repeatedFields_, null);
};
goog.inherits(proto.reconpb.ListExecutionReconResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reconpb.ListExecutionReconResponse.displayName = 'proto.reconpb.ListExecutionReconResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ExecutionRecon.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ExecutionRecon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ExecutionRecon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ExecutionRecon.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    account: jspb.Message.getFieldWithDefault(msg, 4, ""),
    contraAccount: jspb.Message.getFieldWithDefault(msg, 5, ""),
    contraSource: jspb.Message.getFieldWithDefault(msg, 6, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    side: jspb.Message.getFieldWithDefault(msg, 10, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 11, ""),
    contraQty: jspb.Message.getFieldWithDefault(msg, 12, ""),
    diffQty: jspb.Message.getFieldWithDefault(msg, 13, ""),
    amt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    contraAmt: jspb.Message.getFieldWithDefault(msg, 15, ""),
    amtDiff: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ExecutionRecon}
 */
proto.reconpb.ExecutionRecon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ExecutionRecon;
  return proto.reconpb.ExecutionRecon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ExecutionRecon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ExecutionRecon}
 */
proto.reconpb.ExecutionRecon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccount(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSource(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraQty(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiffQty(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAmt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setAmtDiff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ExecutionRecon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ExecutionRecon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ExecutionRecon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ExecutionRecon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getContraAccount();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getContraSource();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getContraQty();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDiffQty();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAmt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getContraAmt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getAmtDiff();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ExecutionRecon.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
*/
proto.reconpb.ExecutionRecon.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ExecutionRecon.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.type.Date trade_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ExecutionRecon.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
*/
proto.reconpb.ExecutionRecon.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ExecutionRecon.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date settle_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ExecutionRecon.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
*/
proto.reconpb.ExecutionRecon.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ExecutionRecon.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string account = 4;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string contra_account = 5;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getContraAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setContraAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string contra_source = 6;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getContraSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setContraSource = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string original_cusip = 7;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cusip = 9;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string side = 10;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string qty = 11;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string contra_qty = 12;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getContraQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setContraQty = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string diff_qty = 13;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getDiffQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setDiffQty = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string amt = 14;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string contra_amt = 15;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getContraAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setContraAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string amt_diff = 16;
 * @return {string}
 */
proto.reconpb.ExecutionRecon.prototype.getAmtDiff = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ExecutionRecon} returns this
 */
proto.reconpb.ExecutionRecon.prototype.setAmtDiff = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListExecutionReconRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListExecutionReconRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListExecutionReconRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListExecutionReconRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListExecutionReconRequest}
 */
proto.reconpb.ListExecutionReconRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListExecutionReconRequest;
  return proto.reconpb.ListExecutionReconRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListExecutionReconRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListExecutionReconRequest}
 */
proto.reconpb.ListExecutionReconRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListExecutionReconRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListExecutionReconRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListExecutionReconRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListExecutionReconRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reconpb.ListExecutionReconRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reconpb.ListExecutionReconRequest} returns this
*/
proto.reconpb.ListExecutionReconRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reconpb.ListExecutionReconRequest} returns this
 */
proto.reconpb.ListExecutionReconRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reconpb.ListExecutionReconRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.reconpb.ListExecutionReconRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reconpb.ListExecutionReconRequest} returns this
 */
proto.reconpb.ListExecutionReconRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reconpb.ListExecutionReconResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reconpb.ListExecutionReconResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reconpb.ListExecutionReconResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reconpb.ListExecutionReconResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListExecutionReconResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    executionReconsList: jspb.Message.toObjectList(msg.getExecutionReconsList(),
    proto.reconpb.ExecutionRecon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reconpb.ListExecutionReconResponse}
 */
proto.reconpb.ListExecutionReconResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reconpb.ListExecutionReconResponse;
  return proto.reconpb.ListExecutionReconResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reconpb.ListExecutionReconResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reconpb.ListExecutionReconResponse}
 */
proto.reconpb.ListExecutionReconResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reconpb.ExecutionRecon;
      reader.readMessage(value,proto.reconpb.ExecutionRecon.deserializeBinaryFromReader);
      msg.addExecutionRecons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reconpb.ListExecutionReconResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reconpb.ListExecutionReconResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reconpb.ListExecutionReconResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reconpb.ListExecutionReconResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getExecutionReconsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reconpb.ExecutionRecon.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ExecutionRecon execution_recons = 1;
 * @return {!Array<!proto.reconpb.ExecutionRecon>}
 */
proto.reconpb.ListExecutionReconResponse.prototype.getExecutionReconsList = function() {
  return /** @type{!Array<!proto.reconpb.ExecutionRecon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reconpb.ExecutionRecon, 1));
};


/**
 * @param {!Array<!proto.reconpb.ExecutionRecon>} value
 * @return {!proto.reconpb.ListExecutionReconResponse} returns this
*/
proto.reconpb.ListExecutionReconResponse.prototype.setExecutionReconsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reconpb.ExecutionRecon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reconpb.ExecutionRecon}
 */
proto.reconpb.ListExecutionReconResponse.prototype.addExecutionRecons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reconpb.ExecutionRecon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reconpb.ListExecutionReconResponse} returns this
 */
proto.reconpb.ListExecutionReconResponse.prototype.clearExecutionReconsList = function() {
  return this.setExecutionReconsList([]);
};


goog.object.extend(exports, proto.reconpb);
