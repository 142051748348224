// source: reorgpb/merger.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.ListMergerRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListMergerResponse', null, global);
goog.exportSymbol('proto.reorgpb.Merger', null, global);
goog.exportSymbol('proto.reorgpb.UpdateMergerRequest', null, global);
goog.exportSymbol('proto.reorgpb.UpdateMergerResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.Merger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.Merger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.Merger.displayName = 'proto.reorgpb.Merger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListMergerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListMergerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListMergerRequest.displayName = 'proto.reorgpb.ListMergerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListMergerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListMergerResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListMergerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListMergerResponse.displayName = 'proto.reorgpb.ListMergerResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateMergerRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateMergerRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateMergerRequest.displayName = 'proto.reorgpb.UpdateMergerRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateMergerResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateMergerResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateMergerResponse.displayName = 'proto.reorgpb.UpdateMergerResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.Merger.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.Merger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.Merger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Merger.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    targetOriginalCusip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    initiatingSymbol: jspb.Message.getFieldWithDefault(msg, 6, ""),
    initiatingOriginalCusip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    newRate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oldRate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    netCash: jspb.Message.getFieldWithDefault(msg, 12, ""),
    netQty: jspb.Message.getFieldWithDefault(msg, 13, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    pendingTrnsCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    detail: jspb.Message.getFieldWithDefault(msg, 16, ""),
    netPayCash: jspb.Message.getFieldWithDefault(msg, 17, ""),
    netPayQty: jspb.Message.getFieldWithDefault(msg, 18, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.Merger}
 */
proto.reorgpb.Merger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.Merger;
  return proto.reorgpb.Merger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.Merger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.Merger}
 */
proto.reorgpb.Merger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOriginalCusip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingSymbol(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingOriginalCusip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCash(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetCash(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetQty(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingTrnsCount(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayCash(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.Merger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.Merger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.Merger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Merger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTargetOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInitiatingSymbol();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInitiatingOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOldRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getNetCash();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getNetQty();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getPendingTrnsCount();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getNetPayCash();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getNetPayQty();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Merger.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Merger} returns this
*/
proto.reorgpb.Merger.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Merger.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date effective_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Merger.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Merger} returns this
*/
proto.reorgpb.Merger.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Merger.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string target_symbol = 4;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string target_original_cusip = 5;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getTargetOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setTargetOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string initiating_symbol = 6;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getInitiatingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setInitiatingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string initiating_original_cusip = 7;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getInitiatingOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setInitiatingOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cash = 8;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setCash = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string new_rate = 9;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string old_rate = 10;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getOldRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setOldRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.type.Date process_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Merger.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Merger} returns this
*/
proto.reorgpb.Merger.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Merger.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional string net_cash = 12;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getNetCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNetCash = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string net_qty = 13;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getNetQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNetQty = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional bool no_cil = 14;
 * @return {boolean}
 */
proto.reorgpb.Merger.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional int32 pending_trns_count = 15;
 * @return {number}
 */
proto.reorgpb.Merger.prototype.getPendingTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setPendingTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string detail = 16;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string net_pay_cash = 17;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getNetPayCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNetPayCash = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string net_pay_qty = 18;
 * @return {string}
 */
proto.reorgpb.Merger.prototype.getNetPayQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Merger} returns this
 */
proto.reorgpb.Merger.prototype.setNetPayQty = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListMergerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListMergerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListMergerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListMergerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    pendingTrnsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListMergerRequest}
 */
proto.reorgpb.ListMergerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListMergerRequest;
  return proto.reorgpb.ListMergerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListMergerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListMergerRequest}
 */
proto.reorgpb.ListMergerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingTrnsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListMergerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListMergerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListMergerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListMergerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPendingTrnsOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string target_symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListMergerRequest.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListMergerRequest} returns this
 */
proto.reorgpb.ListMergerRequest.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListMergerRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListMergerRequest} returns this
*/
proto.reorgpb.ListMergerRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListMergerRequest} returns this
 */
proto.reorgpb.ListMergerRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListMergerRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListMergerRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListMergerRequest} returns this
*/
proto.reorgpb.ListMergerRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListMergerRequest} returns this
 */
proto.reorgpb.ListMergerRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListMergerRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_trns_only = 4;
 * @return {boolean}
 */
proto.reorgpb.ListMergerRequest.prototype.getPendingTrnsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.ListMergerRequest} returns this
 */
proto.reorgpb.ListMergerRequest.prototype.setPendingTrnsOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListMergerResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListMergerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListMergerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListMergerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListMergerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    mergersList: jspb.Message.toObjectList(msg.getMergersList(),
    proto.reorgpb.Merger.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListMergerResponse}
 */
proto.reorgpb.ListMergerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListMergerResponse;
  return proto.reorgpb.ListMergerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListMergerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListMergerResponse}
 */
proto.reorgpb.ListMergerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Merger;
      reader.readMessage(value,proto.reorgpb.Merger.deserializeBinaryFromReader);
      msg.addMergers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListMergerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListMergerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListMergerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListMergerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMergersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.Merger.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Merger mergers = 1;
 * @return {!Array<!proto.reorgpb.Merger>}
 */
proto.reorgpb.ListMergerResponse.prototype.getMergersList = function() {
  return /** @type{!Array<!proto.reorgpb.Merger>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.Merger, 1));
};


/**
 * @param {!Array<!proto.reorgpb.Merger>} value
 * @return {!proto.reorgpb.ListMergerResponse} returns this
*/
proto.reorgpb.ListMergerResponse.prototype.setMergersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.Merger=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.Merger}
 */
proto.reorgpb.ListMergerResponse.prototype.addMergers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.Merger, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListMergerResponse} returns this
 */
proto.reorgpb.ListMergerResponse.prototype.clearMergersList = function() {
  return this.setMergersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateMergerRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateMergerRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateMergerRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateMergerRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    cash: jspb.Message.getFieldWithDefault(msg, 3, ""),
    newRate: jspb.Message.getFieldWithDefault(msg, 4, ""),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateMergerRequest}
 */
proto.reorgpb.UpdateMergerRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateMergerRequest;
  return proto.reorgpb.UpdateMergerRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateMergerRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateMergerRequest}
 */
proto.reorgpb.UpdateMergerRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCash(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateMergerRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateMergerRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateMergerRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateMergerRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.UpdateMergerRequest.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
 */
proto.reorgpb.UpdateMergerRequest.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool no_cil = 2;
 * @return {boolean}
 */
proto.reorgpb.UpdateMergerRequest.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
 */
proto.reorgpb.UpdateMergerRequest.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string cash = 3;
 * @return {string}
 */
proto.reorgpb.UpdateMergerRequest.prototype.getCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
 */
proto.reorgpb.UpdateMergerRequest.prototype.setCash = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string new_rate = 4;
 * @return {string}
 */
proto.reorgpb.UpdateMergerRequest.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
 */
proto.reorgpb.UpdateMergerRequest.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date process_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.UpdateMergerRequest.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
*/
proto.reorgpb.UpdateMergerRequest.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateMergerRequest} returns this
 */
proto.reorgpb.UpdateMergerRequest.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateMergerRequest.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateMergerResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateMergerResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateMergerResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateMergerResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    merger: (f = msg.getMerger()) && proto.reorgpb.Merger.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateMergerResponse}
 */
proto.reorgpb.UpdateMergerResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateMergerResponse;
  return proto.reorgpb.UpdateMergerResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateMergerResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateMergerResponse}
 */
proto.reorgpb.UpdateMergerResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Merger;
      reader.readMessage(value,proto.reorgpb.Merger.deserializeBinaryFromReader);
      msg.setMerger(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateMergerResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateMergerResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateMergerResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateMergerResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMerger();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.Merger.serializeBinaryToWriter
    );
  }
};


/**
 * optional Merger merger = 1;
 * @return {?proto.reorgpb.Merger}
 */
proto.reorgpb.UpdateMergerResponse.prototype.getMerger = function() {
  return /** @type{?proto.reorgpb.Merger} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.Merger, 1));
};


/**
 * @param {?proto.reorgpb.Merger|undefined} value
 * @return {!proto.reorgpb.UpdateMergerResponse} returns this
*/
proto.reorgpb.UpdateMergerResponse.prototype.setMerger = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateMergerResponse} returns this
 */
proto.reorgpb.UpdateMergerResponse.prototype.clearMerger = function() {
  return this.setMerger(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateMergerResponse.prototype.hasMerger = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.reorgpb);
