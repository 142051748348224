/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListReOrgReconDetailRequest } from '../../../proto/reorgpb/reorgrecon_grpc_web_pb';

/*Toast Notification*/
import { notifyError } from 'components/Notification/Notification';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Material UI Components*/
import { TableCell, TableRow, TableFooter } from '@material-ui/core';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ReorgReconDetailsTable({ systemdate, symbol }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const { reorgReconServiceClient: reorgRecon } = useContext(ServiceContext);
  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: false,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate
            ? formatPbDate(rows[dataIndex].systemDate)
            : '--';
        },
      },
    },
    {
      id: '2',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '3',
      name: 'contraAccountNo',
      label: 'Contra Account No',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '4',
      name: 'tradeDate',
      label: 'Trade Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: false,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate
            ? formatPbDate(rows[dataIndex].tradeDate)
            : '--';
        },
      },
    },
    {
      id: '5',
      name: 'settleDate',
      label: 'Settle Date',
      visibleState: useState(true),
      align: 'left',
      options: {
        display: false,
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate
            ? formatPbDate(rows[dataIndex].settleDate)
            : '--';
        },
      },
    },
    {
      id: '6',
      name: 'entryType',
      label: 'Entry Type',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '7',
      name: 'symbol',
      label: 'Symbol',
      visibleState: useState(true),
      align: 'left',
    },
    {
      id: '8',
      name: 'qty',
      label: 'Qty',
      visibleState: useState(true),
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      id: '9',
      name: 'netAmt',
      label: 'Net Amt',
      visibleState: useState(true),
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      id: '10',
      name: 'description',
      label: 'Description',
      visibleState: useState(false),
      align: 'left',
    },
  ];

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customBodyTableFooter = (opts) => {
    let sumNetAmt = opts.data.reduce((total, item) => {
      return (total += parseFloat(
        item.data[8]
          .toString()
          .replace('$', '')
          .replaceAll(',', '')
      ));
    }, 0);

    let totalQty = opts.data.reduce((total, item) => {
      return (total += parseFloat(item.data[7]));
    }, 0);

    return (
      <TableFooter>
        <TableRow>
          {opts.selectableRows !== 'none' ? <TableCell /> : null}
          {columns.map((col, index) => {
            if (col.options.display === true) {
              if (col.name === 'systemDate') {
                return (
                  <TableCell key="total">
                    <b>Total:</b>
                  </TableCell>
                );
              }
              if (col.name === 'netAmt') {
                return (
                  <TableCell key={index}>
                    <b
                      className={classes.totalBadge}
                      style={{
                        background: sumNetAmt >= 0 ? '#4caf50' : '#f44336',
                      }}
                    >
                      {formatCurrency(sumNetAmt)}
                    </b>
                  </TableCell>
                );
              } else if (col.name === 'qty') {
                return (
                  <TableCell key={index}>
                    <b
                      className={classes.totalBadge}
                      style={{
                        background: totalQty >= 0 ? '#4caf50' : '#f44336',
                      }}
                    >
                      {totalQty}
                    </b>
                  </TableCell>
                );
              } else {
                return <TableCell key={index} />;
              }
            }
            return null;
          })}
        </TableRow>
      </TableFooter>
    );
  };

  const options = {
    columnOrder: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
    filterType: 'select',
    download: true,
    filter: true,
    search: true,
    print: false,
    sort: true,
    viewColumns: true,
    selectableRows: false,
    selectableRowsHeader: false,
    rowsPerPage: 10,
    customTableBodyFooterRender: customBodyTableFooter,
  };

  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/
  const [rows, setRows] = useState([]);
  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  useEffect(() => {
    let list = new ListReOrgReconDetailRequest();
    list.setSymbol(symbol);
    if (systemdate) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = moment(new Date(systemdate))
        .format('yyyy-MM-DD')
        .split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    reorgRecon.listReOrgReconDetail(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err);
        return;
      }
      const rows = res.toObject().reorgReconDetailsList.map((data) => ({
        accountNo: data.accountNo,
        contraAccountNo: data.contraAccountNo,
        entryType: data.entryType,
        tradeDate: formatPbDate(data.tradeDate),
        symbol: data.symbol,
        qty: data.qty,
        settleDate: formatPbDate(data.settleDate),
        netAmt: formatCurrency(data.netAmt),
        description: data.description,
        systemDate: formatPbDate(data.systemDate),
      }));
      setRows(rows);
    });
  }, [reorgRecon, systemdate, symbol]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={tableTheme()}>
        <MUIDataTable
          title={'Reorg Reconciliation Details'}
          data={rows}
          columns={columns}
          options={options}
        />
      </MuiThemeProvider>
    </div>
  );
}
