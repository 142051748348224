/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListEntitlementRequest } from '../../../proto/reorgpb/entitlement_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Components*/
import { Box, TextField, Tooltip, IconButton } from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Icons*/
import { Save as SaveIcon } from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom components*/
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import EntryTypeSelect from '../../../components/AutoComplete/EntryType';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function EntitlementTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    entitlementServiceClient: entitlement,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (textNumberValue ? '&textNumber=' + textNumberValue : '') +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (processDateValue ? '&processDate=' + processDateValue : '') +
      (positionDateValue ? '&positionDate=' + positionDateValue : '') +
      (entryTypeValue ? '&entryType=' + entryTypeValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (accountNameValue ? '&accountName=' + accountNameValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListEntitlementRequest();
    list.setTextNumber(textNumberValue);
    list.setEntryType(entryTypeValue);
    list.setAccountNo(accountNoValue);
    list.setAccountName(accountNameValue);
    list.setSymbol(symbolValue);

    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }
    if (processDateValue) {
      let gglProcessDate = new google_date.Date();
      let [y, m, d] = processDateValue.split('-');
      gglProcessDate.setYear(y);
      gglProcessDate.setMonth(m);
      gglProcessDate.setDay(d);
      list.setProcessDate(gglProcessDate);
    }
    if (positionDateValue) {
      let gglPositionDate = new google_date.Date();
      let [y, m, d] = positionDateValue.split('-');
      gglPositionDate.setYear(y);
      gglPositionDate.setMonth(m);
      gglPositionDate.setDay(d);
      list.setPositionDate(gglPositionDate);
    }

    entitlement.listEntitlement(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().entitlementsList.map((data) => ({
        accountDesignator: data.accountDesignator,
        accountName: data.accountName,
        accountNo: data.accountNo,
        broker: data.broker,
        correspondent: data.correspondent,
        cusip: data.cusip,
        entitlementId: data.entitlementId,
        entryType: data.entryType,
        masterAccountNo: data.masterAccountNo,
        originalCusip: data.originalCusip,
        positionDate: data.positionDate
          ? formatPbDate(data.positionDate)
          : '--',
        processDate: data.processDate ? formatPbDate(data.processDate) : '--',
        reorgAmount: formatCurrency(data.reorgAmount),
        reorgFractionalQty: data.reorgFractionalQty,
        reorgFractionalAmount: formatCurrency(data.reorgFractionalAmount),
        reorgQty: data.reorgQty,
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        systemDate: data.systemDate ? formatPbDate(data.systemDate) : '--',
        textNumber: data.textNumber,
        type: data.type,
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Entitlement');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'positionDate',
      label: 'Position Date',
    },
    {
      name: 'processDate',
      label: 'Process Date',
    },
    {
      name: 'reorgAmount',
      label: 'Reorg Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].reorgAmount;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'reorgFractionalAmount',
      label: 'Reorg Fraction Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].reorgFractionalAmount;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'reorgFractionalQty',
      label: 'Reorg Fractional Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].reorgFractionalQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'reorgQty',
      label: 'Reorg Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].reorgQty;
          return <div align="right">{f}</div>;
        },
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'systemDate',
      label: 'System Date',
    },
    {
      name: 'textNumber',
      label: 'Text Number',
    },
    {
      name: 'type',
      label: 'Type',
    },
  ];

  const fileName =
    'Entitlement_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      18,
      17,
      10,
      9,
      6,
      4,
      2,
      1,
      7,
      0,
      3,
      19,
      8,
      15,
      5,
      16,
      14,
      11,
      13,
      12,
    ],
    selectableRowsHeader: false,
    selectableRows: 'none',
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings

      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Entitlement');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Entitlement');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });

            return;
          } else {
            //let viewColumnState = [];
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
  };

  const [rows, setRows] = useState([]);

  const [textNumberValue, setTextNumberValue] = React.useState('');
  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [processDateValue, setProcessDateValue] = React.useState('');
  const [positionDateValue, setPositionDateValue] = React.useState('');
  const [entryTypeValue, setEntryTypeValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      textNumber: query.get('textNumber'),
      systemDate: query.get('systemDate'),
      processDate: query.get('processDate'),
      positionDate: query.get('positionDate'),
      entryType: query.get('entryType'),
      accountNo: query.get('accountNo'),
      accountName: query.get('accountName'),
      symbol: query.get('symbol'),
    };

    if (data.textNumber) {
      setTextNumberValue(data.textNumber);
    }
    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.processDate) {
      setProcessDateValue(data.processDate);
    }
    if (data.positionDate) {
      setPositionDateValue(data.positionDate);
    }
    if (data.entryType) {
      setEntryTypeValue(data.entryType);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }
  }, [systemDate, params]);

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Text Number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={textNumberValue}
                  onChange={(e) => setTextNumberValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <EntryTypeSelect
                  name="entryType"
                  label="Entry Type"
                  value={entryTypeValue}
                  onChange={(e) => setEntryTypeValue(e.currentTarget.value)}
                  // autopopulate={isAutoPopulate}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Process Date"
                  type="date"
                  value={processDateValue}
                  onChange={(e) => setProcessDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="Position Date"
                  type="date"
                  value={positionDateValue}
                  onChange={(e) => setPositionDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Entitlements'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
