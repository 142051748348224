// source: reorgpb/reorgrecon.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.ListReOrgReconDetailRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListReOrgReconDetailResponse', null, global);
goog.exportSymbol('proto.reorgpb.ListReOrgReconRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListReOrgReconResponse', null, global);
goog.exportSymbol('proto.reorgpb.ReOrgRecon', null, global);
goog.exportSymbol('proto.reorgpb.ReOrgReconDetail', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ReOrgRecon = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ReOrgRecon, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ReOrgRecon.displayName = 'proto.reorgpb.ReOrgRecon';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListReOrgReconRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListReOrgReconRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListReOrgReconRequest.displayName = 'proto.reorgpb.ListReOrgReconRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListReOrgReconResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListReOrgReconResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListReOrgReconResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListReOrgReconResponse.displayName = 'proto.reorgpb.ListReOrgReconResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ReOrgReconDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ReOrgReconDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ReOrgReconDetail.displayName = 'proto.reorgpb.ReOrgReconDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListReOrgReconDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListReOrgReconDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListReOrgReconDetailRequest.displayName = 'proto.reorgpb.ListReOrgReconDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListReOrgReconDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListReOrgReconDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListReOrgReconDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListReOrgReconDetailResponse.displayName = 'proto.reorgpb.ListReOrgReconDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ReOrgRecon.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ReOrgRecon.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ReOrgRecon} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ReOrgRecon.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 5, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 6, ""),
    age: jspb.Message.getFieldWithDefault(msg, 7, 0),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 8, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    status: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ReOrgRecon}
 */
proto.reorgpb.ReOrgRecon.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ReOrgRecon;
  return proto.reorgpb.ReOrgRecon.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ReOrgRecon} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ReOrgRecon}
 */
proto.reorgpb.ReOrgRecon.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setAge(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ReOrgRecon.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ReOrgRecon.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ReOrgRecon} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ReOrgRecon.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getAge();
  if (f !== 0) {
    writer.writeInt32(
      7,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string cusip = 3;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_description = 4;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string qty = 5;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string net_amt = 6;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int32 age = 7;
 * @return {number}
 */
proto.reorgpb.ReOrgRecon.prototype.getAge = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setAge = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string original_cusip = 8;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional google.type.Date system_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ReOrgRecon.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
*/
proto.reorgpb.ReOrgRecon.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ReOrgRecon.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.reorgpb.ReOrgRecon.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgRecon} returns this
 */
proto.reorgpb.ReOrgRecon.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListReOrgReconRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListReOrgReconRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListReOrgReconRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListReOrgReconRequest}
 */
proto.reorgpb.ListReOrgReconRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListReOrgReconRequest;
  return proto.reorgpb.ListReOrgReconRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListReOrgReconRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListReOrgReconRequest}
 */
proto.reorgpb.ListReOrgReconRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListReOrgReconRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListReOrgReconRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListReOrgReconRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListReOrgReconRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListReOrgReconRequest} returns this
 */
proto.reorgpb.ListReOrgReconRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListReOrgReconRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListReOrgReconRequest} returns this
*/
proto.reorgpb.ListReOrgReconRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListReOrgReconRequest} returns this
 */
proto.reorgpb.ListReOrgReconRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListReOrgReconRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListReOrgReconResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListReOrgReconResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListReOrgReconResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListReOrgReconResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reorgReconsList: jspb.Message.toObjectList(msg.getReorgReconsList(),
    proto.reorgpb.ReOrgRecon.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListReOrgReconResponse}
 */
proto.reorgpb.ListReOrgReconResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListReOrgReconResponse;
  return proto.reorgpb.ListReOrgReconResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListReOrgReconResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListReOrgReconResponse}
 */
proto.reorgpb.ListReOrgReconResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.ReOrgRecon;
      reader.readMessage(value,proto.reorgpb.ReOrgRecon.deserializeBinaryFromReader);
      msg.addReorgRecons(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListReOrgReconResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListReOrgReconResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListReOrgReconResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReorgReconsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.ReOrgRecon.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReOrgRecon reorg_recons = 1;
 * @return {!Array<!proto.reorgpb.ReOrgRecon>}
 */
proto.reorgpb.ListReOrgReconResponse.prototype.getReorgReconsList = function() {
  return /** @type{!Array<!proto.reorgpb.ReOrgRecon>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.ReOrgRecon, 1));
};


/**
 * @param {!Array<!proto.reorgpb.ReOrgRecon>} value
 * @return {!proto.reorgpb.ListReOrgReconResponse} returns this
*/
proto.reorgpb.ListReOrgReconResponse.prototype.setReorgReconsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.ReOrgRecon=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.ReOrgRecon}
 */
proto.reorgpb.ListReOrgReconResponse.prototype.addReorgRecons = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.ReOrgRecon, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListReOrgReconResponse} returns this
 */
proto.reorgpb.ListReOrgReconResponse.prototype.clearReorgReconsList = function() {
  return this.setReorgReconsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ReOrgReconDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ReOrgReconDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ReOrgReconDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ReOrgReconDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 6, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 7, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 8, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 9, ""),
    description: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ReOrgReconDetail}
 */
proto.reorgpb.ReOrgReconDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ReOrgReconDetail;
  return proto.reorgpb.ReOrgReconDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ReOrgReconDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ReOrgReconDetail}
 */
proto.reorgpb.ReOrgReconDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ReOrgReconDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ReOrgReconDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ReOrgReconDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ReOrgReconDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional string account_no = 1;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string contra_account_no = 2;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.type.Date system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
*/
proto.reorgpb.ReOrgReconDetail.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ReOrgReconDetail.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date settle_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
*/
proto.reorgpb.ReOrgReconDetail.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ReOrgReconDetail.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date trade_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
*/
proto.reorgpb.ReOrgReconDetail.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ReOrgReconDetail.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string entry_type = 6;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symbol = 7;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string qty = 8;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string net_amt = 9;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string description = 10;
 * @return {string}
 */
proto.reorgpb.ReOrgReconDetail.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ReOrgReconDetail} returns this
 */
proto.reorgpb.ReOrgReconDetail.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListReOrgReconDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListReOrgReconDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    symbol: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListReOrgReconDetailRequest}
 */
proto.reorgpb.ListReOrgReconDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListReOrgReconDetailRequest;
  return proto.reorgpb.ListReOrgReconDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListReOrgReconDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListReOrgReconDetailRequest}
 */
proto.reorgpb.ListReOrgReconDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListReOrgReconDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListReOrgReconDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date system_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListReOrgReconDetailRequest} returns this
*/
proto.reorgpb.ListReOrgReconDetailRequest.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListReOrgReconDetailRequest} returns this
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string symbol = 2;
 * @return {string}
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListReOrgReconDetailRequest} returns this
 */
proto.reorgpb.ListReOrgReconDetailRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListReOrgReconDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListReOrgReconDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListReOrgReconDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListReOrgReconDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    reorgReconDetailsList: jspb.Message.toObjectList(msg.getReorgReconDetailsList(),
    proto.reorgpb.ReOrgReconDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListReOrgReconDetailResponse}
 */
proto.reorgpb.ListReOrgReconDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListReOrgReconDetailResponse;
  return proto.reorgpb.ListReOrgReconDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListReOrgReconDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListReOrgReconDetailResponse}
 */
proto.reorgpb.ListReOrgReconDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.ReOrgReconDetail;
      reader.readMessage(value,proto.reorgpb.ReOrgReconDetail.deserializeBinaryFromReader);
      msg.addReorgReconDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListReOrgReconDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListReOrgReconDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListReOrgReconDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListReOrgReconDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReorgReconDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.ReOrgReconDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ReOrgReconDetail reorg_recon_details = 1;
 * @return {!Array<!proto.reorgpb.ReOrgReconDetail>}
 */
proto.reorgpb.ListReOrgReconDetailResponse.prototype.getReorgReconDetailsList = function() {
  return /** @type{!Array<!proto.reorgpb.ReOrgReconDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.ReOrgReconDetail, 1));
};


/**
 * @param {!Array<!proto.reorgpb.ReOrgReconDetail>} value
 * @return {!proto.reorgpb.ListReOrgReconDetailResponse} returns this
*/
proto.reorgpb.ListReOrgReconDetailResponse.prototype.setReorgReconDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.ReOrgReconDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.ReOrgReconDetail}
 */
proto.reorgpb.ListReOrgReconDetailResponse.prototype.addReorgReconDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.ReOrgReconDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListReOrgReconDetailResponse} returns this
 */
proto.reorgpb.ListReOrgReconDetailResponse.prototype.clearReorgReconDetailsList = function() {
  return this.setReorgReconDetailsList([]);
};


goog.object.extend(exports, proto.reorgpb);
