// source: acatspb/multicycle.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.acatspb.ListMultiCycleMROResponse', null, global);
goog.exportSymbol('proto.acatspb.MultiCycleMRO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.MultiCycleMRO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.acatspb.MultiCycleMRO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.MultiCycleMRO.displayName = 'proto.acatspb.MultiCycleMRO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.acatspb.ListMultiCycleMROResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.acatspb.ListMultiCycleMROResponse.repeatedFields_, null);
};
goog.inherits(proto.acatspb.ListMultiCycleMROResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.acatspb.ListMultiCycleMROResponse.displayName = 'proto.acatspb.ListMultiCycleMROResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.MultiCycleMRO.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.MultiCycleMRO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.MultiCycleMRO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.MultiCycleMRO.toObject = function(includeInstance, msg) {
  var f, obj = {
    recordType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    subrecordType: jspb.Message.getFieldWithDefault(msg, 2, ""),
    distributionSide: jspb.Message.getFieldWithDefault(msg, 3, ""),
    acatsControlNumber: jspb.Message.getFieldWithDefault(msg, 4, ""),
    assetSequenceNumber: jspb.Message.getFieldWithDefault(msg, 5, ""),
    processingDate: (f = msg.getProcessingDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    cycle: jspb.Message.getFieldWithDefault(msg, 7, ""),
    distributionParticipant: jspb.Message.getFieldWithDefault(msg, 8, ""),
    systemAction: jspb.Message.getFieldWithDefault(msg, 9, ""),
    systemRejectReason: jspb.Message.getFieldWithDefault(msg, 10, ""),
    status: jspb.Message.getFieldWithDefault(msg, 11, ""),
    daysInStatus: jspb.Message.getFieldWithDefault(msg, 12, ""),
    registrationIndicator: jspb.Message.getFieldWithDefault(msg, 13, ""),
    tiRecordTypeStatus: jspb.Message.getFieldWithDefault(msg, 14, ""),
    atRecordTypeStatus: jspb.Message.getFieldWithDefault(msg, 15, ""),
    tiRecordLengthStatus: jspb.Message.getFieldWithDefault(msg, 16, ""),
    atRecordLengthStatus: jspb.Message.getFieldWithDefault(msg, 17, ""),
    tiPhysicalSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 18, ""),
    atPhysicalSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 19, ""),
    tiLogicalSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 20, ""),
    atLogicalSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 21, ""),
    tiTransactionTypeStatus: jspb.Message.getFieldWithDefault(msg, 22, ""),
    atTransactionTypeStatus: jspb.Message.getFieldWithDefault(msg, 23, ""),
    tiAcatsControlNumberStatus: jspb.Message.getFieldWithDefault(msg, 24, ""),
    atAcatsControlNumberStatus: jspb.Message.getFieldWithDefault(msg, 25, ""),
    tiTransferTypeStatus: jspb.Message.getFieldWithDefault(msg, 26, ""),
    atTransferTypeStatus: jspb.Message.getFieldWithDefault(msg, 27, ""),
    tiSubmittingParticipantNumberStatus: jspb.Message.getFieldWithDefault(msg, 28, ""),
    atSubmittingParticipantNumberStatus: jspb.Message.getFieldWithDefault(msg, 29, ""),
    tiOriginalReceiverNumberStatus: jspb.Message.getFieldWithDefault(msg, 30, ""),
    atOriginalReceiverNumberStatus: jspb.Message.getFieldWithDefault(msg, 31, ""),
    tiOriginalDelivererNumberStatus: jspb.Message.getFieldWithDefault(msg, 32, ""),
    atOriginalDelivererNumberStatus: jspb.Message.getFieldWithDefault(msg, 33, ""),
    originalReceiverCustomerAccountNumberStatus: jspb.Message.getFieldWithDefault(msg, 34, ""),
    originalReceiverCustomerAccountNameStatus: jspb.Message.getFieldWithDefault(msg, 35, ""),
    originalReceiverTaxIdPrimaryStatus: jspb.Message.getFieldWithDefault(msg, 36, ""),
    originalReceiverTaxIdSecondaryStatus: jspb.Message.getFieldWithDefault(msg, 37, ""),
    originalReceiverCustomerAccountTypeStatus: jspb.Message.getFieldWithDefault(msg, 38, ""),
    originalReceiverOccBrokerNumberStatus: jspb.Message.getFieldWithDefault(msg, 39, ""),
    originalReceiverCorrespondentStatus: jspb.Message.getFieldWithDefault(msg, 40, ""),
    originalDelivererCustomerAccountNumberStatus: jspb.Message.getFieldWithDefault(msg, 41, ""),
    transferTypeRejectStatus: jspb.Message.getFieldWithDefault(msg, 42, ""),
    associatedAcatsControlNumberStatus: jspb.Message.getFieldWithDefault(msg, 43, ""),
    associatedSettlementDateStatus: jspb.Message.getFieldWithDefault(msg, 44, ""),
    tiTransactionReferenceIdStatus: jspb.Message.getFieldWithDefault(msg, 45, ""),
    atTransactionReferenceIdStatus: jspb.Message.getFieldWithDefault(msg, 46, ""),
    atAssetSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 47, ""),
    assetSequenceNumberStatus: jspb.Message.getFieldWithDefault(msg, 48, ""),
    assetPricingCategoryStatus: jspb.Message.getFieldWithDefault(msg, 49, ""),
    optionCategoryStatus: jspb.Message.getFieldWithDefault(msg, 50, ""),
    settlingLocationStatus: jspb.Message.getFieldWithDefault(msg, 51, ""),
    settlingLocationReasonStatus: jspb.Message.getFieldWithDefault(msg, 52, ""),
    isinCountryCodeStatus: jspb.Message.getFieldWithDefault(msg, 53, ""),
    isinSecurityIssueIdStatus: jspb.Message.getFieldWithDefault(msg, 54, ""),
    isinSecurityCheckDigitStatus: jspb.Message.getFieldWithDefault(msg, 55, ""),
    assetDescriptionStatus: jspb.Message.getFieldWithDefault(msg, 56, ""),
    assetQuantityRequestIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 57, ""),
    assetRequestTransactionCodeStatus: jspb.Message.getFieldWithDefault(msg, 58, ""),
    assetQuantityStatus: jspb.Message.getFieldWithDefault(msg, 59, ""),
    assetPercentStatus: jspb.Message.getFieldWithDefault(msg, 60, ""),
    positionCodeStatus: jspb.Message.getFieldWithDefault(msg, 61, ""),
    isoCurrencyCodeStatus: jspb.Message.getFieldWithDefault(msg, 62, ""),
    assetAmountStatus: jspb.Message.getFieldWithDefault(msg, 63, ""),
    bearerBondStatus: jspb.Message.getFieldWithDefault(msg, 64, ""),
    cashMarginShortCodeStatus: jspb.Message.getFieldWithDefault(msg, 65, ""),
    whenIssuedIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 66, ""),
    transferTypeReasonStatus: jspb.Message.getFieldWithDefault(msg, 67, ""),
    mbsSerialNoteStatus: jspb.Message.getFieldWithDefault(msg, 68, ""),
    assetCommentStatus: jspb.Message.getFieldWithDefault(msg, 69, ""),
    optionBuySellIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 70, ""),
    optionPcIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 71, ""),
    optionOcIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 72, ""),
    optionSymbolStatus: jspb.Message.getFieldWithDefault(msg, 73, ""),
    optionExpirationDateStatus: jspb.Message.getFieldWithDefault(msg, 74, ""),
    optionStrikePriceIntegerStatus: jspb.Message.getFieldWithDefault(msg, 75, ""),
    optionStrikePriceFractionStatus: jspb.Message.getFieldWithDefault(msg, 76, ""),
    optionOccDelivererNumberStatus: jspb.Message.getFieldWithDefault(msg, 77, ""),
    optionAccountTypeStatus: jspb.Message.getFieldWithDefault(msg, 78, ""),
    subAccountIdStatus: jspb.Message.getFieldWithDefault(msg, 79, ""),
    optionCommentsStatus: jspb.Message.getFieldWithDefault(msg, 80, ""),
    fundCustomerAccountNumberStatus: jspb.Message.getFieldWithDefault(msg, 81, ""),
    bookPhysicalShareIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 82, ""),
    networkControlIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 83, ""),
    accountTypeIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 84, ""),
    dividendCodeStatus: jspb.Message.getFieldWithDefault(msg, 85, ""),
    dividendPayeeIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 86, ""),
    executingBrokerStatus: jspb.Message.getFieldWithDefault(msg, 87, ""),
    mfFullPartialIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 88, ""),
    shareAgingIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 89, ""),
    firmMembershipIndicatorDelivererStatus: jspb.Message.getFieldWithDefault(msg, 90, ""),
    newFuncCustomerAccountNumberReceiverStatus: jspb.Message.getFieldWithDefault(msg, 91, ""),
    reregistrationDateAtFundStatus: jspb.Message.getFieldWithDefault(msg, 92, ""),
    mfAgentProcessingIndicatorDelivererStatus: jspb.Message.getFieldWithDefault(msg, 93, ""),
    commentsStatus: jspb.Message.getFieldWithDefault(msg, 94, ""),
    giftDonationIndicatorStatus: jspb.Message.getFieldWithDefault(msg, 95, ""),
    tiRecordType: jspb.Message.getFieldWithDefault(msg, 96, ""),
    tiRecordLength: jspb.Message.getFieldWithDefault(msg, 97, ""),
    atRecordType: jspb.Message.getFieldWithDefault(msg, 98, ""),
    atRecordLength: jspb.Message.getFieldWithDefault(msg, 99, ""),
    tiPhysicalSequenceNumber: jspb.Message.getFieldWithDefault(msg, 100, ""),
    atPhysicalSequenceNumber: jspb.Message.getFieldWithDefault(msg, 101, ""),
    tiLogicalSequenceNumber: jspb.Message.getFieldWithDefault(msg, 102, ""),
    atLogicalSequenceNumber: jspb.Message.getFieldWithDefault(msg, 103, ""),
    tiTransactionType: jspb.Message.getFieldWithDefault(msg, 104, ""),
    atTransactionType: jspb.Message.getFieldWithDefault(msg, 105, ""),
    tiAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 106, ""),
    atAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 107, ""),
    tiTransferType: jspb.Message.getFieldWithDefault(msg, 108, ""),
    atTransferType: jspb.Message.getFieldWithDefault(msg, 109, ""),
    tiSubmittingParticipantNumber: jspb.Message.getFieldWithDefault(msg, 110, ""),
    atSubmittingParticipantNumber: jspb.Message.getFieldWithDefault(msg, 111, ""),
    tiOriginalReceiverNumber: jspb.Message.getFieldWithDefault(msg, 112, ""),
    atOriginalReceiverNumber: jspb.Message.getFieldWithDefault(msg, 113, ""),
    tiOriginalDelivererNumber: jspb.Message.getFieldWithDefault(msg, 114, ""),
    atOriginalDelivererNumber: jspb.Message.getFieldWithDefault(msg, 115, ""),
    originalReceiverCustomerAccountNumber: jspb.Message.getFieldWithDefault(msg, 116, ""),
    originalReceiverCustomerAccountName: jspb.Message.getFieldWithDefault(msg, 117, ""),
    originalReceiverTaxIdPrimary: jspb.Message.getFieldWithDefault(msg, 118, ""),
    originalReceiverTaxIdSecondary: jspb.Message.getFieldWithDefault(msg, 119, ""),
    originalReceiverCustomerAccountType: jspb.Message.getFieldWithDefault(msg, 120, ""),
    giftDonationIndicator: jspb.Message.getFieldWithDefault(msg, 121, ""),
    originalReceiverOccBrokerNumber: jspb.Message.getFieldWithDefault(msg, 122, ""),
    originalReceiverCorrespondent: jspb.Message.getFieldWithDefault(msg, 123, ""),
    contraParticipantType: jspb.Message.getFieldWithDefault(msg, 124, ""),
    originalDelivererCustomerAccountNumber: jspb.Message.getFieldWithDefault(msg, 125, ""),
    transferTypeReject: jspb.Message.getFieldWithDefault(msg, 126, ""),
    associatedAcatsControlNumber: jspb.Message.getFieldWithDefault(msg, 127, ""),
    associatedSettlementDate: jspb.Message.getFieldWithDefault(msg, 128, ""),
    tiTransactionReferenceId: jspb.Message.getFieldWithDefault(msg, 129, ""),
    atTransactionReferenceId: jspb.Message.getFieldWithDefault(msg, 130, ""),
    atAssetSequenceNumber: jspb.Message.getFieldWithDefault(msg, 131, ""),
    assetPricingCategory: jspb.Message.getFieldWithDefault(msg, 132, ""),
    comments1: jspb.Message.getFieldWithDefault(msg, 133, ""),
    comments2: jspb.Message.getFieldWithDefault(msg, 134, ""),
    optionCategory: jspb.Message.getFieldWithDefault(msg, 135, ""),
    settlingLocation: jspb.Message.getFieldWithDefault(msg, 136, ""),
    settlingLocationReason: jspb.Message.getFieldWithDefault(msg, 137, ""),
    isinCountryCode: jspb.Message.getFieldWithDefault(msg, 138, ""),
    isinSecurityIssueId: jspb.Message.getFieldWithDefault(msg, 139, ""),
    isinSecurityCheckDigit: jspb.Message.getFieldWithDefault(msg, 140, ""),
    dtcSubIssueType: jspb.Message.getFieldWithDefault(msg, 141, ""),
    assetDescription1: jspb.Message.getFieldWithDefault(msg, 142, ""),
    assetDescription2: jspb.Message.getFieldWithDefault(msg, 143, ""),
    assetQuantityRequestIndicator: jspb.Message.getFieldWithDefault(msg, 144, ""),
    assetRequestTransactionCode: jspb.Message.getFieldWithDefault(msg, 145, ""),
    assetQuantity: jspb.Message.getFieldWithDefault(msg, 146, ""),
    assetPercent: jspb.Message.getFieldWithDefault(msg, 147, ""),
    positionCode: jspb.Message.getFieldWithDefault(msg, 148, ""),
    isoCurrencyCode: jspb.Message.getFieldWithDefault(msg, 149, ""),
    assetAmount: jspb.Message.getFieldWithDefault(msg, 150, ""),
    bearerBond: jspb.Message.getFieldWithDefault(msg, 151, ""),
    cashMarginShortCode: jspb.Message.getFieldWithDefault(msg, 152, ""),
    whenIssuedIndicator: jspb.Message.getFieldWithDefault(msg, 153, ""),
    transferTypeReason: jspb.Message.getFieldWithDefault(msg, 154, ""),
    mbsSerialNote: jspb.Message.getFieldWithDefault(msg, 155, ""),
    assetComment1: jspb.Message.getFieldWithDefault(msg, 156, ""),
    assetComment2: jspb.Message.getFieldWithDefault(msg, 157, ""),
    source: jspb.Message.getFieldWithDefault(msg, 158, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.MultiCycleMRO}
 */
proto.acatspb.MultiCycleMRO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.MultiCycleMRO;
  return proto.acatspb.MultiCycleMRO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.MultiCycleMRO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.MultiCycleMRO}
 */
proto.acatspb.MultiCycleMRO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRecordType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubrecordType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionSide(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAcatsControlNumber(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetSequenceNumber(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessingDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCycle(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setDistributionParticipant(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemAction(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSystemRejectReason(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setDaysInStatus(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegistrationIndicator(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiRecordTypeStatus(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtRecordTypeStatus(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiRecordLengthStatus(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtRecordLengthStatus(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiPhysicalSequenceNumberStatus(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtPhysicalSequenceNumberStatus(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiLogicalSequenceNumberStatus(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtLogicalSequenceNumberStatus(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransactionTypeStatus(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransactionTypeStatus(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiAcatsControlNumberStatus(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAcatsControlNumberStatus(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransferTypeStatus(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransferTypeStatus(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiSubmittingParticipantNumberStatus(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtSubmittingParticipantNumberStatus(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalReceiverNumberStatus(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalReceiverNumberStatus(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalDelivererNumberStatus(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalDelivererNumberStatus(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountNumberStatus(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountNameStatus(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdPrimaryStatus(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdSecondaryStatus(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountTypeStatus(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverOccBrokerNumberStatus(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCorrespondentStatus(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalDelivererCustomerAccountNumberStatus(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeRejectStatus(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedAcatsControlNumberStatus(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedSettlementDateStatus(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransactionReferenceIdStatus(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransactionReferenceIdStatus(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAssetSequenceNumberStatus(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetSequenceNumberStatus(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPricingCategoryStatus(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCategoryStatus(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocationStatus(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocationReasonStatus(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinCountryCodeStatus(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityIssueIdStatus(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityCheckDigitStatus(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetDescriptionStatus(value);
      break;
    case 57:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantityRequestIndicatorStatus(value);
      break;
    case 58:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetRequestTransactionCodeStatus(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantityStatus(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPercentStatus(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionCodeStatus(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCurrencyCodeStatus(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetAmountStatus(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setBearerBondStatus(value);
      break;
    case 65:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashMarginShortCodeStatus(value);
      break;
    case 66:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhenIssuedIndicatorStatus(value);
      break;
    case 67:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeReasonStatus(value);
      break;
    case 68:
      var value = /** @type {string} */ (reader.readString());
      msg.setMbsSerialNoteStatus(value);
      break;
    case 69:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetCommentStatus(value);
      break;
    case 70:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionBuySellIndicatorStatus(value);
      break;
    case 71:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionPcIndicatorStatus(value);
      break;
    case 72:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionOcIndicatorStatus(value);
      break;
    case 73:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionSymbolStatus(value);
      break;
    case 74:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionExpirationDateStatus(value);
      break;
    case 75:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionStrikePriceIntegerStatus(value);
      break;
    case 76:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionStrikePriceFractionStatus(value);
      break;
    case 77:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionOccDelivererNumberStatus(value);
      break;
    case 78:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionAccountTypeStatus(value);
      break;
    case 79:
      var value = /** @type {string} */ (reader.readString());
      msg.setSubAccountIdStatus(value);
      break;
    case 80:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCommentsStatus(value);
      break;
    case 81:
      var value = /** @type {string} */ (reader.readString());
      msg.setFundCustomerAccountNumberStatus(value);
      break;
    case 82:
      var value = /** @type {string} */ (reader.readString());
      msg.setBookPhysicalShareIndicatorStatus(value);
      break;
    case 83:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetworkControlIndicatorStatus(value);
      break;
    case 84:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountTypeIndicatorStatus(value);
      break;
    case 85:
      var value = /** @type {string} */ (reader.readString());
      msg.setDividendCodeStatus(value);
      break;
    case 86:
      var value = /** @type {string} */ (reader.readString());
      msg.setDividendPayeeIndicatorStatus(value);
      break;
    case 87:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingBrokerStatus(value);
      break;
    case 88:
      var value = /** @type {string} */ (reader.readString());
      msg.setMfFullPartialIndicatorStatus(value);
      break;
    case 89:
      var value = /** @type {string} */ (reader.readString());
      msg.setShareAgingIndicatorStatus(value);
      break;
    case 90:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirmMembershipIndicatorDelivererStatus(value);
      break;
    case 91:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewFuncCustomerAccountNumberReceiverStatus(value);
      break;
    case 92:
      var value = /** @type {string} */ (reader.readString());
      msg.setReregistrationDateAtFundStatus(value);
      break;
    case 93:
      var value = /** @type {string} */ (reader.readString());
      msg.setMfAgentProcessingIndicatorDelivererStatus(value);
      break;
    case 94:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommentsStatus(value);
      break;
    case 95:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftDonationIndicatorStatus(value);
      break;
    case 96:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiRecordType(value);
      break;
    case 97:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiRecordLength(value);
      break;
    case 98:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtRecordType(value);
      break;
    case 99:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtRecordLength(value);
      break;
    case 100:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiPhysicalSequenceNumber(value);
      break;
    case 101:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtPhysicalSequenceNumber(value);
      break;
    case 102:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiLogicalSequenceNumber(value);
      break;
    case 103:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtLogicalSequenceNumber(value);
      break;
    case 104:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransactionType(value);
      break;
    case 105:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransactionType(value);
      break;
    case 106:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiAcatsControlNumber(value);
      break;
    case 107:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAcatsControlNumber(value);
      break;
    case 108:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransferType(value);
      break;
    case 109:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransferType(value);
      break;
    case 110:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiSubmittingParticipantNumber(value);
      break;
    case 111:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtSubmittingParticipantNumber(value);
      break;
    case 112:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalReceiverNumber(value);
      break;
    case 113:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalReceiverNumber(value);
      break;
    case 114:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiOriginalDelivererNumber(value);
      break;
    case 115:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtOriginalDelivererNumber(value);
      break;
    case 116:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountNumber(value);
      break;
    case 117:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountName(value);
      break;
    case 118:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdPrimary(value);
      break;
    case 119:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverTaxIdSecondary(value);
      break;
    case 120:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCustomerAccountType(value);
      break;
    case 121:
      var value = /** @type {string} */ (reader.readString());
      msg.setGiftDonationIndicator(value);
      break;
    case 122:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverOccBrokerNumber(value);
      break;
    case 123:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalReceiverCorrespondent(value);
      break;
    case 124:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraParticipantType(value);
      break;
    case 125:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalDelivererCustomerAccountNumber(value);
      break;
    case 126:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeReject(value);
      break;
    case 127:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedAcatsControlNumber(value);
      break;
    case 128:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssociatedSettlementDate(value);
      break;
    case 129:
      var value = /** @type {string} */ (reader.readString());
      msg.setTiTransactionReferenceId(value);
      break;
    case 130:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtTransactionReferenceId(value);
      break;
    case 131:
      var value = /** @type {string} */ (reader.readString());
      msg.setAtAssetSequenceNumber(value);
      break;
    case 132:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPricingCategory(value);
      break;
    case 133:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments1(value);
      break;
    case 134:
      var value = /** @type {string} */ (reader.readString());
      msg.setComments2(value);
      break;
    case 135:
      var value = /** @type {string} */ (reader.readString());
      msg.setOptionCategory(value);
      break;
    case 136:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocation(value);
      break;
    case 137:
      var value = /** @type {string} */ (reader.readString());
      msg.setSettlingLocationReason(value);
      break;
    case 138:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinCountryCode(value);
      break;
    case 139:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityIssueId(value);
      break;
    case 140:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsinSecurityCheckDigit(value);
      break;
    case 141:
      var value = /** @type {string} */ (reader.readString());
      msg.setDtcSubIssueType(value);
      break;
    case 142:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetDescription1(value);
      break;
    case 143:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetDescription2(value);
      break;
    case 144:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantityRequestIndicator(value);
      break;
    case 145:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetRequestTransactionCode(value);
      break;
    case 146:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetQuantity(value);
      break;
    case 147:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetPercent(value);
      break;
    case 148:
      var value = /** @type {string} */ (reader.readString());
      msg.setPositionCode(value);
      break;
    case 149:
      var value = /** @type {string} */ (reader.readString());
      msg.setIsoCurrencyCode(value);
      break;
    case 150:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetAmount(value);
      break;
    case 151:
      var value = /** @type {string} */ (reader.readString());
      msg.setBearerBond(value);
      break;
    case 152:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashMarginShortCode(value);
      break;
    case 153:
      var value = /** @type {string} */ (reader.readString());
      msg.setWhenIssuedIndicator(value);
      break;
    case 154:
      var value = /** @type {string} */ (reader.readString());
      msg.setTransferTypeReason(value);
      break;
    case 155:
      var value = /** @type {string} */ (reader.readString());
      msg.setMbsSerialNote(value);
      break;
    case 156:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetComment1(value);
      break;
    case 157:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetComment2(value);
      break;
    case 158:
      var value = /** @type {string} */ (reader.readString());
      msg.setSource(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.MultiCycleMRO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.MultiCycleMRO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.MultiCycleMRO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.MultiCycleMRO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRecordType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSubrecordType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDistributionSide();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAssetSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getProcessingDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCycle();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getDistributionParticipant();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSystemAction();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSystemRejectReason();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getDaysInStatus();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getRegistrationIndicator();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getTiRecordTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getAtRecordTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTiRecordLengthStatus();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getAtRecordLengthStatus();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getTiPhysicalSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getAtPhysicalSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getTiLogicalSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getAtLogicalSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getTiTransactionTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getAtTransactionTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getTiAcatsControlNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getAtAcatsControlNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getTiTransferTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getAtTransferTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getTiSubmittingParticipantNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getAtSubmittingParticipantNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getTiOriginalReceiverNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getAtOriginalReceiverNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getTiOriginalDelivererNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getAtOriginalDelivererNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountNameStatus();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdPrimaryStatus();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdSecondaryStatus();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getOriginalReceiverOccBrokerNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getOriginalReceiverCorrespondentStatus();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getOriginalDelivererCustomerAccountNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getTransferTypeRejectStatus();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getAssociatedAcatsControlNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getAssociatedSettlementDateStatus();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getTiTransactionReferenceIdStatus();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getAtTransactionReferenceIdStatus();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getAtAssetSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getAssetSequenceNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getAssetPricingCategoryStatus();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getOptionCategoryStatus();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getSettlingLocationStatus();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getSettlingLocationReasonStatus();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getIsinCountryCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getIsinSecurityIssueIdStatus();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getIsinSecurityCheckDigitStatus();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getAssetDescriptionStatus();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getAssetQuantityRequestIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      57,
      f
    );
  }
  f = message.getAssetRequestTransactionCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      58,
      f
    );
  }
  f = message.getAssetQuantityStatus();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getAssetPercentStatus();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getPositionCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getIsoCurrencyCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getAssetAmountStatus();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getBearerBondStatus();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getCashMarginShortCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      65,
      f
    );
  }
  f = message.getWhenIssuedIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      66,
      f
    );
  }
  f = message.getTransferTypeReasonStatus();
  if (f.length > 0) {
    writer.writeString(
      67,
      f
    );
  }
  f = message.getMbsSerialNoteStatus();
  if (f.length > 0) {
    writer.writeString(
      68,
      f
    );
  }
  f = message.getAssetCommentStatus();
  if (f.length > 0) {
    writer.writeString(
      69,
      f
    );
  }
  f = message.getOptionBuySellIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      70,
      f
    );
  }
  f = message.getOptionPcIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      71,
      f
    );
  }
  f = message.getOptionOcIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      72,
      f
    );
  }
  f = message.getOptionSymbolStatus();
  if (f.length > 0) {
    writer.writeString(
      73,
      f
    );
  }
  f = message.getOptionExpirationDateStatus();
  if (f.length > 0) {
    writer.writeString(
      74,
      f
    );
  }
  f = message.getOptionStrikePriceIntegerStatus();
  if (f.length > 0) {
    writer.writeString(
      75,
      f
    );
  }
  f = message.getOptionStrikePriceFractionStatus();
  if (f.length > 0) {
    writer.writeString(
      76,
      f
    );
  }
  f = message.getOptionOccDelivererNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      77,
      f
    );
  }
  f = message.getOptionAccountTypeStatus();
  if (f.length > 0) {
    writer.writeString(
      78,
      f
    );
  }
  f = message.getSubAccountIdStatus();
  if (f.length > 0) {
    writer.writeString(
      79,
      f
    );
  }
  f = message.getOptionCommentsStatus();
  if (f.length > 0) {
    writer.writeString(
      80,
      f
    );
  }
  f = message.getFundCustomerAccountNumberStatus();
  if (f.length > 0) {
    writer.writeString(
      81,
      f
    );
  }
  f = message.getBookPhysicalShareIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      82,
      f
    );
  }
  f = message.getNetworkControlIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      83,
      f
    );
  }
  f = message.getAccountTypeIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      84,
      f
    );
  }
  f = message.getDividendCodeStatus();
  if (f.length > 0) {
    writer.writeString(
      85,
      f
    );
  }
  f = message.getDividendPayeeIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      86,
      f
    );
  }
  f = message.getExecutingBrokerStatus();
  if (f.length > 0) {
    writer.writeString(
      87,
      f
    );
  }
  f = message.getMfFullPartialIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      88,
      f
    );
  }
  f = message.getShareAgingIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      89,
      f
    );
  }
  f = message.getFirmMembershipIndicatorDelivererStatus();
  if (f.length > 0) {
    writer.writeString(
      90,
      f
    );
  }
  f = message.getNewFuncCustomerAccountNumberReceiverStatus();
  if (f.length > 0) {
    writer.writeString(
      91,
      f
    );
  }
  f = message.getReregistrationDateAtFundStatus();
  if (f.length > 0) {
    writer.writeString(
      92,
      f
    );
  }
  f = message.getMfAgentProcessingIndicatorDelivererStatus();
  if (f.length > 0) {
    writer.writeString(
      93,
      f
    );
  }
  f = message.getCommentsStatus();
  if (f.length > 0) {
    writer.writeString(
      94,
      f
    );
  }
  f = message.getGiftDonationIndicatorStatus();
  if (f.length > 0) {
    writer.writeString(
      95,
      f
    );
  }
  f = message.getTiRecordType();
  if (f.length > 0) {
    writer.writeString(
      96,
      f
    );
  }
  f = message.getTiRecordLength();
  if (f.length > 0) {
    writer.writeString(
      97,
      f
    );
  }
  f = message.getAtRecordType();
  if (f.length > 0) {
    writer.writeString(
      98,
      f
    );
  }
  f = message.getAtRecordLength();
  if (f.length > 0) {
    writer.writeString(
      99,
      f
    );
  }
  f = message.getTiPhysicalSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      100,
      f
    );
  }
  f = message.getAtPhysicalSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      101,
      f
    );
  }
  f = message.getTiLogicalSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      102,
      f
    );
  }
  f = message.getAtLogicalSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      103,
      f
    );
  }
  f = message.getTiTransactionType();
  if (f.length > 0) {
    writer.writeString(
      104,
      f
    );
  }
  f = message.getAtTransactionType();
  if (f.length > 0) {
    writer.writeString(
      105,
      f
    );
  }
  f = message.getTiAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      106,
      f
    );
  }
  f = message.getAtAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      107,
      f
    );
  }
  f = message.getTiTransferType();
  if (f.length > 0) {
    writer.writeString(
      108,
      f
    );
  }
  f = message.getAtTransferType();
  if (f.length > 0) {
    writer.writeString(
      109,
      f
    );
  }
  f = message.getTiSubmittingParticipantNumber();
  if (f.length > 0) {
    writer.writeString(
      110,
      f
    );
  }
  f = message.getAtSubmittingParticipantNumber();
  if (f.length > 0) {
    writer.writeString(
      111,
      f
    );
  }
  f = message.getTiOriginalReceiverNumber();
  if (f.length > 0) {
    writer.writeString(
      112,
      f
    );
  }
  f = message.getAtOriginalReceiverNumber();
  if (f.length > 0) {
    writer.writeString(
      113,
      f
    );
  }
  f = message.getTiOriginalDelivererNumber();
  if (f.length > 0) {
    writer.writeString(
      114,
      f
    );
  }
  f = message.getAtOriginalDelivererNumber();
  if (f.length > 0) {
    writer.writeString(
      115,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      116,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountName();
  if (f.length > 0) {
    writer.writeString(
      117,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdPrimary();
  if (f.length > 0) {
    writer.writeString(
      118,
      f
    );
  }
  f = message.getOriginalReceiverTaxIdSecondary();
  if (f.length > 0) {
    writer.writeString(
      119,
      f
    );
  }
  f = message.getOriginalReceiverCustomerAccountType();
  if (f.length > 0) {
    writer.writeString(
      120,
      f
    );
  }
  f = message.getGiftDonationIndicator();
  if (f.length > 0) {
    writer.writeString(
      121,
      f
    );
  }
  f = message.getOriginalReceiverOccBrokerNumber();
  if (f.length > 0) {
    writer.writeString(
      122,
      f
    );
  }
  f = message.getOriginalReceiverCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      123,
      f
    );
  }
  f = message.getContraParticipantType();
  if (f.length > 0) {
    writer.writeString(
      124,
      f
    );
  }
  f = message.getOriginalDelivererCustomerAccountNumber();
  if (f.length > 0) {
    writer.writeString(
      125,
      f
    );
  }
  f = message.getTransferTypeReject();
  if (f.length > 0) {
    writer.writeString(
      126,
      f
    );
  }
  f = message.getAssociatedAcatsControlNumber();
  if (f.length > 0) {
    writer.writeString(
      127,
      f
    );
  }
  f = message.getAssociatedSettlementDate();
  if (f.length > 0) {
    writer.writeString(
      128,
      f
    );
  }
  f = message.getTiTransactionReferenceId();
  if (f.length > 0) {
    writer.writeString(
      129,
      f
    );
  }
  f = message.getAtTransactionReferenceId();
  if (f.length > 0) {
    writer.writeString(
      130,
      f
    );
  }
  f = message.getAtAssetSequenceNumber();
  if (f.length > 0) {
    writer.writeString(
      131,
      f
    );
  }
  f = message.getAssetPricingCategory();
  if (f.length > 0) {
    writer.writeString(
      132,
      f
    );
  }
  f = message.getComments1();
  if (f.length > 0) {
    writer.writeString(
      133,
      f
    );
  }
  f = message.getComments2();
  if (f.length > 0) {
    writer.writeString(
      134,
      f
    );
  }
  f = message.getOptionCategory();
  if (f.length > 0) {
    writer.writeString(
      135,
      f
    );
  }
  f = message.getSettlingLocation();
  if (f.length > 0) {
    writer.writeString(
      136,
      f
    );
  }
  f = message.getSettlingLocationReason();
  if (f.length > 0) {
    writer.writeString(
      137,
      f
    );
  }
  f = message.getIsinCountryCode();
  if (f.length > 0) {
    writer.writeString(
      138,
      f
    );
  }
  f = message.getIsinSecurityIssueId();
  if (f.length > 0) {
    writer.writeString(
      139,
      f
    );
  }
  f = message.getIsinSecurityCheckDigit();
  if (f.length > 0) {
    writer.writeString(
      140,
      f
    );
  }
  f = message.getDtcSubIssueType();
  if (f.length > 0) {
    writer.writeString(
      141,
      f
    );
  }
  f = message.getAssetDescription1();
  if (f.length > 0) {
    writer.writeString(
      142,
      f
    );
  }
  f = message.getAssetDescription2();
  if (f.length > 0) {
    writer.writeString(
      143,
      f
    );
  }
  f = message.getAssetQuantityRequestIndicator();
  if (f.length > 0) {
    writer.writeString(
      144,
      f
    );
  }
  f = message.getAssetRequestTransactionCode();
  if (f.length > 0) {
    writer.writeString(
      145,
      f
    );
  }
  f = message.getAssetQuantity();
  if (f.length > 0) {
    writer.writeString(
      146,
      f
    );
  }
  f = message.getAssetPercent();
  if (f.length > 0) {
    writer.writeString(
      147,
      f
    );
  }
  f = message.getPositionCode();
  if (f.length > 0) {
    writer.writeString(
      148,
      f
    );
  }
  f = message.getIsoCurrencyCode();
  if (f.length > 0) {
    writer.writeString(
      149,
      f
    );
  }
  f = message.getAssetAmount();
  if (f.length > 0) {
    writer.writeString(
      150,
      f
    );
  }
  f = message.getBearerBond();
  if (f.length > 0) {
    writer.writeString(
      151,
      f
    );
  }
  f = message.getCashMarginShortCode();
  if (f.length > 0) {
    writer.writeString(
      152,
      f
    );
  }
  f = message.getWhenIssuedIndicator();
  if (f.length > 0) {
    writer.writeString(
      153,
      f
    );
  }
  f = message.getTransferTypeReason();
  if (f.length > 0) {
    writer.writeString(
      154,
      f
    );
  }
  f = message.getMbsSerialNote();
  if (f.length > 0) {
    writer.writeString(
      155,
      f
    );
  }
  f = message.getAssetComment1();
  if (f.length > 0) {
    writer.writeString(
      156,
      f
    );
  }
  f = message.getAssetComment2();
  if (f.length > 0) {
    writer.writeString(
      157,
      f
    );
  }
  f = message.getSource();
  if (f.length > 0) {
    writer.writeString(
      158,
      f
    );
  }
};


/**
 * optional string record_type = 1;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getRecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setRecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string subrecord_type = 2;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSubrecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSubrecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string distribution_side = 3;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDistributionSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDistributionSide = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string acats_control_number = 4;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string asset_sequence_number = 5;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.Date processing_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.acatspb.MultiCycleMRO.prototype.getProcessingDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
*/
proto.acatspb.MultiCycleMRO.prototype.setProcessingDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.clearProcessingDate = function() {
  return this.setProcessingDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.acatspb.MultiCycleMRO.prototype.hasProcessingDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string cycle = 7;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getCycle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setCycle = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string distribution_participant = 8;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDistributionParticipant = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDistributionParticipant = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string system_action = 9;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSystemAction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSystemAction = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string system_reject_reason = 10;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSystemRejectReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSystemRejectReason = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string status = 11;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string days_in_status = 12;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDaysInStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDaysInStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string registration_indicator = 13;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getRegistrationIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setRegistrationIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string ti_record_type_status = 14;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiRecordTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiRecordTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string at_record_type_status = 15;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtRecordTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtRecordTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ti_record_length_status = 16;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiRecordLengthStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiRecordLengthStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string at_record_length_status = 17;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtRecordLengthStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtRecordLengthStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string ti_physical_sequence_number_status = 18;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiPhysicalSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiPhysicalSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string at_physical_sequence_number_status = 19;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtPhysicalSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtPhysicalSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string ti_logical_sequence_number_status = 20;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiLogicalSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiLogicalSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string at_logical_sequence_number_status = 21;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtLogicalSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtLogicalSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string ti_transaction_type_status = 22;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransactionTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransactionTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string at_transaction_type_status = 23;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransactionTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransactionTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string ti_acats_control_number_status = 24;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiAcatsControlNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiAcatsControlNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string at_acats_control_number_status = 25;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtAcatsControlNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtAcatsControlNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string ti_transfer_type_status = 26;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransferTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransferTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string at_transfer_type_status = 27;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransferTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransferTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string ti_submitting_participant_number_status = 28;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiSubmittingParticipantNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiSubmittingParticipantNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string at_submitting_participant_number_status = 29;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtSubmittingParticipantNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtSubmittingParticipantNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string ti_original_receiver_number_status = 30;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiOriginalReceiverNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiOriginalReceiverNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string at_original_receiver_number_status = 31;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtOriginalReceiverNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtOriginalReceiverNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string ti_original_deliverer_number_status = 32;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiOriginalDelivererNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiOriginalDelivererNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string at_original_deliverer_number_status = 33;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtOriginalDelivererNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtOriginalDelivererNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string original_receiver_customer_account_number_status = 34;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string original_receiver_customer_account_name_status = 35;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountNameStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountNameStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string original_receiver_tax_id_primary_status = 36;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverTaxIdPrimaryStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverTaxIdPrimaryStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string original_receiver_tax_id_secondary_status = 37;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverTaxIdSecondaryStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverTaxIdSecondaryStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string original_receiver_customer_account_type_status = 38;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string original_receiver_occ_broker_number_status = 39;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverOccBrokerNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverOccBrokerNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string original_receiver_correspondent_status = 40;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCorrespondentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCorrespondentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string original_deliverer_customer_account_number_status = 41;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalDelivererCustomerAccountNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalDelivererCustomerAccountNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string transfer_type_reject_status = 42;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTransferTypeRejectStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTransferTypeRejectStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string associated_acats_control_number_status = 43;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssociatedAcatsControlNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssociatedAcatsControlNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string associated_settlement_date_status = 44;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssociatedSettlementDateStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssociatedSettlementDateStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string ti_transaction_reference_id_status = 45;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransactionReferenceIdStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransactionReferenceIdStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string at_transaction_reference_id_status = 46;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransactionReferenceIdStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransactionReferenceIdStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string at_asset_sequence_number_status = 47;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtAssetSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtAssetSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string asset_sequence_number_status = 48;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetSequenceNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetSequenceNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string asset_pricing_category_status = 49;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetPricingCategoryStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetPricingCategoryStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string option_category_status = 50;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionCategoryStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionCategoryStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string settling_location_status = 51;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSettlingLocationStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSettlingLocationStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string settling_location_reason_status = 52;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSettlingLocationReasonStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSettlingLocationReasonStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string isin_country_code_status = 53;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinCountryCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinCountryCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string isin_security_issue_id_status = 54;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinSecurityIssueIdStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinSecurityIssueIdStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string isin_security_check_digit_status = 55;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinSecurityCheckDigitStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinSecurityCheckDigitStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string asset_description_status = 56;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetDescriptionStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetDescriptionStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional string asset_quantity_request_indicator_status = 57;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetQuantityRequestIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 57, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetQuantityRequestIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 57, value);
};


/**
 * optional string asset_request_transaction_code_status = 58;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetRequestTransactionCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 58, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetRequestTransactionCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 58, value);
};


/**
 * optional string asset_quantity_status = 59;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetQuantityStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetQuantityStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string asset_percent_status = 60;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetPercentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetPercentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string position_code_status = 61;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getPositionCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setPositionCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string iso_currency_code_status = 62;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsoCurrencyCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsoCurrencyCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string asset_amount_status = 63;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetAmountStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetAmountStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string bearer_bond_status = 64;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getBearerBondStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setBearerBondStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional string cash_margin_short_code_status = 65;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getCashMarginShortCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 65, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setCashMarginShortCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 65, value);
};


/**
 * optional string when_issued_indicator_status = 66;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getWhenIssuedIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 66, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setWhenIssuedIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 66, value);
};


/**
 * optional string transfer_type_reason_status = 67;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTransferTypeReasonStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 67, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTransferTypeReasonStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 67, value);
};


/**
 * optional string mbs_serial_note_status = 68;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getMbsSerialNoteStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 68, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setMbsSerialNoteStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 68, value);
};


/**
 * optional string asset_comment_status = 69;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetCommentStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 69, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetCommentStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 69, value);
};


/**
 * optional string option_buy_sell_indicator_status = 70;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionBuySellIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 70, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionBuySellIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 70, value);
};


/**
 * optional string option_pc_indicator_status = 71;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionPcIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 71, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionPcIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 71, value);
};


/**
 * optional string option_oc_indicator_status = 72;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionOcIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 72, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionOcIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 72, value);
};


/**
 * optional string option_symbol_status = 73;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionSymbolStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 73, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionSymbolStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 73, value);
};


/**
 * optional string option_expiration_date_status = 74;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionExpirationDateStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 74, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionExpirationDateStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 74, value);
};


/**
 * optional string option_strike_price_integer_status = 75;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionStrikePriceIntegerStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 75, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionStrikePriceIntegerStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 75, value);
};


/**
 * optional string option_strike_price_fraction_status = 76;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionStrikePriceFractionStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 76, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionStrikePriceFractionStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 76, value);
};


/**
 * optional string option_occ_deliverer_number_status = 77;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionOccDelivererNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 77, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionOccDelivererNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 77, value);
};


/**
 * optional string option_account_type_status = 78;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionAccountTypeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 78, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionAccountTypeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 78, value);
};


/**
 * optional string sub_account_id_status = 79;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSubAccountIdStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 79, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSubAccountIdStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 79, value);
};


/**
 * optional string option_comments_status = 80;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionCommentsStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 80, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionCommentsStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 80, value);
};


/**
 * optional string fund_customer_account_number_status = 81;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getFundCustomerAccountNumberStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 81, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setFundCustomerAccountNumberStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 81, value);
};


/**
 * optional string book_physical_share_indicator_status = 82;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getBookPhysicalShareIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 82, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setBookPhysicalShareIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 82, value);
};


/**
 * optional string network_control_indicator_status = 83;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getNetworkControlIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 83, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setNetworkControlIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 83, value);
};


/**
 * optional string account_type_indicator_status = 84;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAccountTypeIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 84, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAccountTypeIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 84, value);
};


/**
 * optional string dividend_code_status = 85;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDividendCodeStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 85, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDividendCodeStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 85, value);
};


/**
 * optional string dividend_payee_indicator_status = 86;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDividendPayeeIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 86, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDividendPayeeIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 86, value);
};


/**
 * optional string executing_broker_status = 87;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getExecutingBrokerStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 87, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setExecutingBrokerStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 87, value);
};


/**
 * optional string mf_full_partial_indicator_status = 88;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getMfFullPartialIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 88, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setMfFullPartialIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 88, value);
};


/**
 * optional string share_aging_indicator_status = 89;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getShareAgingIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 89, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setShareAgingIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 89, value);
};


/**
 * optional string firm_membership_indicator_deliverer_status = 90;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getFirmMembershipIndicatorDelivererStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 90, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setFirmMembershipIndicatorDelivererStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 90, value);
};


/**
 * optional string new_func_customer_account_number_receiver_status = 91;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getNewFuncCustomerAccountNumberReceiverStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 91, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setNewFuncCustomerAccountNumberReceiverStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 91, value);
};


/**
 * optional string reregistration_date_at_fund_status = 92;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getReregistrationDateAtFundStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 92, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setReregistrationDateAtFundStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 92, value);
};


/**
 * optional string mf_agent_processing_indicator_deliverer_status = 93;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getMfAgentProcessingIndicatorDelivererStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 93, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setMfAgentProcessingIndicatorDelivererStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 93, value);
};


/**
 * optional string comments_status = 94;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getCommentsStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 94, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setCommentsStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 94, value);
};


/**
 * optional string gift_donation_indicator_status = 95;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getGiftDonationIndicatorStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 95, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setGiftDonationIndicatorStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 95, value);
};


/**
 * optional string ti_record_type = 96;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiRecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 96, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiRecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 96, value);
};


/**
 * optional string ti_record_length = 97;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiRecordLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 97, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiRecordLength = function(value) {
  return jspb.Message.setProto3StringField(this, 97, value);
};


/**
 * optional string at_record_type = 98;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtRecordType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 98, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtRecordType = function(value) {
  return jspb.Message.setProto3StringField(this, 98, value);
};


/**
 * optional string at_record_length = 99;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtRecordLength = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 99, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtRecordLength = function(value) {
  return jspb.Message.setProto3StringField(this, 99, value);
};


/**
 * optional string ti_physical_sequence_number = 100;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiPhysicalSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 100, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiPhysicalSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 100, value);
};


/**
 * optional string at_physical_sequence_number = 101;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtPhysicalSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 101, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtPhysicalSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 101, value);
};


/**
 * optional string ti_logical_sequence_number = 102;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiLogicalSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 102, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiLogicalSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 102, value);
};


/**
 * optional string at_logical_sequence_number = 103;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtLogicalSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 103, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtLogicalSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 103, value);
};


/**
 * optional string ti_transaction_type = 104;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 104, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 104, value);
};


/**
 * optional string at_transaction_type = 105;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransactionType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 105, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransactionType = function(value) {
  return jspb.Message.setProto3StringField(this, 105, value);
};


/**
 * optional string ti_acats_control_number = 106;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 106, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 106, value);
};


/**
 * optional string at_acats_control_number = 107;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 107, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 107, value);
};


/**
 * optional string ti_transfer_type = 108;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 108, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 108, value);
};


/**
 * optional string at_transfer_type = 109;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransferType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 109, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransferType = function(value) {
  return jspb.Message.setProto3StringField(this, 109, value);
};


/**
 * optional string ti_submitting_participant_number = 110;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiSubmittingParticipantNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 110, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiSubmittingParticipantNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 110, value);
};


/**
 * optional string at_submitting_participant_number = 111;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtSubmittingParticipantNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 111, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtSubmittingParticipantNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 111, value);
};


/**
 * optional string ti_original_receiver_number = 112;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiOriginalReceiverNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 112, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiOriginalReceiverNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 112, value);
};


/**
 * optional string at_original_receiver_number = 113;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtOriginalReceiverNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 113, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtOriginalReceiverNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 113, value);
};


/**
 * optional string ti_original_deliverer_number = 114;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiOriginalDelivererNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 114, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiOriginalDelivererNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 114, value);
};


/**
 * optional string at_original_deliverer_number = 115;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtOriginalDelivererNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 115, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtOriginalDelivererNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 115, value);
};


/**
 * optional string original_receiver_customer_account_number = 116;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 116, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 116, value);
};


/**
 * optional string original_receiver_customer_account_name = 117;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 117, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 117, value);
};


/**
 * optional string original_receiver_tax_id_primary = 118;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverTaxIdPrimary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 118, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverTaxIdPrimary = function(value) {
  return jspb.Message.setProto3StringField(this, 118, value);
};


/**
 * optional string original_receiver_tax_id_secondary = 119;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverTaxIdSecondary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 119, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverTaxIdSecondary = function(value) {
  return jspb.Message.setProto3StringField(this, 119, value);
};


/**
 * optional string original_receiver_customer_account_type = 120;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCustomerAccountType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 120, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCustomerAccountType = function(value) {
  return jspb.Message.setProto3StringField(this, 120, value);
};


/**
 * optional string gift_donation_indicator = 121;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getGiftDonationIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 121, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setGiftDonationIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 121, value);
};


/**
 * optional string original_receiver_occ_broker_number = 122;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverOccBrokerNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 122, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverOccBrokerNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 122, value);
};


/**
 * optional string original_receiver_correspondent = 123;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalReceiverCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 123, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalReceiverCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 123, value);
};


/**
 * optional string contra_participant_type = 124;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getContraParticipantType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 124, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setContraParticipantType = function(value) {
  return jspb.Message.setProto3StringField(this, 124, value);
};


/**
 * optional string original_deliverer_customer_account_number = 125;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOriginalDelivererCustomerAccountNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 125, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOriginalDelivererCustomerAccountNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 125, value);
};


/**
 * optional string transfer_type_reject = 126;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTransferTypeReject = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 126, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTransferTypeReject = function(value) {
  return jspb.Message.setProto3StringField(this, 126, value);
};


/**
 * optional string associated_acats_control_number = 127;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssociatedAcatsControlNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 127, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssociatedAcatsControlNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 127, value);
};


/**
 * optional string associated_settlement_date = 128;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssociatedSettlementDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 128, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssociatedSettlementDate = function(value) {
  return jspb.Message.setProto3StringField(this, 128, value);
};


/**
 * optional string ti_transaction_reference_id = 129;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTiTransactionReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 129, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTiTransactionReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 129, value);
};


/**
 * optional string at_transaction_reference_id = 130;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtTransactionReferenceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 130, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtTransactionReferenceId = function(value) {
  return jspb.Message.setProto3StringField(this, 130, value);
};


/**
 * optional string at_asset_sequence_number = 131;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAtAssetSequenceNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 131, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAtAssetSequenceNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 131, value);
};


/**
 * optional string asset_pricing_category = 132;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetPricingCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 132, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetPricingCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 132, value);
};


/**
 * optional string comments1 = 133;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getComments1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 133, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setComments1 = function(value) {
  return jspb.Message.setProto3StringField(this, 133, value);
};


/**
 * optional string comments2 = 134;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getComments2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 134, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setComments2 = function(value) {
  return jspb.Message.setProto3StringField(this, 134, value);
};


/**
 * optional string option_category = 135;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getOptionCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 135, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setOptionCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 135, value);
};


/**
 * optional string settling_location = 136;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSettlingLocation = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 136, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSettlingLocation = function(value) {
  return jspb.Message.setProto3StringField(this, 136, value);
};


/**
 * optional string settling_location_reason = 137;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSettlingLocationReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 137, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSettlingLocationReason = function(value) {
  return jspb.Message.setProto3StringField(this, 137, value);
};


/**
 * optional string isin_country_code = 138;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinCountryCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 138, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinCountryCode = function(value) {
  return jspb.Message.setProto3StringField(this, 138, value);
};


/**
 * optional string isin_security_issue_id = 139;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinSecurityIssueId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 139, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinSecurityIssueId = function(value) {
  return jspb.Message.setProto3StringField(this, 139, value);
};


/**
 * optional string isin_security_check_digit = 140;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsinSecurityCheckDigit = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 140, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsinSecurityCheckDigit = function(value) {
  return jspb.Message.setProto3StringField(this, 140, value);
};


/**
 * optional string dtc_sub_issue_type = 141;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getDtcSubIssueType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 141, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setDtcSubIssueType = function(value) {
  return jspb.Message.setProto3StringField(this, 141, value);
};


/**
 * optional string asset_description1 = 142;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetDescription1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 142, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetDescription1 = function(value) {
  return jspb.Message.setProto3StringField(this, 142, value);
};


/**
 * optional string asset_description2 = 143;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetDescription2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 143, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetDescription2 = function(value) {
  return jspb.Message.setProto3StringField(this, 143, value);
};


/**
 * optional string asset_quantity_request_indicator = 144;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetQuantityRequestIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 144, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetQuantityRequestIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 144, value);
};


/**
 * optional string asset_request_transaction_code = 145;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetRequestTransactionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 145, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetRequestTransactionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 145, value);
};


/**
 * optional string asset_quantity = 146;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 146, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 146, value);
};


/**
 * optional string asset_percent = 147;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetPercent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 147, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetPercent = function(value) {
  return jspb.Message.setProto3StringField(this, 147, value);
};


/**
 * optional string position_code = 148;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getPositionCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 148, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setPositionCode = function(value) {
  return jspb.Message.setProto3StringField(this, 148, value);
};


/**
 * optional string iso_currency_code = 149;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getIsoCurrencyCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 149, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setIsoCurrencyCode = function(value) {
  return jspb.Message.setProto3StringField(this, 149, value);
};


/**
 * optional string asset_amount = 150;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetAmount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 150, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetAmount = function(value) {
  return jspb.Message.setProto3StringField(this, 150, value);
};


/**
 * optional string bearer_bond = 151;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getBearerBond = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 151, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setBearerBond = function(value) {
  return jspb.Message.setProto3StringField(this, 151, value);
};


/**
 * optional string cash_margin_short_code = 152;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getCashMarginShortCode = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 152, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setCashMarginShortCode = function(value) {
  return jspb.Message.setProto3StringField(this, 152, value);
};


/**
 * optional string when_issued_indicator = 153;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getWhenIssuedIndicator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 153, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setWhenIssuedIndicator = function(value) {
  return jspb.Message.setProto3StringField(this, 153, value);
};


/**
 * optional string transfer_type_reason = 154;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getTransferTypeReason = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 154, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setTransferTypeReason = function(value) {
  return jspb.Message.setProto3StringField(this, 154, value);
};


/**
 * optional string mbs_serial_note = 155;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getMbsSerialNote = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 155, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setMbsSerialNote = function(value) {
  return jspb.Message.setProto3StringField(this, 155, value);
};


/**
 * optional string asset_comment1 = 156;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetComment1 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 156, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetComment1 = function(value) {
  return jspb.Message.setProto3StringField(this, 156, value);
};


/**
 * optional string asset_comment2 = 157;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getAssetComment2 = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 157, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setAssetComment2 = function(value) {
  return jspb.Message.setProto3StringField(this, 157, value);
};


/**
 * optional string source = 158;
 * @return {string}
 */
proto.acatspb.MultiCycleMRO.prototype.getSource = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 158, ""));
};


/**
 * @param {string} value
 * @return {!proto.acatspb.MultiCycleMRO} returns this
 */
proto.acatspb.MultiCycleMRO.prototype.setSource = function(value) {
  return jspb.Message.setProto3StringField(this, 158, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.acatspb.ListMultiCycleMROResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.acatspb.ListMultiCycleMROResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.acatspb.ListMultiCycleMROResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.acatspb.ListMultiCycleMROResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListMultiCycleMROResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    multicycleMroList: jspb.Message.toObjectList(msg.getMulticycleMroList(),
    proto.acatspb.MultiCycleMRO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.acatspb.ListMultiCycleMROResponse}
 */
proto.acatspb.ListMultiCycleMROResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.acatspb.ListMultiCycleMROResponse;
  return proto.acatspb.ListMultiCycleMROResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.acatspb.ListMultiCycleMROResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.acatspb.ListMultiCycleMROResponse}
 */
proto.acatspb.ListMultiCycleMROResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.acatspb.MultiCycleMRO;
      reader.readMessage(value,proto.acatspb.MultiCycleMRO.deserializeBinaryFromReader);
      msg.addMulticycleMro(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.acatspb.ListMultiCycleMROResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.acatspb.ListMultiCycleMROResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.acatspb.ListMultiCycleMROResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.acatspb.ListMultiCycleMROResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMulticycleMroList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.acatspb.MultiCycleMRO.serializeBinaryToWriter
    );
  }
};


/**
 * repeated MultiCycleMRO multicycle_mro = 1;
 * @return {!Array<!proto.acatspb.MultiCycleMRO>}
 */
proto.acatspb.ListMultiCycleMROResponse.prototype.getMulticycleMroList = function() {
  return /** @type{!Array<!proto.acatspb.MultiCycleMRO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.acatspb.MultiCycleMRO, 1));
};


/**
 * @param {!Array<!proto.acatspb.MultiCycleMRO>} value
 * @return {!proto.acatspb.ListMultiCycleMROResponse} returns this
*/
proto.acatspb.ListMultiCycleMROResponse.prototype.setMulticycleMroList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.acatspb.MultiCycleMRO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.acatspb.MultiCycleMRO}
 */
proto.acatspb.ListMultiCycleMROResponse.prototype.addMulticycleMro = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.acatspb.MultiCycleMRO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.acatspb.ListMultiCycleMROResponse} returns this
 */
proto.acatspb.ListMultiCycleMROResponse.prototype.clearMulticycleMroList = function() {
  return this.setMulticycleMroList([]);
};


goog.object.extend(exports, proto.acatspb);
