/**
 * @fileoverview gRPC-Web generated client stub for acatspb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var commonpb_file_pb = require('../commonpb/file_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var utilspb_pagination_pb = require('../utilspb/pagination_pb.js')
const proto = {};
proto.acatspb = require('./outgoing_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.acatspb.OutgoingServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.acatspb.OutgoingServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.acatspb.Outgoing,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_OutgoingService_DownloadOutgoing = new grpc.web.MethodDescriptor(
  '/acatspb.OutgoingService/DownloadOutgoing',
  grpc.web.MethodType.UNARY,
  proto.acatspb.Outgoing,
  commonpb_file_pb.File,
  /**
   * @param {!proto.acatspb.Outgoing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.acatspb.OutgoingServiceClient.prototype.downloadOutgoing =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/acatspb.OutgoingService/DownloadOutgoing',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_DownloadOutgoing,
      callback);
};


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.acatspb.OutgoingServicePromiseClient.prototype.downloadOutgoing =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/acatspb.OutgoingService/DownloadOutgoing',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_DownloadOutgoing);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.acatspb.Outgoing,
 *   !proto.acatspb.CreatePendingResponse>}
 */
const methodDescriptor_OutgoingService_CreatePending = new grpc.web.MethodDescriptor(
  '/acatspb.OutgoingService/CreatePending',
  grpc.web.MethodType.UNARY,
  proto.acatspb.Outgoing,
  proto.acatspb.CreatePendingResponse,
  /**
   * @param {!proto.acatspb.Outgoing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.acatspb.CreatePendingResponse.deserializeBinary
);


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.acatspb.CreatePendingResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.acatspb.CreatePendingResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.acatspb.OutgoingServiceClient.prototype.createPending =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/acatspb.OutgoingService/CreatePending',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_CreatePending,
      callback);
};


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.acatspb.CreatePendingResponse>}
 *     Promise that resolves to the response
 */
proto.acatspb.OutgoingServicePromiseClient.prototype.createPending =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/acatspb.OutgoingService/CreatePending',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_CreatePending);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.acatspb.Outgoing,
 *   !proto.acatspb.GetDefaultsResponse>}
 */
const methodDescriptor_OutgoingService_GetDefaults = new grpc.web.MethodDescriptor(
  '/acatspb.OutgoingService/GetDefaults',
  grpc.web.MethodType.UNARY,
  proto.acatspb.Outgoing,
  proto.acatspb.GetDefaultsResponse,
  /**
   * @param {!proto.acatspb.Outgoing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.acatspb.GetDefaultsResponse.deserializeBinary
);


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.acatspb.GetDefaultsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.acatspb.GetDefaultsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.acatspb.OutgoingServiceClient.prototype.getDefaults =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/acatspb.OutgoingService/GetDefaults',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_GetDefaults,
      callback);
};


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.acatspb.GetDefaultsResponse>}
 *     Promise that resolves to the response
 */
proto.acatspb.OutgoingServicePromiseClient.prototype.getDefaults =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/acatspb.OutgoingService/GetDefaults',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_GetDefaults);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.acatspb.Outgoing,
 *   !proto.acatspb.UpdateQtyReponse>}
 */
const methodDescriptor_OutgoingService_UpdateQty = new grpc.web.MethodDescriptor(
  '/acatspb.OutgoingService/UpdateQty',
  grpc.web.MethodType.UNARY,
  proto.acatspb.Outgoing,
  proto.acatspb.UpdateQtyReponse,
  /**
   * @param {!proto.acatspb.Outgoing} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.acatspb.UpdateQtyReponse.deserializeBinary
);


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.acatspb.UpdateQtyReponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.acatspb.UpdateQtyReponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.acatspb.OutgoingServiceClient.prototype.updateQty =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/acatspb.OutgoingService/UpdateQty',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_UpdateQty,
      callback);
};


/**
 * @param {!proto.acatspb.Outgoing} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.acatspb.UpdateQtyReponse>}
 *     Promise that resolves to the response
 */
proto.acatspb.OutgoingServicePromiseClient.prototype.updateQty =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/acatspb.OutgoingService/UpdateQty',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_UpdateQty);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.acatspb.ListPositionRequest,
 *   !proto.acatspb.ListPositionResponse>}
 */
const methodDescriptor_OutgoingService_ListPosition = new grpc.web.MethodDescriptor(
  '/acatspb.OutgoingService/ListPosition',
  grpc.web.MethodType.UNARY,
  proto.acatspb.ListPositionRequest,
  proto.acatspb.ListPositionResponse,
  /**
   * @param {!proto.acatspb.ListPositionRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.acatspb.ListPositionResponse.deserializeBinary
);


/**
 * @param {!proto.acatspb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.acatspb.ListPositionResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.acatspb.ListPositionResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.acatspb.OutgoingServiceClient.prototype.listPosition =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/acatspb.OutgoingService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_ListPosition,
      callback);
};


/**
 * @param {!proto.acatspb.ListPositionRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.acatspb.ListPositionResponse>}
 *     Promise that resolves to the response
 */
proto.acatspb.OutgoingServicePromiseClient.prototype.listPosition =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/acatspb.OutgoingService/ListPosition',
      request,
      metadata || {},
      methodDescriptor_OutgoingService_ListPosition);
};


module.exports = proto.acatspb;

