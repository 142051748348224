/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var accountpb_address_pb = require('../accountpb/address_pb.js')

var commonpb_file_pb = require('../commonpb/file_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')

var reportpb_activity_pb = require('../reportpb/activity_pb.js')

var reportpb_balance_pb = require('../reportpb/balance_pb.js')

var reportpb_statementdisclosure_pb = require('../reportpb/statementdisclosure_pb.js')

var reportpb_template_pb = require('../reportpb/template_pb.js')

var utilspb_pagination_pb = require('../utilspb/pagination_pb.js')
const proto = {};
proto.reportpb = require('./statement_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.StatementServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.StatementServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListStatementRequest,
 *   !proto.reportpb.ListStatementResponse>}
 */
const methodDescriptor_StatementService_ListStatement = new grpc.web.MethodDescriptor(
  '/reportpb.StatementService/ListStatement',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListStatementRequest,
  proto.reportpb.ListStatementResponse,
  /**
   * @param {!proto.reportpb.ListStatementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListStatementResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListStatementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListStatementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementServiceClient.prototype.listStatement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementService/ListStatement',
      request,
      metadata || {},
      methodDescriptor_StatementService_ListStatement,
      callback);
};


/**
 * @param {!proto.reportpb.ListStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListStatementResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementServicePromiseClient.prototype.listStatement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementService/ListStatement',
      request,
      metadata || {},
      methodDescriptor_StatementService_ListStatement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.DownloadStatementRequest,
 *   !proto.commonpb.File>}
 */
const methodDescriptor_StatementService_DownloadStatement = new grpc.web.MethodDescriptor(
  '/reportpb.StatementService/DownloadStatement',
  grpc.web.MethodType.UNARY,
  proto.reportpb.DownloadStatementRequest,
  commonpb_file_pb.File,
  /**
   * @param {!proto.reportpb.DownloadStatementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  commonpb_file_pb.File.deserializeBinary
);


/**
 * @param {!proto.reportpb.DownloadStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.commonpb.File)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.commonpb.File>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementServiceClient.prototype.downloadStatement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementService/DownloadStatement',
      request,
      metadata || {},
      methodDescriptor_StatementService_DownloadStatement,
      callback);
};


/**
 * @param {!proto.reportpb.DownloadStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.commonpb.File>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementServicePromiseClient.prototype.downloadStatement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementService/DownloadStatement',
      request,
      metadata || {},
      methodDescriptor_StatementService_DownloadStatement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.DownloadStatementRequest,
 *   !proto.reportpb.StatementData>}
 */
const methodDescriptor_StatementService_DownloadStatementData = new grpc.web.MethodDescriptor(
  '/reportpb.StatementService/DownloadStatementData',
  grpc.web.MethodType.UNARY,
  proto.reportpb.DownloadStatementRequest,
  proto.reportpb.StatementData,
  /**
   * @param {!proto.reportpb.DownloadStatementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.StatementData.deserializeBinary
);


/**
 * @param {!proto.reportpb.DownloadStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.StatementData)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.StatementData>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.StatementServiceClient.prototype.downloadStatementData =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.StatementService/DownloadStatementData',
      request,
      metadata || {},
      methodDescriptor_StatementService_DownloadStatementData,
      callback);
};


/**
 * @param {!proto.reportpb.DownloadStatementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.StatementData>}
 *     Promise that resolves to the response
 */
proto.reportpb.StatementServicePromiseClient.prototype.downloadStatementData =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.StatementService/DownloadStatementData',
      request,
      metadata || {},
      methodDescriptor_StatementService_DownloadStatementData);
};


module.exports = proto.reportpb;

