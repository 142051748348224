// source: reportpb/segregation.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.ListSegregationBalanceRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSegregationBalanceResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSegregationLockupRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSegregationLockupResponse', null, global);
goog.exportSymbol('proto.reportpb.ListSegregationRequest', null, global);
goog.exportSymbol('proto.reportpb.ListSegregationResponse', null, global);
goog.exportSymbol('proto.reportpb.Segregation', null, global);
goog.exportSymbol('proto.reportpb.SegregationBalance', null, global);
goog.exportSymbol('proto.reportpb.SegregationLockup', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Segregation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Segregation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Segregation.displayName = 'proto.reportpb.Segregation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SegregationLockup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SegregationLockup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SegregationLockup.displayName = 'proto.reportpb.SegregationLockup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.SegregationBalance = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.SegregationBalance, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.SegregationBalance.displayName = 'proto.reportpb.SegregationBalance';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSegregationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationRequest.displayName = 'proto.reportpb.ListSegregationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSegregationResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSegregationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationResponse.displayName = 'proto.reportpb.ListSegregationResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationBalanceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSegregationBalanceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationBalanceRequest.displayName = 'proto.reportpb.ListSegregationBalanceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationBalanceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSegregationBalanceResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSegregationBalanceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationBalanceResponse.displayName = 'proto.reportpb.ListSegregationBalanceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationLockupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListSegregationLockupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationLockupRequest.displayName = 'proto.reportpb.ListSegregationLockupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListSegregationLockupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListSegregationLockupResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListSegregationLockupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListSegregationLockupResponse.displayName = 'proto.reportpb.ListSegregationLockupResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Segregation.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Segregation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Segregation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Segregation.toObject = function(includeInstance, msg) {
  var f, obj = {
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 5, ""),
    previousSegQty: jspb.Message.getFieldWithDefault(msg, 6, ""),
    segQty: jspb.Message.getFieldWithDefault(msg, 7, ""),
    segAmt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    excessMarginQty: jspb.Message.getFieldWithDefault(msg, 9, ""),
    excessMarginAmt: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Segregation}
 */
proto.reportpb.Segregation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Segregation;
  return proto.reportpb.Segregation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Segregation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Segregation}
 */
proto.reportpb.Segregation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousSegQty(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegQty(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegAmt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMarginQty(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMarginAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Segregation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Segregation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Segregation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Segregation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getPreviousSegQty();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSegQty();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSegAmt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getExcessMarginQty();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getExcessMarginAmt();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional google.type.Date settle_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Segregation.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Segregation} returns this
*/
proto.reportpb.Segregation.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Segregation.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string original_cusip = 2;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string cusip = 4;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol_description = 5;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string previous_seg_qty = 6;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getPreviousSegQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setPreviousSegQty = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string seg_qty = 7;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getSegQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setSegQty = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string seg_amt = 8;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getSegAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setSegAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string excess_margin_qty = 9;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getExcessMarginQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setExcessMarginQty = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string excess_margin_amt = 10;
 * @return {string}
 */
proto.reportpb.Segregation.prototype.getExcessMarginAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Segregation} returns this
 */
proto.reportpb.Segregation.prototype.setExcessMarginAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SegregationLockup.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SegregationLockup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SegregationLockup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SegregationLockup.toObject = function(includeInstance, msg) {
  var f, obj = {
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 4, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 6, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 7, ""),
    quantity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 9, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    previousSegQty: jspb.Message.getFieldWithDefault(msg, 11, ""),
    previousMarginPaidQty: jspb.Message.getFieldWithDefault(msg, 12, ""),
    previousShortPaidQty: jspb.Message.getFieldWithDefault(msg, 13, ""),
    segQty: jspb.Message.getFieldWithDefault(msg, 14, ""),
    marginPaidQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
    shortPaidQty: jspb.Message.getFieldWithDefault(msg, 16, ""),
    segAmt: jspb.Message.getFieldWithDefault(msg, 17, ""),
    marginPaidAmt: jspb.Message.getFieldWithDefault(msg, 18, ""),
    shortPaidAmt: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SegregationLockup}
 */
proto.reportpb.SegregationLockup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SegregationLockup;
  return proto.reportpb.SegregationLockup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SegregationLockup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SegregationLockup}
 */
proto.reportpb.SegregationLockup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuantity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousSegQty(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousMarginPaidQty(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousShortPaidQty(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegQty(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginPaidQty(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortPaidQty(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegAmt(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginPaidAmt(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortPaidAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SegregationLockup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SegregationLockup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SegregationLockup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SegregationLockup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getQuantity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPreviousSegQty();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getPreviousMarginPaidQty();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getPreviousShortPaidQty();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSegQty();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getMarginPaidQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getShortPaidQty();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getSegAmt();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMarginPaidAmt();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getShortPaidAmt();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional google.type.Date settle_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.SegregationLockup.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.SegregationLockup} returns this
*/
proto.reportpb.SegregationLockup.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SegregationLockup.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string original_cusip = 4;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string symbol = 5;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string cusip = 6;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string symbol_description = 7;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string quantity = 8;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getQuantity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setQuantity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string closing_price = 9;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string market_value = 10;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string previous_seg_qty = 11;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getPreviousSegQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setPreviousSegQty = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string previous_margin_paid_qty = 12;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getPreviousMarginPaidQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setPreviousMarginPaidQty = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string previous_short_paid_qty = 13;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getPreviousShortPaidQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setPreviousShortPaidQty = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string seg_qty = 14;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getSegQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setSegQty = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string margin_paid_qty = 15;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getMarginPaidQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setMarginPaidQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string short_paid_qty = 16;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getShortPaidQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setShortPaidQty = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string seg_amt = 17;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getSegAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setSegAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string margin_paid_amt = 18;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getMarginPaidAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setMarginPaidAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string short_paid_amt = 19;
 * @return {string}
 */
proto.reportpb.SegregationLockup.prototype.getShortPaidAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationLockup} returns this
 */
proto.reportpb.SegregationLockup.prototype.setShortPaidAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.SegregationBalance.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.SegregationBalance.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.SegregationBalance} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SegregationBalance.toObject = function(includeInstance, msg) {
  var f, obj = {
    segregationId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 7, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 8, ""),
    adjustedBalance: jspb.Message.getFieldWithDefault(msg, 9, ""),
    loanRate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    loanValue: jspb.Message.getFieldWithDefault(msg, 11, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    lockup: jspb.Message.getFieldWithDefault(msg, 13, ""),
    segAmt: jspb.Message.getFieldWithDefault(msg, 14, ""),
    excessMarginAmt: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.SegregationBalance}
 */
proto.reportpb.SegregationBalance.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.SegregationBalance;
  return proto.reportpb.SegregationBalance.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.SegregationBalance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.SegregationBalance}
 */
proto.reportpb.SegregationBalance.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegregationId(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdjustedBalance(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanRate(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoanValue(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setLockup(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setSegAmt(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setExcessMarginAmt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.SegregationBalance.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.SegregationBalance.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.SegregationBalance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.SegregationBalance.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegregationId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAdjustedBalance();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getLoanRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getLoanValue();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLockup();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getSegAmt();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExcessMarginAmt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional string segregation_id = 1;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getSegregationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setSegregationId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.SegregationBalance.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.SegregationBalance} returns this
*/
proto.reportpb.SegregationBalance.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.SegregationBalance.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string cash_balance = 7;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string short_market_value = 8;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string adjusted_balance = 9;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getAdjustedBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setAdjustedBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string loan_rate = 10;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getLoanRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setLoanRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string loan_value = 11;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getLoanValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setLoanValue = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string long_market_value = 12;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string lockup = 13;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getLockup = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setLockup = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string seg_amt = 14;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getSegAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setSegAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string excess_margin_amt = 15;
 * @return {string}
 */
proto.reportpb.SegregationBalance.prototype.getExcessMarginAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.SegregationBalance} returns this
 */
proto.reportpb.SegregationBalance.prototype.setExcessMarginAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationRequest}
 */
proto.reportpb.ListSegregationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationRequest;
  return proto.reportpb.ListSegregationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationRequest}
 */
proto.reportpb.ListSegregationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reportpb.ListSegregationRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationRequest} returns this
 */
proto.reportpb.ListSegregationRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListSegregationRequest.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListSegregationRequest} returns this
*/
proto.reportpb.ListSegregationRequest.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSegregationRequest} returns this
 */
proto.reportpb.ListSegregationRequest.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSegregationRequest.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSegregationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    segregationsList: jspb.Message.toObjectList(msg.getSegregationsList(),
    proto.reportpb.Segregation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationResponse}
 */
proto.reportpb.ListSegregationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationResponse;
  return proto.reportpb.ListSegregationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationResponse}
 */
proto.reportpb.ListSegregationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Segregation;
      reader.readMessage(value,proto.reportpb.Segregation.deserializeBinaryFromReader);
      msg.addSegregations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegregationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Segregation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Segregation segregations = 1;
 * @return {!Array<!proto.reportpb.Segregation>}
 */
proto.reportpb.ListSegregationResponse.prototype.getSegregationsList = function() {
  return /** @type{!Array<!proto.reportpb.Segregation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Segregation, 1));
};


/**
 * @param {!Array<!proto.reportpb.Segregation>} value
 * @return {!proto.reportpb.ListSegregationResponse} returns this
*/
proto.reportpb.ListSegregationResponse.prototype.setSegregationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Segregation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Segregation}
 */
proto.reportpb.ListSegregationResponse.prototype.addSegregations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Segregation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSegregationResponse} returns this
 */
proto.reportpb.ListSegregationResponse.prototype.clearSegregationsList = function() {
  return this.setSegregationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationBalanceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationBalanceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationBalanceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationBalanceRequest}
 */
proto.reportpb.ListSegregationBalanceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationBalanceRequest;
  return proto.reportpb.ListSegregationBalanceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationBalanceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationBalanceRequest}
 */
proto.reportpb.ListSegregationBalanceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationBalanceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationBalanceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationBalanceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string master_account_no = 1;
 * @return {string}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date settle_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
*/
proto.reportpb.ListSegregationBalanceRequest.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string broker = 5;
 * @return {string}
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationBalanceRequest} returns this
 */
proto.reportpb.ListSegregationBalanceRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSegregationBalanceResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationBalanceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationBalanceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationBalanceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationBalanceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    segregationBalancesList: jspb.Message.toObjectList(msg.getSegregationBalancesList(),
    proto.reportpb.SegregationBalance.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationBalanceResponse}
 */
proto.reportpb.ListSegregationBalanceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationBalanceResponse;
  return proto.reportpb.ListSegregationBalanceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationBalanceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationBalanceResponse}
 */
proto.reportpb.ListSegregationBalanceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SegregationBalance;
      reader.readMessage(value,proto.reportpb.SegregationBalance.deserializeBinaryFromReader);
      msg.addSegregationBalances(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationBalanceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationBalanceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationBalanceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationBalanceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegregationBalancesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SegregationBalance.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SegregationBalance segregation_balances = 1;
 * @return {!Array<!proto.reportpb.SegregationBalance>}
 */
proto.reportpb.ListSegregationBalanceResponse.prototype.getSegregationBalancesList = function() {
  return /** @type{!Array<!proto.reportpb.SegregationBalance>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SegregationBalance, 1));
};


/**
 * @param {!Array<!proto.reportpb.SegregationBalance>} value
 * @return {!proto.reportpb.ListSegregationBalanceResponse} returns this
*/
proto.reportpb.ListSegregationBalanceResponse.prototype.setSegregationBalancesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SegregationBalance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SegregationBalance}
 */
proto.reportpb.ListSegregationBalanceResponse.prototype.addSegregationBalances = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SegregationBalance, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSegregationBalanceResponse} returns this
 */
proto.reportpb.ListSegregationBalanceResponse.prototype.clearSegregationBalancesList = function() {
  return this.setSegregationBalancesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationLockupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationLockupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationLockupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationLockupRequest}
 */
proto.reportpb.ListSegregationLockupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationLockupRequest;
  return proto.reportpb.ListSegregationLockupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationLockupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationLockupRequest}
 */
proto.reportpb.ListSegregationLockupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationLockupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationLockupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationLockupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional google.type.Date settle_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListSegregationLockupRequest} returns this
*/
proto.reportpb.ListSegregationLockupRequest.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListSegregationLockupRequest} returns this
 */
proto.reportpb.ListSegregationLockupRequest.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string original_cusip = 2;
 * @return {string}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationLockupRequest} returns this
 */
proto.reportpb.ListSegregationLockupRequest.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.reportpb.ListSegregationLockupRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListSegregationLockupRequest} returns this
 */
proto.reportpb.ListSegregationLockupRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListSegregationLockupResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListSegregationLockupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListSegregationLockupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListSegregationLockupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationLockupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    segregationLockupsList: jspb.Message.toObjectList(msg.getSegregationLockupsList(),
    proto.reportpb.SegregationLockup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListSegregationLockupResponse}
 */
proto.reportpb.ListSegregationLockupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListSegregationLockupResponse;
  return proto.reportpb.ListSegregationLockupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListSegregationLockupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListSegregationLockupResponse}
 */
proto.reportpb.ListSegregationLockupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.SegregationLockup;
      reader.readMessage(value,proto.reportpb.SegregationLockup.deserializeBinaryFromReader);
      msg.addSegregationLockups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListSegregationLockupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListSegregationLockupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListSegregationLockupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListSegregationLockupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSegregationLockupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.SegregationLockup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SegregationLockup segregation_lockups = 1;
 * @return {!Array<!proto.reportpb.SegregationLockup>}
 */
proto.reportpb.ListSegregationLockupResponse.prototype.getSegregationLockupsList = function() {
  return /** @type{!Array<!proto.reportpb.SegregationLockup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.SegregationLockup, 1));
};


/**
 * @param {!Array<!proto.reportpb.SegregationLockup>} value
 * @return {!proto.reportpb.ListSegregationLockupResponse} returns this
*/
proto.reportpb.ListSegregationLockupResponse.prototype.setSegregationLockupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.SegregationLockup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.SegregationLockup}
 */
proto.reportpb.ListSegregationLockupResponse.prototype.addSegregationLockups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.SegregationLockup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListSegregationLockupResponse} returns this
 */
proto.reportpb.ListSegregationLockupResponse.prototype.clearSegregationLockupsList = function() {
  return this.setSegregationLockupsList([]);
};


goog.object.extend(exports, proto.reportpb);
