/**
 * @fileoverview gRPC-Web generated client stub for accountpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var accountpb_accounttype_pb = require('../accountpb/accounttype_pb.js')

var accountpb_address_pb = require('../accountpb/address_pb.js')

var accountpb_contactinfo_pb = require('../accountpb/contactinfo_pb.js')

var accountpb_owner_pb = require('../accountpb/owner_pb.js')

var accountpb_reportsetup_pb = require('../accountpb/reportsetup_pb.js')

var accountpb_subaccount_pb = require('../accountpb/subaccount_pb.js')

var commonpb_note_pb = require('../commonpb/note_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.accountpb = require('./client_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.ClientServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.accountpb.ClientServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.GetNewClientIdRequest,
 *   !proto.accountpb.GetNewClientIdResponse>}
 */
const methodDescriptor_ClientService_GetNewClientId = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/GetNewClientId',
  grpc.web.MethodType.UNARY,
  proto.accountpb.GetNewClientIdRequest,
  proto.accountpb.GetNewClientIdResponse,
  /**
   * @param {!proto.accountpb.GetNewClientIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.GetNewClientIdResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.GetNewClientIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.GetNewClientIdResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.GetNewClientIdResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.getNewClientId =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/GetNewClientId',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetNewClientId,
      callback);
};


/**
 * @param {!proto.accountpb.GetNewClientIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.GetNewClientIdResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.getNewClientId =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/GetNewClientId',
      request,
      metadata || {},
      methodDescriptor_ClientService_GetNewClientId);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.CreateClientRequest,
 *   !proto.accountpb.CreateClientResponse>}
 */
const methodDescriptor_ClientService_CreateClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/CreateClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.CreateClientRequest,
  proto.accountpb.CreateClientResponse,
  /**
   * @param {!proto.accountpb.CreateClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.CreateClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.CreateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.CreateClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.CreateClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.createClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient,
      callback);
};


/**
 * @param {!proto.accountpb.CreateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.CreateClientResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.createClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/CreateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_CreateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.UpdateClientRequest,
 *   !proto.accountpb.UpdateClientResponse>}
 */
const methodDescriptor_ClientService_UpdateClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/UpdateClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.UpdateClientRequest,
  proto.accountpb.UpdateClientResponse,
  /**
   * @param {!proto.accountpb.UpdateClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.UpdateClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.UpdateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.UpdateClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.UpdateClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.updateClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/UpdateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_UpdateClient,
      callback);
};


/**
 * @param {!proto.accountpb.UpdateClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.UpdateClientResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.updateClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/UpdateClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_UpdateClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ReadClientRequest,
 *   !proto.accountpb.ReadClientResponse>}
 */
const methodDescriptor_ClientService_ReadClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ReadClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ReadClientRequest,
  proto.accountpb.ReadClientResponse,
  /**
   * @param {!proto.accountpb.ReadClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ReadClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ReadClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.ReadClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ReadClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.readClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ReadClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ReadClient,
      callback);
};


/**
 * @param {!proto.accountpb.ReadClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ReadClientResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.readClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ReadClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ReadClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.DeleteClientRequest,
 *   !proto.accountpb.DeleteClientResponse>}
 */
const methodDescriptor_ClientService_DeleteClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/DeleteClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.DeleteClientRequest,
  proto.accountpb.DeleteClientResponse,
  /**
   * @param {!proto.accountpb.DeleteClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.DeleteClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.DeleteClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.DeleteClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.DeleteClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.deleteClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_DeleteClient,
      callback);
};


/**
 * @param {!proto.accountpb.DeleteClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.DeleteClientResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.deleteClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/DeleteClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_DeleteClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.ListClientRequest,
 *   !proto.accountpb.ListClientResponse>}
 */
const methodDescriptor_ClientService_ListClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/ListClient',
  grpc.web.MethodType.UNARY,
  proto.accountpb.ListClientRequest,
  proto.accountpb.ListClientResponse,
  /**
   * @param {!proto.accountpb.ListClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.ListClientResponse.deserializeBinary
);


/**
 * @param {!proto.accountpb.ListClientRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.accountpb.ListClientResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.ListClientResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.listClient =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/accountpb.ClientService/ListClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClient,
      callback);
};


/**
 * @param {!proto.accountpb.ListClientRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.accountpb.ListClientResponse>}
 *     Promise that resolves to the response
 */
proto.accountpb.ClientServicePromiseClient.prototype.listClient =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/accountpb.ClientService/ListClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_ListClient);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.accountpb.StreamClientRequest,
 *   !proto.accountpb.Client>}
 */
const methodDescriptor_ClientService_StreamClient = new grpc.web.MethodDescriptor(
  '/accountpb.ClientService/StreamClient',
  grpc.web.MethodType.SERVER_STREAMING,
  proto.accountpb.StreamClientRequest,
  proto.accountpb.Client,
  /**
   * @param {!proto.accountpb.StreamClientRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.accountpb.Client.deserializeBinary
);


/**
 * @param {!proto.accountpb.StreamClientRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.Client>}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServiceClient.prototype.streamClient =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/accountpb.ClientService/StreamClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_StreamClient);
};


/**
 * @param {!proto.accountpb.StreamClientRequest} request The request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!grpc.web.ClientReadableStream<!proto.accountpb.Client>}
 *     The XHR Node Readable Stream
 */
proto.accountpb.ClientServicePromiseClient.prototype.streamClient =
    function(request, metadata) {
  return this.client_.serverStreaming(this.hostname_ +
      '/accountpb.ClientService/StreamClient',
      request,
      metadata || {},
      methodDescriptor_ClientService_StreamClient);
};


module.exports = proto.accountpb;

