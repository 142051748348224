/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';

/*Service*/
import { ListActivityRequest } from '../../../proto/reportpb/activity_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';
import { CancelTransactionRequest } from '../../../proto/trnspb/transaction_grpc_web_pb';
import { Pagination } from 'proto/utilspb/pagination_pb';

/*Toast Notification*/
import {
  notifyInfo,
  notifySuccess,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import {
  Box,
  TextField,
  MenuItem,
  Modal,
  Button,
  Backdrop,
  Typography,
  Fade,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency, formatDollar } from 'lib/fmt';

/*Material UI Icons*/
import {
  Visibility as ViewIcon,
  CompareArrowsRounded as CancelAndCorrectIcon,
  Block as CancelIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import CsvDownload from '../../../components/Table/CsvDownload';
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import SelectSubEntryType from '../../../components/AutoComplete/SelectEntrySubType';
import EntryTypeSelect from '../../../components/AutoComplete/EntryType';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import SearchButton from '../../../components/Button/Search';
import ServerSideTableBodyFooter from '../../../components/Table/ServerSideTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

import { meta } from 'services/TransactionService';
import {
  protoTransactionMetadataLocalFees,
  protoTransactionMetadataToString,
} from 'services/TransactionMetadata';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ActivityTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();
  const confirm = useConfirm();

  const {
    profileServiceClient: profileService,
    transactionServiceClient: transaction,
    activityServiceClient: activity,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const [pagination, setPagination] = useState({
    count: 0,
    rowsPerPage: 50,
    pageNo: 0,
    reload: false,
    sortName: '',
    sortDirection: '',
  });
  const [footerData, setFooterData] = useState({});
  const [filterList, setFilterList] = useState({});

  const search = async (dontNotifySuccess) => {
    let paginationCopy = {
      ...pagination,
      reload: false,
    };
    paginationCopy.pageNo = dontNotifySuccess ? paginationCopy.pageNo : 0;

    const pathWithParams =
      currentPath +
      '?search=true' +
      (correspondentValue ? '&correspondent=' + correspondentValue : '') +
      (accountNoValue ? '&accountNo=' + accountNoValue : '') +
      (masterAccountNoValue ? '&masterAccountNo=' + masterAccountNoValue : '') +
      (contraAccountNoValue ? '&contraAccountNo=' + contraAccountNoValue : '') +
      (accountDesignatorValue
        ? '&accountDesignator=' + accountDesignatorValue
        : '') +
      (brokerValue ? '&broker=' + brokerValue : '') +
      (typeValue ? '&type=' + typeValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '') +
      (cusipValue ? '&cusip=' + cusipValue : '') +
      (statusValue ? '&status=' + statusValue : '') +
      (entryTypeValue ? '&entryType=' + entryTypeValue : '') +
      (entrySubTypeValue ? '&entrySubType=' + entrySubTypeValue : '') +
      (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
      (fromDateValue ? '&fromDate=' + fromDateValue : '') +
      (toDateValue ? '&toDate=' + toDateValue : '') +
      (trnsIdValue ? '&trnsId=' + trnsIdValue : '') +
      (netAmtValue ? '&netAmt=' + netAmtValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let paging = new Pagination();
    paging.setPageNo(paginationCopy.pageNo);
    paging.setPageSize(paginationCopy.rowsPerPage);
    paging.setSortName(paginationCopy.sortName);
    paging.setSortDirection(paginationCopy.sortDirection);

    try {
      const allData = await getData(paging, false);
      paginationCopy.count = allData.summary.totalRows;
      setPagination(paginationCopy);
      setFooterData({
        qty: allData.summary.qty,
        grossAmt: formatDollar(allData.summary.grossAmt),
        regFee: formatDollar(allData.summary.regFee),
        commission: formatDollar(allData.summary.commission),
        tafFee: formatDollar(allData.summary.tafFee),
        fees: formatDollar(allData.summary.fees),
        netAmt: formatDollar(allData.summary.netAmt),
        leavesQty: formatDollar(allData.summary.leavesQty),
      });

      const rows = allData.list;

      setRows(rows);
      if (!dontNotifySuccess) {
        notifyInfo(allData.summary.totalRows + ' search results.');
      }
    } catch (error) {
      notifyError(error.message);
    }

    setLoading(false);
  };

  const getData = async (paging) => {
    let list = new ListActivityRequest();
    list.setCorrespondent(correspondentValue);
    list.setAccountNo(accountNoValue);
    list.setMasterAccountNo(masterAccountNoValue);
    list.setContraAccountNo(contraAccountNoValue);
    list.setAccountDesignator(accountDesignatorValue);
    list.setBroker(brokerValue);
    list.setType(typeValue);
    list.setSymbol(symbolValue);
    list.setCusip(cusipValue);
    list.setDateType(dateTypeValue);
    list.setEntryType(entryTypeValue);
    list.setEntrySubType(entrySubTypeValue);
    if (netAmtValue) {
      list.setNetAmt(netAmtValue);
    }
    if (fromDateValue) {
      let gglFromDate = new google_date.Date();
      let [y, m, d] = fromDateValue.split('-');
      gglFromDate.setYear(y);
      gglFromDate.setMonth(m);
      gglFromDate.setDay(d);

      list.setFromDate(gglFromDate);
    }
    if (toDateValue) {
      let gglToDate = new google_date.Date();
      let [y, m, d] = toDateValue.split('-');
      gglToDate.setYear(y);
      gglToDate.setMonth(m);
      gglToDate.setDay(d);

      list.setToDate(gglToDate);
    }
    list.setStatus(statusValue);
    list.setTrnsId(trnsIdValue);

    if (paging) {
      list.setPagination(paging);
    }

    return new Promise((resolve, reject) => {
      activity.listActivity(list, {}, (err, res) => {
        if (err) {
          reject(err);
        } else {
          const d = res.toObject();

          resolve({
            summary: d.summary,
            list: res.getActivitiesList().map((activity) => {
              const data = activity.toObject();
              return {
                trnsId: data.trnsId,
                accountId: data.accountId,
                correspondent: data.correspondent,
                accountNo: data.accountNo,
                masterAccountNo: data.masterAccountNo,
                accountName: data.accountName,
                accountDesignator: data.accountDesignator,
                broker: data.broker,
                type: data.type,
                capacity: data.capacity,
                representative: data.representative,
                symbol: data.symbol,
                symbolDescription: data.symbolDescription,
                cusip: data.cusip,
                assetType: data.assetType,
                dateType: data.dateType,
                contraAccountNo: data.contraAccountNo,
                tradeDate: formatPbDate(data.tradeDate),
                tradeAt: data.tradeAt
                  ? moment
                    .tz(
                      new Date(data.tradeAt.seconds * 1000),
                      'America/New_York'
                    )
                    .format('hh:mm:ssa')
                  : '--',
                settleDate: formatPbDate(data.settleDate),
                createdAt: data.createdAt
                  ? moment(new Date(data.createdAt.seconds * 1000)).format(
                    'MM/DD/YYYY hh:mm:ssa'
                  )
                  : '--',
                entryType: data.entryType,
                entrySubType: data.entrySubType,
                side: data.side,
                qty: data.qty,
                price: formatCurrency(data.price, ''),
                grossAmt: formatCurrency(data.grossAmt, ''),
                regFee: formatCurrency(data.regFee, ''),
                commission: formatCurrency(data.commission, ''),
                tafFee: formatCurrency(data.tafFee, ''),
                fees: formatCurrency(data.fees, ''),
                netAmt: formatCurrency(data.netAmt, ''),
                description: data.description,
                batchNo: data.batchNo,
                previousTrnId: data.previousTrnId,
                status: data.status,
                createdBy: data.createdBy,
                executionId: data.executionId,
                externalId: data.externalId,
                orderId: data.orderId,
                leavesQty: data.leavesQty,
                vendor: data.vendor,
                systemDate: formatPbDate(data.systemDate),
                contraAccountDesignator: data.contraAccountDesignator,
                contraCorrespondent: data.contraCorrespondent,
                contraAccountId: data.contraAccountId,
                executingVenue: data.executingVenue,
                originalCusip: data.originalCusip,
                entryTypeDescription: data.entryTypeDescription,
                refId: data.refId,
                metadata: protoTransactionMetadataToString(
                  activity.getMetadata()
                ),
                currency: data.contraSymbol,
                swapRate: data.swapRate,
                localPrice: formatCurrency(data.localPrice, ''),
                localGrossAmt: formatCurrency(data.localGrossAmt, ''),
                localFees: formatCurrency(
                  data.fees.includes('-')
                    ? Number(
                      protoTransactionMetadataLocalFees(
                        activity.getMetadata()
                      )
                    ) * -1
                    : protoTransactionMetadataLocalFees(activity.getMetadata()),
                  ''
                ),
                localNetAmt: formatCurrency(data.localNetAmt, ''),
              };
            }),
          });
        }
      });
    });
  };

  const cancelTransaction = (transaction, trnsId, confirm) => {
    confirm({
      description: 'You are about to cancel this transaction, please confirm.',
      confirmationText: 'Yes, Cancel Transaction',
      cancellationText: 'No',
    })
      .then(() => {
        let cancelReq = new CancelTransactionRequest();
        cancelReq.setTrnsId(trnsId);
        transaction.cancelTransaction(cancelReq, meta, (err) => {
          if (err) {
            notifyError(err.message);
            return;
          }
          search(true);
          notifySuccess('Transaction has been canceled.');
          setLoading(true);
        });
      })
      .catch(() => {
        /* ... */
      });
  };

  const cancelAndCorrectTransaction = (accountId, trnsId) => {
    const paramPath =
      '/app/trns/cancel-and-correct/' + trnsId + '/' + accountId;

    window.open(paramPath, '_blank');
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('Activity');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        notifyError(err.message);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const amtColor = (v) => {
    // Remove commas in string number to make comparison work correctly
    return { color: v.replace(/,/g, '') < 0 && '#f44336' };
  };

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className={classes.grdRow} style={{ margin: 0 }}>
              <div className={classes.grdCell1}>
                <Tooltip title="Cancel" arrow>
                  <div>
                    <IconButton
                      aria-label="cancel"
                      disabled={
                        rows[dataIndex].status === 'canceled' ? true : false
                      }
                      onClick={() => {
                        cancelTransaction(
                          transaction,
                          rows[dataIndex].trnsId,
                          confirm
                        );
                      }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
              <div className={classes.grdCell1}>
                <Tooltip title="Correct" arrow>
                  <div>
                    <IconButton
                      aria-label="cancelcorrect"
                      disabled={
                        rows[dataIndex].status === 'canceled' ? true : false
                      }
                      onClick={() => {
                        cancelAndCorrectTransaction(
                          rows[dataIndex].accountId,
                          rows[dataIndex].trnsId
                        );
                      }}
                    >
                      <CancelAndCorrectIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
              <div className={classes.grdCell1}>
                <Tooltip title="View" arrow>
                  <div>
                    <IconButton
                      aria-label="view"
                      onClick={() => {
                        setRowsSelected(rows[dataIndex]);
                        handleOpen();
                      }}
                    >
                      <ViewIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px' },
        }),
      },
    },
    {
      name: 'accountDesignator',
      label: 'Account Designator',
    },
    {
      name: 'accountName',
      label: 'Account Name',
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'assetType',
      label: 'Asset Type',
    },
    {
      name: 'batchNo',
      label: 'Batch No',
    },
    {
      name: 'broker',
      label: 'Broker',
    },
    {
      name: 'capacity',
      label: 'Capacity',
    },
    {
      name: 'commission',
      label: 'Commission',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].commission.replace('$', '');
          const f = rows[dataIndex].commission;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'contraAccountNo',
      label: 'Contra Account No',
    },
    {
      name: 'contraAccountDesignator',
      label: 'Contra Account Designator',
    },
    {
      name: 'contraCorrespondent',
      label: 'Contra Correspondent',
    },
    {
      name: 'correspondent',
      label: 'Correspondent',
    },
    {
      name: 'createdAt',
      label: 'Created At',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].createdAt ? rows[dataIndex].createdAt : '--';
        },
      },
    },
    {
      name: 'createdBy',
      label: 'Created By',
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'description',
      label: 'Description',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].description;
          return (
            <div>
              {v.length > 15 ? (
                <Tooltip title={v} arrow>
                  <div>{`${v.substring(0, 15) + '...'}`}</div>
                </Tooltip>
              ) : (
                v
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'entryType',
      label: 'Entry Type',
    },
    {
      name: 'entrySubType',
      label: 'Entry Subtype',
    },
    {
      name: 'executionId',
      label: 'Execution ID',
    },
    {
      name: 'externalId',
      label: 'External ID',
    },
    {
      name: 'fees',
      label: 'Fees',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const usd = rows[dataIndex].fees;
          const lct = rows[dataIndex].currency
            ? rows[dataIndex].localFees
            : rows[dataIndex].fees;
          return (
            <div align="right" style={amtColor(isUSD ? usd : lct)}>
              {isUSD ? usd : lct}
            </div>
          );
        },
      },
    },
    {
      name: 'grossAmt',
      label: 'Gross Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const usd = rows[dataIndex].grossAmt;
          const lct = rows[dataIndex].currency
            ? rows[dataIndex].localGrossAmt
            : rows[dataIndex].grossAmt;
          return (
            <div align="right" style={amtColor(isUSD ? usd : lct)}>
              {isUSD ? usd : lct}
            </div>
          );
        },
      },
    },
    {
      name: 'leavesQty',
      label: 'Leaves Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].leavesQty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'masterAccountNo',
      label: 'Master Account No',
    },
    {
      name: 'netAmt',
      label: 'Net Amount',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const usd = rows[dataIndex].netAmt;
          const lct = rows[dataIndex].currency
            ? rows[dataIndex].localNetAmt
            : rows[dataIndex].netAmt;
          return (
            <div align="right" style={amtColor(isUSD ? usd : lct)}>
              {isUSD ? usd : lct}
            </div>
          );
        },
      },
    },
    {
      name: 'orderId',
      label: 'Order ID',
    },
    {
      name: 'previousTrnId',
      label: 'Previous Transaction ID',
    },
    {
      name: 'price',
      label: 'Price',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const usd = rows[dataIndex].price;
          const lct = rows[dataIndex].currency
            ? rows[dataIndex].localPrice
            : rows[dataIndex].price;
          return (
            <div align="right" style={amtColor(isUSD ? usd : lct)}>
              {isUSD ? usd : lct}
            </div>
          );
        },
      },
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const f = rows[dataIndex].qty;
          return (
            <div align="right" style={amtColor(f)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'regFee',
      label: 'Reg Fee',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].regFee.replace('$', '');
          const f = rows[dataIndex].regFee;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'representative',
      label: 'Representative',
    },
    {
      name: 'settleDate',
      label: 'Settle Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].settleDate ? rows[dataIndex].settleDate : '--';
        },
      },
    },
    {
      name: 'side',
      label: 'Side',
    },
    {
      name: 'status',
      label: 'Status',
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].symbolDescription;
          return (
            <div>
              {v.length > 15 ? (
                <Tooltip title={v} arrow>
                  <div>{`${v.substring(0, 15) + '...'}`}</div>
                </Tooltip>
              ) : (
                v
              )}
            </div>
          );
        },
      },
    },
    {
      name: 'systemDate',
      label: 'System Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].systemDate ? rows[dataIndex].systemDate : '--';
        },
      },
    },
    {
      name: 'tafFee',
      label: 'Taf Fee',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const v = rows[dataIndex].tafFee.replace('$', '');
          const f = rows[dataIndex].tafFee;
          return (
            <div align="right" style={amtColor(v)}>
              {f}
            </div>
          );
        },
      },
    },
    {
      name: 'tradeAt',
      label: 'Trade At',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeAt ? rows[dataIndex].tradeAt : '--';
        },
      },
    },
    {
      name: 'tradeDate',
      label: 'Trade Date',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return rows[dataIndex].tradeDate ? rows[dataIndex].tradeDate : '--';
        },
      },
    },
    {
      name: 'type',
      label: 'Type',
    },
    {
      name: 'vendor',
      label: 'Vendor',
    },
    {
      name: 'trnsId',
      label: 'Trns ID',
    },
    {
      name: 'contraAccountId',
      label: 'Contra Account ID',
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'entryTypeDescription',
      label: 'Entry Type Description',
    },
    {
      name: 'executingVenue',
      label: 'Executing Venue',
    },
    {
      name: 'refId',
      label: 'Ref ID',
    },
    {
      name: 'metadata',
      label: 'Metadata',
    },
    {
      name: 'currency',
      label: 'Currency',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return isUSD
            ? 'USD'
            : rows[dataIndex].currency.replace('$', '') || 'USD';
        },
      },
    },
    {
      name: 'swapRate',
      label: 'Swap Rate',
      options: {
        customBodyRenderLite: (dataIndex, a, c) => {
          return isUSD ? '1.0' : rows[dataIndex].swapRate || '1.0';
        },
      },
    },
  ];

  const fileName =
    'Activity_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    serverSide: true,
    count: pagination.count,
    page: pagination.pageNo,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000, 15000, 30000, 100000],
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [...Array(52).keys()],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: pagination.rowsPerPage,
    customToolbar: function () {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      setOpenCsvDownload(true);
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('Activity');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('Activity');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;

      switch (action) {
        case 'changePage':
          setPagination({
            ...pagination,
            pageNo: tableState.page,
            reload: true,
          });
          break;
        case 'changeRowsPerPage':
          setPagination({
            ...pagination,
            rowsPerPage: tableState.rowsPerPage,
            reload: true,
          });
          break;
        case 'sort':
          setPagination({
            ...pagination,
            sortDirection: tableState.sortOrder.direction,
            sortName: tableState.sortOrder.name,
            reload: true,
          });
          break;
        case 'filterChange':
          {
            let filter = {};
            tableState.filterList.forEach((item, index) => {
              if (item.length) {
                filter[columns[index].name] = item[0];
              }
            });
            setFilterList(filter);
          }
          break;
        default:
      }
    },
    customTableBodyFooterRender: function (opts) {
      return (
        <ServerSideTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          data={footerData}
        ></ServerSideTableBodyFooter>
      );
    },
  };

  const profile = useProfile();
  const [openCsvDownload, setOpenCsvDownload] = React.useState(false);
  const [rowsSelected, setRowsSelected] = React.useState([]);
  const [rows, setRows] = useState([]);
  const [correspondentValue, setCorrespondentValue] = useState('');
  const [accountNoValue, setAccountNoValue] = useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = useState('');
  const [contraAccountNoValue, setContraAccountNoValue] = useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = useState('');
  const [brokerValue, setBrokerValue] = useState('');
  const [typeValue, setTypeValue] = useState('');
  const [symbolValue, setSymbolValue] = useState('');
  const [cusipValue, setCusipValue] = useState('');
  const [dateTypeValue, setDateTypeValue] = useState('system_date');
  const [fromDateValue, setFromDateValue] = useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [toDateValue, setToDateValue] = useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [statusValue, setStatusValue] = useState('');
  const [entryTypeValue, setEntryTypeValue] = useState('');
  const [entrySubTypeValue, setEntrySubTypeValue] = useState('');
  const [netAmtValue, setNetAmtValue] = useState('');
  const [trnsIdValue, setTrnsIdValue] = useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isUSD, setIsUSD] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      contraAccountNo: query.get('contraAccountNo'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      type: query.get('type'),
      symbol: query.get('symbol'),
      cusip: query.get('cusip'),
      status: query.get('status'),
      entryType: query.get('entryType'),
      entrySubType: query.get('entrySubType'),
      dateType: query.get('dateType'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
      netAmt: query.get('netAmt'),
      trnsId: query.get('trnsId'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.contraAccountNo) {
      setContraAccountNoValue(data.contraAccountNo);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.type) {
      setTypeValue(data.type);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.cusip) {
      setCusipValue(data.cusip);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.entryType) {
      setEntryTypeValue(data.entryType);
    }
    if (data.entrySubType) {
      setEntrySubTypeValue(data.entrySubType);
    }
    if (data.dateType) {
      setDateTypeValue(data.dateType);
    }
    if (data.netAmt) {
      setNetAmtValue(data.netAmt);
    }
    if (data.trnsId) {
      setTrnsIdValue(data.trnsId);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    setCurrentPath(path);
    // eslint-disable-next-line
  }, [profileService, params]);

  useEffect(() => {
    if (pagination.reload) {
      search(true);
    }
    // eslint-disable-next-line
  }, [
    pagination.pageNo,
    pagination.rowsPerPage,
    pagination.reload,
    pagination.sortName,
    pagination.sortDirection,
  ]);

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterRows = () => {
    const filterKeys = Object.keys(filterList);
    if (!filterKeys.length) {
      return rows;
    }

    const filtered = rows.filter((r) => {
      let ok = true;
      filterKeys.forEach((key) => {
        if (filterList[key] !== r[key]) {
          ok = false;
          return;
        }
      });

      return ok;
    });

    return filtered;
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="correspondent"
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="accountNo"
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="masterAccountNo"
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  type="accountNo"
                  freeSolo={true}
                  name="contraAccount"
                  label="Contra Account"
                  value={contraAccountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setContraAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="account_designator">
                  Account Designator
                </InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Broker</InputLabel>
                <Select
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Type</InputLabel>
                <Select
                  fullWidth
                  value={typeValue}
                  onChange={(e) => setTypeValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="client">Client</MenuItem>
                  <MenuItem value="gl">GL</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSymbol
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  type="symbol"
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Cusip"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={cusipValue}
                  onChange={(e) => setCusipValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="executed">Executed</MenuItem>
                  <MenuItem value="canceled">Canceled</MenuItem>
                  <MenuItem value="correct">Correct</MenuItem>
                </Select>
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <EntryTypeSelect
                  name="entryType"
                  label="Entry Type"
                  value={entryTypeValue}
                  onChange={(e) => {
                    if (e.currentTarget.value !== 'WH') {
                      setEntrySubTypeValue('');
                    }
                    setEntryTypeValue(e.currentTarget.value);
                  }}
                // autopopulate={isAutoPopulate}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSubEntryType
                  name="entrySubType"
                  label="Entry Subtype"
                  value={entrySubTypeValue}
                  onChange={(e) => {
                    setEntrySubTypeValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => {
                    setDateTypeValue(e.target.value);
                  }}
                >
                  <MenuItem value="system_date">System Date</MenuItem>
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                // inputProps={{
                //   max: toDateValue,
                // }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                // inputProps={{
                //   min: fromDateValue,
                // }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Net Amt"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={netAmtValue}
                  onChange={(e) => setNetAmtValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Transaction ID"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={trnsIdValue}
                  onChange={(e) => setTrnsIdValue(e.target.value)}
                />
              </div>
              {entryTypeValue === 'WH' && (
                <div className={classes.grdCell1}>
                  <InputLabel shrink>Entry Subtype</InputLabel>
                  <Select
                    fullWidth
                    value={entrySubTypeValue}
                    onChange={(e) => setEntrySubTypeValue(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Blank</em>
                    </MenuItem>
                    <MenuItem value="FWH">FWH</MenuItem>
                    <MenuItem value="SWH">SWH</MenuItem>
                  </Select>
                </div>
              )}
              <div
                className={classes.grdCell1}
                style={{ marginRight: 30 }}
              ></div>
              <div
                className={classes.grdCell1}
                style={{ marginRight: 30 }}
              ></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div
          className={classes.actionContainer}
          style={{ alignItems: 'flex-end' }}
        >
          <div className={classes.grdCellNone} style={{ marginRight: '10px' }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setIsUSD(!isUSD)}
            >
              {isUSD ? 'Show Local Currency' : 'Show USD'}
            </Button>
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {openCsvDownload && (
          <CsvDownload
            getData={getData}
            filename={fileName}
            dataKey="list"
            breaks={false}
            open={openCsvDownload}
            onClose={() => {
              setOpenCsvDownload(false);
            }}
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Activity'}
            data={filterRows()}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={(classes.modalBackdrop, classes.modalScrollable)}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              View Activity Details
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <Typography
                      variant="h6"
                      className={classes.textBold}
                      gutterBottom
                    >
                      TRNS
                    </Typography>
                  </div>
                  <div className={classes.grdCell1}></div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Account ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.accountId}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Correspondent"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.correspondent}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Account No"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.accountNo}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Account Name"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.accountName}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Master Account No"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.masterAccountNo}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Account Designator"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.accountDesignator}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Broker"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.broker}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Type"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.type}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Capacity"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.capacity}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Representative"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.representative}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Contra Account ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.contraAccountId}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Contra Correspondent"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.contraCorrespondent}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Contra Account No"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.contraAccountNo}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Transaction ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.trnsId}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Previous Transaction ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.previousTrnId}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Status"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.status}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="System Date"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.systemDate}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Trade Date"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.tradeDate}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Trade At"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.tradeAt}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Settle Date"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.settleDate}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Original Cusip"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.originalCusip}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Symbol"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.symbol}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Symbol Description"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.symbolDescription}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Cusip"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.cusip}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Asset Type"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.assetType}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Entry Type"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.entryType}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Entry Type Description"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.entryTypeDescription}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Side"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.side}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Quantity"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.qty}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Price"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.price}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Gross Amount"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.grossAmt}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Reg Fee"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.regFee}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Taf Fee"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.tafFee}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Commission"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.commission}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Fees"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.fees}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Net Amount"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.netAmt}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Contra Account Designator"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.contraAccountDesignator}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Description"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.description}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Execution ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.executionId}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="External ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.externalId}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Leaves Quantity"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.leavesQty}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Order ID"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.orderId}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Executing Venue"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.executingVenue}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Vendor"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.vendor}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Batch No"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.batchNo}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Created by"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.createdBy}
                    />
                  </div>
                </div>

                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Created At"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.createdAt}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Entry Subtype"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.entrySubType}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Contra Symbol"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.contraSymbol}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Swap Rate"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.swapRate}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Ref Id"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.refId}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Local Price"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.localPrice}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Local Gross Amount"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.localGrossAmt}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Local Net Amount"
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{ shrink: true }}
                      value={rowsSelected.localNetAmt}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
