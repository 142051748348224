// source: reportpb/dailytradeconfirm.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var commonpb_file_pb = require('../commonpb/file_pb.js');
goog.object.extend(proto, commonpb_file_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reportpb.DailyTradeConfirmation', null, global);
goog.exportSymbol('proto.reportpb.DownloadDailyTradeConfirmationRequest', null, global);
goog.exportSymbol('proto.reportpb.DownloadDailyTradeConfirmationResponse', null, global);
goog.exportSymbol('proto.reportpb.ListDailyTradeConfirmRequest', null, global);
goog.exportSymbol('proto.reportpb.ListDailyTradeConfirmResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.DailyTradeConfirmation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.DailyTradeConfirmation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.DailyTradeConfirmation.displayName = 'proto.reportpb.DailyTradeConfirmation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListDailyTradeConfirmRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListDailyTradeConfirmRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListDailyTradeConfirmRequest.displayName = 'proto.reportpb.ListDailyTradeConfirmRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListDailyTradeConfirmResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListDailyTradeConfirmResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListDailyTradeConfirmResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListDailyTradeConfirmResponse.displayName = 'proto.reportpb.ListDailyTradeConfirmResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.DownloadDailyTradeConfirmationRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.DownloadDailyTradeConfirmationRequest.displayName = 'proto.reportpb.DownloadDailyTradeConfirmationRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.DownloadDailyTradeConfirmationResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.DownloadDailyTradeConfirmationResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.DownloadDailyTradeConfirmationResponse.displayName = 'proto.reportpb.DownloadDailyTradeConfirmationResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.DailyTradeConfirmation.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.DailyTradeConfirmation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.DailyTradeConfirmation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DailyTradeConfirmation.toObject = function(includeInstance, msg) {
  var f, obj = {
    trnsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tradeConfirmCount: jspb.Message.getFieldWithDefault(msg, 7, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    entryType: jspb.Message.getFieldWithDefault(msg, 9, ""),
    type: jspb.Message.getFieldWithDefault(msg, 10, ""),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.DailyTradeConfirmation}
 */
proto.reportpb.DailyTradeConfirmation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.DailyTradeConfirmation;
  return proto.reportpb.DailyTradeConfirmation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.DailyTradeConfirmation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.DailyTradeConfirmation}
 */
proto.reportpb.DailyTradeConfirmation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeConfirmCount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.DailyTradeConfirmation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.DailyTradeConfirmation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.DailyTradeConfirmation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DailyTradeConfirmation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTradeConfirmCount();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string trns_id = 1;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string correspondent = 3;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_no = 4;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_name = 5;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string master_account_no = 6;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string trade_confirm_count = 7;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getTradeConfirmCount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setTradeConfirmCount = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string net_amt = 8;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string entry_type = 9;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string type = 10;
 * @return {string}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.type.Date trade_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.DailyTradeConfirmation.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
*/
proto.reportpb.DailyTradeConfirmation.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.DailyTradeConfirmation} returns this
 */
proto.reportpb.DailyTradeConfirmation.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.DailyTradeConfirmation.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListDailyTradeConfirmRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListDailyTradeConfirmRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListDailyTradeConfirmRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest}
 */
proto.reportpb.ListDailyTradeConfirmRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListDailyTradeConfirmRequest;
  return proto.reportpb.ListDailyTradeConfirmRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListDailyTradeConfirmRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest}
 */
proto.reportpb.ListDailyTradeConfirmRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListDailyTradeConfirmRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListDailyTradeConfirmRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListDailyTradeConfirmRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string account_id = 1;
 * @return {string}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_no = 3;
 * @return {string}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional google.type.Date from_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
*/
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.Date to_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
*/
proto.reportpb.ListDailyTradeConfirmRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListDailyTradeConfirmRequest} returns this
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListDailyTradeConfirmRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 7) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListDailyTradeConfirmResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListDailyTradeConfirmResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListDailyTradeConfirmResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListDailyTradeConfirmResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListDailyTradeConfirmResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    dailyTradeConfirmationsList: jspb.Message.toObjectList(msg.getDailyTradeConfirmationsList(),
    proto.reportpb.DailyTradeConfirmation.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListDailyTradeConfirmResponse}
 */
proto.reportpb.ListDailyTradeConfirmResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListDailyTradeConfirmResponse;
  return proto.reportpb.ListDailyTradeConfirmResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListDailyTradeConfirmResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListDailyTradeConfirmResponse}
 */
proto.reportpb.ListDailyTradeConfirmResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.DailyTradeConfirmation;
      reader.readMessage(value,proto.reportpb.DailyTradeConfirmation.deserializeBinaryFromReader);
      msg.addDailyTradeConfirmations(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListDailyTradeConfirmResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListDailyTradeConfirmResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListDailyTradeConfirmResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListDailyTradeConfirmResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDailyTradeConfirmationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.DailyTradeConfirmation.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DailyTradeConfirmation daily_trade_confirmations = 1;
 * @return {!Array<!proto.reportpb.DailyTradeConfirmation>}
 */
proto.reportpb.ListDailyTradeConfirmResponse.prototype.getDailyTradeConfirmationsList = function() {
  return /** @type{!Array<!proto.reportpb.DailyTradeConfirmation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.DailyTradeConfirmation, 1));
};


/**
 * @param {!Array<!proto.reportpb.DailyTradeConfirmation>} value
 * @return {!proto.reportpb.ListDailyTradeConfirmResponse} returns this
*/
proto.reportpb.ListDailyTradeConfirmResponse.prototype.setDailyTradeConfirmationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.DailyTradeConfirmation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.DailyTradeConfirmation}
 */
proto.reportpb.ListDailyTradeConfirmResponse.prototype.addDailyTradeConfirmations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.DailyTradeConfirmation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListDailyTradeConfirmResponse} returns this
 */
proto.reportpb.ListDailyTradeConfirmResponse.prototype.clearDailyTradeConfirmationsList = function() {
  return this.setDailyTradeConfirmationsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.DownloadDailyTradeConfirmationRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationRequest}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.DownloadDailyTradeConfirmationRequest;
  return proto.reportpb.DownloadDailyTradeConfirmationRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationRequest}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.DownloadDailyTradeConfirmationRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string trade_date = 1;
 * @return {string}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.getTradeDate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationRequest} returns this
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_id = 2;
 * @return {string}
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationRequest} returns this
 */
proto.reportpb.DownloadDailyTradeConfirmationRequest.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.DownloadDailyTradeConfirmationResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    filesList: jspb.Message.toObjectList(msg.getFilesList(),
    commonpb_file_pb.File.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationResponse}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.DownloadDailyTradeConfirmationResponse;
  return proto.reportpb.DownloadDailyTradeConfirmationResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationResponse}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commonpb_file_pb.File;
      reader.readMessage(value,commonpb_file_pb.File.deserializeBinaryFromReader);
      msg.addFiles(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.DownloadDailyTradeConfirmationResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.DownloadDailyTradeConfirmationResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      commonpb_file_pb.File.serializeBinaryToWriter
    );
  }
};


/**
 * repeated commonpb.File files = 1;
 * @return {!Array<!proto.commonpb.File>}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.getFilesList = function() {
  return /** @type{!Array<!proto.commonpb.File>} */ (
    jspb.Message.getRepeatedWrapperField(this, commonpb_file_pb.File, 1));
};


/**
 * @param {!Array<!proto.commonpb.File>} value
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationResponse} returns this
*/
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.setFilesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.commonpb.File=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commonpb.File}
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.addFiles = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.commonpb.File, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.DownloadDailyTradeConfirmationResponse} returns this
 */
proto.reportpb.DownloadDailyTradeConfirmationResponse.prototype.clearFilesList = function() {
  return this.setFilesList([]);
};


goog.object.extend(exports, proto.reportpb);
