// source: reorgpb/announcement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.Announcement', null, global);
goog.exportSymbol('proto.reorgpb.GetAnnoucementRequest', null, global);
goog.exportSymbol('proto.reorgpb.GetAnnoucementResponse', null, global);
goog.exportSymbol('proto.reorgpb.ListAnnouncementRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListAnnouncementResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.Announcement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.Announcement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.Announcement.displayName = 'proto.reorgpb.Announcement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListAnnouncementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListAnnouncementRequest.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListAnnouncementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListAnnouncementRequest.displayName = 'proto.reorgpb.ListAnnouncementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.GetAnnoucementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.GetAnnoucementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.GetAnnoucementRequest.displayName = 'proto.reorgpb.GetAnnoucementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.GetAnnoucementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.GetAnnoucementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.GetAnnoucementResponse.displayName = 'proto.reorgpb.GetAnnoucementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListAnnouncementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListAnnouncementResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListAnnouncementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListAnnouncementResponse.displayName = 'proto.reorgpb.ListAnnouncementResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.Announcement.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.Announcement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.Announcement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Announcement.toObject = function(includeInstance, msg) {
  var f, obj = {
    caType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    textNumber: jspb.Message.getFieldWithDefault(msg, 2, ""),
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    initiatingSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    oldRate: jspb.Message.getFieldWithDefault(msg, 5, ""),
    newRate: jspb.Message.getFieldWithDefault(msg, 6, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    effectiveDate: (f = msg.getEffectiveDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    recordDate: (f = msg.getRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    payableDate: (f = msg.getPayableDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    targetOriginalCusip: jspb.Message.getFieldWithDefault(msg, 14, ""),
    initiatingOriginalCusip: jspb.Message.getFieldWithDefault(msg, 15, ""),
    caSubType: jspb.Message.getFieldWithDefault(msg, 16, ""),
    cash: jspb.Message.getFieldWithDefault(msg, 17, ""),
    declarationDate: (f = msg.getDeclarationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    annoucementId: jspb.Message.getFieldWithDefault(msg, 19, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.Announcement}
 */
proto.reorgpb.Announcement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.Announcement;
  return proto.reorgpb.Announcement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.Announcement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.Announcement}
 */
proto.reorgpb.Announcement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setEffectiveDate(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setRecordDate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPayableDate(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPositionDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOriginalCusip(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingOriginalCusip(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setCaSubType(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setCash(value);
      break;
    case 18:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setDeclarationDate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnoucementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.Announcement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.Announcement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.Announcement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.Announcement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCaType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getInitiatingSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getOldRate();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getRecordDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPayableDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTargetOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getInitiatingOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getCaSubType();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getDeclarationDate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAnnoucementId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
};


/**
 * optional string ca_type = 1;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getCaType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setCaType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string text_number = 2;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string target_symbol = 3;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string initiating_symbol = 4;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getInitiatingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setInitiatingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string old_rate = 5;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getOldRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setOldRate = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string new_rate = 6;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional google.type.Date system_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.type.Date effective_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getEffectiveDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearEffectiveDate = function() {
  return this.setEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasEffectiveDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.type.Date expiration_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.Date record_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearRecordDate = function() {
  return this.setRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasRecordDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.type.Date payable_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getPayableDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setPayableDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearPayableDate = function() {
  return this.setPayableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasPayableDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.type.Date process_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.type.Date position_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getPositionDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setPositionDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearPositionDate = function() {
  return this.setPositionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasPositionDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string target_original_cusip = 14;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getTargetOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setTargetOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string initiating_original_cusip = 15;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getInitiatingOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setInitiatingOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string ca_sub_type = 16;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getCaSubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setCaSubType = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string cash = 17;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setCash = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional google.type.Date declaration_date = 18;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.Announcement.prototype.getDeclarationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 18));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.Announcement} returns this
*/
proto.reorgpb.Announcement.prototype.setDeclarationDate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.clearDeclarationDate = function() {
  return this.setDeclarationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.Announcement.prototype.hasDeclarationDate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string annoucement_id = 19;
 * @return {string}
 */
proto.reorgpb.Announcement.prototype.getAnnoucementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.Announcement} returns this
 */
proto.reorgpb.Announcement.prototype.setAnnoucementId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListAnnouncementRequest.repeatedFields_ = [1,12,14];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListAnnouncementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListAnnouncementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListAnnouncementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbolList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    fromExpirationDate: (f = msg.getFromExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toExpirationDate: (f = msg.getToExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    fromRecordDate: (f = msg.getFromRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toRecordDate: (f = msg.getToRecordDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    fromPayableDate: (f = msg.getFromPayableDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toPayableDate: (f = msg.getToPayableDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    fromEffectiveDate: (f = msg.getFromEffectiveDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toEffectiveDate: (f = msg.getToEffectiveDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    caTypesList: (f = jspb.Message.getRepeatedField(msg, 12)) == null ? undefined : f,
    cusip: jspb.Message.getFieldWithDefault(msg, 13, ""),
    originalCusipList: (f = jspb.Message.getRepeatedField(msg, 14)) == null ? undefined : f,
    fromDeclarationDate: (f = msg.getFromDeclarationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDeclarationDate: (f = msg.getToDeclarationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    textNumber: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListAnnouncementRequest}
 */
proto.reorgpb.ListAnnouncementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListAnnouncementRequest;
  return proto.reorgpb.ListAnnouncementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListAnnouncementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListAnnouncementRequest}
 */
proto.reorgpb.ListAnnouncementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromExpirationDate(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToExpirationDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromRecordDate(value);
      break;
    case 7:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToRecordDate(value);
      break;
    case 8:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromPayableDate(value);
      break;
    case 9:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToPayableDate(value);
      break;
    case 10:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromEffectiveDate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToEffectiveDate(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.addCaTypes(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.addOriginalCusip(value);
      break;
    case 15:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDeclarationDate(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDeclarationDate(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListAnnouncementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListAnnouncementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListAnnouncementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbolList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFromExpirationDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToExpirationDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFromRecordDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToRecordDate();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFromPayableDate();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToPayableDate();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getFromEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToEffectiveDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCaTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      12,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getOriginalCusipList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      14,
      f
    );
  }
  f = message.getFromDeclarationDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDeclarationDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * repeated string symbol = 1;
 * @return {!Array<string>}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getSymbolList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.setSymbolList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.addSymbol = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearSymbolList = function() {
  return this.setSymbolList([]);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.type.Date from_expiration_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromExpirationDate = function() {
  return this.setFromExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromExpirationDate = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.type.Date to_expiration_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToExpirationDate = function() {
  return this.setToExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToExpirationDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date from_record_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromRecordDate = function() {
  return this.setFromRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromRecordDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional google.type.Date to_record_date = 7;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToRecordDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 7));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToRecordDate = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToRecordDate = function() {
  return this.setToRecordDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToRecordDate = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional google.type.Date from_payable_date = 8;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromPayableDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 8));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromPayableDate = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromPayableDate = function() {
  return this.setFromPayableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromPayableDate = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional google.type.Date to_payable_date = 9;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToPayableDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 9));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToPayableDate = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToPayableDate = function() {
  return this.setToPayableDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToPayableDate = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.type.Date from_effective_date = 10;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromEffectiveDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 10));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromEffectiveDate = function() {
  return this.setFromEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromEffectiveDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.type.Date to_effective_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToEffectiveDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToEffectiveDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToEffectiveDate = function() {
  return this.setToEffectiveDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToEffectiveDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated string ca_types = 12;
 * @return {!Array<string>}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getCaTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 12));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.setCaTypesList = function(value) {
  return jspb.Message.setField(this, 12, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.addCaTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 12, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearCaTypesList = function() {
  return this.setCaTypesList([]);
};


/**
 * optional string cusip = 13;
 * @return {string}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * repeated string original_cusip = 14;
 * @return {!Array<string>}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getOriginalCusipList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 14));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.setOriginalCusipList = function(value) {
  return jspb.Message.setField(this, 14, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.addOriginalCusip = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 14, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearOriginalCusipList = function() {
  return this.setOriginalCusipList([]);
};


/**
 * optional google.type.Date from_declaration_date = 15;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getFromDeclarationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 15));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setFromDeclarationDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearFromDeclarationDate = function() {
  return this.setFromDeclarationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasFromDeclarationDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.type.Date to_declaration_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getToDeclarationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
*/
proto.reorgpb.ListAnnouncementRequest.prototype.setToDeclarationDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.clearToDeclarationDate = function() {
  return this.setToDeclarationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.hasToDeclarationDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string text_number = 17;
 * @return {string}
 */
proto.reorgpb.ListAnnouncementRequest.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListAnnouncementRequest} returns this
 */
proto.reorgpb.ListAnnouncementRequest.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.GetAnnoucementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.GetAnnoucementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.GetAnnoucementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.GetAnnoucementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    annoucementId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.GetAnnoucementRequest}
 */
proto.reorgpb.GetAnnoucementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.GetAnnoucementRequest;
  return proto.reorgpb.GetAnnoucementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.GetAnnoucementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.GetAnnoucementRequest}
 */
proto.reorgpb.GetAnnoucementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnoucementId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.GetAnnoucementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.GetAnnoucementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.GetAnnoucementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.GetAnnoucementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnoucementId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string annoucement_id = 1;
 * @return {string}
 */
proto.reorgpb.GetAnnoucementRequest.prototype.getAnnoucementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.GetAnnoucementRequest} returns this
 */
proto.reorgpb.GetAnnoucementRequest.prototype.setAnnoucementId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.GetAnnoucementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.GetAnnoucementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.GetAnnoucementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.GetAnnoucementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    annoucement: (f = msg.getAnnoucement()) && proto.reorgpb.Announcement.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.GetAnnoucementResponse}
 */
proto.reorgpb.GetAnnoucementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.GetAnnoucementResponse;
  return proto.reorgpb.GetAnnoucementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.GetAnnoucementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.GetAnnoucementResponse}
 */
proto.reorgpb.GetAnnoucementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Announcement;
      reader.readMessage(value,proto.reorgpb.Announcement.deserializeBinaryFromReader);
      msg.setAnnoucement(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.GetAnnoucementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.GetAnnoucementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.GetAnnoucementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.GetAnnoucementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnoucement();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.Announcement.serializeBinaryToWriter
    );
  }
};


/**
 * optional Announcement annoucement = 1;
 * @return {?proto.reorgpb.Announcement}
 */
proto.reorgpb.GetAnnoucementResponse.prototype.getAnnoucement = function() {
  return /** @type{?proto.reorgpb.Announcement} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.Announcement, 1));
};


/**
 * @param {?proto.reorgpb.Announcement|undefined} value
 * @return {!proto.reorgpb.GetAnnoucementResponse} returns this
*/
proto.reorgpb.GetAnnoucementResponse.prototype.setAnnoucement = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.GetAnnoucementResponse} returns this
 */
proto.reorgpb.GetAnnoucementResponse.prototype.clearAnnoucement = function() {
  return this.setAnnoucement(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.GetAnnoucementResponse.prototype.hasAnnoucement = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListAnnouncementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListAnnouncementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListAnnouncementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListAnnouncementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListAnnouncementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    announcementsList: jspb.Message.toObjectList(msg.getAnnouncementsList(),
    proto.reorgpb.Announcement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListAnnouncementResponse}
 */
proto.reorgpb.ListAnnouncementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListAnnouncementResponse;
  return proto.reorgpb.ListAnnouncementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListAnnouncementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListAnnouncementResponse}
 */
proto.reorgpb.ListAnnouncementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.Announcement;
      reader.readMessage(value,proto.reorgpb.Announcement.deserializeBinaryFromReader);
      msg.addAnnouncements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListAnnouncementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListAnnouncementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListAnnouncementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListAnnouncementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAnnouncementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.Announcement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Announcement announcements = 1;
 * @return {!Array<!proto.reorgpb.Announcement>}
 */
proto.reorgpb.ListAnnouncementResponse.prototype.getAnnouncementsList = function() {
  return /** @type{!Array<!proto.reorgpb.Announcement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.Announcement, 1));
};


/**
 * @param {!Array<!proto.reorgpb.Announcement>} value
 * @return {!proto.reorgpb.ListAnnouncementResponse} returns this
*/
proto.reorgpb.ListAnnouncementResponse.prototype.setAnnouncementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.Announcement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.Announcement}
 */
proto.reorgpb.ListAnnouncementResponse.prototype.addAnnouncements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.Announcement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListAnnouncementResponse} returns this
 */
proto.reorgpb.ListAnnouncementResponse.prototype.clearAnnouncementsList = function() {
  return this.setAnnouncementsList([]);
};


goog.object.extend(exports, proto.reorgpb);
