/**
 * @fileoverview gRPC-Web generated client stub for reportpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.reportpb = require('./accountmanagement_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.AccountManagementServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reportpb.AccountManagementServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListAccountManagementRequest,
 *   !proto.reportpb.ListAccountManagementResponse>}
 */
const methodDescriptor_AccountManagementService_ListAccountManagement = new grpc.web.MethodDescriptor(
  '/reportpb.AccountManagementService/ListAccountManagement',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListAccountManagementRequest,
  proto.reportpb.ListAccountManagementResponse,
  /**
   * @param {!proto.reportpb.ListAccountManagementRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListAccountManagementResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListAccountManagementRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListAccountManagementResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListAccountManagementResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.AccountManagementServiceClient.prototype.listAccountManagement =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagement',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagement,
      callback);
};


/**
 * @param {!proto.reportpb.ListAccountManagementRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListAccountManagementResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.AccountManagementServicePromiseClient.prototype.listAccountManagement =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagement',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagement);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListAccountManagementBalanceDetailRequest,
 *   !proto.reportpb.ListAccountManagementBalanceDetailResponse>}
 */
const methodDescriptor_AccountManagementService_ListAccountManagementBalanceDetail = new grpc.web.MethodDescriptor(
  '/reportpb.AccountManagementService/ListAccountManagementBalanceDetail',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListAccountManagementBalanceDetailRequest,
  proto.reportpb.ListAccountManagementBalanceDetailResponse,
  /**
   * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListAccountManagementBalanceDetailResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListAccountManagementBalanceDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListAccountManagementBalanceDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.AccountManagementServiceClient.prototype.listAccountManagementBalanceDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementBalanceDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementBalanceDetail,
      callback);
};


/**
 * @param {!proto.reportpb.ListAccountManagementBalanceDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListAccountManagementBalanceDetailResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.AccountManagementServicePromiseClient.prototype.listAccountManagementBalanceDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementBalanceDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementBalanceDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListAccountManagementPositionDetailRequest,
 *   !proto.reportpb.ListAccountManagementPositionDetailResponse>}
 */
const methodDescriptor_AccountManagementService_ListAccountManagementPositionDetail = new grpc.web.MethodDescriptor(
  '/reportpb.AccountManagementService/ListAccountManagementPositionDetail',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListAccountManagementPositionDetailRequest,
  proto.reportpb.ListAccountManagementPositionDetailResponse,
  /**
   * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListAccountManagementPositionDetailResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListAccountManagementPositionDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListAccountManagementPositionDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.AccountManagementServiceClient.prototype.listAccountManagementPositionDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementPositionDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementPositionDetail,
      callback);
};


/**
 * @param {!proto.reportpb.ListAccountManagementPositionDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListAccountManagementPositionDetailResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.AccountManagementServicePromiseClient.prototype.listAccountManagementPositionDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementPositionDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementPositionDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListAccountManagementMarginReqDetailRequest,
 *   !proto.reportpb.ListAccountManagementMarginReqDetailResponse>}
 */
const methodDescriptor_AccountManagementService_ListAccountManagementMarginReqDetail = new grpc.web.MethodDescriptor(
  '/reportpb.AccountManagementService/ListAccountManagementMarginReqDetail',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListAccountManagementMarginReqDetailRequest,
  proto.reportpb.ListAccountManagementMarginReqDetailResponse,
  /**
   * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListAccountManagementMarginReqDetailResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListAccountManagementMarginReqDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListAccountManagementMarginReqDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.AccountManagementServiceClient.prototype.listAccountManagementMarginReqDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementMarginReqDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementMarginReqDetail,
      callback);
};


/**
 * @param {!proto.reportpb.ListAccountManagementMarginReqDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListAccountManagementMarginReqDetailResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.AccountManagementServicePromiseClient.prototype.listAccountManagementMarginReqDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementMarginReqDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementMarginReqDetail);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reportpb.ListAccountManagementSegregationDetailRequest,
 *   !proto.reportpb.ListAccountManagementSegregationDetailResponse>}
 */
const methodDescriptor_AccountManagementService_ListAccountManagementSegregationDetail = new grpc.web.MethodDescriptor(
  '/reportpb.AccountManagementService/ListAccountManagementSegregationDetail',
  grpc.web.MethodType.UNARY,
  proto.reportpb.ListAccountManagementSegregationDetailRequest,
  proto.reportpb.ListAccountManagementSegregationDetailResponse,
  /**
   * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reportpb.ListAccountManagementSegregationDetailResponse.deserializeBinary
);


/**
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reportpb.ListAccountManagementSegregationDetailResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reportpb.ListAccountManagementSegregationDetailResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reportpb.AccountManagementServiceClient.prototype.listAccountManagementSegregationDetail =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementSegregationDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementSegregationDetail,
      callback);
};


/**
 * @param {!proto.reportpb.ListAccountManagementSegregationDetailRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reportpb.ListAccountManagementSegregationDetailResponse>}
 *     Promise that resolves to the response
 */
proto.reportpb.AccountManagementServicePromiseClient.prototype.listAccountManagementSegregationDetail =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reportpb.AccountManagementService/ListAccountManagementSegregationDetail',
      request,
      metadata || {},
      methodDescriptor_AccountManagementService_ListAccountManagementSegregationDetail);
};


module.exports = proto.reportpb;

