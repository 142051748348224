/*Services*/
import {
  Account,
  UpdateAccountRequest,
  ListAccountRequest,
} from '../../../proto/bankpb/account_grpc_web_pb.js';

import {
  CreateBankAddressRequest,
  ListBankAddressRequest,
} from '../../../proto/bankpb/address_grpc_web_pb.js';

import { ReadPrimaryOwnerRequest } from '../../../proto/commonpb/list_grpc_web_pb';

/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  IconButton,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
} from '@material-ui/core';

/*Toast Notification*/
import {
  notifyError,
  notifyInfo,
  notifySuccess,
  notifyWarning,
} from '../../../components/Notification/Notification';

/*Material UI Icons*/
import { Add as AddIcon, Create as EditIcon } from '@material-ui/icons';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import BankAddressSelect from '../../../components/AutoComplete/BankAddress';
import StateSelect from './../../../components/Dropdown/State';
import SearchButton from '../../../components/Button/Search';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';
import { ServiceContext } from 'context/ServiceContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

/*=========================================================================================
Add and Update bank account function
===========================================================================================*/
function saveBankAccount(
  bankAccount,
  rows,
  isAdd,
  closeModal,
  isBankOwnerNameValid,
  bankId,
  bankAddressId,
  bankAddress,
  correspondent,
  accountNo,
  bankOwnerName,
  bankName,
  achRoutingNo,
  wireRoutingNo,
  bankAccountNo,
  bankAccountType,
  approvedMethod,
  paidAccessToken,
  status,
  bankIdentifierCode
) {
  if (isAdd) {
    if (!correspondent) {
      notifyError('Correspondent is required.');
      return;
    }
    if (!accountNo) {
      notifyError('Account no. is required.');
      return;
    }
    if (!bankOwnerName) {
      notifyError('Bank owner name is required.');
      return;
    }
    if (!bankName) {
      notifyError('Bank name is required.');
      return;
    }
    if (!bankAccountNo) {
      notifyError('Bank account no. is required.');
      return;
    }
    if (!bankAccountType) {
      notifyError('Bank account type is required.');
      return;
    }
    if (!approvedMethod) {
      notifyError('Approved method is required.');
      return;
    }
    if (!paidAccessToken && approvedMethod === 'PLAID') {
      notifyError('Access token is required.');
      return;
    }
    if (!bankIdentifierCode || !wireRoutingNo || !achRoutingNo) {
      if (!bankIdentifierCode && !wireRoutingNo && !achRoutingNo) {
        notifyError(
          'Enter either ACH routing no., Wire routing no, or Bank identifier code.'
        );
        return;
      }
      if (achRoutingNo && achRoutingNo.length < 9) {
        notifyError('ACH routing no. should be 9 digits');
        return;
      }
      if (wireRoutingNo && wireRoutingNo.length < 9) {
        notifyError('Wire routing no. should be 9 digits');
        return;
      }
    }
    if (!bankAddress && wireRoutingNo) {
      notifyError('Address is required.');
      return;
    }

    let addReq = new Account();
    addReq.setBankAddressId(bankAddress);
    addReq.setCorrespondent(correspondent);
    addReq.setAccountNo(accountNo);
    addReq.setBankOwnerName(bankOwnerName);
    addReq.setBankName(bankName);
    if (achRoutingNo) {
      addReq.setAchRoutingNo(achRoutingNo);
    }
    if (wireRoutingNo) {
      addReq.setWireRoutingNo(wireRoutingNo);
    }
    addReq.setBankAccountNo(bankAccountNo);
    addReq.setBankAccountType(bankAccountType);
    addReq.setApprovedMethod(approvedMethod);
    addReq.setPaidAccessToken(paidAccessToken);
    addReq.setStatus(status);
    if (bankIdentifierCode) {
      addReq.setBankIdentifierCode(bankIdentifierCode);
    }

    bankAccount.createAccount(addReq, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        return;
      }

      const data = res.toObject().bankAccount;
      const newRow = {
        accountNo: data.accountNo,
        achRoutingNo: data.achRoutingNo,
        approvedMethod: data.approvedMethod,
        bankAccountNo: data.bankAccountNo,
        bankAccountType: data.bankAccountType,
        bankId: data.bankId,
        bankName: data.bankName,
        bankOwnerName: data.bankOwnerName,
        correspondent: data.correspondent,
        createdAt: data.createdAt
          ? moment
              .tz(new Date(data.createdAt.seconds * 1000), 'America/New_York')
              .format('hh:mm:ss')
          : '',
        createdBy: data.createdBy,
        masterAccountNo: data.masterAccountNo,
        note: data.note,
        paidAccessToken: data.paidAccessToken,
        bankIdentifierCode: data.bankIdentifierCode,
        status: data.status,
        wireRoutingNo: data.wireRoutingNo,
      };

      rows.push(newRow);
      if (isBankOwnerNameValid) {
        notifySuccess('Bank account has been added.');
      } else {
        notifyWarning('Primary owner is not set in the client account.');
      }

      closeModal();
    });
  } else {
    if (!bankAddress && !bankAddressId && wireRoutingNo) {
      notifyError('Address is required.');
      return;
    }
    let updateReq = new UpdateAccountRequest();
    updateReq.setBankId(bankId);
    updateReq.setBankAddressId(bankAddress ? bankAddress : bankAddressId);
    updateReq.setStatus(status);

    bankAccount.updateAccount(updateReq, {}, (err) => {
      if (err) {
        console.error('err', err.message);
        notifyError(err.message);
        return;
      }
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].bankId === bankId) {
          rows[i].status = status;
          rows[i].bankAddressId = bankAddress ? bankAddress : bankAddressId;
        }
      }
      notifySuccess('Bank account has been updated.');
      closeModal();
    });
  }
}
/*=========================================================================================
End of add and update bank account function
===========================================================================================*/

/*=========================================================================================
Add Bank Address function
===========================================================================================*/
function saveBankAddress(
  bankaddress,
  closeModal,
  bankName,
  bankRoutingNo,
  address,
  city,
  zipCode,
  state,
  status,
  setBankAddressList
) {
  if (!bankName) {
    notifyError('Bank name is required.');
    return;
  }
  if (!bankRoutingNo) {
    notifyError('Bank routing no is required.');
    return;
  }

  let addReq = new CreateBankAddressRequest();
  addReq.setBankName(bankName);
  addReq.setBankRoutingNo(bankRoutingNo);
  addReq.setAddress(address);
  addReq.setCity(city);
  addReq.setZipCode(zipCode);
  addReq.setState(state);
  addReq.setStatus(status);

  bankaddress.createBankAddress(addReq, {}, (err, res) => {
    if (err) {
      notifyError(err.message);
    } else {
      loadBankAddress(bankaddress, setBankAddressList);
      notifySuccess('New bank address has been added.');
      closeModal();
    }
  });
}
/*=========================================================================================
Add Bank Address function
===========================================================================================*/

/*=========================================================================================
Load Bank Address function
===========================================================================================*/
function loadBankAddress(bankaddress, setBankAddressList) {
  let listReq = new ListBankAddressRequest();
  bankaddress.listBankAddress(listReq, {}, (err, res) => {
    if (err) {
      console.log(err);
      return;
    } else {
      let data = res.toObject().bankAddressesList;
      setBankAddressList(data);
    }
  });
}
/*=========================================================================================
End of load Bank Address function
===========================================================================================*/

export default function BankAccountTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  const {
    listAccountServiceClient: accounts,
    accountServiceClient: bankAccount,
    bankAddressServiceClient: bankaddress,
  } = useContext(ServiceContext);

  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountNo:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          achRoutingNo:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          approvedMethod:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          bankAccountNo:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          bankAccountType:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          bankIdentifierCode:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          bankName:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          bankOwnerName:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          correspondent:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          createdAt:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          createdBy:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          masterAccountNo:
            columnFilter[11].value !== undefined
              ? [columnFilter[11].value]
              : [],
          paidAccessToken:
            columnFilter[12].value !== undefined
              ? [columnFilter[12].value]
              : [],
          status:
            columnFilter[13].value !== undefined
              ? [columnFilter[13].value]
              : [],
          systemDate:
            columnFilter[14].value !== undefined
              ? [columnFilter[14].value]
              : [],
          wireRoutingNo:
            columnFilter[15].value !== undefined
              ? [columnFilter[15].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (key === 'createdAt') {
                if (item[key] !== undefined) {
                  item[key] =
                    typeof item[key] === 'object'
                      ? moment(new Date(item[key].seconds * 1000)).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : item[key];
                } else {
                  item[key] = '--';
                }
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          accountNo: data.accountNo,
          achRoutingNo: data.achRoutingNo,
          approvedMethod: data.approvedMethod,
          bankAccountNo: data.bankAccountNo,
          bankAccountType: data.bankAccountType,
          bankId: data.bankId,
          bankName: data.bankName,
          bankOwnerName: data.bankOwnerName,
          correspondent: data.correspondent,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          masterAccountNo: data.masterAccountNo,
          note: data.note,
          paidAccessToken: data.paidAccessToken,
          bankIdentifierCode: data.bankIdentifierCode,
          status: data.status,
          wireRoutingNo: data.wireRoutingNo,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          bankAddressId: data.bankAddressId,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          accountNo: data.accountNo,
          achRoutingNo: data.achRoutingNo,
          approvedMethod: data.approvedMethod,
          bankAccountNo: data.bankAccountNo,
          bankAccountType: data.bankAccountType,
          bankId: data.bankId,
          bankName: data.bankName,
          bankOwnerName: data.bankOwnerName,
          correspondent: data.correspondent,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          masterAccountNo: data.masterAccountNo,
          note: data.note,
          paidAccessToken: data.paidAccessToken,
          bankIdentifierCode: data.bankIdentifierCode,
          status: data.status,
          wireRoutingNo: data.wireRoutingNo,
          systemDate:
            typeof data.systemDate === 'object'
              ? formatPbDate(data.systemDate)
              : data.systemDate,
          bankAddressId: data.bankAddressId,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**Search Bank Account function ==================================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      setLoading(true);

      const pathWithParams =
        currentPath +
        '?search=true' +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (achRoutingNoValue ? '&achRoutingNo=' + achRoutingNoValue : '') +
        (bankNameValue ? '&bankName=' + bankNameValue : '') +
        (bankAccountNoValue ? '&bankAccountNo=' + bankAccountNoValue : '') +
        (bankOwnerNameValue ? '&bankOwnerName=' + bankOwnerNameValue : '') +
        (statusValue ? '&status=' + statusValue : '') +
        (fromSystemDateValue ? '&fromSystemDate=' + fromSystemDateValue : '') +
        (toSystemDateValue ? '&toSystemDate=' + toSystemDateValue : '');
      window.history.pushState({}, null, pathWithParams);

      let list = new ListAccountRequest();
      list.setCorrespondent(correspondentValue);
      list.setMasterAccountNo(masterAccountNoValue);
      list.setAccountNo(accountNoValue);
      list.setBankOwnerName(bankOwnerNameValue);
      list.setBankName(bankNameValue);
      list.setAchRoutingNo(achRoutingNoValue);
      list.setBankAccountNo(bankAccountNoValue);
      list.setStatus(statusValue);

      if (fromSystemDateValue) {
        let gglFromSystemDate = new google_date.Date();
        let [y, m, d] = fromSystemDateValue.split('-');
        gglFromSystemDate.setYear(y);
        gglFromSystemDate.setMonth(m);
        gglFromSystemDate.setDay(d);
        list.setFromSystemDate(gglFromSystemDate);
      }

      if (toSystemDateValue) {
        let gglToSystemDate = new google_date.Date();
        let [y, m, d] = toSystemDateValue.split('-');
        gglToSystemDate.setYear(y);
        gglToSystemDate.setMonth(m);
        gglToSystemDate.setDay(d);
        list.setToSystemDate(gglToSystemDate);
      }

      bankAccount.listAccount(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().bankAccountsList;
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**Search Bank Account function end ==============================================================*/

  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);
  /*=========================================================================================
  Table configuration
  ===========================================================================================*/
  const columns = [
    {
      id: '1',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '2',
      name: 'achRoutingNo',
      label: 'Ach Routing No',
      visibleState: useState(false),
      align: 'left',
      order: 5,
    },
    {
      id: '3',
      name: 'approvedMethod',
      label: 'Approved Method',
      visibleState: useState(true),
      align: 'left',
      order: 9,
    },
    {
      id: '4',
      name: 'bankAccountNo',
      label: 'Bank Account No',
      visibleState: useState(true),
      align: 'left',
      order: 7,
    },
    {
      id: '5',
      name: 'bankAccountType',
      label: 'Bank Account Type',
      visibleState: useState(true),
      align: 'left',
      order: 8,
    },
    {
      id: '6',
      name: 'bankIdentifierCode',
      label: 'BIC',
      visibleState: useState(false),
      align: 'left',
      order: 15,
    },
    {
      id: '7',
      name: 'bankName',
      label: 'Bank Name',
      visibleState: useState(false),
      align: 'left',
      order: 4,
    },
    {
      id: '8',
      name: 'bankOwnerName',
      label: 'Bank Owner Name',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '9',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '10',
      name: 'createdAt',
      label: 'Created At',
      visibleState: useState(false),
      align: 'left',
      order: 13,
    },
    {
      id: '11',
      name: 'createdBy',
      label: 'Created By',
      visibleState: useState(false),
      align: 'left',
      order: 12,
    },
    {
      id: '12',
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '13',
      name: 'paidAccessToken',
      label: 'Paid Access Token',
      visibleState: useState(true),
      align: 'left',
      order: 10,
    },
    {
      id: '11',
      name: 'status',
      label: 'Status',
      visibleState: useState(true),
      align: 'left',
      order: 11,
    },
    {
      id: '15',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(true),
      align: 'left',
      order: 14,
    },
    {
      id: '16',
      name: 'wireRoutingNo',
      label: 'Wire Routing No',
      visibleState: useState(false),
      align: 'left',
      order: 6,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={rows[rowIndex].bankId}>
        <TableCell padding="checkbox">
          <IconButton
            aria-label="edit"
            onClick={() => handleOpen(rows[rowIndex], false)}
          >
            <EditIcon />
          </IconButton>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'BankAccount_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    downloadOptions: {
      filename: fileName,
    },
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [8, 11, 0, 7, 6, 1, 15, 3, 4, 2, 12, 13, 10, 9, 14, 5],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table configuration
  ===========================================================================================*/
  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };

  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [bankId, setBankId] = React.useState('');
  const [correspondentValue, setCorrespondentValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [bankOwnerNameValue, setBankOwnerNameValue] = React.useState('');
  const [bankNameValue, setBankNameValue] = React.useState('');
  const [achRoutingNoValue, setAchRoutingNoValue] = React.useState('');
  const [bankAccountNoValue, setBankAccountNoValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [fromSystemDateValue, setFromSystemDateValue] = React.useState('');
  const [toSystemDateValue, setToSystemDateValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  const [bankAddressList, setBankAddressList] = React.useState('');

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      bankOwnerName: query.get('bankOwnerName'),
      bankName: query.get('bankName'),
      achRoutingNo: query.get('achRoutingNo'),
      bankAccountNo: query.get('bankAccountNo'),
      status: query.get('status'),
      fromSystemDate: query.get('fromSystemDate'),
      toSystemDate: query.get('toSystemDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.bankOwnerName) {
      setBankOwnerNameValue(data.bankOwnerName);
    }
    if (data.bankName) {
      setBankNameValue(data.bankName);
    }
    if (data.achRoutingNo) {
      setAchRoutingNoValue(data.achRoutingNo);
    }
    if (data.bankAccountNo) {
      setBankAccountNoValue(data.bankAccountNo);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.fromSystemDate) {
      setFromSystemDateValue(data.fromSystemDate);
    }
    if (data.toSystemDate) {
      setToSystemDateValue(data.toSystemDate);
    }

    setCurrentPath(path);

    //load bank addresses
    loadBankAddress(bankaddress, setBankAddressList);
  }, [bankaddress, params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalCorrespondentValue, setModalCorrespondentValue] = React.useState(
    ''
  );
  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [modalBankOwnerNameValue, setModalBankOwnerNameValue] = React.useState(
    ''
  );
  const [modalBankNameValue, setmodalBankNameValue] = React.useState('');
  const [modalAchRoutingNoValue, setmodalAchRoutingNoValue] = React.useState(
    ''
  );
  const [modalWireRoutingNoValue, setmodalWireRoutingNoValue] = React.useState(
    ''
  );
  const [modalBankAccountNoValue, setModalBankAccountNoValue] = React.useState(
    ''
  );
  const [modalBankAddressIdValue, setModalBankAddressIdValue] = React.useState(
    ''
  );
  const [
    modalBankAccountTypeValue,
    setModalBankAccountTypeValue,
  ] = React.useState('');
  const [modalBankAddressValue, setModalBankAddressValue] = React.useState('');
  const [
    modalApprovedMethodValue,
    setModalApprovedMethodValue,
  ] = React.useState('');
  const [modalPaidAccessToken, setModalPaidAccessToken] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState('');
  const [
    modalBankIdentifierCodeValue,
    setModalBankIdentifierCodeValue,
  ] = React.useState('');

  const [isBankOwnerNameValid, setIsBankOwnerNameValid] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalCorrespondentValue('');
      setModalAccountNoValue('');
      setModalBankOwnerNameValue('');
      setmodalBankNameValue('');
      setmodalAchRoutingNoValue('');
      setmodalWireRoutingNoValue('');
      setModalBankAccountNoValue('');
      setModalBankAccountTypeValue('');
      setModalApprovedMethodValue('');
      setModalPaidAccessToken('');
      setModalBankAddressIdValue('');
      setModalStatusValue('pending_approval');
      setModalBankIdentifierCodeValue('');

      setModalAddAction(true);
      setIsDisabled(false);
      setModalTitleValue('Add New');
    } else {
      console.log(rowData);
      setBankId(rowData.bankId);
      setModalCorrespondentValue(rowData.correspondent);
      setModalAccountNoValue(rowData.accountNo);
      setModalBankOwnerNameValue(rowData.bankOwnerName);
      setmodalBankNameValue(rowData.bankName);
      setmodalAchRoutingNoValue(rowData.achRoutingNo);
      setmodalWireRoutingNoValue(rowData.wireRoutingNo);
      setModalBankAccountNoValue(rowData.bankAccountNo);
      setModalBankAccountTypeValue(rowData.bankAccountType);
      setModalApprovedMethodValue(rowData.approvedMethod);
      setModalPaidAccessToken(rowData.paidAccessToken);
      setModalStatusValue(rowData.status);
      setModalBankAddressIdValue(rowData.bankAddressId);
      setModalBankIdentifierCodeValue(rowData.bankIdentifierCode);

      setModalAddAction(false);
      setIsDisabled(true);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setModalCorrespondentValue('');
    setModalAccountNoValue('');
    setModalBankOwnerNameValue('');
    setIsBankOwnerNameValid(false);

    setOpen(false);
  };

  const [
    modalAddressBankNameValue,
    setModalAddressBankNameValue,
  ] = React.useState('');
  const [modalBankRoutingNoValue, setModalBankRoutingNoValue] = React.useState(
    ''
  );
  const [modalAddressValue, setModalAddressValue] = React.useState('');
  const [modalCityValue, setModalCityValue] = React.useState('');
  const [modalZipCodeValue, setModalZipCodeValue] = React.useState('');
  const [modalStateValue, setModalStateValue] = React.useState('');
  const [modalAddressStatusValue, setModalAddressStatusValue] = React.useState(
    ''
  );
  const [openBankAddressModal, setOpenBankAddressModal] = React.useState(false);

  const handleOpen2 = () => {
    setModalAddressBankNameValue(modalBankNameValue);
    setModalBankRoutingNoValue('');
    setModalAddressValue('');
    setModalCityValue('');
    setModalZipCodeValue('');
    setModalStateValue('');
    setOpenBankAddressModal(true);
  };

  const handleClose2 = () => {
    setOpenBankAddressModal(false);
  };

  const loadPrimaryOwner = (correspondent, accountNo) => {
    const primaryOwnerReq = new ReadPrimaryOwnerRequest();
    primaryOwnerReq.setCorrespondent(correspondent);
    primaryOwnerReq.setAccountNo(accountNo);

    accounts.readPrimaryOwner(primaryOwnerReq, {}, (err, res) => {
      if (err) {
        if (modalBankOwnerNameValue !== '') {
          setIsBankOwnerNameValid(false);
          return;
        }
        setIsBankOwnerNameValid(false);
        console.error(err);
        notifyError(err.message);
        return;
      }
      if (res.toObject().fullName) {
        setIsBankOwnerNameValid(true);
        setModalBankOwnerNameValue(res.toObject().fullName);
      } else {
        setIsBankOwnerNameValid(false);
      }
    });
  };

  if (modalCorrespondentValue && modalAccountNoValue) {
    if (!isBankOwnerNameValid) {
      loadPrimaryOwner(modalCorrespondentValue, modalAccountNoValue);
    }
  }

  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="ACH Routing No"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={achRoutingNoValue}
                  onChange={(e) => setAchRoutingNoValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  label="Bank Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={bankNameValue}
                  onChange={(e) => setBankNameValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Bank Account No"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={bankAccountNoValue}
                  onChange={(e) => setBankAccountNoValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Bank Owner Name"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={bankOwnerNameValue}
                  onChange={(e) => setBankOwnerNameValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="pending_approval">Pending Apporval</MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                  <MenuItem value="denied">Denied</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From System Date"
                  type="date"
                  value={fromSystemDateValue}
                  onChange={(e) => setFromSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toSystemDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="To System Date"
                  type="date"
                  value={toSystemDateValue}
                  onChange={(e) => setToSystemDateValue(e.target.value)}
                  className={classes.textField}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromSystemDateValue,
                  }}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Bank Accounts'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalLg}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} Bank Account
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    {modalAddAction ? (
                      <CorrespondentSelect
                        freeSolo={false}
                        required={true}
                        name="correspondent"
                        label="Correspondent"
                        value={modalCorrespondentValue}
                        onChange={(e) => {
                          setModalCorrespondentValue(e.currentTarget.value);
                        }}
                      />
                    ) : (
                      <TextField
                        label="Correspondent"
                        required
                        fullWidth={true}
                        disabled={isDisabled}
                        value={modalCorrespondentValue}
                        onChange={(e) =>
                          setModalCorrespondentValue(e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    {modalAddAction ? (
                      <AccountNoSelect
                        freeSolo={false}
                        name="accountNo"
                        label="Account No"
                        value={modalAccountNoValue}
                        onChange={(e) => {
                          setModalAccountNoValue(e.currentTarget.value);

                          if (e.currentTarget.value === null) {
                            setModalAccountNoValue('');
                            setModalBankOwnerNameValue('');
                          }

                          if (
                            modalCorrespondentValue &&
                            e.currentTarget.value &&
                            !modalBankOwnerNameValue
                          ) {
                            loadPrimaryOwner(
                              modalCorrespondentValue,
                              e.currentTarget.value
                            );
                          }
                        }}
                        selectedCorrespondent={modalCorrespondentValue}
                      />
                    ) : (
                      <TextField
                        label="Account No"
                        required
                        fullWidth={true}
                        disabled={isDisabled}
                        value={modalAccountNoValue}
                        onChange={(e) => setModalAccountNoValue(e.target.value)}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Bank Owner Name"
                      required
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalBankOwnerNameValue}
                      onChange={(e) =>
                        setModalBankOwnerNameValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Bank Name"
                      required
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalBankNameValue}
                      onChange={(e) => setmodalBankNameValue(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="ACH Routing No"
                      required
                      type="text"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalAchRoutingNoValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setmodalAchRoutingNoValue(e.target.value);
                        }
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 9);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Wire Routing No"
                      type="text"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalWireRoutingNoValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setmodalWireRoutingNoValue(e.target.value);
                        }
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 9);
                      }}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 15 }}>
                    <TextField
                      label="Bank Account No"
                      required
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalBankAccountNoValue}
                      onChange={(e) =>
                        setModalBankAccountNoValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell2} style={{ marginLeft: 15 }}>
                    <BankAddressSelect
                      id="bankAddress"
                      // disabled={isDisabled}
                      labelid="bankAddress"
                      label="Bank Address"
                      list={bankAddressList}
                      value={modalBankAddressValue}
                      selectedvalue={modalBankAddressIdValue}
                      onChange={(e) => setModalBankAddressValue(e.target.value)}
                      setNewValue={(event, newValue) => {
                        if (newValue) {
                          setModalBankAddressValue(newValue.bankAddressId);
                        } else {
                          return;
                        }
                      }}
                      openmodal={handleOpen2}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Bank Account Type *</InputLabel>
                    <Select
                      required
                      displayEmpty
                      fullWidth
                      disabled={isDisabled}
                      value={modalBankAccountTypeValue}
                      onChange={(e) =>
                        setModalBankAccountTypeValue(e.target.value)
                      }
                    >
                      <MenuItem value="checking">Checking</MenuItem>
                      <MenuItem value="savings">Savings</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Approved Method *</InputLabel>
                    <Select
                      required
                      displayEmpty
                      fullWidth
                      disabled={isDisabled}
                      value={modalApprovedMethodValue}
                      onChange={(e) =>
                        setModalApprovedMethodValue(e.target.value)
                      }
                    >
                      <MenuItem value="micro_deposit">Micro Deposit</MenuItem>
                      <MenuItem value="plaid">Plaid</MenuItem>
                      <MenuItem value="correspondent">Correspondent</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Paid Access Token"
                      required
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalPaidAccessToken}
                      onChange={(e) => setModalPaidAccessToken(e.target.value)}
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalStatusValue}
                      onChange={(e) => setModalStatusValue(e.target.value)}
                    >
                      <MenuItem value="pending_approval">
                        Pending Apporval
                      </MenuItem>
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="disabled">Disabled</MenuItem>
                      <MenuItem value="denied">Denied</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Bank Identifier Code (BIC)"
                      fullWidth={true}
                      disabled={isDisabled}
                      value={modalBankIdentifierCodeValue}
                      onChange={(e) =>
                        setModalBankIdentifierCodeValue(e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                    />
                  </div>
                  <div className={classes.grdCell1}></div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveBankAccount(
                          bankAccount,
                          rows,
                          modalAddAction,
                          handleClose,
                          isBankOwnerNameValid,
                          bankId,
                          modalBankAddressIdValue,
                          modalBankAddressValue,
                          modalCorrespondentValue,
                          modalAccountNoValue,
                          modalBankOwnerNameValue,
                          modalBankNameValue,
                          modalAchRoutingNoValue,
                          modalWireRoutingNoValue,
                          modalBankAccountNoValue,
                          modalBankAccountTypeValue,
                          modalApprovedMethodValue,
                          modalPaidAccessToken,
                          modalStatusValue,
                          modalBankIdentifierCodeValue
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={openBankAddressModal}
        onClose={handleClose2}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={openBankAddressModal}>
          <div className={classes.fadeModalLg}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              Add Bank Address
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Bank Name"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalAddressBankNameValue}
                      onChange={(e) =>
                        setModalAddressBankNameValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Bank Routing No"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalBankRoutingNoValue}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === '' || re.test(e.target.value)) {
                          setModalBankRoutingNoValue(e.target.value);
                        }
                      }}
                      onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 9);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Address"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalAddressValue}
                      onChange={(e) => setModalAddressValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="City"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalCityValue}
                      onChange={(e) => setModalCityValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Zip Code"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalZipCodeValue}
                      onChange={(e) => setModalZipCodeValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>State</InputLabel>
                    <StateSelect
                      value={modalStateValue}
                      onChange={(e) => setModalStateValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalAddressStatusValue}
                      onChange={(e) =>
                        setModalAddressStatusValue(e.target.value)
                      }
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose2}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveBankAddress(
                          bankaddress,
                          handleClose2,
                          modalAddressBankNameValue,
                          modalBankRoutingNoValue,
                          modalAddressValue,
                          modalCityValue,
                          modalZipCodeValue,
                          modalStateValue,
                          modalAddressStatusValue,
                          setBankAddressList
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
