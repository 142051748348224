// source: reorgpb/spinoff.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.ListSpinOffRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListSpinOffResponse', null, global);
goog.exportSymbol('proto.reorgpb.SpinOff', null, global);
goog.exportSymbol('proto.reorgpb.UpdateSpinOffRequest', null, global);
goog.exportSymbol('proto.reorgpb.UpdateSpinOffResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.SpinOff = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.SpinOff, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.SpinOff.displayName = 'proto.reorgpb.SpinOff';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListSpinOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListSpinOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListSpinOffRequest.displayName = 'proto.reorgpb.ListSpinOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListSpinOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListSpinOffResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListSpinOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListSpinOffResponse.displayName = 'proto.reorgpb.ListSpinOffResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateSpinOffRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateSpinOffRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateSpinOffRequest.displayName = 'proto.reorgpb.UpdateSpinOffRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateSpinOffResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateSpinOffResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateSpinOffResponse.displayName = 'proto.reorgpb.UpdateSpinOffResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.SpinOff.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.SpinOff.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.SpinOff} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.SpinOff.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    expirationDate: (f = msg.getExpirationDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    targetOriginalCusip: jspb.Message.getFieldWithDefault(msg, 5, ""),
    initiatingSymbol: jspb.Message.getFieldWithDefault(msg, 6, ""),
    initiatingOriginalCusip: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cash: jspb.Message.getFieldWithDefault(msg, 8, ""),
    newRate: jspb.Message.getFieldWithDefault(msg, 9, ""),
    oldRate: jspb.Message.getFieldWithDefault(msg, 10, ""),
    positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    netCash: jspb.Message.getFieldWithDefault(msg, 14, ""),
    netQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 16, false),
    pendingTrnsCount: jspb.Message.getFieldWithDefault(msg, 17, 0),
    detail: jspb.Message.getFieldWithDefault(msg, 18, ""),
    netPayCash: jspb.Message.getFieldWithDefault(msg, 19, ""),
    netPayQty: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.SpinOff}
 */
proto.reorgpb.SpinOff.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.SpinOff;
  return proto.reorgpb.SpinOff.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.SpinOff} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.SpinOff}
 */
proto.reorgpb.SpinOff.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setExpirationDate(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetOriginalCusip(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingSymbol(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setInitiatingOriginalCusip(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCash(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setOldRate(value);
      break;
    case 11:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPositionDate(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetCash(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetQty(value);
      break;
    case 16:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 17:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingTrnsCount(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayCash(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.SpinOff.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.SpinOff.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.SpinOff} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.SpinOff.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getExpirationDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTargetOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getInitiatingSymbol();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getInitiatingOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCash();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getOldRate();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getNetCash();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNetQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      16,
      f
    );
  }
  f = message.getPendingTrnsCount();
  if (f !== 0) {
    writer.writeInt32(
      17,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getNetPayCash();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getNetPayQty();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.SpinOff.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.SpinOff} returns this
*/
proto.reorgpb.SpinOff.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.SpinOff.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date expiration_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.SpinOff.prototype.getExpirationDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.SpinOff} returns this
*/
proto.reorgpb.SpinOff.prototype.setExpirationDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.clearExpirationDate = function() {
  return this.setExpirationDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.SpinOff.prototype.hasExpirationDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional string target_symbol = 4;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string target_original_cusip = 5;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getTargetOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setTargetOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string initiating_symbol = 6;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getInitiatingSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setInitiatingSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string initiating_original_cusip = 7;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getInitiatingOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setInitiatingOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cash = 8;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setCash = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string new_rate = 9;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string old_rate = 10;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getOldRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setOldRate = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional google.type.Date position_date = 11;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.SpinOff.prototype.getPositionDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 11));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.SpinOff} returns this
*/
proto.reorgpb.SpinOff.prototype.setPositionDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.clearPositionDate = function() {
  return this.setPositionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.SpinOff.prototype.hasPositionDate = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional google.type.Date process_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.SpinOff.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.SpinOff} returns this
*/
proto.reorgpb.SpinOff.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.SpinOff.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string net_cash = 14;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getNetCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNetCash = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string net_qty = 15;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getNetQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNetQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool no_cil = 16;
 * @return {boolean}
 */
proto.reorgpb.SpinOff.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 16, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 16, value);
};


/**
 * optional int32 pending_trns_count = 17;
 * @return {number}
 */
proto.reorgpb.SpinOff.prototype.getPendingTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 17, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setPendingTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 17, value);
};


/**
 * optional string detail = 18;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string net_pay_cash = 19;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getNetPayCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNetPayCash = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string net_pay_qty = 20;
 * @return {string}
 */
proto.reorgpb.SpinOff.prototype.getNetPayQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.SpinOff} returns this
 */
proto.reorgpb.SpinOff.prototype.setNetPayQty = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListSpinOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListSpinOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListSpinOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSpinOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    targetSymbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    pendingTrnsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListSpinOffRequest}
 */
proto.reorgpb.ListSpinOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListSpinOffRequest;
  return proto.reorgpb.ListSpinOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListSpinOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListSpinOffRequest}
 */
proto.reorgpb.ListSpinOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTargetSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingTrnsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListSpinOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListSpinOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListSpinOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSpinOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTargetSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPendingTrnsOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string target_symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListSpinOffRequest.prototype.getTargetSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
 */
proto.reorgpb.ListSpinOffRequest.prototype.setTargetSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListSpinOffRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
*/
proto.reorgpb.ListSpinOffRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
 */
proto.reorgpb.ListSpinOffRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListSpinOffRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListSpinOffRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
*/
proto.reorgpb.ListSpinOffRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
 */
proto.reorgpb.ListSpinOffRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListSpinOffRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_trns_only = 4;
 * @return {boolean}
 */
proto.reorgpb.ListSpinOffRequest.prototype.getPendingTrnsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.ListSpinOffRequest} returns this
 */
proto.reorgpb.ListSpinOffRequest.prototype.setPendingTrnsOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListSpinOffResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListSpinOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListSpinOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListSpinOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSpinOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spinOffsList: jspb.Message.toObjectList(msg.getSpinOffsList(),
    proto.reorgpb.SpinOff.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListSpinOffResponse}
 */
proto.reorgpb.ListSpinOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListSpinOffResponse;
  return proto.reorgpb.ListSpinOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListSpinOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListSpinOffResponse}
 */
proto.reorgpb.ListSpinOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.SpinOff;
      reader.readMessage(value,proto.reorgpb.SpinOff.deserializeBinaryFromReader);
      msg.addSpinOffs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListSpinOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListSpinOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListSpinOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListSpinOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpinOffsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.SpinOff.serializeBinaryToWriter
    );
  }
};


/**
 * repeated SpinOff spin_offs = 1;
 * @return {!Array<!proto.reorgpb.SpinOff>}
 */
proto.reorgpb.ListSpinOffResponse.prototype.getSpinOffsList = function() {
  return /** @type{!Array<!proto.reorgpb.SpinOff>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.SpinOff, 1));
};


/**
 * @param {!Array<!proto.reorgpb.SpinOff>} value
 * @return {!proto.reorgpb.ListSpinOffResponse} returns this
*/
proto.reorgpb.ListSpinOffResponse.prototype.setSpinOffsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.SpinOff=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.SpinOff}
 */
proto.reorgpb.ListSpinOffResponse.prototype.addSpinOffs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.SpinOff, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListSpinOffResponse} returns this
 */
proto.reorgpb.ListSpinOffResponse.prototype.clearSpinOffsList = function() {
  return this.setSpinOffsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateSpinOffRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateSpinOffRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSpinOffRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    noCil: jspb.Message.getBooleanFieldWithDefault(msg, 2, false),
    newRate: jspb.Message.getFieldWithDefault(msg, 3, ""),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateSpinOffRequest}
 */
proto.reorgpb.UpdateSpinOffRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateSpinOffRequest;
  return proto.reorgpb.UpdateSpinOffRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateSpinOffRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateSpinOffRequest}
 */
proto.reorgpb.UpdateSpinOffRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNoCil(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setNewRate(value);
      break;
    case 4:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateSpinOffRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateSpinOffRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSpinOffRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getNoCil();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
  f = message.getNewRate();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateSpinOffRequest} returns this
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bool no_cil = 2;
 * @return {boolean}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.getNoCil = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.UpdateSpinOffRequest} returns this
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.setNoCil = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


/**
 * optional string new_rate = 3;
 * @return {string}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.getNewRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateSpinOffRequest} returns this
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.setNewRate = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.type.Date process_date = 4;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 4));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.UpdateSpinOffRequest} returns this
*/
proto.reorgpb.UpdateSpinOffRequest.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateSpinOffRequest} returns this
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateSpinOffRequest.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 4) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateSpinOffResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateSpinOffResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateSpinOffResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSpinOffResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    spinOff: (f = msg.getSpinOff()) && proto.reorgpb.SpinOff.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateSpinOffResponse}
 */
proto.reorgpb.UpdateSpinOffResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateSpinOffResponse;
  return proto.reorgpb.UpdateSpinOffResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateSpinOffResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateSpinOffResponse}
 */
proto.reorgpb.UpdateSpinOffResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.SpinOff;
      reader.readMessage(value,proto.reorgpb.SpinOff.deserializeBinaryFromReader);
      msg.setSpinOff(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateSpinOffResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateSpinOffResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateSpinOffResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateSpinOffResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSpinOff();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.SpinOff.serializeBinaryToWriter
    );
  }
};


/**
 * optional SpinOff spin_off = 1;
 * @return {?proto.reorgpb.SpinOff}
 */
proto.reorgpb.UpdateSpinOffResponse.prototype.getSpinOff = function() {
  return /** @type{?proto.reorgpb.SpinOff} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.SpinOff, 1));
};


/**
 * @param {?proto.reorgpb.SpinOff|undefined} value
 * @return {!proto.reorgpb.UpdateSpinOffResponse} returns this
*/
proto.reorgpb.UpdateSpinOffResponse.prototype.setSpinOff = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateSpinOffResponse} returns this
 */
proto.reorgpb.UpdateSpinOffResponse.prototype.clearSpinOff = function() {
  return this.setSpinOff(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateSpinOffResponse.prototype.hasSpinOff = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.reorgpb);
