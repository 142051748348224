// source: usrpb/administrator.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.usrpb.Administrator', null, global);
goog.exportSymbol('proto.usrpb.CreateAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.DeleteAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.ListAdministratorRequest', null, global);
goog.exportSymbol('proto.usrpb.ListAdministratorResponse', null, global);
goog.exportSymbol('proto.usrpb.UpdateAdministratorResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.Administrator = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.Administrator, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.Administrator.displayName = 'proto.usrpb.Administrator';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListAdministratorRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.ListAdministratorRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListAdministratorRequest.displayName = 'proto.usrpb.ListAdministratorRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.ListAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.usrpb.ListAdministratorResponse.repeatedFields_, null);
};
goog.inherits(proto.usrpb.ListAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.ListAdministratorResponse.displayName = 'proto.usrpb.ListAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.CreateAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.CreateAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.CreateAdministratorResponse.displayName = 'proto.usrpb.CreateAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.UpdateAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.UpdateAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.UpdateAdministratorResponse.displayName = 'proto.usrpb.UpdateAdministratorResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.usrpb.DeleteAdministratorResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.usrpb.DeleteAdministratorResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.usrpb.DeleteAdministratorResponse.displayName = 'proto.usrpb.DeleteAdministratorResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.Administrator.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.Administrator.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.Administrator} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Administrator.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    role: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.Administrator.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.Administrator;
  return proto.usrpb.Administrator.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.Administrator} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.Administrator.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.Administrator.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.Administrator.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.Administrator} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.Administrator.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRole();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.usrpb.Administrator.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.usrpb.Administrator} returns this
*/
proto.usrpb.Administrator.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.Administrator.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string role = 5;
 * @return {string}
 */
proto.usrpb.Administrator.prototype.getRole = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.Administrator} returns this
 */
proto.usrpb.Administrator.prototype.setRole = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListAdministratorRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListAdministratorRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListAdministratorRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    email: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListAdministratorRequest}
 */
proto.usrpb.ListAdministratorRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListAdministratorRequest;
  return proto.usrpb.ListAdministratorRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListAdministratorRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListAdministratorRequest}
 */
proto.usrpb.ListAdministratorRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListAdministratorRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListAdministratorRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListAdministratorRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string email = 1;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.usrpb.ListAdministratorRequest.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.usrpb.ListAdministratorRequest} returns this
 */
proto.usrpb.ListAdministratorRequest.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.usrpb.ListAdministratorResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.ListAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.ListAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.ListAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administratorsList: jspb.Message.toObjectList(msg.getAdministratorsList(),
    proto.usrpb.Administrator.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.ListAdministratorResponse}
 */
proto.usrpb.ListAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.ListAdministratorResponse;
  return proto.usrpb.ListAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.ListAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.ListAdministratorResponse}
 */
proto.usrpb.ListAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.addAdministrators(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.ListAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.ListAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.ListAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.ListAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministratorsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Administrator administrators = 1;
 * @return {!Array<!proto.usrpb.Administrator>}
 */
proto.usrpb.ListAdministratorResponse.prototype.getAdministratorsList = function() {
  return /** @type{!Array<!proto.usrpb.Administrator>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {!Array<!proto.usrpb.Administrator>} value
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
*/
proto.usrpb.ListAdministratorResponse.prototype.setAdministratorsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.usrpb.Administrator=} opt_value
 * @param {number=} opt_index
 * @return {!proto.usrpb.Administrator}
 */
proto.usrpb.ListAdministratorResponse.prototype.addAdministrators = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.usrpb.Administrator, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.usrpb.ListAdministratorResponse} returns this
 */
proto.usrpb.ListAdministratorResponse.prototype.clearAdministratorsList = function() {
  return this.setAdministratorsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.CreateAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.CreateAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.CreateAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.CreateAdministratorResponse}
 */
proto.usrpb.CreateAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.CreateAdministratorResponse;
  return proto.usrpb.CreateAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.CreateAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.CreateAdministratorResponse}
 */
proto.usrpb.CreateAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.CreateAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.CreateAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.CreateAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.CreateAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.CreateAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.CreateAdministratorResponse} returns this
*/
proto.usrpb.CreateAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.CreateAdministratorResponse} returns this
 */
proto.usrpb.CreateAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.CreateAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.UpdateAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.UpdateAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.UpdateAdministratorResponse}
 */
proto.usrpb.UpdateAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.UpdateAdministratorResponse;
  return proto.usrpb.UpdateAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.UpdateAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.UpdateAdministratorResponse}
 */
proto.usrpb.UpdateAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.UpdateAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.UpdateAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.UpdateAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.UpdateAdministratorResponse} returns this
*/
proto.usrpb.UpdateAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.UpdateAdministratorResponse} returns this
 */
proto.usrpb.UpdateAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.UpdateAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.usrpb.DeleteAdministratorResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.usrpb.DeleteAdministratorResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    administrator: (f = msg.getAdministrator()) && proto.usrpb.Administrator.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.usrpb.DeleteAdministratorResponse}
 */
proto.usrpb.DeleteAdministratorResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.usrpb.DeleteAdministratorResponse;
  return proto.usrpb.DeleteAdministratorResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.usrpb.DeleteAdministratorResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.usrpb.DeleteAdministratorResponse}
 */
proto.usrpb.DeleteAdministratorResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.usrpb.Administrator;
      reader.readMessage(value,proto.usrpb.Administrator.deserializeBinaryFromReader);
      msg.setAdministrator(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.usrpb.DeleteAdministratorResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.usrpb.DeleteAdministratorResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.usrpb.DeleteAdministratorResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAdministrator();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.usrpb.Administrator.serializeBinaryToWriter
    );
  }
};


/**
 * optional Administrator administrator = 1;
 * @return {?proto.usrpb.Administrator}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.getAdministrator = function() {
  return /** @type{?proto.usrpb.Administrator} */ (
    jspb.Message.getWrapperField(this, proto.usrpb.Administrator, 1));
};


/**
 * @param {?proto.usrpb.Administrator|undefined} value
 * @return {!proto.usrpb.DeleteAdministratorResponse} returns this
*/
proto.usrpb.DeleteAdministratorResponse.prototype.setAdministrator = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.usrpb.DeleteAdministratorResponse} returns this
 */
proto.usrpb.DeleteAdministratorResponse.prototype.clearAdministrator = function() {
  return this.setAdministrator(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.usrpb.DeleteAdministratorResponse.prototype.hasAdministrator = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.usrpb);
