/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  GeneralLedger,
  DeleteGeneralLedgerRequest,
} from '../../../proto/accountpb/generalledger_grpc_web_pb';

/*Toast Notification*/
import {
  notifySuccess,
  notifyInfo,
  notifyError,
} from 'components/Notification/Notification';

/*Material UI Components*/
import {
  TableCell,
  TableRow,
  Typography,
  Button,
  Modal,
  Backdrop,
  Fade,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Checkbox,
} from '@material-ui/core';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate } from 'lib/fmt';

/*Icons*/
import {
  Add as AddIcon,
  Create as EditIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';

/*Custom components*/
import CorrespondentSelect from '../../../components/AutoComplete/Correspondent';
import AccountNoSelect from '../../../components/AutoComplete/AccountNo';
import AccountNameSelect from '../../../components/AutoComplete/AccountName';
import MasterAccountNoSelect from '../../../components/AutoComplete/MasterAccountNo';
import AccountDesignatorSelect from '../../../components/Dropdown/AccountDesignator';
import GLTypeSelect from '../../../components/Dropdown/GLType';
import SearchButton from '../../../components/Button/Search';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

import { ServiceContext } from 'context/ServiceContext';
import SystemCodeSelect from 'components/AutoComplete/SystemCode';

/*=========================================================================================
Add and Update GL Account function
===========================================================================================*/
function saveGLAccount(
  glAccount,
  isAdd,
  closeModal,
  accountId,
  accountName,
  accountNo,
  masterAccountNo,
  glType,
  accountDesignator,
  broker,
  status,
  forCorrespondent,
  referenceNo,
  baseCurrency,
  purpose,
  rows
) {
  if (!accountName) {
    notifyError('Account name is required.');
    return;
  }
  if (!accountNo) {
    notifyError('Account no. is required.');
    return;
  }

  if (isAdd) {
    let addReq = new GeneralLedger();
    addReq.setAccountName(accountName);
    addReq.setAccountNo(accountNo);
    addReq.setMasterAccountNo(masterAccountNo);
    addReq.setGlType(glType);
    addReq.setAccountDesignator(accountDesignator);
    addReq.setBroker(broker);
    addReq.setStatus(status);
    addReq.setCreatedBy('guest user');
    addReq.setForCorrespondent(forCorrespondent);
    addReq.setBaseCurrency(baseCurrency);
    addReq.setPurpose(purpose);

    addReq.setReferenceNo(referenceNo);
    glAccount.createGeneralLedger(addReq, {}, (err, res) => {
      if (err) {
        console.error(err.message);
      } else {
        let data = res.toObject().generalLedger;
        let newRow = {
          accountDesignator: data.accountDesignator,
          accountId: data.accountId,
          accountName: data.accountName,
          accountNo: data.accountNo,
          broker: data.broker,
          createdAt: data.createdAt
            ? moment(new Date(data.createdAt.seconds * 1000)).format(
                'MM/DD/YYYY hh:mm'
              )
            : '--',
          createdBy: data.createdBy,
          forCorrespondent: data.forCorrespondent,
          glType: data.glType,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          referenceNo: data.referenceNo,
          baseCurrency: data.baseCurrency,
          purpose: data.purpose,
        };
        rows.push(newRow);
        notifySuccess('New GL account has been added.');
        closeModal();
      }
    });
  } else {
    let updateReq = new GeneralLedger();
    updateReq.setAccountId(accountId);
    updateReq.setAccountName(accountName);
    updateReq.setAccountNo(accountNo);
    updateReq.setMasterAccountNo(masterAccountNo);
    updateReq.setGlType(glType);
    updateReq.setAccountDesignator(accountDesignator);
    updateReq.setBroker(broker);
    updateReq.setStatus(status);
    updateReq.setForCorrespondent(forCorrespondent);
    updateReq.setReferenceNo(referenceNo);
    updateReq.setBaseCurrency(baseCurrency);
    updateReq.setPurpose(purpose);
    glAccount.updateGeneralLedger(updateReq, {}, (err) => {
      if (err) {
        console.log(err);
      } else {
        for (let i = 0; i < rows.length; i++) {
          if (rows[i].accountId === accountId) {
            rows[i].masterAccountNo = masterAccountNo;
            rows[i].glType = glType;
            rows[i].accountDesignator = accountDesignator;
            rows[i].broker = broker;
            rows[i].status = status;
            rows[i].forCorrespondent = forCorrespondent;
            rows[i].referenceNo = referenceNo;
            rows[i].baseCurrency = baseCurrency;
            rows[i].purpose = purpose;
          }
        }
        notifySuccess('GL Account has been updated.');
        closeModal();
      }
    });
  }
}
/*=========================================================================================
End of Add and Update GL Account function
===========================================================================================*/

/*=========================================================================================
Delete GL account function: Multiple
===========================================================================================*/
function removeGLAccount(glAccount, rows, accountIds, handleClick, confirm) {
  if (accountIds.length < 1) {
    notifyError('No item is selected.');
    return;
  }

  confirm({
    description:
      'You are about to disable ' +
      accountIds.length +
      ' GL Account(s) in your system. Please confirm your action.',
    confirmationText: 'Yes, Disable',
  })
    .then(() => {
      accountIds.forEach(removeFunction);
      function removeFunction(item) {
        let deleteReq = new DeleteGeneralLedgerRequest();
        deleteReq.setAccountId(item);
        glAccount.deleteGeneralLedger(deleteReq, {}, (err) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
          }
          for (let c = 0; c < rows.length; c++) {
            if (rows[c].accountId === item) {
              rows.splice(c, 1);
            }
          }
        });
      }
      handleClick('', '', true);
      notifySuccess(accountIds.length + ' GL Account has been disabled');
    })
    .catch(() => {
      /* ... */
    });
}
/*=========================================================================================
End of delete GL account function: Multiple
===========================================================================================*/

/*=========================================================================================
Delete GL account function: Single
===========================================================================================*/
function removeGLAccountSingle(
  glAccount,
  rows,
  accountId,
  accountName,
  confirm
) {
  confirm({
    description:
      "You are about to disable '" +
      accountName +
      "' account in your system. Please confirm your action.",
    confirmationText: 'Yes, Disable',
  })
    .then(() => {
      let deleteReq = new DeleteGeneralLedgerRequest();
      deleteReq.setAccountId(accountId);
      glAccount.deleteGeneralLedger(deleteReq, {}, (err) => {
        if (err) {
          console.error(err.message);
          notifyError(err.message);
          return;
        }
        for (let c = 0; c < rows.length; c++) {
          if (rows[c].accountId === accountId) {
            rows.splice(c, 1);
          }
        }
      });
      notifySuccess('GL Account has been disabled');
    })
    .catch(() => {
      // showToastMessage("error", "System error.");
    });
}
/*=========================================================================================
End of delete GL account function: Single
===========================================================================================*/

export default function GLAccountTable({ params }) {
  const { generalLedgerServiceClient: glAccount } = useContext(ServiceContext);

  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/

  /*START OF PAGING LOGIC ===================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        let val1 = a[sortField];
        let val2 = b[sortField];

        if (typeof val1 === 'object') {
          if (val1.seconds) {
            val1 = a[sortField]
              ? moment(new Date(a[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
            val2 = b[sortField]
              ? moment(new Date(b[sortField].seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : '--';
          } else {
            val1 = formatPbDate(a[sortField]);
            val2 = formatPbDate(b[sortField]);
          }
        }
        if (val1 < val2) {
          return 1 * (sortDir === 'asc' ? -1 : 1);
        } else if (val1 > val2) {
          return -1 * (sortDir === 'asc' ? -1 : 1);
        } else {
          return 0;
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountDesignator:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          accountName:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          accountNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          broker:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          createdAt:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          createdBy:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          glType:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          masterAccountNo:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          status:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          forCorrespondent:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          referenceNo:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (key === 'createdAt') {
                if (item[key] !== undefined) {
                  item[key] =
                    typeof item[key] === 'object'
                      ? moment(new Date(item[key].seconds * 1000)).format(
                          'MM/DD/YYYY hh:mm'
                        )
                      : item[key];
                } else {
                  item[key] = '--';
                }
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }

      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(
        pageData.map((data) => ({
          accountDesignator: data.accountDesignator,
          accountId: data.accountId,
          accountName: data.accountName,
          accountNo: data.accountNo,
          broker: data.broker,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          forCorrespondent: data.forCorrespondent,
          glType: data.glType,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          referenceNo: data.referenceNo,
          baseCurrency: data.baseCurrency,
          purpose: data.purpose,
        }))
      );

      setDownloadableRows(
        fullData.map((data) => ({
          accountDesignator: data.accountDesignator,
          accountId: data.accountId,
          accountName: data.accountName,
          accountNo: data.accountNo,
          broker: data.broker,
          createdAt: data.createdAt
            ? typeof data.createdAt === 'object'
              ? moment(new Date(data.createdAt.seconds * 1000)).format(
                  'MM/DD/YYYY hh:mm'
                )
              : data.createdAt
            : '--',
          createdBy: data.createdBy,
          forCorrespondent: data.forCorrespondent,
          glType: data.glType,
          masterAccountNo: data.masterAccountNo,
          status: data.status,
          refereneceNo: data.referenceNo,
          baseCurrency: data.baseCurrency,
          purpose: data.purpose,
        }))
      );

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }

      setLoading(false);
    }
  }, [page]);

  /**search function to get balances ========================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (accountNameValue ? '&accountName=' + accountNameValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (masterAccountNoValue
          ? '&masterAccountNo=' + masterAccountNoValue
          : '') +
        (glTypeValue ? '&glType=' + glTypeValue : '') +
        (accountDesignatorValue
          ? '&accountDesignator=' + accountDesignatorValue
          : '') +
        (brokerValue ? '&broker=' + brokerValue : '') +
        (statusValue ? '&status=' + statusValue : '') +
        (forCorrespondentValue
          ? '&forCorrespondent=' + forCorrespondentValue
          : '') +
        (referenceNoValue ? '&referenceNo=' + referenceNoValue : '');
      window.history.pushState({}, null, pathWithParams);

      setLoading(true);

      let list = new GeneralLedger();
      list.setAccountName(accountNameValue);
      list.setAccountNo(accountNoValue);
      list.setMasterAccountNo(masterAccountNoValue);
      list.setGlType(glTypeValue);
      list.setAccountDesignator(accountDesignatorValue);
      list.setBroker(brokerValue);
      list.setStatus(statusValue);
      list.setForCorrespondent(forCorrespondentValue);
      list.setReferenceNo(referenceNoValue);

      glAccount.listGeneralLedger(list, {}, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.toObject().generalLedgersList;
        console.log(fullData);
        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**search function end */

  /**PAGING LOGIC END ======================================================*/

  /*=========================================================================================
  Table modifications
  ===========================================================================================*/
  const [selected, setSelected] = React.useState([]);
  const [rowID, setRowID] = React.useState('');

  const columns = [
    {
      id: '1',
      name: 'accountDesignator',
      label: 'Account Designator',
      visibleState: useState(true),
      align: 'left',
      order: 4,
    },
    {
      id: '2',
      name: 'accountName',
      label: 'Account Name',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '3',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '4',
      name: 'broker',
      label: 'Broker',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '5',
      name: 'createdAt',
      label: 'Created At',
      visibleState: useState(true),
      align: 'left',
      order: 8,
    },
    {
      id: '6',
      name: 'createdBy',
      label: 'Created By',
      visibleState: useState(true),
      align: 'left',
      order: 9,
    },
    {
      id: '7',
      name: 'glType',
      label: 'GL Type',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '8',
      name: 'masterAccountNo',
      label: 'Master Account No',
      visibleState: useState(true),
      align: 'left',
      order: 2,
    },
    {
      id: '9',
      name: 'status',
      label: 'Status',
      visibleState: useState(true),
      align: 'left',
      order: 7,
    },
    {
      id: '10',
      name: 'forCorrespondent',
      label: 'For Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '10',
      name: 'referenceNo',
      label: 'Reference No',
      visibleState: useState(false),
      align: 'left',
      order: 9,
    },
    {
      id: '11',
      name: 'baseCurrency',
      label: 'Base Currency',
      visibleState: useState(false),
      align: 'left',
      order: 10,
    },
    {
      id: '12',
      name: 'purpose',
      label: 'Purpose',
      visibleState: useState(false),
      align: 'left',
      order: 11,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};

  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, accountId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(accountId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, accountId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };
  const isSelected = (accountId) => selected.indexOf(accountId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const isItemSelected = isSelected(rows[rowIndex].accountId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={rowIndex}>
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCell1}>
              <Checkbox
                onClick={(event) =>
                  handleClick(event, rows[rowIndex].accountId)
                }
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  removeGLAccountSingle(
                    glAccount,
                    rows,
                    rows[rowIndex].accountId,
                    rows[rowIndex].accountName,
                    confirm
                  );
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
            <div className={classes.grdCell1}>
              <IconButton
                aria-label="edit"
                onClick={() => handleOpen(rows[rowIndex], false)}
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => (v.options.empty ? false : v.options.display))
          .map((col) => (
            <TableCell align={col.align || 'left'} key={col.name}>
              {rows[rowIndex][col.name]}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'GLAccount_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [1, 2, 7, 6, 0, 9, 3, 8, 4, 5, 10, 11, 12],
    selectableRowsHeader: false,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        default: {
          break;
        }
      }
    },
  };
  /*=========================================================================================
  End of table modifications
  ===========================================================================================*/

  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };

  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [accountId, setAccountId] = React.useState('');
  const [accountNameValue, setAccountNameValue] = React.useState('');
  const [accountNoValue, setAccountNoValue] = React.useState('');
  const [masterAccountNoValue, setMasterAccountNoValue] = React.useState('');
  const [glTypeValue, setGlTypeValue] = React.useState('');
  const [accountDesignatorValue, setAccountDesignatorValue] = React.useState(
    ''
  );
  const [brokerValue, setBrokerValue] = React.useState('');
  const [statusValue, setStatusValue] = React.useState('');
  const [forCorrespondentValue, setForCorrespondentValue] = React.useState('');
  const [referenceNoValue, setreferenceNoValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      accountName: query.get('accountName'),
      accountNo: query.get('accountNo'),
      masterAccountNo: query.get('masterAccountNo'),
      glType: query.get('glType'),
      accountDesignator: query.get('accountDesignator'),
      broker: query.get('broker'),
      status: query.get('status'),
      forCorrespondent: query.get('forCorrespondent'),
      referenceNo: query.get('referenceNo'),
    };

    if (data.accountName) {
      setAccountNameValue(data.accountName);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.masterAccountNo) {
      setMasterAccountNoValue(data.masterAccountNo);
    }
    if (data.glType) {
      setGlTypeValue(data.glType);
    }
    if (data.accountDesignator) {
      setAccountDesignatorValue(data.accountDesignator);
    }
    if (data.broker) {
      setBrokerValue(data.broker);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.referenceNo) {
      setreferenceNoValue(data.referenceNo);
    }

    if (data.forCorrespondent) {
      setForCorrespondentValue(data.forCorrespondent);
    }

    setCurrentPath(path);
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  /*=========================================================================================
  Handles modal
  ===========================================================================================*/
  const [modalAccountNameValue, setModalAccountNameValue] = React.useState('');
  const [modalAccountNoValue, setModalAccountNoValue] = React.useState('');
  const [
    modalMasterAccountNoValue,
    setModalMasterAccountNoValue,
  ] = React.useState('');
  const [modalGlTypeValue, setModalGlTypeValue] = React.useState('');
  const [
    modalAccountDesignatorValue,
    setModalAccountDesignatorValue,
  ] = React.useState('');
  const [modalBrokerValue, setModalBrokerValue] = React.useState('');
  const [modalStatusValue, setModalStatusValue] = React.useState('');
  const [
    modalForCorrespondentValue,
    setModalForCorrespondentValue,
  ] = React.useState('');
  const [modalReferenceNo, setModalReferenceNo] = React.useState('');
  const [modalBaseCurrencyValue, setModalBaseCurrencyValue] = React.useState(
    ''
  );
  const [modalPurposeValue, setModalPurpose] = React.useState('');

  const [isDisabled, setIsDisabled] = React.useState(false);
  const [disableBaseCurrency, setDisableBaseCurrency] = React.useState(false);
  const [modalAddAction, setModalAddAction] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [modalTitleValue, setModalTitleValue] = React.useState('Add New');

  const handleOpen = (rowData, isAdd) => {
    if (isAdd) {
      setModalAccountNameValue('');
      setModalAccountNoValue('');
      setModalMasterAccountNoValue('');
      setModalGlTypeValue('');
      setModalAccountDesignatorValue('customer');
      setModalBrokerValue('non_broker_dealer');
      setModalStatusValue('active');
      setModalForCorrespondentValue('');
      setModalReferenceNo('');
      setModalBaseCurrencyValue('usd');
      setModalPurpose('');

      setModalAddAction(true);
      setIsDisabled(false);
      setDisableBaseCurrency(false);
      setModalTitleValue('Add New');
    } else {
      setAccountId(rowData.accountId);
      setModalAccountNameValue(rowData.accountName);
      setModalAccountNoValue(rowData.accountNo);
      setModalMasterAccountNoValue(rowData.masterAccountNo);
      setModalGlTypeValue(rowData.glType);
      setModalAccountDesignatorValue(rowData.accountDesignator);
      setModalBrokerValue(rowData.broker);
      setModalStatusValue(rowData.status);
      setModalForCorrespondentValue(rowData.forCorrespondent);
      setModalReferenceNo(rowData.referenceNo);
      setModalBaseCurrencyValue(rowData.baseCurrency?.toLowerCase());
      setModalPurpose(rowData.purpose);

      setModalAddAction(false);
      setIsDisabled(true);
      setDisableBaseCurrency(true);
      setModalTitleValue('Edit');
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  /*=========================================================================================
  End of modal input fields
  ===========================================================================================*/

  /*=========================================================================================
  Table component
  ===========================================================================================*/
  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNameSelect
                  freeSolo={true}
                  name="accountName"
                  label="Account Name"
                  value={accountNameValue}
                  onChange={(e) => {
                    setAccountNameValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <AccountNoSelect
                  freeSolo={false}
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <MasterAccountNoSelect
                  freeSolo={false}
                  name="masterAccountNo"
                  label="Master Account Number"
                  value={masterAccountNoValue}
                  onChange={(e) => {
                    setMasterAccountNoValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink id="gl_type">
                  GL Type
                </InputLabel>
                <GLTypeSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={glTypeValue}
                  onChange={(e) => setGlTypeValue(e.target.value)}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Account Designator</InputLabel>
                <AccountDesignatorSelect
                  required={false}
                  labelId="accountDesignator"
                  fullWidth
                  value={accountDesignatorValue}
                  onChange={(e) => setAccountDesignatorValue(e.target.value)}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Broker</InputLabel>
                <Select
                  fullWidth
                  value={brokerValue}
                  onChange={(e) => setBrokerValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                  <MenuItem value="non_broker_dealer">
                    Non Broker Dealer
                  </MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="disabled">Disabled</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <CorrespondentSelect
                  freeSolo={false}
                  label="For correspondent"
                  name="forCorrespondent"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={forCorrespondentValue}
                  onChange={(e) =>
                    setForCorrespondentValue(e.currentTarget.value)
                  }
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  label="Reference No"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  value={referenceNoValue}
                  onChange={(e) => setreferenceNoValue(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                removeGLAccount(glAccount, rows, rowID, handleClick, confirm);
              }}
              startIcon={<DeleteIcon />}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={() => {
                handleOpen('', true);
              }}
            >
              Add New
            </Button>
          </div>
          <div className={classes.grdCellNone}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'GL Accounts'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.fadeModalMd}>
            <Typography
              id="transition-modal-title"
              variant="h4"
              className={classes.textBold}
              gutterBottom
            >
              {modalTitleValue} GL Account
            </Typography>
            <Box mt={5}>
              <form noValidate autoComplete="off">
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      required
                      label="Account Name"
                      fullWidth
                      disabled={isDisabled}
                      InputLabelProps={{ shrink: true }}
                      value={modalAccountNameValue}
                      onChange={(e) => setModalAccountNameValue(e.target.value)}
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      required
                      label="Account No"
                      fullWidth
                      disabled={isDisabled}
                      InputLabelProps={{ shrink: true }}
                      value={modalAccountNoValue}
                      onChange={(e) => setModalAccountNoValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <TextField
                      label="Master Account No"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalMasterAccountNoValue}
                      onChange={(e) =>
                        setModalMasterAccountNoValue(e.target.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink id="gl_type">
                      GL Type
                    </InputLabel>
                    <GLTypeSelect
                      required={false}
                      labelId="accountDesignator"
                      fullWidth
                      value={modalGlTypeValue}
                      onChange={(e) => setModalGlTypeValue(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Account Designator</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalAccountDesignatorValue}
                      onChange={(e) =>
                        setModalAccountDesignatorValue(e.target.value)
                      }
                    >
                      <MenuItem value="customer">Customer</MenuItem>
                      <MenuItem value="gl">GL</MenuItem>
                      <MenuItem value="house_hold_member_affiliated">
                        House Hold Member Affiliated
                      </MenuItem>
                      <MenuItem value="memo_account">Memo Account</MenuItem>
                      <MenuItem value="omnibus">Omnibus</MenuItem>
                      <MenuItem value="paib">Paib</MenuItem>
                      <MenuItem value="share_holders">Share Holders</MenuItem>
                      <MenuItem value="prop">Prop</MenuItem>
                      <MenuItem value="acats_wash">ACATS Wash Account</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <InputLabel shrink>Broker</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalBrokerValue}
                      onChange={(e) => setModalBrokerValue(e.target.value)}
                    >
                      <MenuItem value="broker_dealer">Broker Dealer</MenuItem>
                      <MenuItem value="non_broker_dealer">
                        Non Broker Dealer
                      </MenuItem>
                    </Select>
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <CorrespondentSelect
                      freeSolo={true}
                      label="For correspondent"
                      name="forCorrespondent"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalForCorrespondentValue}
                      onChange={(e) =>
                        setModalForCorrespondentValue(e.currentTarget.value)
                      }
                    />
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel shrink>Status</InputLabel>
                    <Select
                      displayEmpty
                      fullWidth
                      value={modalStatusValue}
                      onChange={(e) => setModalStatusValue(e.target.value)}
                    >
                      <MenuItem value="active">Active</MenuItem>
                      <MenuItem value="disabled">Disabled</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1}>
                    <TextField
                      label="Reference No"
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      value={modalReferenceNo}
                      onChange={(e) => setModalReferenceNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className={classes.grdRow}>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <InputLabel id="baseCurrency" shrink>
                      Base Currency
                    </InputLabel>
                    <Select
                      displayEmpty
                      disabled={disableBaseCurrency}
                      fullWidth
                      value={modalBaseCurrencyValue}
                      onChange={(e) =>
                        setModalBaseCurrencyValue(e.target.value)
                      }
                    >
                      <MenuItem value="usd">USD</MenuItem>
                      <MenuItem value="cad">CAD</MenuItem>
                      <MenuItem value="chf">CHF</MenuItem>
                      <MenuItem value="eur">EUR</MenuItem>
                      <MenuItem value="gbp">GBP</MenuItem>
                      <MenuItem value="jpy">JPY</MenuItem>
                      <MenuItem value="try">TRY</MenuItem>
                      <MenuItem value="aud">AUD</MenuItem>
                    </Select>
                  </div>
                  <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                    <SystemCodeSelect
                      freeSolo={true}
                      name="purpose"
                      label="Purpose"
                      InputLabelProps={{ shrink: true }}
                      type="GL Purpose"
                      value={modalPurposeValue}
                      onChange={(e) => {
                        setModalPurpose(e.currentTarget.value);
                      }}
                    />
                  </div>
                </div>
                <div className={classes.modalFooter}>
                  <div
                    className={classes.grdCellNone}
                    style={{ marginRight: 10 }}
                  >
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </div>
                  <div className={classes.grdCellNone}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={() => {
                        saveGLAccount(
                          glAccount,
                          modalAddAction,
                          handleClose,
                          accountId,
                          modalAccountNameValue,
                          modalAccountNoValue,
                          modalMasterAccountNoValue,
                          modalGlTypeValue,
                          modalAccountDesignatorValue,
                          modalBrokerValue,
                          modalStatusValue,
                          modalForCorrespondentValue,
                          modalReferenceNo,
                          modalBaseCurrencyValue,
                          modalPurposeValue,
                          rows
                        );
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </form>
            </Box>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
