// source: marginpb/requirement.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.marginpb.ListRequirementDetailRequest', null, global);
goog.exportSymbol('proto.marginpb.ListRequirementDetailResponse', null, global);
goog.exportSymbol('proto.marginpb.ListRequirementRequest', null, global);
goog.exportSymbol('proto.marginpb.ListRequirementResponse', null, global);
goog.exportSymbol('proto.marginpb.Requirement', null, global);
goog.exportSymbol('proto.marginpb.RequirementDetail', null, global);
goog.exportSymbol('proto.marginpb.StreamRequirementRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.Requirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.Requirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.Requirement.displayName = 'proto.marginpb.Requirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListRequirementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.ListRequirementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListRequirementRequest.displayName = 'proto.marginpb.ListRequirementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.StreamRequirementRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.StreamRequirementRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.StreamRequirementRequest.displayName = 'proto.marginpb.StreamRequirementRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListRequirementResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.ListRequirementResponse.repeatedFields_, null);
};
goog.inherits(proto.marginpb.ListRequirementResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListRequirementResponse.displayName = 'proto.marginpb.ListRequirementResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.RequirementDetail = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.RequirementDetail, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.RequirementDetail.displayName = 'proto.marginpb.RequirementDetail';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListRequirementDetailRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.marginpb.ListRequirementDetailRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListRequirementDetailRequest.displayName = 'proto.marginpb.ListRequirementDetailRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.marginpb.ListRequirementDetailResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.marginpb.ListRequirementDetailResponse.repeatedFields_, null);
};
goog.inherits(proto.marginpb.ListRequirementDetailResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.marginpb.ListRequirementDetailResponse.displayName = 'proto.marginpb.ListRequirementDetailResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.Requirement.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.Requirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.Requirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.Requirement.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cashBalance: jspb.Message.getFieldWithDefault(msg, 8, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 9, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 10, ""),
    equity: jspb.Message.getFieldWithDefault(msg, 11, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 12, ""),
    openingBp: jspb.Message.getFieldWithDefault(msg, 13, ""),
    exchangeReq: jspb.Message.getFieldWithDefault(msg, 14, ""),
    exchangeCall: jspb.Message.getFieldWithDefault(msg, 15, ""),
    fedReq: jspb.Message.getFieldWithDefault(msg, 16, ""),
    fedCall: jspb.Message.getFieldWithDefault(msg, 17, ""),
    houseReq: jspb.Message.getFieldWithDefault(msg, 18, ""),
    houseCall: jspb.Message.getFieldWithDefault(msg, 19, ""),
    bpCall: jspb.Message.getFieldWithDefault(msg, 20, ""),
    sma: jspb.Message.getFieldWithDefault(msg, 22, ""),
    buyingPower: jspb.Message.getFieldWithDefault(msg, 23, ""),
    multiplier: jspb.Message.getFieldWithDefault(msg, 24, ""),
    dayTradeCount: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.Requirement}
 */
proto.marginpb.Requirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.Requirement;
  return proto.marginpb.Requirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.Requirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.Requirement}
 */
proto.marginpb.Requirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashBalance(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setEquity(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setOpeningBp(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeReq(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeCall(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedReq(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedCall(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseReq(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseCall(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setBpCall(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setSma(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setBuyingPower(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setMultiplier(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setDayTradeCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.Requirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.Requirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.Requirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.Requirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCashBalance();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getEquity();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getOpeningBp();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getExchangeReq();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getExchangeCall();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getFedReq();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getFedCall();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getHouseReq();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getHouseCall();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getBpCall();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getSma();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getBuyingPower();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getMultiplier();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getDayTradeCount();
  if (f !== 0) {
    writer.writeInt32(
      25,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.Requirement.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.Requirement} returns this
*/
proto.marginpb.Requirement.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.Requirement.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string correspondent = 4;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_name = 6;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string margin_type = 7;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cash_balance = 8;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getCashBalance = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setCashBalance = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string long_market_value = 9;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string short_market_value = 10;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string equity = 11;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getEquity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setEquity = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string market_value = 12;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string opening_bp = 13;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getOpeningBp = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setOpeningBp = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string exchange_req = 14;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getExchangeReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setExchangeReq = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string exchange_call = 15;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getExchangeCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setExchangeCall = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string fed_req = 16;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getFedReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setFedReq = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string fed_call = 17;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getFedCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setFedCall = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string house_req = 18;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getHouseReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setHouseReq = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string house_call = 19;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getHouseCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setHouseCall = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string bp_call = 20;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getBpCall = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setBpCall = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string sma = 22;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getSma = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setSma = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string buying_power = 23;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getBuyingPower = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setBuyingPower = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string multiplier = 24;
 * @return {string}
 */
proto.marginpb.Requirement.prototype.getMultiplier = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setMultiplier = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional int32 day_trade_count = 25;
 * @return {number}
 */
proto.marginpb.Requirement.prototype.getDayTradeCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.Requirement} returns this
 */
proto.marginpb.Requirement.prototype.setDayTradeCount = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListRequirementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListRequirementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListRequirementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exchangeCall: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fedCall: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    houseCall: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    bpCall: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListRequirementRequest}
 */
proto.marginpb.ListRequirementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListRequirementRequest;
  return proto.marginpb.ListRequirementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListRequirementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListRequirementRequest}
 */
proto.marginpb.ListRequirementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExchangeCall(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFedCall(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHouseCall(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBpCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListRequirementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListRequirementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListRequirementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExchangeCall();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFedCall();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHouseCall();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBpCall();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListRequirementRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
*/
proto.marginpb.ListRequirementRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListRequirementRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.marginpb.ListRequirementRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.marginpb.ListRequirementRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.marginpb.ListRequirementRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool exchange_call = 5;
 * @return {boolean}
 */
proto.marginpb.ListRequirementRequest.prototype.getExchangeCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setExchangeCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool fed_call = 6;
 * @return {boolean}
 */
proto.marginpb.ListRequirementRequest.prototype.getFedCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setFedCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool house_call = 7;
 * @return {boolean}
 */
proto.marginpb.ListRequirementRequest.prototype.getHouseCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setHouseCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool bp_call = 8;
 * @return {boolean}
 */
proto.marginpb.ListRequirementRequest.prototype.getBpCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.ListRequirementRequest} returns this
 */
proto.marginpb.ListRequirementRequest.prototype.setBpCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.StreamRequirementRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.StreamRequirementRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.StreamRequirementRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.StreamRequirementRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    correspondent: jspb.Message.getFieldWithDefault(msg, 2, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    exchangeCall: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    fedCall: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    houseCall: jspb.Message.getBooleanFieldWithDefault(msg, 7, false),
    bpCall: jspb.Message.getBooleanFieldWithDefault(msg, 8, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.StreamRequirementRequest}
 */
proto.marginpb.StreamRequirementRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.StreamRequirementRequest;
  return proto.marginpb.StreamRequirementRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.StreamRequirementRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.StreamRequirementRequest}
 */
proto.marginpb.StreamRequirementRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExchangeCall(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setFedCall(value);
      break;
    case 7:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHouseCall(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBpCall(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.StreamRequirementRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.StreamRequirementRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.StreamRequirementRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.StreamRequirementRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getExchangeCall();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getFedCall();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getHouseCall();
  if (f) {
    writer.writeBool(
      7,
      f
    );
  }
  f = message.getBpCall();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.StreamRequirementRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
*/
proto.marginpb.StreamRequirementRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.StreamRequirementRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string correspondent = 2;
 * @return {string}
 */
proto.marginpb.StreamRequirementRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string master_account_no = 3;
 * @return {string}
 */
proto.marginpb.StreamRequirementRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string account_name = 4;
 * @return {string}
 */
proto.marginpb.StreamRequirementRequest.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool exchange_call = 5;
 * @return {boolean}
 */
proto.marginpb.StreamRequirementRequest.prototype.getExchangeCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setExchangeCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional bool fed_call = 6;
 * @return {boolean}
 */
proto.marginpb.StreamRequirementRequest.prototype.getFedCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setFedCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional bool house_call = 7;
 * @return {boolean}
 */
proto.marginpb.StreamRequirementRequest.prototype.getHouseCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 7, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setHouseCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 7, value);
};


/**
 * optional bool bp_call = 8;
 * @return {boolean}
 */
proto.marginpb.StreamRequirementRequest.prototype.getBpCall = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.marginpb.StreamRequirementRequest} returns this
 */
proto.marginpb.StreamRequirementRequest.prototype.setBpCall = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.ListRequirementResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListRequirementResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListRequirementResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListRequirementResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requirementsList: jspb.Message.toObjectList(msg.getRequirementsList(),
    proto.marginpb.Requirement.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListRequirementResponse}
 */
proto.marginpb.ListRequirementResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListRequirementResponse;
  return proto.marginpb.ListRequirementResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListRequirementResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListRequirementResponse}
 */
proto.marginpb.ListRequirementResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.Requirement;
      reader.readMessage(value,proto.marginpb.Requirement.deserializeBinaryFromReader);
      msg.addRequirements(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListRequirementResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListRequirementResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListRequirementResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirementsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marginpb.Requirement.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Requirement requirements = 1;
 * @return {!Array<!proto.marginpb.Requirement>}
 */
proto.marginpb.ListRequirementResponse.prototype.getRequirementsList = function() {
  return /** @type{!Array<!proto.marginpb.Requirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marginpb.Requirement, 1));
};


/**
 * @param {!Array<!proto.marginpb.Requirement>} value
 * @return {!proto.marginpb.ListRequirementResponse} returns this
*/
proto.marginpb.ListRequirementResponse.prototype.setRequirementsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marginpb.Requirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marginpb.Requirement}
 */
proto.marginpb.ListRequirementResponse.prototype.addRequirements = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marginpb.Requirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.ListRequirementResponse} returns this
 */
proto.marginpb.ListRequirementResponse.prototype.clearRequirementsList = function() {
  return this.setRequirementsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.RequirementDetail.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.RequirementDetail.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.RequirementDetail} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.RequirementDetail.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    accountId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    correspondent: jspb.Message.getFieldWithDefault(msg, 4, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 5, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 6, ""),
    marginType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 8, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 9, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 10, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    marginable: jspb.Message.getFieldWithDefault(msg, 12, ""),
    leveragedFactor: jspb.Message.getFieldWithDefault(msg, 13, 0),
    closingPrice: jspb.Message.getFieldWithDefault(msg, 14, ""),
    longQty: jspb.Message.getFieldWithDefault(msg, 15, ""),
    shortQty: jspb.Message.getFieldWithDefault(msg, 16, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 17, ""),
    previousQty: jspb.Message.getFieldWithDefault(msg, 18, ""),
    longMarketValue: jspb.Message.getFieldWithDefault(msg, 19, ""),
    shortMarketValue: jspb.Message.getFieldWithDefault(msg, 20, ""),
    marketValue: jspb.Message.getFieldWithDefault(msg, 21, ""),
    previousMarketValue: jspb.Message.getFieldWithDefault(msg, 22, ""),
    exchangeReq: jspb.Message.getFieldWithDefault(msg, 23, ""),
    exchangeRate: jspb.Message.getFieldWithDefault(msg, 24, ""),
    houseReq: jspb.Message.getFieldWithDefault(msg, 25, ""),
    houseRate: jspb.Message.getFieldWithDefault(msg, 26, ""),
    fedReq: jspb.Message.getFieldWithDefault(msg, 27, ""),
    fedRate: jspb.Message.getFieldWithDefault(msg, 28, ""),
    tdCostBasis: jspb.Message.getFieldWithDefault(msg, 29, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.RequirementDetail}
 */
proto.marginpb.RequirementDetail.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.RequirementDetail;
  return proto.marginpb.RequirementDetail.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.RequirementDetail} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.RequirementDetail}
 */
proto.marginpb.RequirementDetail.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarginable(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLeveragedFactor(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setClosingPrice(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongQty(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortQty(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 18:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousQty(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setLongMarketValue(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortMarketValue(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setMarketValue(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousMarketValue(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeReq(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setExchangeRate(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseReq(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setHouseRate(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedReq(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setFedRate(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setTdCostBasis(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.RequirementDetail.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.RequirementDetail.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.RequirementDetail} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.RequirementDetail.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getMarginType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getMarginable();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getLeveragedFactor();
  if (f !== 0) {
    writer.writeInt32(
      13,
      f
    );
  }
  f = message.getClosingPrice();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getLongQty();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getShortQty();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getPreviousQty();
  if (f.length > 0) {
    writer.writeString(
      18,
      f
    );
  }
  f = message.getLongMarketValue();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getShortMarketValue();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getMarketValue();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPreviousMarketValue();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getExchangeReq();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getExchangeRate();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getHouseReq();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getHouseRate();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getFedReq();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getFedRate();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getTdCostBasis();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.RequirementDetail.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.RequirementDetail} returns this
*/
proto.marginpb.RequirementDetail.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.RequirementDetail.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string account_id = 3;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string correspondent = 4;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string master_account_no = 5;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account_name = 6;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string margin_type = 7;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getMarginType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setMarginType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string original_cusip = 8;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string symbol = 9;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string cusip = 10;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string symbol_description = 11;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string marginable = 12;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getMarginable = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setMarginable = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional int32 leveraged_factor = 13;
 * @return {number}
 */
proto.marginpb.RequirementDetail.prototype.getLeveragedFactor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 13, 0));
};


/**
 * @param {number} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setLeveragedFactor = function(value) {
  return jspb.Message.setProto3IntField(this, 13, value);
};


/**
 * optional string closing_price = 14;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getClosingPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setClosingPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string long_qty = 15;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getLongQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setLongQty = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string short_qty = 16;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getShortQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setShortQty = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * optional string qty = 17;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional string previous_qty = 18;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getPreviousQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 18, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setPreviousQty = function(value) {
  return jspb.Message.setProto3StringField(this, 18, value);
};


/**
 * optional string long_market_value = 19;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getLongMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setLongMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string short_market_value = 20;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getShortMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setShortMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string market_value = 21;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string previous_market_value = 22;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getPreviousMarketValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setPreviousMarketValue = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string exchange_req = 23;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getExchangeReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setExchangeReq = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string exchange_rate = 24;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getExchangeRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setExchangeRate = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string house_req = 25;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getHouseReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setHouseReq = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string house_rate = 26;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getHouseRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setHouseRate = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string fed_req = 27;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getFedReq = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setFedReq = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string fed_rate = 28;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getFedRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setFedRate = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string td_cost_basis = 29;
 * @return {string}
 */
proto.marginpb.RequirementDetail.prototype.getTdCostBasis = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.RequirementDetail} returns this
 */
proto.marginpb.RequirementDetail.prototype.setTdCostBasis = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListRequirementDetailRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListRequirementDetailRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListRequirementDetailRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementDetailRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListRequirementDetailRequest}
 */
proto.marginpb.ListRequirementDetailRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListRequirementDetailRequest;
  return proto.marginpb.ListRequirementDetailRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListRequirementDetailRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListRequirementDetailRequest}
 */
proto.marginpb.ListRequirementDetailRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListRequirementDetailRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListRequirementDetailRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListRequirementDetailRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementDetailRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional google.type.Date trade_date = 1;
 * @return {?proto.google.type.Date}
 */
proto.marginpb.ListRequirementDetailRequest.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 1));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.marginpb.ListRequirementDetailRequest} returns this
*/
proto.marginpb.ListRequirementDetailRequest.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.marginpb.ListRequirementDetailRequest} returns this
 */
proto.marginpb.ListRequirementDetailRequest.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.marginpb.ListRequirementDetailRequest.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string master_account_no = 2;
 * @return {string}
 */
proto.marginpb.ListRequirementDetailRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.marginpb.ListRequirementDetailRequest} returns this
 */
proto.marginpb.ListRequirementDetailRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.marginpb.ListRequirementDetailResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.marginpb.ListRequirementDetailResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.marginpb.ListRequirementDetailResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.marginpb.ListRequirementDetailResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementDetailResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    requirementDetailsList: jspb.Message.toObjectList(msg.getRequirementDetailsList(),
    proto.marginpb.RequirementDetail.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.marginpb.ListRequirementDetailResponse}
 */
proto.marginpb.ListRequirementDetailResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.marginpb.ListRequirementDetailResponse;
  return proto.marginpb.ListRequirementDetailResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.marginpb.ListRequirementDetailResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.marginpb.ListRequirementDetailResponse}
 */
proto.marginpb.ListRequirementDetailResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.marginpb.RequirementDetail;
      reader.readMessage(value,proto.marginpb.RequirementDetail.deserializeBinaryFromReader);
      msg.addRequirementDetails(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.marginpb.ListRequirementDetailResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.marginpb.ListRequirementDetailResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.marginpb.ListRequirementDetailResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.marginpb.ListRequirementDetailResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequirementDetailsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.marginpb.RequirementDetail.serializeBinaryToWriter
    );
  }
};


/**
 * repeated RequirementDetail requirement_details = 1;
 * @return {!Array<!proto.marginpb.RequirementDetail>}
 */
proto.marginpb.ListRequirementDetailResponse.prototype.getRequirementDetailsList = function() {
  return /** @type{!Array<!proto.marginpb.RequirementDetail>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.marginpb.RequirementDetail, 1));
};


/**
 * @param {!Array<!proto.marginpb.RequirementDetail>} value
 * @return {!proto.marginpb.ListRequirementDetailResponse} returns this
*/
proto.marginpb.ListRequirementDetailResponse.prototype.setRequirementDetailsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.marginpb.RequirementDetail=} opt_value
 * @param {number=} opt_index
 * @return {!proto.marginpb.RequirementDetail}
 */
proto.marginpb.ListRequirementDetailResponse.prototype.addRequirementDetails = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.marginpb.RequirementDetail, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.marginpb.ListRequirementDetailResponse} returns this
 */
proto.marginpb.ListRequirementDetailResponse.prototype.clearRequirementDetailsList = function() {
  return this.setRequirementDetailsList([]);
};


goog.object.extend(exports, proto.marginpb);
