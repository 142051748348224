// source: reorgpb/cashinlieu.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.reorgpb.CashInLieu', null, global);
goog.exportSymbol('proto.reorgpb.ListCashInLieuRequest', null, global);
goog.exportSymbol('proto.reorgpb.ListCashInLieuResponse', null, global);
goog.exportSymbol('proto.reorgpb.UpdateCashInLieuRequest', null, global);
goog.exportSymbol('proto.reorgpb.UpdateCashInLieuResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.CashInLieu = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.CashInLieu, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.CashInLieu.displayName = 'proto.reorgpb.CashInLieu';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListCashInLieuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.ListCashInLieuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListCashInLieuRequest.displayName = 'proto.reorgpb.ListCashInLieuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.ListCashInLieuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reorgpb.ListCashInLieuResponse.repeatedFields_, null);
};
goog.inherits(proto.reorgpb.ListCashInLieuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.ListCashInLieuResponse.displayName = 'proto.reorgpb.ListCashInLieuResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateCashInLieuRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateCashInLieuRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateCashInLieuRequest.displayName = 'proto.reorgpb.UpdateCashInLieuRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reorgpb.UpdateCashInLieuResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reorgpb.UpdateCashInLieuResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reorgpb.UpdateCashInLieuResponse.displayName = 'proto.reorgpb.UpdateCashInLieuResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.CashInLieu.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.CashInLieu.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.CashInLieu} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.CashInLieu.toObject = function(includeInstance, msg) {
  var f, obj = {
    textNumber: jspb.Message.getFieldWithDefault(msg, 1, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 2, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 4, ""),
    positionDate: (f = msg.getPositionDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    processDate: (f = msg.getProcessDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    price: jspb.Message.getFieldWithDefault(msg, 7, ""),
    cashRate: jspb.Message.getFieldWithDefault(msg, 8, ""),
    netCash: jspb.Message.getFieldWithDefault(msg, 9, ""),
    netQty: jspb.Message.getFieldWithDefault(msg, 10, ""),
    netPayCash: jspb.Message.getFieldWithDefault(msg, 11, ""),
    netPayQty: jspb.Message.getFieldWithDefault(msg, 12, ""),
    detail: jspb.Message.getFieldWithDefault(msg, 13, ""),
    announcementType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    pendingTrnsCount: jspb.Message.getFieldWithDefault(msg, 15, 0),
    externalId: jspb.Message.getFieldWithDefault(msg, 16, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.CashInLieu}
 */
proto.reorgpb.CashInLieu.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.CashInLieu;
  return proto.reorgpb.CashInLieu.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.CashInLieu} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.CashInLieu}
 */
proto.reorgpb.CashInLieu.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextNumber(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setPositionDate(value);
      break;
    case 6:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setProcessDate(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCashRate(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetCash(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetQty(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayCash(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetPayQty(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setDetail(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnnouncementType(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setPendingTrnsCount(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.CashInLieu.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.CashInLieu.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.CashInLieu} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.CashInLieu.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTextNumber();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPositionDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getProcessDate();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCashRate();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getNetCash();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getNetQty();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getNetPayCash();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getNetPayQty();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getDetail();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getAnnouncementType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getPendingTrnsCount();
  if (f !== 0) {
    writer.writeInt32(
      15,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
};


/**
 * optional string text_number = 1;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getTextNumber = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setTextNumber = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string cusip = 2;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string symbol = 3;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string symbol_description = 4;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date position_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.CashInLieu.prototype.getPositionDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.CashInLieu} returns this
*/
proto.reorgpb.CashInLieu.prototype.setPositionDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.clearPositionDate = function() {
  return this.setPositionDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.CashInLieu.prototype.hasPositionDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional google.type.Date process_date = 6;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.CashInLieu.prototype.getProcessDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 6));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.CashInLieu} returns this
*/
proto.reorgpb.CashInLieu.prototype.setProcessDate = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.clearProcessDate = function() {
  return this.setProcessDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.CashInLieu.prototype.hasProcessDate = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional string price = 7;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string cash_rate = 8;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getCashRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setCashRate = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string net_cash = 9;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getNetCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setNetCash = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string net_qty = 10;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getNetQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setNetQty = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string net_pay_cash = 11;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getNetPayCash = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setNetPayCash = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string net_pay_qty = 12;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getNetPayQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setNetPayQty = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string detail = 13;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getDetail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setDetail = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional string announcement_type = 14;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getAnnouncementType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setAnnouncementType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional int32 pending_trns_count = 15;
 * @return {number}
 */
proto.reorgpb.CashInLieu.prototype.getPendingTrnsCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setPendingTrnsCount = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional string external_id = 16;
 * @return {string}
 */
proto.reorgpb.CashInLieu.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.CashInLieu} returns this
 */
proto.reorgpb.CashInLieu.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListCashInLieuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListCashInLieuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListCashInLieuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    symbol: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromSystemDate: (f = msg.getFromSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toSystemDate: (f = msg.getToSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    pendingTrnsOnly: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListCashInLieuRequest}
 */
proto.reorgpb.ListCashInLieuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListCashInLieuRequest;
  return proto.reorgpb.ListCashInLieuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListCashInLieuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListCashInLieuRequest}
 */
proto.reorgpb.ListCashInLieuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromSystemDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToSystemDate(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPendingTrnsOnly(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListCashInLieuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListCashInLieuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListCashInLieuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromSystemDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToSystemDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getPendingTrnsOnly();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string symbol = 1;
 * @return {string}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
 */
proto.reorgpb.ListCashInLieuRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_system_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.getFromSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
*/
proto.reorgpb.ListCashInLieuRequest.prototype.setFromSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
 */
proto.reorgpb.ListCashInLieuRequest.prototype.clearFromSystemDate = function() {
  return this.setFromSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.hasFromSystemDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_system_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.getToSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
*/
proto.reorgpb.ListCashInLieuRequest.prototype.setToSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
 */
proto.reorgpb.ListCashInLieuRequest.prototype.clearToSystemDate = function() {
  return this.setToSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.hasToSystemDate = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool pending_trns_only = 4;
 * @return {boolean}
 */
proto.reorgpb.ListCashInLieuRequest.prototype.getPendingTrnsOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.reorgpb.ListCashInLieuRequest} returns this
 */
proto.reorgpb.ListCashInLieuRequest.prototype.setPendingTrnsOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reorgpb.ListCashInLieuResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.ListCashInLieuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.ListCashInLieuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.ListCashInLieuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListCashInLieuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cashInLieusList: jspb.Message.toObjectList(msg.getCashInLieusList(),
    proto.reorgpb.CashInLieu.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.ListCashInLieuResponse}
 */
proto.reorgpb.ListCashInLieuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.ListCashInLieuResponse;
  return proto.reorgpb.ListCashInLieuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.ListCashInLieuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.ListCashInLieuResponse}
 */
proto.reorgpb.ListCashInLieuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.CashInLieu;
      reader.readMessage(value,proto.reorgpb.CashInLieu.deserializeBinaryFromReader);
      msg.addCashInLieus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.ListCashInLieuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.ListCashInLieuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.ListCashInLieuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.ListCashInLieuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashInLieusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reorgpb.CashInLieu.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CashInLieu cash_in_lieus = 1;
 * @return {!Array<!proto.reorgpb.CashInLieu>}
 */
proto.reorgpb.ListCashInLieuResponse.prototype.getCashInLieusList = function() {
  return /** @type{!Array<!proto.reorgpb.CashInLieu>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reorgpb.CashInLieu, 1));
};


/**
 * @param {!Array<!proto.reorgpb.CashInLieu>} value
 * @return {!proto.reorgpb.ListCashInLieuResponse} returns this
*/
proto.reorgpb.ListCashInLieuResponse.prototype.setCashInLieusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reorgpb.CashInLieu=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reorgpb.CashInLieu}
 */
proto.reorgpb.ListCashInLieuResponse.prototype.addCashInLieus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reorgpb.CashInLieu, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reorgpb.ListCashInLieuResponse} returns this
 */
proto.reorgpb.ListCashInLieuResponse.prototype.clearCashInLieusList = function() {
  return this.setCashInLieusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateCashInLieuRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateCashInLieuRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateCashInLieuRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    price: jspb.Message.getFieldWithDefault(msg, 1, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateCashInLieuRequest}
 */
proto.reorgpb.UpdateCashInLieuRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateCashInLieuRequest;
  return proto.reorgpb.UpdateCashInLieuRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateCashInLieuRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateCashInLieuRequest}
 */
proto.reorgpb.UpdateCashInLieuRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateCashInLieuRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateCashInLieuRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateCashInLieuRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string price = 1;
 * @return {string}
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateCashInLieuRequest} returns this
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string external_id = 2;
 * @return {string}
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reorgpb.UpdateCashInLieuRequest} returns this
 */
proto.reorgpb.UpdateCashInLieuRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reorgpb.UpdateCashInLieuResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reorgpb.UpdateCashInLieuResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reorgpb.UpdateCashInLieuResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateCashInLieuResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    cashInLieu: (f = msg.getCashInLieu()) && proto.reorgpb.CashInLieu.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reorgpb.UpdateCashInLieuResponse}
 */
proto.reorgpb.UpdateCashInLieuResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reorgpb.UpdateCashInLieuResponse;
  return proto.reorgpb.UpdateCashInLieuResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reorgpb.UpdateCashInLieuResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reorgpb.UpdateCashInLieuResponse}
 */
proto.reorgpb.UpdateCashInLieuResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reorgpb.CashInLieu;
      reader.readMessage(value,proto.reorgpb.CashInLieu.deserializeBinaryFromReader);
      msg.setCashInLieu(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reorgpb.UpdateCashInLieuResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reorgpb.UpdateCashInLieuResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reorgpb.UpdateCashInLieuResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reorgpb.UpdateCashInLieuResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCashInLieu();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.reorgpb.CashInLieu.serializeBinaryToWriter
    );
  }
};


/**
 * optional CashInLieu cash_in_lieu = 1;
 * @return {?proto.reorgpb.CashInLieu}
 */
proto.reorgpb.UpdateCashInLieuResponse.prototype.getCashInLieu = function() {
  return /** @type{?proto.reorgpb.CashInLieu} */ (
    jspb.Message.getWrapperField(this, proto.reorgpb.CashInLieu, 1));
};


/**
 * @param {?proto.reorgpb.CashInLieu|undefined} value
 * @return {!proto.reorgpb.UpdateCashInLieuResponse} returns this
*/
proto.reorgpb.UpdateCashInLieuResponse.prototype.setCashInLieu = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reorgpb.UpdateCashInLieuResponse} returns this
 */
proto.reorgpb.UpdateCashInLieuResponse.prototype.clearCashInLieu = function() {
  return this.setCashInLieu(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reorgpb.UpdateCashInLieuResponse.prototype.hasCashInLieu = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.reorgpb);
