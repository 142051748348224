// source: admpb/userguide.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
goog.exportSymbol('proto.admpb.CreateUserGuideResponse', null, global);
goog.exportSymbol('proto.admpb.DeleteUserGuideRequest', null, global);
goog.exportSymbol('proto.admpb.DeleteUserGuideResponse', null, global);
goog.exportSymbol('proto.admpb.ListUserGuideResponse', null, global);
goog.exportSymbol('proto.admpb.ReadUserGuideRequest', null, global);
goog.exportSymbol('proto.admpb.ReadUserGuideResponse', null, global);
goog.exportSymbol('proto.admpb.UpdateUserGuideRequest', null, global);
goog.exportSymbol('proto.admpb.UpdateUserGuideResponse', null, global);
goog.exportSymbol('proto.admpb.UserGuide', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UserGuide = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UserGuide, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UserGuide.displayName = 'proto.admpb.UserGuide';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.CreateUserGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.CreateUserGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.CreateUserGuideResponse.displayName = 'proto.admpb.CreateUserGuideResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ReadUserGuideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ReadUserGuideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ReadUserGuideRequest.displayName = 'proto.admpb.ReadUserGuideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ReadUserGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.ReadUserGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ReadUserGuideResponse.displayName = 'proto.admpb.ReadUserGuideResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateUserGuideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateUserGuideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateUserGuideRequest.displayName = 'proto.admpb.UpdateUserGuideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.UpdateUserGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.UpdateUserGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.UpdateUserGuideResponse.displayName = 'proto.admpb.UpdateUserGuideResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.DeleteUserGuideRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.DeleteUserGuideRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.DeleteUserGuideRequest.displayName = 'proto.admpb.DeleteUserGuideRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.DeleteUserGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.admpb.DeleteUserGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.DeleteUserGuideResponse.displayName = 'proto.admpb.DeleteUserGuideResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.admpb.ListUserGuideResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.admpb.ListUserGuideResponse.repeatedFields_, null);
};
goog.inherits(proto.admpb.ListUserGuideResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.admpb.ListUserGuideResponse.displayName = 'proto.admpb.ListUserGuideResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UserGuide.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UserGuide.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UserGuide} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UserGuide.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modifiedDate: (f = msg.getModifiedDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tags: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pageName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pagePath: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UserGuide}
 */
proto.admpb.UserGuide.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UserGuide;
  return proto.admpb.UserGuide.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UserGuide} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UserGuide}
 */
proto.admpb.UserGuide.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGuideId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setModifiedDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTags(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPagePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UserGuide.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UserGuide.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UserGuide} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UserGuide.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModifiedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTags();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPageName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPagePath();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string user_guide_id = 1;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getUserGuideId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string modified_by = 4;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date modified_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.admpb.UserGuide.prototype.getModifiedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.UserGuide} returns this
*/
proto.admpb.UserGuide.prototype.setModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.clearModifiedDate = function() {
  return this.setModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UserGuide.prototype.hasModifiedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string tags = 6;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setTags = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string page_name = 8;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getPageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setPageName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string page_path = 9;
 * @return {string}
 */
proto.admpb.UserGuide.prototype.getPagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UserGuide} returns this
 */
proto.admpb.UserGuide.prototype.setPagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.CreateUserGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.CreateUserGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.CreateUserGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateUserGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuide: (f = msg.getUserGuide()) && proto.admpb.UserGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.CreateUserGuideResponse}
 */
proto.admpb.CreateUserGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.CreateUserGuideResponse;
  return proto.admpb.CreateUserGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.CreateUserGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.CreateUserGuideResponse}
 */
proto.admpb.CreateUserGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.UserGuide;
      reader.readMessage(value,proto.admpb.UserGuide.deserializeBinaryFromReader);
      msg.setUserGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.CreateUserGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.CreateUserGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.CreateUserGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.CreateUserGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.UserGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGuide user_guide = 1;
 * @return {?proto.admpb.UserGuide}
 */
proto.admpb.CreateUserGuideResponse.prototype.getUserGuide = function() {
  return /** @type{?proto.admpb.UserGuide} */ (
    jspb.Message.getWrapperField(this, proto.admpb.UserGuide, 1));
};


/**
 * @param {?proto.admpb.UserGuide|undefined} value
 * @return {!proto.admpb.CreateUserGuideResponse} returns this
*/
proto.admpb.CreateUserGuideResponse.prototype.setUserGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.CreateUserGuideResponse} returns this
 */
proto.admpb.CreateUserGuideResponse.prototype.clearUserGuide = function() {
  return this.setUserGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.CreateUserGuideResponse.prototype.hasUserGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ReadUserGuideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ReadUserGuideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ReadUserGuideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadUserGuideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ReadUserGuideRequest}
 */
proto.admpb.ReadUserGuideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ReadUserGuideRequest;
  return proto.admpb.ReadUserGuideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ReadUserGuideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ReadUserGuideRequest}
 */
proto.admpb.ReadUserGuideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGuideId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ReadUserGuideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ReadUserGuideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ReadUserGuideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadUserGuideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_guide_id = 1;
 * @return {string}
 */
proto.admpb.ReadUserGuideRequest.prototype.getUserGuideId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.ReadUserGuideRequest} returns this
 */
proto.admpb.ReadUserGuideRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ReadUserGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ReadUserGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ReadUserGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadUserGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuide: (f = msg.getUserGuide()) && proto.admpb.UserGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ReadUserGuideResponse}
 */
proto.admpb.ReadUserGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ReadUserGuideResponse;
  return proto.admpb.ReadUserGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ReadUserGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ReadUserGuideResponse}
 */
proto.admpb.ReadUserGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.UserGuide;
      reader.readMessage(value,proto.admpb.UserGuide.deserializeBinaryFromReader);
      msg.setUserGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ReadUserGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ReadUserGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ReadUserGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ReadUserGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.UserGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGuide user_guide = 1;
 * @return {?proto.admpb.UserGuide}
 */
proto.admpb.ReadUserGuideResponse.prototype.getUserGuide = function() {
  return /** @type{?proto.admpb.UserGuide} */ (
    jspb.Message.getWrapperField(this, proto.admpb.UserGuide, 1));
};


/**
 * @param {?proto.admpb.UserGuide|undefined} value
 * @return {!proto.admpb.ReadUserGuideResponse} returns this
*/
proto.admpb.ReadUserGuideResponse.prototype.setUserGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.ReadUserGuideResponse} returns this
 */
proto.admpb.ReadUserGuideResponse.prototype.clearUserGuide = function() {
  return this.setUserGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.ReadUserGuideResponse.prototype.hasUserGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateUserGuideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateUserGuideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateUserGuideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateUserGuideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    content: jspb.Message.getFieldWithDefault(msg, 3, ""),
    modifiedBy: jspb.Message.getFieldWithDefault(msg, 4, ""),
    modifiedDate: (f = msg.getModifiedDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tags: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    pageName: jspb.Message.getFieldWithDefault(msg, 8, ""),
    pagePath: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateUserGuideRequest}
 */
proto.admpb.UpdateUserGuideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateUserGuideRequest;
  return proto.admpb.UpdateUserGuideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateUserGuideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateUserGuideRequest}
 */
proto.admpb.UpdateUserGuideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGuideId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContent(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setModifiedBy(value);
      break;
    case 5:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setModifiedDate(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setTags(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setPageName(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setPagePath(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateUserGuideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateUserGuideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateUserGuideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateUserGuideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContent();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getModifiedBy();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getModifiedDate();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTags();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPageName();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getPagePath();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string user_guide_id = 1;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getUserGuideId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string content = 3;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getContent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setContent = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string modified_by = 4;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getModifiedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setModifiedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.type.Date modified_date = 5;
 * @return {?proto.google.type.Date}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getModifiedDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 5));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
*/
proto.admpb.UpdateUserGuideRequest.prototype.setModifiedDate = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.clearModifiedDate = function() {
  return this.setModifiedDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UpdateUserGuideRequest.prototype.hasModifiedDate = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string tags = 6;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getTags = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setTags = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string page_name = 8;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getPageName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setPageName = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string page_path = 9;
 * @return {string}
 */
proto.admpb.UpdateUserGuideRequest.prototype.getPagePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.UpdateUserGuideRequest} returns this
 */
proto.admpb.UpdateUserGuideRequest.prototype.setPagePath = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.UpdateUserGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.UpdateUserGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.UpdateUserGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateUserGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuide: (f = msg.getUserGuide()) && proto.admpb.UserGuide.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.UpdateUserGuideResponse}
 */
proto.admpb.UpdateUserGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.UpdateUserGuideResponse;
  return proto.admpb.UpdateUserGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.UpdateUserGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.UpdateUserGuideResponse}
 */
proto.admpb.UpdateUserGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.UserGuide;
      reader.readMessage(value,proto.admpb.UserGuide.deserializeBinaryFromReader);
      msg.setUserGuide(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.UpdateUserGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.UpdateUserGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.UpdateUserGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.UpdateUserGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuide();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.admpb.UserGuide.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserGuide user_guide = 1;
 * @return {?proto.admpb.UserGuide}
 */
proto.admpb.UpdateUserGuideResponse.prototype.getUserGuide = function() {
  return /** @type{?proto.admpb.UserGuide} */ (
    jspb.Message.getWrapperField(this, proto.admpb.UserGuide, 1));
};


/**
 * @param {?proto.admpb.UserGuide|undefined} value
 * @return {!proto.admpb.UpdateUserGuideResponse} returns this
*/
proto.admpb.UpdateUserGuideResponse.prototype.setUserGuide = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.admpb.UpdateUserGuideResponse} returns this
 */
proto.admpb.UpdateUserGuideResponse.prototype.clearUserGuide = function() {
  return this.setUserGuide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.admpb.UpdateUserGuideResponse.prototype.hasUserGuide = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.DeleteUserGuideRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.DeleteUserGuideRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.DeleteUserGuideRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteUserGuideRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.DeleteUserGuideRequest}
 */
proto.admpb.DeleteUserGuideRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.DeleteUserGuideRequest;
  return proto.admpb.DeleteUserGuideRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.DeleteUserGuideRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.DeleteUserGuideRequest}
 */
proto.admpb.DeleteUserGuideRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGuideId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.DeleteUserGuideRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.DeleteUserGuideRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.DeleteUserGuideRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteUserGuideRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_guide_id = 1;
 * @return {string}
 */
proto.admpb.DeleteUserGuideRequest.prototype.getUserGuideId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.DeleteUserGuideRequest} returns this
 */
proto.admpb.DeleteUserGuideRequest.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.DeleteUserGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.DeleteUserGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.DeleteUserGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteUserGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuideId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.DeleteUserGuideResponse}
 */
proto.admpb.DeleteUserGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.DeleteUserGuideResponse;
  return proto.admpb.DeleteUserGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.DeleteUserGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.DeleteUserGuideResponse}
 */
proto.admpb.DeleteUserGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserGuideId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.DeleteUserGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.DeleteUserGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.DeleteUserGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.DeleteUserGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuideId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string user_guide_id = 1;
 * @return {string}
 */
proto.admpb.DeleteUserGuideResponse.prototype.getUserGuideId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.admpb.DeleteUserGuideResponse} returns this
 */
proto.admpb.DeleteUserGuideResponse.prototype.setUserGuideId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.admpb.ListUserGuideResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.admpb.ListUserGuideResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.admpb.ListUserGuideResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.admpb.ListUserGuideResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListUserGuideResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    userGuidesList: jspb.Message.toObjectList(msg.getUserGuidesList(),
    proto.admpb.UserGuide.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.admpb.ListUserGuideResponse}
 */
proto.admpb.ListUserGuideResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.admpb.ListUserGuideResponse;
  return proto.admpb.ListUserGuideResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.admpb.ListUserGuideResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.admpb.ListUserGuideResponse}
 */
proto.admpb.ListUserGuideResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.admpb.UserGuide;
      reader.readMessage(value,proto.admpb.UserGuide.deserializeBinaryFromReader);
      msg.addUserGuides(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.admpb.ListUserGuideResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.admpb.ListUserGuideResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.admpb.ListUserGuideResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.admpb.ListUserGuideResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserGuidesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.admpb.UserGuide.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UserGuide user_guides = 1;
 * @return {!Array<!proto.admpb.UserGuide>}
 */
proto.admpb.ListUserGuideResponse.prototype.getUserGuidesList = function() {
  return /** @type{!Array<!proto.admpb.UserGuide>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.admpb.UserGuide, 1));
};


/**
 * @param {!Array<!proto.admpb.UserGuide>} value
 * @return {!proto.admpb.ListUserGuideResponse} returns this
*/
proto.admpb.ListUserGuideResponse.prototype.setUserGuidesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.admpb.UserGuide=} opt_value
 * @param {number=} opt_index
 * @return {!proto.admpb.UserGuide}
 */
proto.admpb.ListUserGuideResponse.prototype.addUserGuides = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.admpb.UserGuide, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.admpb.ListUserGuideResponse} returns this
 */
proto.admpb.ListUserGuideResponse.prototype.clearUserGuidesList = function() {
  return this.setUserGuidesList([]);
};


goog.object.extend(exports, proto.admpb);
