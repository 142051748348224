export const trnsOptions = [
  {
    value: 'ACATC',
    label: 'ACATC',
    description: 'ACATC IN/OUT (Cash)',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'ACATS',
    label: 'ACATS',
    description: 'ACATC IN/OUT (Securities)',
    screen: 'Position Movement',
    screen_no: 3,
  },
  {
    value: 'CSD',
    label: 'CSD',
    description: 'Cash disbursement(+)',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'CSW',
    label: 'CSW',
    description: 'Cash Withdrawal',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'INT',
    label: 'INR',
    description: 'Credit/Margin Interest',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'JNLC',
    label: 'JNLC',
    description: 'Journal Entry(Cash)',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'JNLS',
    label: 'JNLS',
    description: 'Journal Entry(Stock)',
    screen: 'Position Movement',
    screen_no: 3,
  },
  {
    value: 'OCT',
    label: 'OCT',
    description: 'On Chain Transfer',
    screen: 'Position Movement',
    screen_no: 3,
  },
  {
    value: 'PTC',
    label: 'PTC',
    description: 'Pass-Thru Charge',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'PTR',
    label: 'PTR',
    description: 'Pass-Thru Rebate',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'TRD',
    label: 'TRD',
    description: 'Trade Entry',
    screen: 'Trade',
    screen_no: 1,
  },
  {
    value: 'REORG',
    label: 'REORG',
    description: 'ReOrg Corp Action',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'SPIN',
    label: 'SPIN',
    description: 'Stock SpinOff',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'SPLIT',
    label: 'SPLIT',
    description: 'Stock Split',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'DIV',
    label: 'DIV',
    description: 'Dividends',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'ALLOC',
    label: 'ALLOC',
    description: 'Allocation',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'DIVNRA',
    label: 'DIVNRA',
    description: 'Div. Adj(NRA Withheld)',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'DIVWH',
    label: 'DIVWH',
    description: 'Div. Withholding',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'DIVPWH',
    label: 'DIVPWH',
    description: 'Div. product Withholding',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'MA',
    label: 'MA',
    description: 'Merger/Acquisition',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'FEE',
    label: 'FEE',
    description: 'Fee',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'CORR',
    label: 'CORR',
    description: 'Corrected trade entry',
    screen: 'Trade',
    screen_no: 1,
  },
  {
    value: 'SEG',
    label: 'SEG',
    description: 'Segregation',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'CIL',
    label: 'CIL',
    description: 'CIL',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'INVPNL',
    label: 'INVPNL',
    description: 'INVPNL',
    screen: 'Cash Movement',
    screen_no: 2,
  },
  {
    value: 'FXTRD',
    label: 'FXTRD',
    description: 'FX Trade Entry',
    screen: 'Trade',
    screen_no: 1,
  },
  {
    value: 'MEM',
    label: 'MEM',
    description: 'Memopost Entry',
    screen: 'Position Movement',
    screen_no: 3,
  },
  {
    value: 'JSJC',
    label: 'JSJC',
    description: 'Journal Entry(Stock & Cash)',
    screen: 'Journal Position and Cash',
    screen_no: 5,
  },
  {
    value: 'MMSWP',
    label: 'MMSWP',
    description: 'Money Market Sweep',
    screen: 'Position Movement',
    screen_no: 3,
  },
  {
    value: 'CFEE',
    label: 'CFEE',
    description: 'Cypto Fee Entry',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
  {
    value: 'WH',
    label: 'WH',
    description: 'Withholdings',
    screen: 'Cash and/or Position Movement',
    screen_no: 4,
  },
];

export const entrySubTypeOptions = [
  {
    value: 'REG',
    label: 'REG',
  },
  {
    value: 'TAF',
    label: 'TAF',
  },
  {
    value: 'JIT',
    label: 'JIT',
  },
  { value: 'FWH', label: 'FWH' },
  { value: 'SWH', label: 'SWH' },
  { value: 'SNC', label: 'SNC' },
  { value: 'CNC', label: 'CNC' },
  { value: 'SCNC', label: 'SCNC' },
];

export const sideOptions = [
  { value: 'sell', label: 'Sell' },
  { value: 'sell_short', label: 'Sell Short' },
  { value: 'buy', label: 'Buy' },
];
