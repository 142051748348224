export const menus = [
  {
    id: 1,
    groupLabel: 'Admin / User Management',
    label: 'Administrator',
    link: '/app/user-management/administrator',
    page: 'administrator',
  },
  {
    id: 1,
    groupLabel: 'Admin / User Management',
    label: 'Role',
    link: '/app/user-management/role',
    page: 'role',
  },
  {
    id: 1,
    groupLabel: 'Admin / Account Setup',
    label: 'Client Account',
    link: '/app/account-setup/client-account',
    page: 'client-account',
  },
  {
    id: 2,
    groupLabel: 'Admin / Account Setup',
    label: 'GL Account',
    link: '/app/account-setup/gl-account',
    page: 'gl-account',
  },
  {
    id: 3,
    groupLabel: 'Admin / Account Setup',
    label: 'Bank Account',
    link: '/app/account-setup/bank-account',
    page: 'bank-account',
  },
  {
    id: 4,
    groupLabel: 'Admin / Account Setup',
    label: 'Bank Address',
    link: '/app/account-setup/bank-address',
    page: 'bank-address',
  },
  {
    id: 5,
    groupLabel: 'Admin / Account Setup',
    label: 'ACH/Wire',
    link: '/app/account-setup/ach-wire',
    page: 'ach-wire',
  },
  {
    id: 6,
    groupLabel: 'Admin / Account Setup',
    label: 'Recon Mapping',
    link: '/app/account-setup/recon-mapping',
    page: 'recon-mapping',
  },
  {
    id: 7,
    groupLabel: 'Admin / System Profile',
    label: 'System Profile',
    link: '/app/system-profile/system-profile',
    page: 'system-profile',
  },
  {
    id: 8,
    groupLabel: 'Admin / System Profile',
    label: 'System Code',
    link: '/app/system-profile/system-code',
    page: 'system-code',
  },
  {
    id: 9,
    groupLabel: 'Admin / System Profile',
    label: 'System Number',
    link: '/app/system-profile/system-number',
    page: 'system-number',
  },
  {
    id: 10,
    groupLabel: 'Admin / System Profile',
    label: 'Calendar',
    link: '/app/system-profile/calendar',
    page: 'calendar',
  },
  {
    id: 11,
    groupLabel: 'Balance / Cash',
    label: 'Balance',
    link: '/app/cash/balance',
    page: 'balance',
  },
  {
    id: 12,
    groupLabel: 'Balance / Cash',
    label: 'Balance Reconciliation',
    link: '/app/cash/reconciliation-balance',
    page: 'reconciliation-balance',
  },
  {
    id: 13,
    groupLabel: 'Balance / Cash',
    label: 'Trial Balance',
    link: '/app/cash/trial-balance',
    page: 'trial-balance',
  },
  {
    id: 14,
    groupLabel: 'Balance / Cash',
    label: 'GL Report',
    link: '/app/cash/gl-report',
    page: 'gl-report',
  },
  {
    id: 15,
    groupLabel: 'Balance / Margin Interest',
    label: 'Margin Rate',
    link: '/app/margin-interest/margin-rate',
    page: 'margin-rate',
  },
  {
    id: 16,
    groupLabel: 'Balance / Margin Interest',
    label: 'Margin Requirement',
    link: '/app/margin-interest/margin-requirement',
    page: 'margin-requirement',
  },
  {
    id: 17,
    groupLabel: 'Balance / Margin Interest',
    label: 'Margin Interest Calc',
    link: '/app/margin-interest/margin-interest-calc',
    page: 'margin-interest-calc',
  },
  {
    id: 18,
    groupLabel: 'Balance / Margin Interest',
    label: 'Margin Interest Rate',
    link: '/app/margin-interest/margin-interest-rate',
    page: 'margin-interest-rate',
  },
  {
    id: 19,
    groupLabel: 'Balance / Margin Interest',
    label: 'Call Logs',
    link: '/app/margin-interest/call-log',
    page: 'call-log',
  },
  {
    id: 20,
    groupLabel: 'Compliance / Segregation',
    label: 'Segregation',
    link: '/app/segregation/segregation',
    page: 'segregation',
  },
  {
    id: 21,
    groupLabel: 'Compliance / Calculation',
    label: 'Reserve Calc',
    link: '/app/calculation/reserve-calc',
    page: 'reserve-calc',
  },
  {
    id: 22,
    groupLabel: 'Transaction',
    label: 'Enter TRNS',
    link: '/app/trns/enter-trns',
    page: 'enter-trns',
  },
  {
    id: 23,
    groupLabel: 'Transaction',
    label: 'Activity',
    link: '/app/trns/activity',
    page: 'activity',
  },
  {
    id: 24,
    groupLabel: 'Transaction',
    label: 'Pending TRNS',
    link: '/app/trns/pending-trns',
    page: 'pending-trns',
  },
  {
    id: 25,
    groupLabel: 'Transaction',
    label: 'Execution Reconciliation',
    link: '/app/trns/reconciliation-execution',
    page: 'reconciliation-execution',
  },
  {
    id: 26,
    groupLabel: 'Transaction',
    label: 'Fee',
    link: '/app/trns/fee',
    page: 'fee',
  },
  {
    id: 27,
    groupLabel: 'Transaction',
    label: 'Fee Management',
    link: '/app/trns/fee-management',
    page: 'fee-management',
  },
  {
    id: 28,
    groupLabel: 'Position',
    label: 'Position',
    link: '/app/position/position',
    page: 'position',
  },
  {
    id: 29,
    groupLabel: 'Position',
    label: 'Position Reconciliation',
    link: '/app/position/reconciliation-position',
    page: 'reconciliation-position',
  },
  {
    id: 30,
    groupLabel: 'Position',
    label: 'Fractional Shares Reconciliation',
    link: '/app/position/fractional-share-recon',
    page: 'fractional-share-recon',
  },
  {
    id: 31,
    groupLabel: 'Security / Security Details',
    label: 'Price',
    link: '/app/security-details/price',
    page: 'price',
  },
  {
    id: 32,
    groupLabel: 'Security / Security Details',
    label: 'Security Master',
    link: '/app/security-details/security-master',
    page: 'security-master',
  },
  {
    id: 33,
    groupLabel: 'Security / Security Details',
    label: 'Etf',
    link: '/app/security-details/etf',
    page: 'etf',
  },
  {
    id: 34,
    groupLabel: 'Security / Corp Action',
    label: 'Announcement',
    link: '/app/corp-action/announcement',
    page: 'announcement',
  },
  {
    id: 35,
    groupLabel: 'Security / Corp Action',
    label: 'Entitlement',
    link: '/app/corp-action/entitlement',
    page: 'entitlement',
  },
  {
    id: 36,
    groupLabel: 'Security / Corp Action',
    label: 'ReOrg Reconciliation',
    link: '/app/corp-action/reconciliation-reorg',
    page: 'reconciliation-reorg',
  },
  {
    id: 37,
    groupLabel: 'Report / Report',
    label: 'Profit and Loss',
    link: '/app/report/profit-and-loss',
    page: 'profit-and-loss',
  },
  {
    id: 38,
    groupLabel: 'Report / Report',
    label: 'Monthly Statement',
    link: '/app/report/monthly-statement',
    page: 'monthly-statement',
  },
  {
    id: 39,
    groupLabel: 'Report / Report',
    label: 'Daily Trade Confirm',
    link: '/app/report/daily-trade-confirm',
    page: 'daily-trade-confirm',
  },
  {
    id: 40,
    groupLabel: 'Balance / Cash',
    label: 'Settlement Funding',
    link: '/app/cash/settlement-funding',
    page: 'settlement-funding',
  },
  {
    id: 41,
    groupLabel: 'Report / Report',
    label: 'Account Management',
    link: '/app/report/account-management',
    page: 'account-management',
  },
  {
    id: 42,
    groupLabel: 'Tax / Rate',
    label: 'Tax Rate',
    link: '/app/tax-rate/tax-rate',
    page: 'tax-rate',
  },
  {
    id: 43,
    groupLabel: 'Position',
    label: 'Fractional Shares PL',
    link: '/app/position/fractional-share-pl',
    page: 'fractional-share-pl',
  },
  {
    id: 44,
    groupLabel: 'Position',
    label: 'Inventory Report',
    link: '/app/position/inventory-report',
    page: 'inventory-report',
  },
  {
    id: 45,
    groupLabel: 'Tax / Withholding',
    label: 'Dividend',
    link: '/app/withholding/dividend',
    page: 'dividend',
  },
  {
    id: 46,
    groupLabel: 'Position / ACATS',
    label: 'Transfer Input',
    link: '/app/acats/transfer-input',
    page: 'transfer-input',
  },
  {
    id: 47,
    groupLabel: 'Report / Report',
    label: 'Monthly Statement Disclosure',
    link: '/app/report/statement-disclosure',
    page: 'statement-disclosure',
  },
  {
    id: 48,
    groupLabel: 'Report / Report',
    label: 'Report Template',
    link: '/app/report/report-template',
    page: 'report-template',
  },
  {
    id: 49,
    groupLabel: 'Compliance / Segregation',
    label: 'Segregation Priority',
    link: '/app/segregation/priority',
    page: 'segregation-priority',
  },
  {
    id: 50,
    groupLabel: 'Position / ACATS',
    label: 'Asset Transfer',
    link: '/app/acats/asset-transfer',
    page: 'asset-transfer',
  },
  {
    id: 51,
    groupLabel: 'Position / ACATS',
    label: 'Outgoing',
    link: '/app/acats/outgoing',
    page: 'outgoing',
  },
  {
    id: 52,
    groupLabel: 'Position / ACATS',
    label: 'Output Position',
    link: '/app/acats/output-position',
    page: 'output-position',
  },
  {
    id: 53,
    groupLabel: 'Report / Report',
    label: 'Self Service Reporting',
    link: '/app/report/self-service-reporting',
    page: 'self-service-reporting',
  },
  {
    id: 54,
    groupLabel: 'Clearing',
    label: 'Clearing Trades',
    link: '/app/clearing/trades',
    page: 'clearing-trades',
  },
  {
    id: 55,
    groupLabel: 'Clearing',
    label: 'Clearing Settlement',
    link: '/app/clearing/settlement',
    page: 'clearing-settlement',
  },
  {
    id: 56,
    groupLabel: 'Clearing',
    label: 'Clearing Permissions',
    link: '/app/clearing/permissions',
    page: 'clearing-permissions',
  },
];
