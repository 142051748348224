/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import { ListReOrgReconRequest } from '../../../proto/reorgpb/reorgrecon_grpc_web_pb';
import { ReadProfileRequest } from '../../../proto/admpb/profile_grpc_web_pb';
import { TableSettings } from '../../../proto/admpb/tablesettings_grpc_web_pb';

/*Material UI Components*/
import {
  Box,
  TextField,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Tooltip,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

import UpdateIcon from '@material-ui/icons/Update';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Icons*/
import {
  Visibility as ViewIcon,
  Close as CloseIcon,
  Save as SaveIcon,
} from '@material-ui/icons';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Formatter*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Custom Component*/
import ReOrgReconDetailsTable from './ReorgReconDetailsTable';
import SymbolSelect from '../../../components/AutoComplete/Symbol';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';
import { useUserState } from 'context/UserContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');

export default function ReOrgReconTable({ params }) {
  var { googleAccount } = useUserState();

  const classes = useStyles();

  const {
    profileServiceClient: systemDate,
    reorgReconServiceClient: reorgRecon,
    tableSettingsServiceClient: tableSettings,
  } = useContext(ServiceContext);

  const search = () => {
    const pathWithParams =
      currentPath +
      '?search=true' +
      (systemDateValue ? '&systemDate=' + systemDateValue : '') +
      (symbolValue ? '&symbol=' + symbolValue : '');
    window.history.pushState({}, null, pathWithParams);

    setLoading(true);

    let list = new ListReOrgReconRequest();
    list.setSymbol(symbolValue);
    if (systemDateValue) {
      let gglSystemDate = new google_date.Date();
      let [y, m, d] = systemDateValue.split('-');
      gglSystemDate.setYear(y);
      gglSystemDate.setMonth(m);
      gglSystemDate.setDay(d);
      list.setSystemDate(gglSystemDate);
    }

    reorgRecon.listReOrgRecon(list, {}, (err, res) => {
      if (err) {
        console.error(err);
        notifyError(err.message);
        setLoading(false);
        return;
      }

      const rows = res.toObject().reorgReconsList.map((data) => ({
        accountNo: data.accountNo,
        age: data.age,
        cusip: data.cusip,
        netAmt: formatCurrency(data.netAmt),
        originalCusip: data.originalCusip,
        qty: data.qty,
        symbol: data.symbol,
        symbolDescription: data.symbolDescription,
        systemDate: data.systemDate ? formatPbDate(data.systemDate) : '--',
        status: data.status,
      }));
      setRows(rows);
      notifyInfo(rows.length + ' search results.');
      setLoading(false);
    });
  };

  const updateTableSettings = () => {
    let req = new TableSettings();
    req.setGoogleId(googleAccount.googleId);
    req.setPageName('ReOrg Reconciliations');
    req.setViewColumns(tableSetting.viewColumns.toString());

    tableSettings.updateTableSettings(req, {}, (err, res) => {
      if (err) {
        console.error(err);
        return;
      }
      notifySuccess('Table has been saved.');
    });
  };

  const openPendingTrns = (symbol) => {
    const paramPath = '/app/trns/pending-trns/process/' + symbol;

    window.open(paramPath, '_blank');
  };

  const [tableSetting, setTableSetting] = useState({
    load: true,
    viewColumns: [],
  });

  const columns = [
    {
      name: '',
      options: {
        draggable: false,
        resizable: false,
        print: false,
        searchable: false,
        filter: false,
        sort: false,
        empty: true,
        viewColumns: false,
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className={classes.grdRow} style={{ margin: '0px' }}>
              <div className={classes.grdCellNone}>
                <Tooltip title="Process" arrow>
                  <IconButton
                    aria-label="pending-trns"
                    onClick={() => {
                      openPendingTrns(rows[dataIndex].symbol);
                    }}
                  >
                    <UpdateIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className={classes.grdCellNone}>
                <Tooltip title="Details" arrow>
                  <IconButton
                    aria-label="details"
                    onClick={() => {
                      handleOpen(systemDateValue, rows[dataIndex].symbol);
                    }}
                  >
                    <ViewIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          );
        },
        setCellProps: () => ({
          style: { padding: '2px 16px', width: '130px' },
        }),
      },
    },
    {
      name: 'accountNo',
      label: 'Account No',
    },
    {
      name: 'age',
      label: 'Age',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'cusip',
      label: 'Cusip',
    },
    {
      name: 'netAmt',
      label: 'Net Amt',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'originalCusip',
      label: 'Original Cusip',
    },
    {
      name: 'qty',
      label: 'Qty',
      options: {
        setCellProps: () => ({
          align: 'right',
        }),
      },
    },
    {
      name: 'symbol',
      label: 'Symbol',
    },
    {
      name: 'symbolDescription',
      label: 'Symbol Description',
    },
    {
      name: 'status',
      label: 'Status',
    },
  ];

  const fileName =
    'ReOrgRecon_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [0, 1, 7, 3, 8, 6, 4, 2, 5, 9],
    selectableRowsHeader: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    customToolbar: function() {
      return (
        <Tooltip title="Save Table" arrow>
          <IconButton onClick={() => updateTableSettings()}>
            <SaveIcon />
          </IconButton>
        </Tooltip>
      );
    },
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      //load table settings
      if (tableSetting.viewColumns.length === 0) {
        let req = new TableSettings();
        req.setGoogleId(googleAccount.googleId);
        req.setPageName('ReOrg Reconciliations');

        tableSettings.readTableSettings(req, {}, (err, res) => {
          if (err) {
            let req = new TableSettings();
            req.setGoogleId(googleAccount.profileObj.googleId);
            req.setUserName(googleAccount.profileObj.name);
            req.setPageName('ReOrg Reconciliations');
            req.setViewColumns(tableSetting.viewColumns.join(','));

            tableSettings.createTableSettings(req, {}, (err, res) => {
              if (err) {
                console.error(err);
                return;
              }
            });
            return;
          } else {
            let viewColumnState = res.toObject().tableSetting.viewColumns;
            let viewColumns = viewColumnState.split(',');

            for (let i = 0; i < tableState.columns.length; i++) {
              tableState.columns[i].display = viewColumns[i];
            }

            setTableSetting({
              load: true,
              viewColumns: viewColumns,
            });
          }
        });
      } else {
        if (tableSetting.load) {
          for (let i = 0; i < tableState.columns.length; i++) {
            tableState.columns[i].display = tableSetting.viewColumns[i];
          }
          tableSetting.load = false;
        }
      }

      let viewColumnState = [];

      for (let i = 0; i < tableState.columns.length; i++) {
        viewColumnState.push(tableState.columns[i].display);
      }

      tableSetting.viewColumns = viewColumnState;
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['netAmt']}
          columnsWithQty={[]}
          rows={rows}
        ></CustomTableBodyFooter>
      );
    },
  };

  const [rows, setRows] = useState([]);

  const [systemDateValue, setSystemDateValue] = React.useState('');
  const [symbolValue, setSymbolValue] = React.useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      systemDate: query.get('systemDate'),
      symbol: query.get('symbol'),
    };

    if (data.systemDate) {
      setSystemDateValue(data.systemDate);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }

    setCurrentPath(path);

    if (!data.systemDate) {
      //Get system date
      let profileReq = new ReadProfileRequest();

      systemDate.readProfile(profileReq, {}, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          setSystemDateValue(
            moment(
              new Date(formatPbDate(res.toObject().profile.systemDate))
            ).format('yyyy-MM-DD')
          );
        }
      });
    }
  }, [systemDate, params]);

  const [selectedSystemDate, setSelectedSystemDate] = React.useState('');
  const [selectedSymbol, setSelectedSymbol] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleOpen = (systemDate, symbol) => {
    setSelectedSystemDate(systemDate);
    setSelectedSymbol(symbol);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="System Date"
                  type="date"
                  value={systemDateValue}
                  onChange={(e) => setSystemDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCell1}>
                <SymbolSelect
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div className={classes.actionContainer}>
          <div className={classes.grdCellNone} style={{ marginRight: 10 }}>
            <SearchButton
              onClick={() => search()}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          {/* <div className={classes.grdCellNone}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              startIcon={<ViewIcon />}
              onClick={() => {
                showBreaksOnly();
              }}
            >
              {page.breaksOnly ? 'Show All' : 'Show Breaks Only'}
            </Button>
          </div> */}
        </div>
      </Box>
      <Box component="div" mt={2}>
        {rows && (
          <CSVLink id="csvDL" data={rows} filename={fileName} target="_blank" />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'ReOrg Reconciliations'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalBackdrop}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={open}>
          <div className={classes.tableModalFull}>
            <Box>
              <ReOrgReconDetailsTable
                systemdate={selectedSystemDate}
                symbol={selectedSymbol}
              />
            </Box>
            <div className={classes.modalCloseIcon}>
              <IconButton aria-label="close" onClick={handleClose}>
                <CloseIcon style={{ color: '#f1f1f1' }} />
              </IconButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
