/**
 * @fileoverview gRPC-Web generated client stub for reorgpb
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_type_date_pb = require('../google/type/date_pb.js')
const proto = {};
proto.reorgpb = require('./cashinlieu_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.CashInLieuServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.reorgpb.CashInLieuServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.ListCashInLieuRequest,
 *   !proto.reorgpb.ListCashInLieuResponse>}
 */
const methodDescriptor_CashInLieuService_ListCashInLieu = new grpc.web.MethodDescriptor(
  '/reorgpb.CashInLieuService/ListCashInLieu',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.ListCashInLieuRequest,
  proto.reorgpb.ListCashInLieuResponse,
  /**
   * @param {!proto.reorgpb.ListCashInLieuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.ListCashInLieuResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.ListCashInLieuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reorgpb.ListCashInLieuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.ListCashInLieuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.CashInLieuServiceClient.prototype.listCashInLieu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.CashInLieuService/ListCashInLieu',
      request,
      metadata || {},
      methodDescriptor_CashInLieuService_ListCashInLieu,
      callback);
};


/**
 * @param {!proto.reorgpb.ListCashInLieuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.ListCashInLieuResponse>}
 *     Promise that resolves to the response
 */
proto.reorgpb.CashInLieuServicePromiseClient.prototype.listCashInLieu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.CashInLieuService/ListCashInLieu',
      request,
      metadata || {},
      methodDescriptor_CashInLieuService_ListCashInLieu);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.reorgpb.UpdateCashInLieuRequest,
 *   !proto.reorgpb.UpdateCashInLieuResponse>}
 */
const methodDescriptor_CashInLieuService_UpdateCashInLieu = new grpc.web.MethodDescriptor(
  '/reorgpb.CashInLieuService/UpdateCashInLieu',
  grpc.web.MethodType.UNARY,
  proto.reorgpb.UpdateCashInLieuRequest,
  proto.reorgpb.UpdateCashInLieuResponse,
  /**
   * @param {!proto.reorgpb.UpdateCashInLieuRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.reorgpb.UpdateCashInLieuResponse.deserializeBinary
);


/**
 * @param {!proto.reorgpb.UpdateCashInLieuRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.reorgpb.UpdateCashInLieuResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.reorgpb.UpdateCashInLieuResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.reorgpb.CashInLieuServiceClient.prototype.updateCashInLieu =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/reorgpb.CashInLieuService/UpdateCashInLieu',
      request,
      metadata || {},
      methodDescriptor_CashInLieuService_UpdateCashInLieu,
      callback);
};


/**
 * @param {!proto.reorgpb.UpdateCashInLieuRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.reorgpb.UpdateCashInLieuResponse>}
 *     Promise that resolves to the response
 */
proto.reorgpb.CashInLieuServicePromiseClient.prototype.updateCashInLieu =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/reorgpb.CashInLieuService/UpdateCashInLieu',
      request,
      metadata || {},
      methodDescriptor_CashInLieuService_UpdateCashInLieu);
};


module.exports = proto.reorgpb;

