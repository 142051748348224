import { useContext } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ServiceContext } from 'context/ServiceContext';

import {
  CreateSsrReportRequest,
  UpdateSsrReportRequest,
  ListSsrConfigRequest,
  ListSsrExecutionRequest,
  ListSsrReportRequest,
  ListSsrRowRequest,
  ListSsrErrorRequest,
  SsrConfig,
  SsrReport,
  ReviewSsrRowRequest,
  ClearSsrReviewRequest,
  TriggerSsrReportRequest,
  UploadReviewedFileRequest,
  GenerateReviewedFileRequest,
  ExecuteSsrQueryRequest,
  UpdateSsrExecutionRequest,
} from 'proto/reportpb/ssr_pb';
import { useMutationWithLoginAdmin } from './useAdministrator';
import {
  Pagination,
  PaginationSort,
  PaginationSortDirection,
} from 'proto/utilspb/pagination_pb';
import callbackToPromise from './callbackToPromise';

const defaultCacheConfig = {
  // cache result for 5 mins
  staleTime: 5 * 60 * 1000,
};

function createPagination(name, direction) {
  const paginationSort = new PaginationSort();
  paginationSort.setName(name);
  paginationSort.setDirection(direction);

  const pagination = new Pagination();
  pagination.addSort(paginationSort);

  return pagination;
}

export function useListSsrReport() {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useQuery(
    'listSsrReport',
    async () => {
      const req = new ListSsrReportRequest();
      req.setPagination(
        createPagination('created_at', PaginationSortDirection.DESC)
      );

      const res = await callbackToPromise((cb) =>
        ssrServiceClient.listSsrReport(req, {}, cb)
      );

      return res.getReportsList().map((report) => {
        const config = report.getConfig();

        return {
          id: report.getId(),
          status: report.getStatus(),
          configId: config.getId(),
          name: config.getName(),
          description: config.getDescription(),
          reportDirectory: config.getReportDirectory(),
          requireReview: config.getRequireReview(),
          reviewDirectory: config.getReviewDirectory(),
          scheduleType: config.getScheduleType(),
          scheduleMeta: config.getScheduleMeta(),
          scheduleDescription: config.getScheduleDescription(),
          ownerId: config.getOwnerId(),
          query: config.getQuery(),
          conditionalQuery: config.getConditionalQuery(),
          pendingReviewCount: report.getPendingReviewCount(),
        };
      });
    },
    defaultCacheConfig
  );
}

export function useListSsrConfig(reportId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useQuery(
    ['listSsrConfig', reportId],
    async () => {
      const req = new ListSsrConfigRequest();
      req.setReportId(reportId);
      req.setPagination(
        createPagination('created_at', PaginationSortDirection.DESC)
      );

      const res = await callbackToPromise((cb) =>
        ssrServiceClient.listSsrConfig(req, {}, cb)
      );

      return res.getConfigsList();
    },
    { ...defaultCacheConfig }
  );
}

export function useListSsrExecution({ reportId, configId, executionIds }) {
  const { ssrServiceClient } = useContext(ServiceContext);

  // key = reportId, configId, executionIds
  const key = `reportId=${reportId ?? ''},configId=${configId ??
    ''},executionIds=${(executionIds ?? []).join('|')}`;

  return useQuery(
    ['listSsrExecution', key],
    async () => {
      const req = new ListSsrExecutionRequest();
      req.setReportId(reportId);
      req.setConfigId(configId);
      req.setExecutionIdsList(executionIds);
      req.setPagination(
        createPagination('created_at', PaginationSortDirection.DESC)
      );

      const res = await callbackToPromise((cb) =>
        ssrServiceClient.listSsrExecution(req, {}, cb)
      );

      return res.getExecutionsList().map((execution) => {
        let reportUrl;
        if (execution.getReportDomain() && execution.getReportPath()) {
          const reportPath = execution.getReportPath();
          reportUrl = `https://${execution.getReportDomain()}/app/index.do#storage/files/1/Shared/books_and_records/SSR${reportPath.substring(
            0,
            reportPath.lastIndexOf('/')
          )}`;
        }

        let reviewUrl;
        if (execution.getReviewDomain() && execution.getReviewPath()) {
          const reviewPath = execution.getReviewPath();
          reviewUrl = `https://${execution.getReviewDomain()}/app/index.do#storage/files/1/Shared/books_and_records/SSR${reviewPath.substring(
            0,
            reviewPath.lastIndexOf('/')
          )}`;
        }

        return {
          id: execution.getId(),
          reportId: execution.getReportId(),
          configId: execution.getConfigId(),
          status: execution.getStatus(),
          headers: execution.getHeadersList(),
          rowCount: execution.getRowCount(),
          reviewCount: execution.getReviewCount(),
          reportUploadedAt: execution.getReportUploadedAt()?.toDate(),
          reportUrl: reportUrl,
          reviewUploadedAt: execution.getReviewUploadedAt()?.toDate(),
          reviewUrl: reviewUrl,
          reviewComment: execution.getReviewComment(),
          reviewCommenterId: execution.getReviewCommenterId(),
          reviewCommenterName: execution.getReviewCommenter()?.getName(),
          dataClearedAt: execution.getDataClearedAt()?.toDate(),
        };
      });
    },
    defaultCacheConfig
  );
}

export function useListSsrRow(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useQuery(
    ['listSsrRow', executionId],
    async () => {
      const req = new ListSsrRowRequest();
      req.setExecutionId(executionId);
      req.setPagination(
        createPagination('row_number', PaginationSortDirection.ASC)
      );

      const res = await callbackToPromise((cb) =>
        ssrServiceClient.listSsrRow(req, {}, cb)
      );

      return res.toObject().rowsList;
    },
    defaultCacheConfig
  );
}

export function useListSsrError(reportId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useQuery(
    ['listSsrError', reportId],
    async () => {
      const req = new ListSsrErrorRequest();
      req.setReportId(reportId);
      req.setPagination(
        createPagination('created_at', PaginationSortDirection.DESC)
      );

      const res = await callbackToPromise((cb) =>
        ssrServiceClient.listSsrError(req, {}, cb)
      );

      return res.getErrorsList();
    },
    defaultCacheConfig
  );
}

export function useCreateReport() {
  const { ssrServiceClient } = useContext(ServiceContext);
  const queryClient = useQueryClient();

  return useMutationWithLoginAdmin(
    async (formData, { id: loginAdminId }) => {
      const config = new SsrConfig();
      config.setOwnerId(formData.reportOwnerId);
      config.setCreatorId(loginAdminId);
      config.setName(formData.reportName);
      config.setQuery(formData.query);
      config.setDescription(formData.reportDescription);
      config.setRequireReview(formData.requireReview);
      config.setReportDirectory(formData.reportDirectory);
      config.setReviewDirectory(formData.reviewDirectory);
      config.setScheduleType(formData.scheduleType);
      config.setScheduleMeta(formData.scheduleMeta);
      config.setScheduleDescription(formData.scheduleDescription);
      config.setConditionalQuery(formData.conditionalQuery);

      const report = new SsrReport();
      report.setConfig(config);
      report.setStatus('active');

      const req = new CreateSsrReportRequest();
      req.setReport(report);

      const result = await callbackToPromise((cb) =>
        ssrServiceClient.createSsrReport(req, {}, cb)
      );

      return {
        report: {
          id: result.getReport().getId(),
        },
      };
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listSsrReport');
      },
    }
  );
}

export function useUpdateReportConfig() {
  const { ssrServiceClient } = useContext(ServiceContext);
  const queryClient = useQueryClient();

  return useMutationWithLoginAdmin(
    async (formData, { id: loginAdminId }) => {
      const config = new SsrConfig();
      config.setOwnerId(formData.reportOwnerId);
      config.setCreatorId(loginAdminId);
      config.setName(formData.reportName);
      config.setQuery(formData.query);
      config.setDescription(formData.reportDescription);
      config.setRequireReview(formData.requireReview);
      config.setReportDirectory(formData.reportDirectory);
      config.setReviewDirectory(formData.reviewDirectory);
      config.setScheduleType(formData.scheduleType);
      config.setScheduleMeta(formData.scheduleMeta);
      config.setScheduleDescription(formData.scheduleDescription);
      config.setConditionalQuery(formData.conditionalQuery);

      const report = new SsrReport();
      report.setId(formData.reportId);
      report.setConfig(config);

      const req = new UpdateSsrReportRequest();
      req.setReport(report);

      await callbackToPromise((cb) =>
        ssrServiceClient.updateSsrReport(req, {}, cb)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listSsrReport');
        queryClient.invalidateQueries(['listSsrConfig']);
      },
    }
  );
}

export function useUpdateReportStatus() {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useMutation(async ({ reportId, status }) => {
    const report = new SsrReport();
    report.setId(reportId);
    report.setStatus(status);

    const req = new UpdateSsrReportRequest();
    req.setReport(report);

    await callbackToPromise((cb) =>
      ssrServiceClient.updateSsrReport(req, {}, cb)
    );
  });
}

export function useExecuteQuery() {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useMutation(async ({ filename, query }) => {
    const req = new ExecuteSsrQueryRequest();
    req.setQuery(query);

    const res = ssrServiceClient.executeSsrQuery(req);

    await downloadFromStream(filename, res);
  });
}

export function useTriggerSsrReport(reportId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const req = new TriggerSsrReportRequest();
      req.setReportId(reportId);

      await callbackToPromise((cb) =>
        ssrServiceClient.triggerSsrReport(req, {}, cb)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listSsrExecution']);
        queryClient.invalidateQueries(['listSsrError']);
      },
    }
  );
}

export function useReviewRow(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);
  const queryClient = useQueryClient();

  // reviews: [{rowId, comment}]
  return useMutationWithLoginAdmin(
    async ({ reviews }, { id: loginAdminId }) => {
      const req = new ReviewSsrRowRequest();
      req.setExecutionId(executionId);
      req.setReviewerId(loginAdminId);

      for (const review of reviews) {
        const rowComment = new ReviewSsrRowRequest.RowComment();
        rowComment.setRowId(review.rowId);
        rowComment.setComment(review.comment);

        req.addRowComments(rowComment);
      }

      await callbackToPromise((cb) =>
        ssrServiceClient.reviewSsrRow(req, {}, cb)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listSsrRow']);
        queryClient.invalidateQueries(['listSsrExecution']);
      },
    }
  );
}

export function useClearReview(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);
  const queryClient = useQueryClient();

  return useMutationWithLoginAdmin(
    async ({ rowIds }, { id: loginAdminId }) => {
      const req = new ClearSsrReviewRequest();
      req.setExecutionId(executionId);
      req.setReviewerId(loginAdminId);
      req.setRowIdsList(rowIds);

      await callbackToPromise((cb) =>
        ssrServiceClient.clearSsrReview(req, {}, cb)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listSsrRow', executionId]);
      },
    }
  );
}

// download file from SsrFileResponseChunk
// return a promise, resolved if succeeded
// rejected if any error
// stream need to be ssr file response chunk
async function downloadFromStream(filename, stream) {
  const chunks = [];
  let lastMessage;

  await new Promise((resolve, reject) => {
    stream.on('error', (err) => {
      reject(err);
    });

    stream.on('data', (data) => {
      chunks.push(data.getChunk());
      lastMessage = data;
    });

    stream.on('end', () => {
      resolve();
    });
  });

  const blob = new Blob(chunks);

  // browser limitation
  if (lastMessage && !lastMessage.getIsLast()) {
    const blobSizeInMB = blob.size / 1024 / 1024;
    throw new Error(
      `did not receive complete file (received ${blobSizeInMB} MB) and it might be caused by file too large`
    );
  }

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
      // remove <a> as well
    }, 30 * 1000);
  };

  a.addEventListener('click', clickHandler, false);
  a.click();
}

export function useGenerateReviewedFile(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useMutationWithLoginAdmin(async (filename, { id: loginAdminId }) => {
    const req = new GenerateReviewedFileRequest();
    req.setExecutionId(executionId);
    req.setUploaderId(loginAdminId);

    const res = ssrServiceClient.generateReviewedFile(req);

    await downloadFromStream(filename, res);
  });
}

export function useUploadReviewedFile(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);

  return useMutationWithLoginAdmin(async (_, { id: loginAdminId }) => {
    const req = new UploadReviewedFileRequest();
    req.setExecutionId(executionId);
    req.setUploaderId(loginAdminId);

    await callbackToPromise((cb) =>
      ssrServiceClient.uploadReviewedFile(req, {}, cb)
    );
  });
}

export function useUpdateExecution(executionId) {
  const { ssrServiceClient } = useContext(ServiceContext);
  const queryClient = useQueryClient();

  return useMutationWithLoginAdmin(
    async ({ reviewComment }, loginAdmin) => {
      const req = new UpdateSsrExecutionRequest();
      req.setExecutionId(executionId);

      if ((reviewComment ?? '').length > 0) {
        req.setReviewComment(reviewComment);
        req.setReviewCommenterId(loginAdmin.id);
      } else {
        req.setClearReviewComment(true);
      }

      await callbackToPromise((cb) =>
        ssrServiceClient.updateSsrExecution(req, {}, cb)
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listSsrExecution']);
      },
    }
  );
}
