/*ReactJS*/
import React, { useState, useEffect, useContext } from 'react';
import { CSVLink } from 'react-csv';

/*Service*/
import {
  UpdatePendingTrnsApplyCILRequest,
  ListPendingTrnsRequest,
  DeletePendingTrnsRequest,
  ExecutePendingTrnsRequest,
  PendingTrnsFilters,
  PendingTrnsDateFilter,
  PendingTrnsApplyCIL,
} from '../../../proto/trnspb/pendingtrns_grpc_web_pb';

import {
  Transaction,
  TransactionFee,
  BatchProcessPendingTransactionRequest,
} from '../../../proto/trnspb/transaction_grpc_web_pb';

import {
  protoTransactionMetadataLocalFees,
  protoTransactionMetadataToString,
  stringToProtoTransactionMetadata,
} from 'services/TransactionMetadata';

/*Toast Notification*/
import {
  notifyInfo,
  notifyError,
  notifySuccess,
} from 'components/Notification/Notification';

/*Material UI Table Components*/
import {
  TableCell,
  TableRow,
  Box,
  TextField,
  MenuItem,
  InputLabel,
  Select,
  IconButton,
  Tooltip,
  Checkbox,
  Button,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';

/*Material UI Table*/
import MUIDataTable from 'mui-datatables';

/*Moment JS*/
import moment from 'moment-timezone';
import { formatPbDate, formatCurrency } from 'lib/fmt';

/*Material UI Icons*/
import { CompareArrowsRounded as CancelAndCorrectIcon } from '@material-ui/icons';

/*Material UI Confirm*/
import { useConfirm } from 'material-ui-confirm';

/*Styles*/
import tableTheme from '../../../components/Table/TableStyle';
import { MuiThemeProvider } from '@material-ui/core/styles';
import useStyles from '../../../styles';

/*Custom components*/
import SelectAccount from '../../../components/AutoComplete/SelectAccount';
import SelectSymbol from '../../../components/AutoComplete/SelectSymbol';
import EntryTypeSelect from '../../../components/AutoComplete/EntryType';
import SearchButton from '../../../components/Button/Search';
import CustomTableBodyFooter from '../../../components/Table/CustomTableBodyFooter';

import { ServiceContext } from 'context/ServiceContext';

import { meta } from 'services/TransactionService';

import { dateToPBDate } from 'lib/convert/Convert';
import { useProfile } from 'context/ProfileContext';

/*Google Date*/
const google_date = require('../../../google/type/date_pb.js');
const timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');

/*=========================================================================================
 Main PendingTrns Table
===========================================================================================*/
export default function PendingTrnsTable({ params }) {
  /*=========================================================================================
  Component style
  ===========================================================================================*/
  const classes = useStyles();
  const confirm = useConfirm();
  /*=========================================================================================
  End of component style
  ===========================================================================================*/
  const {
    pendingTrnsServiceClient: pendingtrns,
    transactionServiceClient: trnsClient,
  } = useContext(ServiceContext);

  /*START OF PAGING LOGIC ===================================================================*/
  const [page, setPageState] = useState({
    currentPage: 0,
    displayedRows: 100,
    totalRows: 0,
    searchSrc: '',
    sortOrder: {},
    filter: [],
    selectedRows: [],
  });

  useEffect(() => {
    if (page.data) {
      /*sorting*/
      let sortField = page.sortOrder.name;
      let sortDir = page.sortOrder.direction;

      let fullData = page.data.sort((a, b) => {
        if (typeof a[sortField] === 'object') {
          if (formatPbDate(a[sortField]) < formatPbDate(b[sortField])) {
            return 1 * (sortDir === 'asc' ? -1 : 1);
          } else if (formatPbDate(a[sortField]) > formatPbDate(b[sortField])) {
            return -1 * (sortDir === 'asc' ? -1 : 1);
          } else {
            return 0;
          }
        } else {
          if (a[sortField] < b[sortField]) {
            return 1 * (sortDir === 'asc' ? -1 : 1);
          } else if (a[sortField] > b[sortField]) {
            return -1 * (sortDir === 'asc' ? -1 : 1);
          } else {
            return 0;
          }
        }
      });

      /*filter*/
      if (page.filter.length > 0) {
        let columnFilter = page.filter;
        let filterParams = {
          accountId:
            columnFilter[0].value !== undefined ? [columnFilter[0].value] : [],
          accountNo:
            columnFilter[1].value !== undefined ? [columnFilter[1].value] : [],
          batchNo:
            columnFilter[2].value !== undefined ? [columnFilter[2].value] : [],
          contraAccountId:
            columnFilter[3].value !== undefined ? [columnFilter[3].value] : [],
          contraAccountNo:
            columnFilter[4].value !== undefined ? [columnFilter[4].value] : [],
          correspondent:
            columnFilter[5].value !== undefined ? [columnFilter[5].value] : [],
          createdAt:
            columnFilter[6].value !== undefined ? [columnFilter[6].value] : [],
          createdBy:
            columnFilter[7].value !== undefined ? [columnFilter[7].value] : [],
          description:
            columnFilter[8].value !== undefined ? [columnFilter[8].value] : [],
          entryType:
            columnFilter[9].value !== undefined ? [columnFilter[9].value] : [],
          entrySubType:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          errorMessage:
            columnFilter[10].value !== undefined
              ? [columnFilter[10].value]
              : [],
          executionId:
            columnFilter[11].value !== undefined
              ? [columnFilter[11].value]
              : [],
          executionVenue:
            columnFilter[12].value !== undefined
              ? [columnFilter[12].value]
              : [],
          externalId:
            columnFilter[13].value !== undefined
              ? [columnFilter[13].value]
              : [],
          fees:
            columnFilter[14].value !== undefined
              ? [columnFilter[14].value]
              : [],
          grossAmt:
            columnFilter[15].value !== undefined
              ? [columnFilter[15].value]
              : [],
          leavesQty:
            columnFilter[16].value !== undefined
              ? [columnFilter[16].value]
              : [],
          netAmt:
            columnFilter[17].value !== undefined
              ? [columnFilter[17].value]
              : [],
          orderId:
            columnFilter[18].value !== undefined
              ? [columnFilter[18].value]
              : [],
          originalCusip:
            columnFilter[19].value !== undefined
              ? [columnFilter[19].value]
              : [],
          previousTrnId:
            columnFilter[20].value !== undefined
              ? [columnFilter[20].value]
              : [],
          price:
            columnFilter[21].value !== undefined
              ? [columnFilter[21].value]
              : [],
          qty:
            columnFilter[22].value !== undefined
              ? [columnFilter[22].value]
              : [],
          settleDate:
            columnFilter[23].value !== undefined
              ? [columnFilter[23].value]
              : [],
          side:
            columnFilter[24].value !== undefined
              ? [columnFilter[24].value]
              : [],
          stageId:
            columnFilter[25].value !== undefined
              ? [columnFilter[25].value]
              : [],
          status:
            columnFilter[26].value !== undefined
              ? [columnFilter[26].value]
              : [],
          symbol:
            columnFilter[27].value !== undefined
              ? [columnFilter[27].value]
              : [],
          systemDate:
            columnFilter[28].value !== undefined
              ? [columnFilter[28].value]
              : [],
          tradeAt:
            columnFilter[29].value !== undefined
              ? [columnFilter[29].value]
              : [],
          tradeDate:
            columnFilter[30].value !== undefined
              ? [columnFilter[30].value]
              : [],
          vendor:
            columnFilter[31].value !== undefined
              ? [columnFilter[31].value]
              : [],
          refId:
            columnFilter[32].value !== undefined
              ? [columnFilter[32].value]
              : [],
          currency:
            columnFilter[33].value !== undefined
              ? [columnFilter[33].value]
              : [],
          swapRate:
            columnFilter[34].value !== undefined
              ? [columnFilter[34].value]
              : [],
          metadata:
            columnFilter[35].value !== undefined
              ? [columnFilter[35].value]
              : [],
        };

        const buildFilter = (filter) => {
          let query = {};
          for (let keys in filter) {
            if (filter[keys].constructor === Array && filter[keys].length > 0) {
              query[keys] = filter[keys];
            }
          }
          return query;
        };

        const filterData = (data, query) => {
          const filteredData = data.filter((item) => {
            for (let key in query) {
              if (
                key === 'price' ||
                key === 'grossAmt' ||
                key === 'netAmt' ||
                key === 'fees'
              ) {
                item[key] = item[key].includes('$')
                  ? item[key]
                  : formatCurrency(item[key], '');
              }
              if (key === 'tradeAt') {
                item[key] =
                  typeof item[key] === 'object'
                    ? moment
                        .tz(
                          new Date(item[key].seconds * 1000),
                          'America/New_York'
                        )
                        .format('hh:mm:ssa')
                    : item[key];
              }
              if (key === 'createdAt') {
                item[key] =
                  typeof item[key] === 'object'
                    ? moment(new Date(item[key].seconds * 1000)).format(
                        'MM/DD/YYYY hh:mm'
                      )
                    : item[key];
              }
              if (key.includes('Date')) {
                item[key] =
                  typeof item[key] === 'object'
                    ? formatPbDate(item[key])
                    : item[key];
              }
              if (item[key] === undefined || !query[key].includes(item[key])) {
                return false;
              }
            }
            return true;
          });
          return filteredData;
        };

        const query = buildFilter(filterParams);
        fullData = filterData(page.data, query);
      }
      var pageData = fullData.slice(
        page.currentPage * page.displayedRows,
        (page.currentPage + 1) * page.displayedRows
      );

      setRows(pageData);

      setDownloadableRows(fullData);

      if (page.searchSrc === 'search') {
        notifyInfo(page.totalRows + ' search results.');
      }
      if (page.searchSrc === 'search without message') {
        setIsApplyingPrice(false);
      }
      setLoading(false);
    }
  }, [page]);

  /**search function to get pending trns ========================================================*/
  const search = (
    type,
    cPage,
    rowsPerPage,
    field,
    sortDirection,
    filterValue
  ) => {
    if (type === 'search' || type === 'search without message') {
      const pathWithParams =
        currentPath +
        '?search=true' +
        (correspondentValue ? '&correspondent=' + correspondentValue : '') +
        (accountNoValue ? '&accountNo=' + accountNoValue : '') +
        (contraAccountNoValue ? '&accountNo=' + contraAccountNoValue : '') +
        (symbolValue ? '&symbol=' + symbolValue : '') +
        (statusValue ? '&status=' + statusValue : '') +
        (entryTypeValue ? '&entryType=' + entryTypeValue : '') +
        (entrySubTypeValue ? '&entrySubType=' + entrySubTypeValue : '') +
        (dateTypeValue ? '&dateType=' + dateTypeValue : '') +
        (fromDateValue ? '&fromDate=' + fromDateValue : '') +
        (toDateValue ? '&toDate=' + toDateValue : '') +
        (batchNoValue ? '&batchNo=' + batchNoValue : '');
      window.history.pushState({}, null, pathWithParams);

      if (type === 'search') {
        setLoading(true);
      }
      if (type === 'search without message') {
        setLoading(false);
      }

      let list = new ListPendingTrnsRequest();
      list.setCorrespondent(correspondentValue);
      list.setAccountNo(accountNoValue);
      list.setContraAccountNo(contraAccountNoValue);
      list.setSymbol(symbolValue);
      list.setDateType(dateTypeValue);
      list.setEntryType(entryTypeValue);
      list.setEntrySubType(entrySubTypeValue);
      if (fromDateValue) {
        let gglFromDate = new google_date.Date();
        let [y, m, d] = fromDateValue.split('-');
        gglFromDate.setYear(y);
        gglFromDate.setMonth(m);
        gglFromDate.setDay(d);

        list.setFromDate(gglFromDate);
      }
      if (toDateValue) {
        let gglToDate = new google_date.Date();
        let [y, m, d] = toDateValue.split('-');
        gglToDate.setYear(y);
        gglToDate.setMonth(m);
        gglToDate.setDay(d);

        list.setToDate(gglToDate);
      }
      list.setStatus(statusValue);
      list.setBatchNo(batchNoValue);

      pendingtrns.listPendingTrns(list, meta, (err, res) => {
        if (err) {
          console.error(err);
          notifyError(err.message);
          setLoading(false);
          return;
        }

        let fullData = res.getPendingTransactionsList().map((pendingTrns) => {
          const data = pendingTrns.toObject();
          return {
            stageId: data.stageId,
            correspondent: data.correspondent,
            accountNo: data.accountNo,
            symbol: data.symbol,
            entryType: data.entryType,
            entrySubType: data.entrySubType,
            systemDate:
              typeof data.systemDate === 'object'
                ? formatPbDate(data.systemDate)
                : data.systemDate,
            tradeDate:
              typeof data.tradeDate === 'object'
                ? formatPbDate(data.tradeDate)
                : data.tradeDate,
            tradeAt: data.tradeAt
              ? moment
                  .tz(new Date(data.tradeAt.seconds * 1000), 'America/New_York')
                  .format('hh:mm:ssa')
              : '--',
            createdAt: data.createdAt
              ? typeof data.createdAt === 'object'
                ? moment(new Date(data.createdAt.seconds * 1000)).format(
                    'MM/DD/YYYY hh:mm'
                  )
                : data.createdAt
              : '',
            settleDate:
              typeof data.settleDate === 'object'
                ? formatPbDate(data.settleDate)
                : data.settleDate,
            side: data.side,
            qty: data.qty,
            price: data.price.includes('$')
              ? data.price
              : formatCurrency(data.price, ''),
            localPrice: data.price.includes(',')
              ? data.localPrice
              : formatCurrency(data.localPrice, ''),
            batchNo: data.batchNo,
            previousTrnId: data.previousTrnId,
            status: data.status,
            contraAccountNo: data.contraAccountNo,
            grossAmt: data.grossAmt.includes('$')
              ? data.grossAmt
              : formatCurrency(data.grossAmt, ''),
            localGrossAmt: data.localGrossAmt.includes(',')
              ? data.localGrossAmt
              : formatCurrency(data.localGrossAmt, ''),
            netAmt: data.netAmt.includes('$')
              ? data.netAmt
              : formatCurrency(data.netAmt, ''),
            localNetAmt: data.localNetAmt.includes(',')
              ? data.localNetAmt
              : formatCurrency(data.localNetAmt, ''),
            fees: data.fees.includes('$')
              ? data.fees
              : formatCurrency(data.fees, ''),
            description: data.description,
            createdBy: data.createdBy,
            accountId: data.accountId,
            contraAccountId: data.contraAccountId,
            originalCusip: data.originalCusip,
            errorMessage: data.errorMessage,
            executionId: data.executionId,
            externalId: data.externalId,
            executionVenue: data.executionVenue,
            orderId: data.orderId,
            leavesQty: data.leavesQty,
            vendor: data.vendor,
            refId: data.refId,
            currency: data.contraSymbol.replace('$', '') || 'USD',
            swapRate: data.swapRate,
            metadata: protoTransactionMetadataToString(
              pendingTrns.getMetadata()
            ),
            localFees: protoTransactionMetadataLocalFees(data.metadata),
          };
        });

        setPageState({
          ...page,
          totalRows: fullData.length,
          searchSrc: type,
          currentPage: cPage,
          displayedRows: rowsPerPage,
          data: fullData,
          sortOrder: { name: field, direction: sortDirection },
        });
      });
    } else {
      setPageState({
        ...page,
        searchSrc: type,
        currentPage: cPage,
        displayedRows: rowsPerPage,
        sortOrder: { name: field, direction: sortDirection },
        filter: filterValue,
      });
    }
  };
  /**search function end */

  /**PAGING LOGIC END ==========================================================*/

  const [selected, setSelected] = React.useState([]);

  useEffect(() => {
    if (selected.length === 0) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [selected]);
  //const [rowID, setRowID] = React.useState('');
  const columns = [
    {
      id: '34',
      name: 'currency',
      label: 'Currency',
      visibleState: useState(true),
      align: 'left',
      order: 0,
    },
    {
      id: '35',
      name: 'swapRate',
      label: 'Swap Rate',
      visibleState: useState(true),
      align: 'left',
      order: 1,
    },
    {
      id: '1',
      name: 'accountId',
      label: 'Account ID',
      visibleState: useState(false),
      align: 'left',
      order: 24,
    },
    {
      id: '2',
      name: 'accountNo',
      label: 'Account No',
      visibleState: useState(true),
      align: 'left',
      order: 4,
    },
    {
      id: '3',
      name: 'batchNo',
      label: 'Batch No',
      visibleState: useState(false),
      align: 'left',
      order: 15,
    },
    {
      id: '4',
      name: 'contraAccountId',
      label: 'Contra AccountID',
      visibleState: useState(false),
      align: 'left',
      order: 25,
    },
    {
      id: '5',
      name: 'contraAccountNo',
      label: 'Contra Account No',
      visibleState: useState(true),
      align: 'left',
      order: 21,
    },
    {
      id: '6',
      name: 'correspondent',
      label: 'Correspondent',
      visibleState: useState(true),
      align: 'left',
      order: 3,
    },
    {
      id: '7',
      name: 'createdAt',
      label: 'Created At',
      visibleState: useState(false),
      align: 'left',
      order: 14,
    },
    {
      id: '8',
      name: 'createdBy',
      label: ' created By',
      visibleState: useState(false),
      align: 'left',
      order: 23,
    },
    {
      id: '9',
      name: 'description',
      label: 'Description',
      visibleState: useState(false),
      align: 'left',
      order: 22,
    },
    {
      id: '10',
      name: 'entryType',
      label: 'Entry Type',
      visibleState: useState(true),
      align: 'left',
      order: 6,
    },
    {
      id: '11',
      name: 'errorMessage',
      label: 'Error Message',
      visibleState: useState(true),
      align: 'left',
      order: 27,
    },
    {
      id: '12',
      name: 'executionId',
      label: 'Execution ID',
      visibleState: useState(false),
      align: 'left',
      order: 28,
    },
    {
      id: '13',
      name: 'executionVenue',
      label: 'Execution Venue',
      visibleState: useState(false),
      align: 'left',
      order: 30,
    },
    {
      id: '14',
      name: 'externalId',
      label: 'External ID',
      visibleState: useState(false),
      align: 'left',
      order: 29,
    },
    {
      id: '15',
      name: 'fees',
      label: 'Fees',
      visibleState: useState(true),
      align: 'right',
      order: 20,
    },
    {
      id: '16',
      name: 'grossAmt',
      label: 'Gross Amount',
      visibleState: useState(true),
      align: 'right',
      order: 18,
    },
    {
      id: '17',
      name: 'leavesQty',
      label: 'Leaves Qty',
      visibleState: useState(false),
      align: 'right',
      order: 31,
    },
    {
      id: '18',
      name: 'netAmt',
      label: 'Net Amount',
      visibleState: useState(true),
      align: 'right',
      order: 19,
    },
    {
      id: '19',
      name: 'orderId',
      label: 'Order ID',
      visibleState: useState(false),
      align: 'left',
      order: 32,
    },
    {
      id: '20',
      name: 'originalCusip',
      label: 'Original Cusip',
      visibleState: useState(false),
      align: 'left',
      order: 26,
    },
    {
      id: '21',
      name: 'previousTrnId',
      label: 'Previous Trn ID',
      visibleState: useState(false),
      align: 'left',
      order: 16,
    },
    {
      id: '22',
      name: 'price',
      label: 'Price',
      visibleState: useState(true),
      align: 'right',
      order: 13,
    },
    {
      id: '23',
      name: 'qty',
      label: 'Qty',
      visibleState: useState(true),
      align: 'right',
      order: 12,
    },
    {
      id: '24',
      name: 'settleDate',
      label: 'Settle Date',
      visibleState: useState(true),
      align: 'left',
      order: 10,
    },
    {
      id: '25',
      name: 'side',
      label: 'Side',
      visibleState: useState(true),
      align: 'left',
      order: 11,
    },
    {
      id: '26',
      name: 'stageId',
      label: 'Stage ID',
      visibleState: useState(false),
      align: 'left',
      order: 2,
    },
    {
      id: '27',
      name: 'status',
      label: 'Status',
      visibleState: useState(false),
      align: 'left',
      order: 17,
    },
    {
      id: '28',
      name: 'symbol',
      label: 'Symbol',
      visibleState: useState(true),
      align: 'left',
      order: 5,
    },
    {
      id: '29',
      name: 'systemDate',
      label: 'System Date',
      visibleState: useState(false),
      align: 'left',
      order: 7,
    },
    {
      id: '30',
      name: 'tradeAt',
      label: 'Trade At',
      visibleState: useState(true),
      align: 'left',
      order: 9,
    },
    {
      id: '31',
      name: 'tradeDate',
      label: 'Trade Date',
      visibleState: useState(true),
      align: 'left',
      order: 8,
    },
    {
      id: '32',
      name: 'vendor',
      label: 'Vendor',
      visibleState: useState(false),
      align: 'left',
      order: 33,
    },
    {
      id: '33',
      name: 'refId',
      label: 'Ref ID',
      visibleState: useState(false),
      align: 'left',
      order: 34,
    },
    {
      id: '34',
      name: 'metadata',
      label: 'Metadata',
      visibleState: useState(true),
      align: 'left',
      order: 35,
    },
    {
      id: '36',
      name: 'entrySubType',
      label: 'Entry Subtype',
      visibleState: useState(true),
      align: 'left',
      order: 36,
    },
  ];

  const sortedColumns = columns.slice(0);
  sortedColumns.sort(function(a, b) {
    return a.order - b.order;
  });

  const setVisibles = {};
  columns.forEach((col) => {
    if (col.name === '') {
      col.options = {
        display: true,
        viewColumns: false,
        empty: true,
      };
      return;
    }
    col.options = {
      display: col.visibleState[0],
    };
    setVisibles[col.name] = col.visibleState[1];
    return;
  });

  const handleClick = (event, stageId, removed) => {
    if (!removed) {
      const selectedIndex = selected.indexOf(stageId);
      let newSelected = [];
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, stageId);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        );
      }
      setSelected(newSelected);
      //setRowID(newSelected);
    } else {
      setSelected([]);
    }
  };

  const isSelected = (stageId) => selected.indexOf(stageId) !== -1;

  const customTableRow = (data, dataIndex, rowIndex) => {
    if (!rows[rowIndex]) {
      return;
    }

    const processPendingTransaction = (stageId, accountId) => {
      const paramPath = '/app/trns/process-pending/' + stageId;

      window.open(paramPath, '_blank');
    };

    const isItemSelected = isSelected(rows[rowIndex].stageId);
    const labelId = `enhanced-table-checkbox-${rowIndex}`;
    return (
      <TableRow
        aria-checked={isItemSelected}
        tabIndex={-1}
        key={rowIndex}
        selected={isItemSelected}
      >
        <TableCell padding="checkbox">
          <div className={classes.grdRow} style={{ margin: 0 }}>
            <div className={classes.grdCellNone}>
              <Checkbox
                onClick={(event) => handleClick(event, rows[rowIndex].stageId)}
                checked={isItemSelected}
                inputProps={{ 'aria-labelledby': labelId }}
              />
            </div>
            <div className={classes.grdCell1}>
              <Tooltip title="Process" arrow>
                <div>
                  <IconButton
                    aria-label="process"
                    // disabled={
                    //   rows[rowIndex].status === 'canceled' ? true : false
                    // }
                    onClick={() => {
                      processPendingTransaction(
                        rows[rowIndex].stageId,
                        rows[rowIndex].accountId
                      );
                    }}
                  >
                    <CancelAndCorrectIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </div>
          </div>
        </TableCell>
        {sortedColumns
          .filter((v) => v.options.display)
          .map((col) => (
            <TableCell
              className={
                parseFloat(
                  rows[rowIndex][col.name] ||
                    ''
                      .toString()
                      .replace('$', '')
                      .replaceAll(',', '')
                ) < 0
                  ? classes.textColorRed
                  : ''
              }
              align={col.align || 'left'}
              key={col.name}
            >
              {showLctUsd(rows[rowIndex], col.name)}
            </TableCell>
          ))}
      </TableRow>
    );
  };

  const showLctUsd = (row, colname) => {
    let showUSD = isUSD || row.currency === 'USD';
    switch (colname) {
      case 'currency':
        return showUSD ? 'USD' : row[colname];
      case 'price':
        return showUSD ? row[colname] : row.localPrice;
      case 'grossAmt':
        return showUSD ? row[colname] : row.localGrossAmt;
      case 'netAmt':
        return showUSD ? row[colname] : row.localNetAmt;
      case 'fees': {
        let feeLCT = row.localFees?.includes('$')
          ? row.localFees
          : formatCurrency(row.localFees, '');
        return showUSD ? row[colname] : feeLCT;
      }
      case 'swapRate':
        return showUSD ? 1.0 : row.swapRate;
      default:
        return row[colname];
    }
  };

  const showHideColumns = (column, action) => {
    setVisibles[column](action === 'remove' ? false : true);
  };

  const fileName =
    'PendingTRNS_' + moment().format('MMMM Do YYYY, h:mm:ss a') + '.csv';

  const options = {
    filterType: 'select',
    customRowRender: customTableRow,
    onColumnViewChange: showHideColumns,
    download: true,
    filter: true,
    search: false,
    print: false,
    sort: true,
    viewColumns: true,
    columnOrder: [
      0,
      1,
      27,
      7,
      3,
      29,
      11,
      30,
      32,
      31,
      25,
      26,
      24,
      23,
      8,
      4,
      22,
      28,
      17,
      19,
      16,
      6,
      10,
      9,
      2,
      5,
      21,
      12,
      13,
      15,
      14,
      18,
      20,
      33,
      34,
      35,
      36,
    ],
    selectedRows: page.selectedRows,
    count: page.totalRows,
    serverSide: true,
    rowsPerPage: page.displayedRows,
    rowsPerPageOptions: [50, 100, 500, 1000, 5000],
    disableToolbarSelect: true,
    onDownload: () => {
      document.getElementById('csvDL').click();
      return false;
    },
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'filterChange': {
          let filterValues = [];

          for (let i = 0; i < tableState.filterList.length; i++) {
            if (tableState.filterList[i].length > 0) {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            } else {
              filterValues.push({
                columnIndex: i,
                value: tableState.filterList[i][0],
              });
            }
          }
          search(
            'filterChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            tableState.sortOrder.direction,
            filterValues
          );
          break;
        }
        case 'resetFilters': {
          search('search', 0, page.displayedRows);
          break;
        }
        case 'changePage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'sort': {
          search(
            'sortChange',
            tableState.page,
            tableState.rowsPerPage,
            tableState.columns[tableState.activeColumn].name,
            tableState.sortOrder.direction,
            page.filter
          );
          break;
        }
        case 'changeRowsPerPage': {
          search(
            'pageChange',
            tableState.page,
            tableState.rowsPerPage,
            '',
            '',
            page.filter
          );
          break;
        }
        case 'propsUpdate': {
          return { ...tableState, count: page.totalRows };
        }
        case 'rowSelectionChange': {
          let newSelected = [];
          if (
            selected.length >= page.displayedRows ||
            selected.length >= page.totalRows
          ) {
            handleClick('', '', true);
          } else {
            rows.forEach((row) => {
              newSelected = newSelected.concat(selected, row.stageId);
            });
            setSelected(newSelected);
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    customTableBodyFooterRender: function(opts) {
      return (
        <CustomTableBodyFooter
          columnOrder={options.columnOrder}
          columns={opts.columns}
          columnsWithAmt={['grossAmt', 'netAmt', 'fees']}
          columnsWithQty={['qty', 'leavesQty']}
          rows={rows}
          additionalCell={true}
        ></CustomTableBodyFooter>
      );
    },
  };
  /*=========================================================================================
  End of Table Configuration
  ===========================================================================================*/
  const profile = useProfile();
  const [rows, setRows] = useState([]);
  const [downloadableRows, setDownloadableRows] = useState([]);

  const handleSearch = (page) => {
    search('search', 0, page.displayedRows);
  };

  /*=========================================================================================
  Search input fields
  ===========================================================================================*/
  const [correspondentValue, setCorrespondentValue] = useState('');
  const [accountNoValue, setAccountNoValue] = useState('');
  const [contraAccountNoValue, setContraAccountNoValue] = useState('');
  const [symbolValue, setSymbolValue] = useState('');
  const [dateTypeValue, setDateTypeValue] = useState('system_date');
  const [fromDateValue, setFromDateValue] = useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [toDateValue, setToDateValue] = useState(
    profile.systemDate.format('yyyy-MM-DD')
  );
  const [batchNoValue, setBatchNoValue] = useState('');
  const [statusValue, setStatusValue] = useState('');
  const [entryTypeValue, setEntryTypeValue] = useState('');
  const [entrySubTypeValue, setEntrySubTypeValue] = useState('');
  const [cilPriceValue, setCilPriceValue] = useState('');
  const [currentPath, setCurrentPath] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [showFilter, setShowFilter] = React.useState(true);
  const [isProcess, setIsProcess] = React.useState(false);
  const [isAutoPopulate, setIsAutoPopulate] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isApplyingPrice, setIsApplyingPrice] = React.useState(false);
  const [disableOnLoadSearch, setDisableOnLoadSearch] = React.useState(false);
  const [isUSD, setIsUSD] = React.useState(true);
  const [allValue, setAllValue] = React.useState(false);

  useEffect(() => {
    const query = new URLSearchParams(params.location.search);
    const path = params.truepath;
    const data = {
      correspondent: query.get('correspondent'),
      accountNo: query.get('accountNo'),
      symbol: query.get('symbol'),
      status: query.get('status'),
      entryType: query.get('entryType'),
      entrySubType: query.get('entrySubType'),
      dateType: query.get('dateType'),
      fromDate: query.get('fromDate'),
      toDate: query.get('toDate'),
    };

    if (data.correspondent) {
      setCorrespondentValue(data.correspondent);
    }
    if (data.accountNo) {
      setAccountNoValue(data.accountNo);
    }
    if (data.contraAccountNo) {
      setContraAccountNoValue(data.contraAccountNo);
    }
    if (data.symbol) {
      setSymbolValue(data.symbol);
    }
    if (data.status) {
      setStatusValue(data.status);
    }
    if (data.batchNo) {
      setBatchNoValue(data.batchNo);
    }
    if (data.entryType) {
      setEntryTypeValue(data.entryType);
    }
    if (data.entrySubType) {
      setEntrySubTypeValue(data.entrySubType);
    }
    if (data.dateType) {
      setDateTypeValue(data.dateType);
    }
    if (data.fromDate) {
      setFromDateValue(data.fromDate);
    }
    if (data.toDate) {
      setToDateValue(data.toDate);
    }

    setCurrentPath(path);

    const query2 = params.location.pathname.split('/');
    const action = query2[4];
    const symbol = query2[5];

    if (action === 'process') {
      setShowFilter(false);
      setIsProcess(true);
      setIsAutoPopulate(true);
      setSymbolValue(symbol);
      setEntryTypeValue('CIL');

      if (fromDateValue) {
        if (!disableOnLoadSearch) {
          search('search', 0, page.displayedRows);
          setDisableOnLoadSearch(true);
        }
      }
    } else {
      setIsProcess(false);
      setShowFilter(true);
    }
    // eslint-disable-next-line
  }, [params]);
  /*=========================================================================================
  End of search input fields
  ===========================================================================================*/

  async function updatePendingTrnsApplyCIL(req) {
    return new Promise((resolve, reject) => {
      pendingtrns.updatePendingTrnsApplyCIL(req, meta, (err, res) => {
        if (err) {
          console.log(err);
          reject(err);
        } else {
          resolve(res);
          setIsApplyingPrice(true);
          search('search without message', 0, page.displayedRows);
          setSelected([]);
          notifySuccess('Price CIL has been applied');
        }
      });
    });
  }

  const applyCILPrice = (selectedPendingTrns, price, symbol) => {
    if (selectedPendingTrns.length < 1) {
      notifyError('No item is selected.');
      return;
    }
    let listReq = [];

    const applyFunction = async (item) => {
      rows.forEach(async (row) => {
        if (row.stageId === item) {
          let req = new PendingTrnsApplyCIL();

          req.setPrice(price);
          req.setSymbol(row.symbol);
          req.setQty(row.qty);
          req.setStageId(row.stageId);

          listReq.push(req);
        }
      });
    };

    selectedPendingTrns.forEach(applyFunction);

    let applyReq = new UpdatePendingTrnsApplyCILRequest();
    applyReq.setApplyCilList(listReq);

    try {
      updatePendingTrnsApplyCIL(applyReq);
    } catch (err) {
      console.log(err);
      notifyError(err.message);
    }
  };

  function processPendingTrns(
    trnsClient,
    pendingTrns,
    rows,
    selectedPendingTrns,
    confirm,
    setIsLoading,
    isLoading,
    setRows,
    setSelected
  ) {
    if (isLoading) {
      return false;
    }

    setIsLoading(true);
    if (!allValue && selectedPendingTrns.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'Are you sure you want to process ' +
        (allValue ? 'all matching' : selectedPendingTrns.length) +
        ' records?',
      confirmationText: 'Yes, Confirm',
    })
      .then(() => {
        if (allValue) {
          let request = new ExecutePendingTrnsRequest();

          let filters = new PendingTrnsFilters();
          let dateFilter = new PendingTrnsDateFilter();

          filters.setCorrespondent(correspondentValue);
          filters.setAccountNo(accountNoValue);
          filters.setContraAccountNo(contraAccountNoValue);
          filters.setSymbol(symbolValue);
          filters.setEntryType(entryTypeValue);
          filters.setEntrySubType(entrySubTypeValue);
          if (fromDateValue && toDateValue) {
            dateFilter.setType(dateTypeValue);
            dateFilter.setFrom(dateToPBDate(fromDateValue));
            dateFilter.setTo(dateToPBDate(toDateValue));
            filters.setDate(dateFilter);
          }
          request.setFilters(filters);

          pendingtrns.executePendingTrns(request, meta, (err, res) => {
            if (err) {
              console.error(err.message);
              notifyError(err.message);
              return false;
            }

            notifySuccess(
              res.getExecutedCount() +
                ' records have been executed, and ' +
                res.getRejectedCount() +
                ' records have been rejected'
            );
          });

          setIsLoading(false);
          return;
        }

        function processFunction(item) {
          rows.forEach((row) => {
            if (row.stageId === item) {
              let trns = new Transaction();
              trns.setTrnsId(row.stageId);
              trns.setCorrespondent(row.correspondent);
              trns.setAccountNo(row.accountNo);
              trns.setSymbol(row.symbol);
              trns.setEntryType(row.entryType);
              trns.setEntrySubType(row.entrySubType);

              if (row.tradeDate) {
                const tradeDate = moment
                  .tz(
                    row.tradeDate + ' ' + row.tradeAt,
                    'MM/DD/YYYY hh:mm:ssa',
                    'America/New_York'
                  )
                  .toDate();
                const tradeTime = timestamp_pb.Timestamp.fromDate(tradeDate);
                trns.setTradeAt(tradeTime);
              }

              if (row.settleDate) {
                let dt = new google_date.Date();
                let [m, d, y] = row.settleDate.split('/');
                dt.setYear(y);
                dt.setMonth(m);
                dt.setDay(d);
                trns.setSettleDate(dt);
              }

              trns.setSide(row.side);
              trns.setQty(row.qty);
              trns.setPrice(
                row.price
                  .toString()
                  .replace('$', '')
                  .replaceAll(',', '')
              );
              // trns.setPreviousTrnId(row.previousTrnId);
              trns.setContraAccountNo(row.contraAccountNo);
              trns.setGrossAmt(
                row.grossAmt
                  .toString()
                  .replace('$', '')
                  .replaceAll(',', '')
              );
              trns.setNetAmt(
                row.netAmt
                  .toString()
                  .replace('$', '')
                  .replaceAll(',', '')
              );
              trns.setDescription(row.description);
              trns.setExecutionId(row.executionId);
              trns.setExternalId(row.externalId);
              trns.setExecutingVenue(row.executionVenue);
              trns.setOrderId(row.orderId);
              trns.setLeavesQty(row.leavesQty);
              trns.setVendor(row.vendor);
              trns.setRefId(row.refId);

              const fee = row.fees
                .toString()
                .replace('$', '')
                .replaceAll(',', '');

              trns.setFees(fee);
              let trnsFees = [];
              let trnsFee = new TransactionFee();
              trnsFee.setFeeType('commission');
              trnsFee.setFee(fee);
              trnsFees.push(trnsFee);
              trns.setTransactionFeesList(trnsFees);
              if (row.currency && row.currency !== 'USD') {
                trns.setContraSymbol(
                  row.currency.startsWith('$')
                    ? row.currency
                    : '$' + row.currency
                );
              }
              trns.setSwapRate(row.swapRate);
              if (row.swapRateAt) {
                const swapRateAt = moment(
                  row.swapRateAt,
                  'MM/DD/YYYY hh:mm:ssa Z'
                ).toDate();
                const swapRateAtTime = timestamp_pb.Timestamp.fromDate(
                  swapRateAt
                );
                trns.setSwapRateAt(swapRateAtTime);
              }
              trns.setLocalGrossAmt(row.localGrossAmt);
              trns.setLocalNetAmt(row.localNetAmt);
              trns.setSwapFeeBpsAlpa(row.swapFeeBpsAlpa);
              trns.setSwapFeeBpsCorr(row.swapFeeBpsCorr);
              trns.setMetadata(
                row.localFees
                  ? stringToProtoTransactionMetadata(
                      '{"local_fees":"' + row.localFees + '"}'
                    )
                  : null
              );

              trnsList.push(trns);
            }
          });
        }

        let trnsList = [];
        selectedPendingTrns.forEach(processFunction);

        let processReq = new BatchProcessPendingTransactionRequest();
        processReq.setTransactionsList(trnsList);
        trnsClient.batchProcessPendingTransaction(
          processReq,
          meta,
          (err, res) => {
            if (err) {
              notifyError(err.message);
              setIsLoading(false);
              return false;
            }

            if (res) {
              const result = res.toObject().responsesList;
              const rowsCopy = [...rows];

              let executedCounter = 0;
              let correctedCounter = 0;
              result.forEach((r) => {
                for (let i = 0; i < rows.length; i++) {
                  if (r.trnsId === rows[i].stageId) {
                    if (r.errorMessage) {
                      notifyError(
                        r.status + ' ' + r.trnsId + ' ' + r.errorMessage
                      );
                    } else {
                      rowsCopy.splice(i, 1);
                      if (rows[i].status === 'pending') {
                        executedCounter++;
                      } else {
                        correctedCounter++;
                      }
                    }
                  }
                }
              });

              if (executedCounter > 0) {
                notifySuccess(executedCounter + ' records have been executed');
              }
              if (correctedCounter > 0) {
                notifySuccess(
                  correctedCounter + ' records have been corrected'
                );
              }

              setRows(rowsCopy);
            }
            setSelected([]);
            setIsLoading(false);
          }
        );
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
        /* ... */
      });
  }

  function removePendingTrns(
    pendingtrns,
    rows,
    selectedPendingTrns,
    handleClick,
    confirm,
    setPageState,
    page
  ) {
    if (!allValue && selectedPendingTrns.length < 1) {
      notifyError('No item is selected.');
      return;
    }

    confirm({
      description:
        'You are about to delete ' +
        (allValue ? 'all matching' : selectedPendingTrns.length) +
        ' Pending Transactions in your system. Please confirm your action.',
      confirmationText: 'Yes, Delete',
    })
      .then(() => {
        let deleteReq = new DeletePendingTrnsRequest();

        if (allValue) {
          let filters = new PendingTrnsFilters();
          let dateFilter = new PendingTrnsDateFilter();

          filters.setCorrespondent(correspondentValue);
          filters.setAccountNo(accountNoValue);
          filters.setContraAccountNo(contraAccountNoValue);
          filters.setSymbol(symbolValue);
          filters.setEntryType(entryTypeValue);
          filters.setEntrySubType(entrySubTypeValue);
          if (fromDateValue && toDateValue) {
            dateFilter.setType(dateTypeValue);
            dateFilter.setFrom(dateToPBDate(fromDateValue));
            dateFilter.setTo(dateToPBDate(toDateValue));
            filters.setDate(dateFilter);
          }
          deleteReq.setFilters(filters);
        }
        let stage_ids = [];
        selectedPendingTrns.forEach(removeFunction);

        function removeFunction(item) {
          stage_ids.push(item);
        }

        deleteReq.setStageIdList(stage_ids);
        pendingtrns.deletePendingTrns(deleteReq, meta, (err, res) => {
          if (err) {
            console.error(err.message);
            notifyError(err.message);
            return false;
          }

          let data = page.data;

          if (allValue) {
            data = [];
          } else {
            stage_ids.forEach((stage_id) => {
              for (let i = 0; i < data.length; i++) {
                if (stage_id === data[i].stageId) {
                  data.splice(i, 1);
                }
              }
            });
          }

          notifySuccess(res.getDeletedCount() + ' records have been deleted');

          handleClick('', '', true);

          setPageState({
            ...page,
            totalRows: data.length,
            data: data,
            searchSrc: 'deleteRow',
            selectedRows: [],
          });
        });
      })
      .catch(() => {
        /* ... */
      });
  }

  return (
    <div className={classes.root}>
      <Box component="div" mt={5}>
        {showFilter ? (
          <div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={true}
                  type="correspondent"
                  name="correspondent"
                  label="Correspondent"
                  value={correspondentValue}
                  onChange={(e) => {
                    setCorrespondentValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={false}
                  type="accountNo"
                  name="accountNo"
                  label="Account No"
                  value={accountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={
                    correspondentValue ? correspondentValue : ''
                  }
                  showInactive={true}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectAccount
                  freeSolo={false}
                  type="accountNo"
                  name="contraAccountNo"
                  label="Contra Account No"
                  value={contraAccountNoValue}
                  showAccountName={true}
                  onChange={(e) => {
                    setContraAccountNoValue(e.currentTarget.value);
                  }}
                  selectedCorrespondent={''}
                />
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <SelectSymbol
                  freeSolo={true}
                  name="symbol"
                  label="Symbol"
                  type="symbol"
                  value={symbolValue}
                  onChange={(e) => {
                    setSymbolValue(e.currentTarget.value);
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <InputLabel shrink>Status</InputLabel>
                <Select
                  fullWidth
                  value={statusValue}
                  onChange={(e) => setStatusValue(e.target.value)}
                >
                  <MenuItem value="">
                    <em>Blank</em>
                  </MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="rejected">Rejected</MenuItem>
                </Select>
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <EntryTypeSelect
                  name="entryType"
                  label="Entry Type"
                  value={entryTypeValue}
                  onChange={(e) => {
                    if (e.currentTarget.value !== 'WH') {
                      setEntrySubTypeValue('');
                    }
                    setEntryTypeValue(e.currentTarget.value);
                  }}
                  autopopulate={isAutoPopulate}
                />
              </div>
              {entryTypeValue === 'WH' && (
                <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                  <InputLabel shrink>Entry Subtype</InputLabel>
                  <Select
                    fullWidth
                    value={entrySubTypeValue}
                    onChange={(e) => setEntrySubTypeValue(e.target.value)}
                  >
                    <MenuItem value="">
                      <em>Blank</em>
                    </MenuItem>
                    <MenuItem value="FWH">FWH</MenuItem>
                    <MenuItem value="SWH">SWH</MenuItem>
                  </Select>
                </div>
              )}
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <InputLabel shrink>Date Type</InputLabel>
                <Select
                  displayEmpty
                  fullWidth
                  value={dateTypeValue}
                  onChange={(e) => {
                    setDateTypeValue(e.target.value);
                    // if (e.target.value !== 'system_date') {
                    //   setFromDateValue('');
                    //   setToDateValue('');
                    // }
                  }}
                >
                  <MenuItem value="system_date">System Date</MenuItem>
                  <MenuItem value="trade_date">Trade Date</MenuItem>
                  <MenuItem value="settle_date">Settle Date</MenuItem>
                </Select>
              </div>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="From Date"
                  type="date"
                  value={fromDateValue}
                  onChange={(e) => setFromDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    max: toDateValue,
                  }}
                />
              </div>
              <div className={classes.grdCell1}>
                <TextField
                  fullWidth
                  label="To Date"
                  type="date"
                  value={toDateValue}
                  onChange={(e) => setToDateValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{
                    min: fromDateValue,
                  }}
                />
              </div>
            </div>
            <div className={classes.grdRow}>
              <div className={classes.grdCell1} style={{ marginRight: 30 }}>
                <TextField
                  fullWidth
                  label="Batch No"
                  value={batchNoValue}
                  onChange={(e) => setBatchNoValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div
                className={classes.grdCell1}
                style={{ marginRight: 30 }}
              ></div>
              <div className={classes.grdCell1}></div>
            </div>
          </div>
        ) : null}
        <div
          className={classes.actionContainer}
          style={{ alignItems: 'flex-end' }}
        >
          <div className={classes.grdCellNone} style={{ marginRight: '10px' }}>
            <SearchButton
              onClick={() => handleSearch(page)}
              loading={loading}
              showfilter={(status) => setShowFilter(status)}
            />
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => setIsUSD(!isUSD)}
            >
              {isUSD ? 'Show Local Currency' : 'Show USD'}
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() =>
                processPendingTrns(
                  trnsClient,
                  pendingtrns,
                  rows,
                  selected,
                  confirm,
                  setIsLoading,
                  isLoading,
                  setRows,
                  setSelected
                )
              }
              startIcon={
                isLoading ? (
                  <CircularProgress
                    style={{ color: '#ffffff', height: 20, width: 20 }}
                  />
                ) : null
              }
              disabled={isDisabled}
            >
              Process
            </Button>
          </div>
          <div className={classes.grdCellNone} style={{ marginRight: '30px' }}>
            <Button
              id="cmdDelete"
              variant="contained"
              color="primary"
              size="large"
              onClick={() =>
                removePendingTrns(
                  pendingtrns,
                  rows,
                  selected,
                  handleClick,
                  confirm,
                  setPageState,
                  page
                )
              }
              disabled={isDisabled}
            >
              Delete
            </Button>
          </div>
          <div className={classes.grdCell1}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allValue}
                  onChange={(e) => {
                    setAllValue(e.target.checked);
                    setIsDisabled(!e.target.checked);
                  }}
                />
              }
              label="All"
            />
          </div>
          {isProcess ? (
            <div className={classes.grdRow} style={{ margin: '0px' }}>
              <div
                className={classes.grdCellNone}
                style={{ marginRight: '10px' }}
              >
                <TextField
                  fullWidth
                  label="CIL Price"
                  value={cilPriceValue}
                  onChange={(e) => setCilPriceValue(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                />
              </div>
              <div className={classes.grdCellNone}>
                <Button
                  id="applyPrice"
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={cilPriceValue ? false : true}
                  onClick={() =>
                    applyCILPrice(selected, cilPriceValue, symbolValue)
                  }
                  startIcon={
                    isApplyingPrice ? (
                      <CircularProgress
                        style={{ color: '#ffffff', height: 20, width: 20 }}
                      />
                    ) : null
                  }
                >
                  {isApplyingPrice ? 'Applying...' : 'Apply CIL Price'}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </Box>
      <Box component="div" mt={2}>
        {downloadableRows && (
          <CSVLink
            id="csvDL"
            data={downloadableRows}
            filename={fileName}
            target="_blank"
          />
        )}
        <MuiThemeProvider theme={tableTheme()}>
          <MUIDataTable
            title={'Pending TRNS'}
            data={rows}
            columns={columns}
            options={options}
          />
        </MuiThemeProvider>
      </Box>
    </div>
  );
}
