// source: reportpb/activity.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_api_annotations_pb = require('../google/api/annotations_pb.js');
goog.object.extend(proto, google_api_annotations_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
var google_type_date_pb = require('../google/type/date_pb.js');
goog.object.extend(proto, google_type_date_pb);
var trnspb_transaction_pb = require('../trnspb/transaction_pb.js');
goog.object.extend(proto, trnspb_transaction_pb);
var utilspb_pagination_pb = require('../utilspb/pagination_pb.js');
goog.object.extend(proto, utilspb_pagination_pb);
goog.exportSymbol('proto.reportpb.Activity', null, global);
goog.exportSymbol('proto.reportpb.AdditionalFilter', null, global);
goog.exportSymbol('proto.reportpb.ListActivityRequest', null, global);
goog.exportSymbol('proto.reportpb.ListActivityResponse', null, global);
goog.exportSymbol('proto.reportpb.ListActivitySummary', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.Activity = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.Activity, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.Activity.displayName = 'proto.reportpb.Activity';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivityRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListActivityRequest.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListActivityRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivityRequest.displayName = 'proto.reportpb.ListActivityRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.AdditionalFilter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.AdditionalFilter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.AdditionalFilter.displayName = 'proto.reportpb.AdditionalFilter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivityResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.reportpb.ListActivityResponse.repeatedFields_, null);
};
goog.inherits(proto.reportpb.ListActivityResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivityResponse.displayName = 'proto.reportpb.ListActivityResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.reportpb.ListActivitySummary = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.reportpb.ListActivitySummary, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.reportpb.ListActivitySummary.displayName = 'proto.reportpb.ListActivitySummary';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.Activity.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.Activity.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.Activity} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Activity.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    accountName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    capacity: jspb.Message.getFieldWithDefault(msg, 8, ""),
    representative: jspb.Message.getFieldWithDefault(msg, 9, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 10, ""),
    symbolDescription: jspb.Message.getFieldWithDefault(msg, 11, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 12, ""),
    assetType: jspb.Message.getFieldWithDefault(msg, 13, ""),
    createdAt: (f = msg.getCreatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    systemDate: (f = msg.getSystemDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeDate: (f = msg.getTradeDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    tradeAt: (f = msg.getTradeAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    settleDate: (f = msg.getSettleDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 19, ""),
    side: jspb.Message.getFieldWithDefault(msg, 20, ""),
    qty: jspb.Message.getFieldWithDefault(msg, 21, ""),
    price: jspb.Message.getFieldWithDefault(msg, 22, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 23, ""),
    regFee: jspb.Message.getFieldWithDefault(msg, 24, ""),
    tafFee: jspb.Message.getFieldWithDefault(msg, 25, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 26, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 27, ""),
    description: jspb.Message.getFieldWithDefault(msg, 28, ""),
    batchNo: jspb.Message.getFieldWithDefault(msg, 29, ""),
    previousTrnId: jspb.Message.getFieldWithDefault(msg, 30, ""),
    status: jspb.Message.getFieldWithDefault(msg, 31, ""),
    createdBy: jspb.Message.getFieldWithDefault(msg, 32, ""),
    trnsId: jspb.Message.getFieldWithDefault(msg, 33, ""),
    accountId: jspb.Message.getFieldWithDefault(msg, 34, ""),
    executionId: jspb.Message.getFieldWithDefault(msg, 35, ""),
    executingVenue: jspb.Message.getFieldWithDefault(msg, 36, ""),
    externalId: jspb.Message.getFieldWithDefault(msg, 37, ""),
    leavesQty: jspb.Message.getFieldWithDefault(msg, 38, ""),
    orderId: jspb.Message.getFieldWithDefault(msg, 39, ""),
    vendor: jspb.Message.getFieldWithDefault(msg, 40, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 41, ""),
    contraAccountDesignator: jspb.Message.getFieldWithDefault(msg, 42, ""),
    commission: jspb.Message.getFieldWithDefault(msg, 43, ""),
    contraCorrespondent: jspb.Message.getFieldWithDefault(msg, 44, ""),
    contraAccountId: jspb.Message.getFieldWithDefault(msg, 45, ""),
    originalCusip: jspb.Message.getFieldWithDefault(msg, 46, ""),
    entryTypeDescription: jspb.Message.getFieldWithDefault(msg, 47, ""),
    refId: jspb.Message.getFieldWithDefault(msg, 48, ""),
    entrySubType: jspb.Message.getFieldWithDefault(msg, 49, ""),
    cumQty: jspb.Message.getFieldWithDefault(msg, 50, ""),
    orderType: jspb.Message.getFieldWithDefault(msg, 51, ""),
    tif: jspb.Message.getFieldWithDefault(msg, 52, ""),
    clientOrderId: jspb.Message.getFieldWithDefault(msg, 53, ""),
    executingBrokerMpid: jspb.Message.getFieldWithDefault(msg, 54, ""),
    contraSymbol: jspb.Message.getFieldWithDefault(msg, 55, ""),
    swapRate: jspb.Message.getFieldWithDefault(msg, 56, ""),
    swapRateAt: (f = msg.getSwapRateAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    localPrice: jspb.Message.getFieldWithDefault(msg, 59, ""),
    localNetAmt: jspb.Message.getFieldWithDefault(msg, 60, ""),
    activityId: jspb.Message.getFieldWithDefault(msg, 61, ""),
    localGrossAmt: jspb.Message.getFieldWithDefault(msg, 62, ""),
    swapFeeBpsCorr: jspb.Message.getFieldWithDefault(msg, 63, ""),
    swapFeeBpsAlpa: jspb.Message.getFieldWithDefault(msg, 64, ""),
    metadata: (f = msg.getMetadata()) && trnspb_transaction_pb.Metadata.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.Activity.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.Activity;
  return proto.reportpb.Activity.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.Activity} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.Activity.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setCapacity(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setRepresentative(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbolDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setAssetType(value);
      break;
    case 14:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setCreatedAt(value);
      break;
    case 15:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSystemDate(value);
      break;
    case 16:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setTradeDate(value);
      break;
    case 17:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTradeAt(value);
      break;
    case 18:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setSettleDate(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setSide(value);
      break;
    case 21:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 22:
      var value = /** @type {string} */ (reader.readString());
      msg.setPrice(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegFee(value);
      break;
    case 25:
      var value = /** @type {string} */ (reader.readString());
      msg.setTafFee(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 27:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 28:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 29:
      var value = /** @type {string} */ (reader.readString());
      msg.setBatchNo(value);
      break;
    case 30:
      var value = /** @type {string} */ (reader.readString());
      msg.setPreviousTrnId(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 32:
      var value = /** @type {string} */ (reader.readString());
      msg.setCreatedBy(value);
      break;
    case 33:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountId(value);
      break;
    case 35:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutionId(value);
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingVenue(value);
      break;
    case 37:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 38:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeavesQty(value);
      break;
    case 39:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 40:
      var value = /** @type {string} */ (reader.readString());
      msg.setVendor(value);
      break;
    case 41:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 42:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountDesignator(value);
      break;
    case 43:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommission(value);
      break;
    case 44:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraCorrespondent(value);
      break;
    case 45:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountId(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.setOriginalCusip(value);
      break;
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryTypeDescription(value);
      break;
    case 48:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefId(value);
      break;
    case 49:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntrySubType(value);
      break;
    case 50:
      var value = /** @type {string} */ (reader.readString());
      msg.setCumQty(value);
      break;
    case 51:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderType(value);
      break;
    case 52:
      var value = /** @type {string} */ (reader.readString());
      msg.setTif(value);
      break;
    case 53:
      var value = /** @type {string} */ (reader.readString());
      msg.setClientOrderId(value);
      break;
    case 54:
      var value = /** @type {string} */ (reader.readString());
      msg.setExecutingBrokerMpid(value);
      break;
    case 55:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraSymbol(value);
      break;
    case 56:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapRate(value);
      break;
    case 57:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setSwapRateAt(value);
      break;
    case 59:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalPrice(value);
      break;
    case 60:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalNetAmt(value);
      break;
    case 61:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivityId(value);
      break;
    case 62:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocalGrossAmt(value);
      break;
    case 63:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsCorr(value);
      break;
    case 64:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwapFeeBpsAlpa(value);
      break;
    case 65:
      var value = new trnspb_transaction_pb.Metadata;
      reader.readMessage(value,trnspb_transaction_pb.Metadata.deserializeBinaryFromReader);
      msg.setMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.Activity.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.Activity.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.Activity} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.Activity.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getAccountName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getCapacity();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRepresentative();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getSymbolDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getAssetType();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
  f = message.getCreatedAt();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSystemDate();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeDate();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getTradeAt();
  if (f != null) {
    writer.writeMessage(
      17,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSettleDate();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getSide();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      21,
      f
    );
  }
  f = message.getPrice();
  if (f.length > 0) {
    writer.writeString(
      22,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getRegFee();
  if (f.length > 0) {
    writer.writeString(
      24,
      f
    );
  }
  f = message.getTafFee();
  if (f.length > 0) {
    writer.writeString(
      25,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      26,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      27,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      28,
      f
    );
  }
  f = message.getBatchNo();
  if (f.length > 0) {
    writer.writeString(
      29,
      f
    );
  }
  f = message.getPreviousTrnId();
  if (f.length > 0) {
    writer.writeString(
      30,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getCreatedBy();
  if (f.length > 0) {
    writer.writeString(
      32,
      f
    );
  }
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      33,
      f
    );
  }
  f = message.getAccountId();
  if (f.length > 0) {
    writer.writeString(
      34,
      f
    );
  }
  f = message.getExecutionId();
  if (f.length > 0) {
    writer.writeString(
      35,
      f
    );
  }
  f = message.getExecutingVenue();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      37,
      f
    );
  }
  f = message.getLeavesQty();
  if (f.length > 0) {
    writer.writeString(
      38,
      f
    );
  }
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      39,
      f
    );
  }
  f = message.getVendor();
  if (f.length > 0) {
    writer.writeString(
      40,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      41,
      f
    );
  }
  f = message.getContraAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      42,
      f
    );
  }
  f = message.getCommission();
  if (f.length > 0) {
    writer.writeString(
      43,
      f
    );
  }
  f = message.getContraCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      44,
      f
    );
  }
  f = message.getContraAccountId();
  if (f.length > 0) {
    writer.writeString(
      45,
      f
    );
  }
  f = message.getOriginalCusip();
  if (f.length > 0) {
    writer.writeString(
      46,
      f
    );
  }
  f = message.getEntryTypeDescription();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
  f = message.getRefId();
  if (f.length > 0) {
    writer.writeString(
      48,
      f
    );
  }
  f = message.getEntrySubType();
  if (f.length > 0) {
    writer.writeString(
      49,
      f
    );
  }
  f = message.getCumQty();
  if (f.length > 0) {
    writer.writeString(
      50,
      f
    );
  }
  f = message.getOrderType();
  if (f.length > 0) {
    writer.writeString(
      51,
      f
    );
  }
  f = message.getTif();
  if (f.length > 0) {
    writer.writeString(
      52,
      f
    );
  }
  f = message.getClientOrderId();
  if (f.length > 0) {
    writer.writeString(
      53,
      f
    );
  }
  f = message.getExecutingBrokerMpid();
  if (f.length > 0) {
    writer.writeString(
      54,
      f
    );
  }
  f = message.getContraSymbol();
  if (f.length > 0) {
    writer.writeString(
      55,
      f
    );
  }
  f = message.getSwapRate();
  if (f.length > 0) {
    writer.writeString(
      56,
      f
    );
  }
  f = message.getSwapRateAt();
  if (f != null) {
    writer.writeMessage(
      57,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLocalPrice();
  if (f.length > 0) {
    writer.writeString(
      59,
      f
    );
  }
  f = message.getLocalNetAmt();
  if (f.length > 0) {
    writer.writeString(
      60,
      f
    );
  }
  f = message.getActivityId();
  if (f.length > 0) {
    writer.writeString(
      61,
      f
    );
  }
  f = message.getLocalGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      62,
      f
    );
  }
  f = message.getSwapFeeBpsCorr();
  if (f.length > 0) {
    writer.writeString(
      63,
      f
    );
  }
  f = message.getSwapFeeBpsAlpa();
  if (f.length > 0) {
    writer.writeString(
      64,
      f
    );
  }
  f = message.getMetadata();
  if (f != null) {
    writer.writeMessage(
      65,
      f,
      trnspb_transaction_pb.Metadata.serializeBinaryToWriter
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string account_name = 3;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string capacity = 8;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCapacity = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCapacity = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string representative = 9;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRepresentative = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRepresentative = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string symbol = 10;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string symbol_description = 11;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSymbolDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSymbolDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string cusip = 12;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional string asset_type = 13;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAssetType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAssetType = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};


/**
 * optional google.protobuf.Timestamp created_at = 14;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.Activity.prototype.getCreatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 14));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setCreatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearCreatedAt = function() {
  return this.setCreatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasCreatedAt = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * optional google.type.Date system_date = 15;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Activity.prototype.getSystemDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 15));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setSystemDate = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearSystemDate = function() {
  return this.setSystemDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasSystemDate = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional google.type.Date trade_date = 16;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Activity.prototype.getTradeDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 16));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setTradeDate = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearTradeDate = function() {
  return this.setTradeDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasTradeDate = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional google.protobuf.Timestamp trade_at = 17;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.Activity.prototype.getTradeAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 17));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setTradeAt = function(value) {
  return jspb.Message.setWrapperField(this, 17, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearTradeAt = function() {
  return this.setTradeAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasTradeAt = function() {
  return jspb.Message.getField(this, 17) != null;
};


/**
 * optional google.type.Date settle_date = 18;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.Activity.prototype.getSettleDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 18));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setSettleDate = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearSettleDate = function() {
  return this.setSettleDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasSettleDate = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string entry_type = 19;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional string side = 20;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSide = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSide = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional string qty = 21;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 21, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 21, value);
};


/**
 * optional string price = 22;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 22, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 22, value);
};


/**
 * optional string gross_amt = 23;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * optional string reg_fee = 24;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 24, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 24, value);
};


/**
 * optional string taf_fee = 25;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 25, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 25, value);
};


/**
 * optional string fees = 26;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 26, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 26, value);
};


/**
 * optional string net_amt = 27;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 27, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 27, value);
};


/**
 * optional string description = 28;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 28, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 28, value);
};


/**
 * optional string batch_no = 29;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getBatchNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 29, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setBatchNo = function(value) {
  return jspb.Message.setProto3StringField(this, 29, value);
};


/**
 * optional string previous_trn_id = 30;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getPreviousTrnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setPreviousTrnId = function(value) {
  return jspb.Message.setProto3StringField(this, 30, value);
};


/**
 * optional string status = 31;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional string created_by = 32;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCreatedBy = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 32, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCreatedBy = function(value) {
  return jspb.Message.setProto3StringField(this, 32, value);
};


/**
 * optional string trns_id = 33;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 33, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 33, value);
};


/**
 * optional string account_id = 34;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 34, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 34, value);
};


/**
 * optional string execution_id = 35;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExecutionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 35, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExecutionId = function(value) {
  return jspb.Message.setProto3StringField(this, 35, value);
};


/**
 * optional string executing_venue = 36;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExecutingVenue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExecutingVenue = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * optional string external_id = 37;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 37, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 37, value);
};


/**
 * optional string leaves_qty = 38;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLeavesQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 38, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLeavesQty = function(value) {
  return jspb.Message.setProto3StringField(this, 38, value);
};


/**
 * optional string order_id = 39;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 39, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 39, value);
};


/**
 * optional string vendor = 40;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getVendor = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 40, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setVendor = function(value) {
  return jspb.Message.setProto3StringField(this, 40, value);
};


/**
 * optional string contra_account_no = 41;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 41, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 41, value);
};


/**
 * optional string contra_account_designator = 42;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 42, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 42, value);
};


/**
 * optional string commission = 43;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 43, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 43, value);
};


/**
 * optional string contra_correspondent = 44;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 44, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 44, value);
};


/**
 * optional string contra_account_id = 45;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 45, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 45, value);
};


/**
 * optional string original_cusip = 46;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOriginalCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 46, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOriginalCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 46, value);
};


/**
 * optional string entry_type_description = 47;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEntryTypeDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEntryTypeDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string ref_id = 48;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getRefId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 48, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setRefId = function(value) {
  return jspb.Message.setProto3StringField(this, 48, value);
};


/**
 * optional string entry_sub_type = 49;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getEntrySubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 49, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setEntrySubType = function(value) {
  return jspb.Message.setProto3StringField(this, 49, value);
};


/**
 * optional string cum_qty = 50;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getCumQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 50, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setCumQty = function(value) {
  return jspb.Message.setProto3StringField(this, 50, value);
};


/**
 * optional string order_type = 51;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getOrderType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 51, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setOrderType = function(value) {
  return jspb.Message.setProto3StringField(this, 51, value);
};


/**
 * optional string tif = 52;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getTif = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 52, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setTif = function(value) {
  return jspb.Message.setProto3StringField(this, 52, value);
};


/**
 * optional string client_order_id = 53;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getClientOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 53, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setClientOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 53, value);
};


/**
 * optional string executing_broker_mpid = 54;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getExecutingBrokerMpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 54, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setExecutingBrokerMpid = function(value) {
  return jspb.Message.setProto3StringField(this, 54, value);
};


/**
 * optional string contra_symbol = 55;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getContraSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 55, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setContraSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 55, value);
};


/**
 * optional string swap_rate = 56;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSwapRate = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 56, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSwapRate = function(value) {
  return jspb.Message.setProto3StringField(this, 56, value);
};


/**
 * optional google.protobuf.Timestamp swap_rate_at = 57;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.Activity.prototype.getSwapRateAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 57));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setSwapRateAt = function(value) {
  return jspb.Message.setWrapperField(this, 57, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearSwapRateAt = function() {
  return this.setSwapRateAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasSwapRateAt = function() {
  return jspb.Message.getField(this, 57) != null;
};


/**
 * optional string local_price = 59;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLocalPrice = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 59, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLocalPrice = function(value) {
  return jspb.Message.setProto3StringField(this, 59, value);
};


/**
 * optional string local_net_amt = 60;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLocalNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 60, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLocalNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 60, value);
};


/**
 * optional string activity_id = 61;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getActivityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 61, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setActivityId = function(value) {
  return jspb.Message.setProto3StringField(this, 61, value);
};


/**
 * optional string local_gross_amt = 62;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getLocalGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 62, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setLocalGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 62, value);
};


/**
 * optional string swap_fee_bps_corr = 63;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSwapFeeBpsCorr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 63, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSwapFeeBpsCorr = function(value) {
  return jspb.Message.setProto3StringField(this, 63, value);
};


/**
 * optional string swap_fee_bps_alpa = 64;
 * @return {string}
 */
proto.reportpb.Activity.prototype.getSwapFeeBpsAlpa = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 64, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.setSwapFeeBpsAlpa = function(value) {
  return jspb.Message.setProto3StringField(this, 64, value);
};


/**
 * optional trnspb.Metadata metadata = 65;
 * @return {?proto.trnspb.Metadata}
 */
proto.reportpb.Activity.prototype.getMetadata = function() {
  return /** @type{?proto.trnspb.Metadata} */ (
    jspb.Message.getWrapperField(this, trnspb_transaction_pb.Metadata, 65));
};


/**
 * @param {?proto.trnspb.Metadata|undefined} value
 * @return {!proto.reportpb.Activity} returns this
*/
proto.reportpb.Activity.prototype.setMetadata = function(value) {
  return jspb.Message.setWrapperField(this, 65, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.Activity} returns this
 */
proto.reportpb.Activity.prototype.clearMetadata = function() {
  return this.setMetadata(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.Activity.prototype.hasMetadata = function() {
  return jspb.Message.getField(this, 65) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListActivityRequest.repeatedFields_ = [17,24];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivityRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivityRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivityRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    correspondent: jspb.Message.getFieldWithDefault(msg, 1, ""),
    accountNo: jspb.Message.getFieldWithDefault(msg, 2, ""),
    contraAccountNo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    masterAccountNo: jspb.Message.getFieldWithDefault(msg, 4, ""),
    accountDesignator: jspb.Message.getFieldWithDefault(msg, 5, ""),
    broker: jspb.Message.getFieldWithDefault(msg, 6, ""),
    type: jspb.Message.getFieldWithDefault(msg, 7, ""),
    symbol: jspb.Message.getFieldWithDefault(msg, 8, ""),
    cusip: jspb.Message.getFieldWithDefault(msg, 9, ""),
    status: jspb.Message.getFieldWithDefault(msg, 10, ""),
    dateType: jspb.Message.getFieldWithDefault(msg, 11, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    entryType: jspb.Message.getFieldWithDefault(msg, 14, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 15, ""),
    trnsId: jspb.Message.getFieldWithDefault(msg, 16, ""),
    excludedEntryTypesList: (f = jspb.Message.getRepeatedField(msg, 17)) == null ? undefined : f,
    pagination: (f = msg.getPagination()) && utilspb_pagination_pb.Pagination.toObject(includeInstance, f),
    externalId: jspb.Message.getFieldWithDefault(msg, 19, ""),
    fromTime: (f = msg.getFromTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    toTime: (f = msg.getToTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    additionalFilter: (f = msg.getAdditionalFilter()) && proto.reportpb.AdditionalFilter.toObject(includeInstance, f),
    entrySubType: jspb.Message.getFieldWithDefault(msg, 23, ""),
    entryTypesList: (f = jspb.Message.getRepeatedField(msg, 24)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivityRequest}
 */
proto.reportpb.ListActivityRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivityRequest;
  return proto.reportpb.ListActivityRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivityRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivityRequest}
 */
proto.reportpb.ListActivityRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCorrespondent(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountNo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setContraAccountNo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setMasterAccountNo(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountDesignator(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setBroker(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSymbol(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setCusip(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setStatus(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 12:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 13:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    case 14:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntryType(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 16:
      var value = /** @type {string} */ (reader.readString());
      msg.setTrnsId(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.addExcludedEntryTypes(value);
      break;
    case 18:
      var value = new utilspb_pagination_pb.Pagination;
      reader.readMessage(value,utilspb_pagination_pb.Pagination.deserializeBinaryFromReader);
      msg.setPagination(value);
      break;
    case 19:
      var value = /** @type {string} */ (reader.readString());
      msg.setExternalId(value);
      break;
    case 20:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setFromTime(value);
      break;
    case 21:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setToTime(value);
      break;
    case 22:
      var value = new proto.reportpb.AdditionalFilter;
      reader.readMessage(value,proto.reportpb.AdditionalFilter.deserializeBinaryFromReader);
      msg.setAdditionalFilter(value);
      break;
    case 23:
      var value = /** @type {string} */ (reader.readString());
      msg.setEntrySubType(value);
      break;
    case 24:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntryTypes(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivityRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivityRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivityRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorrespondent();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAccountNo();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getContraAccountNo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMasterAccountNo();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getAccountDesignator();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getBroker();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSymbol();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getCusip();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getStatus();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      13,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getEntryType();
  if (f.length > 0) {
    writer.writeString(
      14,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getTrnsId();
  if (f.length > 0) {
    writer.writeString(
      16,
      f
    );
  }
  f = message.getExcludedEntryTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      17,
      f
    );
  }
  f = message.getPagination();
  if (f != null) {
    writer.writeMessage(
      18,
      f,
      utilspb_pagination_pb.Pagination.serializeBinaryToWriter
    );
  }
  f = message.getExternalId();
  if (f.length > 0) {
    writer.writeString(
      19,
      f
    );
  }
  f = message.getFromTime();
  if (f != null) {
    writer.writeMessage(
      20,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getToTime();
  if (f != null) {
    writer.writeMessage(
      21,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getAdditionalFilter();
  if (f != null) {
    writer.writeMessage(
      22,
      f,
      proto.reportpb.AdditionalFilter.serializeBinaryToWriter
    );
  }
  f = message.getEntrySubType();
  if (f.length > 0) {
    writer.writeString(
      23,
      f
    );
  }
  f = message.getEntryTypesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      24,
      f
    );
  }
};


/**
 * optional string correspondent = 1;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getCorrespondent = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCorrespondent = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string account_no = 2;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string contra_account_no = 3;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getContraAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setContraAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string master_account_no = 4;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getMasterAccountNo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setMasterAccountNo = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string account_designator = 5;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getAccountDesignator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setAccountDesignator = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string broker = 6;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getBroker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setBroker = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string type = 7;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string symbol = 8;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string cusip = 9;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getCusip = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setCusip = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional string status = 10;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getStatus = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setStatus = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string date_type = 11;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional google.type.Date from_date = 12;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListActivityRequest.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 12));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 12) != null;
};


/**
 * optional google.type.Date to_date = 13;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.ListActivityRequest.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 13));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 13, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 13) != null;
};


/**
 * optional string entry_type = 14;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getEntryType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 14, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setEntryType = function(value) {
  return jspb.Message.setProto3StringField(this, 14, value);
};


/**
 * optional string net_amt = 15;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional string trns_id = 16;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getTrnsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 16, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setTrnsId = function(value) {
  return jspb.Message.setProto3StringField(this, 16, value);
};


/**
 * repeated string excluded_entry_types = 17;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivityRequest.prototype.getExcludedEntryTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 17));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setExcludedEntryTypesList = function(value) {
  return jspb.Message.setField(this, 17, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.addExcludedEntryTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 17, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearExcludedEntryTypesList = function() {
  return this.setExcludedEntryTypesList([]);
};


/**
 * optional utilspb.Pagination pagination = 18;
 * @return {?proto.utilspb.Pagination}
 */
proto.reportpb.ListActivityRequest.prototype.getPagination = function() {
  return /** @type{?proto.utilspb.Pagination} */ (
    jspb.Message.getWrapperField(this, utilspb_pagination_pb.Pagination, 18));
};


/**
 * @param {?proto.utilspb.Pagination|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setPagination = function(value) {
  return jspb.Message.setWrapperField(this, 18, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearPagination = function() {
  return this.setPagination(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasPagination = function() {
  return jspb.Message.getField(this, 18) != null;
};


/**
 * optional string external_id = 19;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getExternalId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 19, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setExternalId = function(value) {
  return jspb.Message.setProto3StringField(this, 19, value);
};


/**
 * optional google.protobuf.Timestamp from_time = 20;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.ListActivityRequest.prototype.getFromTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 20));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setFromTime = function(value) {
  return jspb.Message.setWrapperField(this, 20, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearFromTime = function() {
  return this.setFromTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasFromTime = function() {
  return jspb.Message.getField(this, 20) != null;
};


/**
 * optional google.protobuf.Timestamp to_time = 21;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.reportpb.ListActivityRequest.prototype.getToTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 21));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setToTime = function(value) {
  return jspb.Message.setWrapperField(this, 21, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearToTime = function() {
  return this.setToTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasToTime = function() {
  return jspb.Message.getField(this, 21) != null;
};


/**
 * optional AdditionalFilter additional_filter = 22;
 * @return {?proto.reportpb.AdditionalFilter}
 */
proto.reportpb.ListActivityRequest.prototype.getAdditionalFilter = function() {
  return /** @type{?proto.reportpb.AdditionalFilter} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.AdditionalFilter, 22));
};


/**
 * @param {?proto.reportpb.AdditionalFilter|undefined} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
*/
proto.reportpb.ListActivityRequest.prototype.setAdditionalFilter = function(value) {
  return jspb.Message.setWrapperField(this, 22, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearAdditionalFilter = function() {
  return this.setAdditionalFilter(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityRequest.prototype.hasAdditionalFilter = function() {
  return jspb.Message.getField(this, 22) != null;
};


/**
 * optional string entry_sub_type = 23;
 * @return {string}
 */
proto.reportpb.ListActivityRequest.prototype.getEntrySubType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 23, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setEntrySubType = function(value) {
  return jspb.Message.setProto3StringField(this, 23, value);
};


/**
 * repeated string entry_types = 24;
 * @return {!Array<string>}
 */
proto.reportpb.ListActivityRequest.prototype.getEntryTypesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 24));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.setEntryTypesList = function(value) {
  return jspb.Message.setField(this, 24, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.addEntryTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 24, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivityRequest} returns this
 */
proto.reportpb.ListActivityRequest.prototype.clearEntryTypesList = function() {
  return this.setEntryTypesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.AdditionalFilter.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.AdditionalFilter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.AdditionalFilter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AdditionalFilter.toObject = function(includeInstance, msg) {
  var f, obj = {
    dateType: jspb.Message.getFieldWithDefault(msg, 1, ""),
    fromDate: (f = msg.getFromDate()) && google_type_date_pb.Date.toObject(includeInstance, f),
    toDate: (f = msg.getToDate()) && google_type_date_pb.Date.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.AdditionalFilter}
 */
proto.reportpb.AdditionalFilter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.AdditionalFilter;
  return proto.reportpb.AdditionalFilter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.AdditionalFilter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.AdditionalFilter}
 */
proto.reportpb.AdditionalFilter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDateType(value);
      break;
    case 2:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setFromDate(value);
      break;
    case 3:
      var value = new google_type_date_pb.Date;
      reader.readMessage(value,google_type_date_pb.Date.deserializeBinaryFromReader);
      msg.setToDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.AdditionalFilter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.AdditionalFilter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.AdditionalFilter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.AdditionalFilter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDateType();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFromDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
  f = message.getToDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_type_date_pb.Date.serializeBinaryToWriter
    );
  }
};


/**
 * optional string date_type = 1;
 * @return {string}
 */
proto.reportpb.AdditionalFilter.prototype.getDateType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.AdditionalFilter} returns this
 */
proto.reportpb.AdditionalFilter.prototype.setDateType = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.type.Date from_date = 2;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.AdditionalFilter.prototype.getFromDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 2));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.AdditionalFilter} returns this
*/
proto.reportpb.AdditionalFilter.prototype.setFromDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.AdditionalFilter} returns this
 */
proto.reportpb.AdditionalFilter.prototype.clearFromDate = function() {
  return this.setFromDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.AdditionalFilter.prototype.hasFromDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.type.Date to_date = 3;
 * @return {?proto.google.type.Date}
 */
proto.reportpb.AdditionalFilter.prototype.getToDate = function() {
  return /** @type{?proto.google.type.Date} */ (
    jspb.Message.getWrapperField(this, google_type_date_pb.Date, 3));
};


/**
 * @param {?proto.google.type.Date|undefined} value
 * @return {!proto.reportpb.AdditionalFilter} returns this
*/
proto.reportpb.AdditionalFilter.prototype.setToDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.AdditionalFilter} returns this
 */
proto.reportpb.AdditionalFilter.prototype.clearToDate = function() {
  return this.setToDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.AdditionalFilter.prototype.hasToDate = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.reportpb.ListActivityResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivityResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivityResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivityResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    activitiesList: jspb.Message.toObjectList(msg.getActivitiesList(),
    proto.reportpb.Activity.toObject, includeInstance),
    summary: (f = msg.getSummary()) && proto.reportpb.ListActivitySummary.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivityResponse}
 */
proto.reportpb.ListActivityResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivityResponse;
  return proto.reportpb.ListActivityResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivityResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivityResponse}
 */
proto.reportpb.ListActivityResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.reportpb.Activity;
      reader.readMessage(value,proto.reportpb.Activity.deserializeBinaryFromReader);
      msg.addActivities(value);
      break;
    case 2:
      var value = new proto.reportpb.ListActivitySummary;
      reader.readMessage(value,proto.reportpb.ListActivitySummary.deserializeBinaryFromReader);
      msg.setSummary(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivityResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivityResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivityResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivityResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getActivitiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.reportpb.Activity.serializeBinaryToWriter
    );
  }
  f = message.getSummary();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.reportpb.ListActivitySummary.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Activity activities = 1;
 * @return {!Array<!proto.reportpb.Activity>}
 */
proto.reportpb.ListActivityResponse.prototype.getActivitiesList = function() {
  return /** @type{!Array<!proto.reportpb.Activity>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.reportpb.Activity, 1));
};


/**
 * @param {!Array<!proto.reportpb.Activity>} value
 * @return {!proto.reportpb.ListActivityResponse} returns this
*/
proto.reportpb.ListActivityResponse.prototype.setActivitiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.reportpb.Activity=} opt_value
 * @param {number=} opt_index
 * @return {!proto.reportpb.Activity}
 */
proto.reportpb.ListActivityResponse.prototype.addActivities = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.reportpb.Activity, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.clearActivitiesList = function() {
  return this.setActivitiesList([]);
};


/**
 * optional ListActivitySummary summary = 2;
 * @return {?proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivityResponse.prototype.getSummary = function() {
  return /** @type{?proto.reportpb.ListActivitySummary} */ (
    jspb.Message.getWrapperField(this, proto.reportpb.ListActivitySummary, 2));
};


/**
 * @param {?proto.reportpb.ListActivitySummary|undefined} value
 * @return {!proto.reportpb.ListActivityResponse} returns this
*/
proto.reportpb.ListActivityResponse.prototype.setSummary = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.reportpb.ListActivityResponse} returns this
 */
proto.reportpb.ListActivityResponse.prototype.clearSummary = function() {
  return this.setSummary(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.reportpb.ListActivityResponse.prototype.hasSummary = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.reportpb.ListActivitySummary.prototype.toObject = function(opt_includeInstance) {
  return proto.reportpb.ListActivitySummary.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.reportpb.ListActivitySummary} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitySummary.toObject = function(includeInstance, msg) {
  var f, obj = {
    totalRows: jspb.Message.getFieldWithDefault(msg, 1, 0),
    qty: jspb.Message.getFieldWithDefault(msg, 2, ""),
    grossAmt: jspb.Message.getFieldWithDefault(msg, 3, ""),
    regFee: jspb.Message.getFieldWithDefault(msg, 4, ""),
    tafFee: jspb.Message.getFieldWithDefault(msg, 5, ""),
    commission: jspb.Message.getFieldWithDefault(msg, 6, ""),
    fees: jspb.Message.getFieldWithDefault(msg, 7, ""),
    netAmt: jspb.Message.getFieldWithDefault(msg, 8, ""),
    leavesQty: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivitySummary.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.reportpb.ListActivitySummary;
  return proto.reportpb.ListActivitySummary.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.reportpb.ListActivitySummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.reportpb.ListActivitySummary}
 */
proto.reportpb.ListActivitySummary.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setTotalRows(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setQty(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setGrossAmt(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegFee(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTafFee(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCommission(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFees(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setNetAmt(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setLeavesQty(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.reportpb.ListActivitySummary.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.reportpb.ListActivitySummary.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.reportpb.ListActivitySummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.reportpb.ListActivitySummary.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTotalRows();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getQty();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getGrossAmt();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getRegFee();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getTafFee();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCommission();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFees();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getNetAmt();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getLeavesQty();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional uint32 total_rows = 1;
 * @return {number}
 */
proto.reportpb.ListActivitySummary.prototype.getTotalRows = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setTotalRows = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string qty = 2;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setQty = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string gross_amt = 3;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getGrossAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setGrossAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string reg_fee = 4;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getRegFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setRegFee = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string taf_fee = 5;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getTafFee = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setTafFee = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string commission = 6;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getCommission = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setCommission = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string fees = 7;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getFees = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setFees = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string net_amt = 8;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getNetAmt = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setNetAmt = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string leaves_qty = 9;
 * @return {string}
 */
proto.reportpb.ListActivitySummary.prototype.getLeavesQty = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.reportpb.ListActivitySummary} returns this
 */
proto.reportpb.ListActivitySummary.prototype.setLeavesQty = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


goog.object.extend(exports, proto.reportpb);
